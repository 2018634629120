import AsyncStorage from '@react-native-async-storage/async-storage'
import axios from 'axios'
// import * as Amplitude from 'expo-analytics-amplitude'
import Constants from 'expo-constants'
import * as Device from 'expo-device'
import * as Localization from 'expo-localization'
import moment from 'moment/moment'
import {Dimensions, Platform} from 'react-native'
import ZohoSalesIQ from '../nativeModules/ZohoSalesIQ'
import {getAppVersion, getInstallationId, hv, platformChecker} from '../utils'
import * as ExpoManifest from './../../app.json'
import {sdkSentry} from './sentry'

// var AWS = require('aws-sdk/dist/aws-sdk-react-native')
//
// AWS.config.update({
//   region: 'us-west-2',
//   accessKeyId: 'AKIAI3B5DDEK5TIDXFLQ',
//   secretAccessKey: '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'
// })

export const EventLogger = {
  async context() {
    let count = Localization.region
    let locale = Localization.locale
    let timezone = Localization.timezone
    let net = null
    let windows = Dimensions.get('window')
    // let agent = await Constants.getWebViewUserAgentAsync()
    let agent = ''
    let manifest = Constants.manifest
    let installation_id = await getInstallationId()

    let _data = {
      app: {
        app_version: ExpoManifest.expo?.version ?? null,
        sdk_version: manifest?.sdkVersion ?? null,
        exp_version: Constants.expoVersion ?? null,
        owner: Constants.appOwnership
      },
      device: {
        device_id: installation_id,
        device_name: Constants.deviceName,
        device_year_class: Device.deviceYearClass,
        is_device: Device.isDevice,
        platform: Constants.platform,
        os_name: Platform.OS,
        os_version: Platform.Version,
        windows: windows
      },
      session_id: Constants.sessionId,
      country: count,
      locale: locale,
      timezone: timezone,
      network: net,
      user_agent: agent
    }

    return JSON.parse(JSON.stringify(_data))
  },

  _setSentryUser(user: any) {
    if (user) {
      sdkSentry.lib().setUser({
        id: user?.id ?? '',
        name: user?.name ?? '',
        email: user?.email ?? '',
        username: user?.username ?? ''
      })

      sdkSentry.lib().setTag('team_id', user?.current_team_id ?? '')
      sdkSentry.lib().setTag('team_name', user?.team?.name ?? '')
      sdkSentry.lib().setTag('team_schema', user?.team?.schema_name ?? '')
    }
  },

  async setUser(user: any) {
    if (platformChecker.isNative) {
      // await Amplitude.setUserIdAsync(user?.id?.toString())
      // await Amplitude.setUserPropertiesAsync(user)
      await ZohoSalesIQ.setUser(user)
    }
    EventLogger._setSentryUser(user)
  },

  async user() {
    let me = await AsyncStorage.getItem('me')
    let data: any = {}

    if (me) {
      data = JSON.parse(me)
    }

    return {
      id: hv(data, 'id') ? data.id : 'null',
      name: hv(data, 'name') ? data.name : 'null',
      team_id: hv(data, 'team.id') ? data.team.id : 'null',
      team_schema: data?.team?.schema_name ?? 'null'
    }
  },

  async logEvent(event_name: string, event_type: string, object_id: string | number = 'null', object_type = 'null') {
    try {
      if (platformChecker.isWeb) return

      const context = await EventLogger.context()
      const user = await EventLogger.user()

      const data = {
        team_id: '' + user.team_id,
        event_name: event_name,
        event_type: event_type,
        timestamp: moment.utc().format(),
        user_id: user.id,
        user_name: user.name,
        object_id: object_id,
        object_type: object_type,
        context: context
      }

      axios({
        method: 'post',
        url: 'https://events.condoid.com.br/v1',
        data: data
      })

      if (platformChecker.isNative) {
        // await Amplitude.logEventWithPropertiesAsync(event_name, { data: data })
      }

    } catch (e) {
      console.warn(e)
      sdkSentry.sendSentry(e, '')
    }
  },

  async debugLog(message: any) {
    try {
      if (platformChecker.isWeb) return

      const context = await EventLogger.context()
      const user = await EventLogger.user()

      let data = {
        'ddsource': 'native-app',
        'ddtags': 'version:' + getAppVersion(),
        'message': {
          message_data: message
        },
        'service': 'condoid-mobile',
        //
        team_id: '' + user.team_id,
        user_id: user.id,
        user_name: user.name,
        context: context
      }

      await axios({
        method: 'post',
        url: 'https://http-intake.logs.datadoghq.com/v1/input',
        data: data,
        headers: {
          'Content-Type': 'application/json',
          'DD-API-KEY': '7c66da993c88dcbb9c23eca7064ec972'
        }
      })

    } catch (e) {
      console.warn(e)
      sdkSentry.sendSentry(e, '')
    }
  }
}
