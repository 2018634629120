import {createNavigationContainerRef, DrawerActions, NavigationState} from '@react-navigation/native'
import {hasPerm} from '../components/acl'
import InMessagingManager from './InMessagingManager'

export const navigationRef = createNavigationContainerRef()

function navigate(name: any, params: any = {}) {
  if (navigationRef.isReady()) {
    // @ts-ignore
    navigationRef.navigate(name, params)
  }
}

function openDrawer() {
  if (navigationRef.isReady()) {
    navigationRef.dispatch(DrawerActions.openDrawer())
  }
}

export const listenRouteChange = (currentState: NavigationState | undefined) => {
  if (navigationRef && navigationRef.isReady()) {
    // @ts-ignore
    const currentScreen = navigationRef.getCurrentRoute().name

    InMessagingManager.getMessages()
      .then(() => {
        InMessagingManager.show('SeeScreen', currentScreen)
      })
      .catch(() => {
        InMessagingManager.show('SeeScreen', currentScreen)
      })

    switch (currentScreen) {
      case 'ResidentNewScreen--':
        if (!hasPerm(['add_person'])) {
          navigate('HomeScreen')
        }
        break
    }
  }
}

export default {
  navigate,
  openDrawer
}