import Ionicons from '@expo/vector-icons/Ionicons'
import moment from 'moment'
import React, {Component} from 'react'
import {Platform, StyleSheet, Text, TouchableOpacity, View} from 'react-native'
import {Day, GiftedChat, MessageImage, MessageText, Send, Time, utils} from 'react-native-gifted-chat'
import {Appbar, Button, Modal, Portal} from 'react-native-paper'
import {SafeAreaInsetsContext} from 'react-native-safe-area-context'
import {connect} from 'react-redux'
import {MODELTYPE_TYPE_ID} from '../constants/model_types'
import {actionMap as actions} from '../redux/modules/comments'
import Style from '../styles/Style'
import {theme_app} from '../styles/theme'

const { isSameUser, isSameDay } = utils

class Bubble extends React.Component {
  constructor(props) {
    super(props)
    this.onLongPress = this.onLongPress.bind(this)
  }

  onLongPress() {
    // if (this.props.onLongPress) {
    //   this.props.onLongPress(this.context)
    // } else if (this.props.currentMessage.text) {
    //   const options = [
    //     'Copy Text',
    //     'Cancel',
    //   ]
    //   const cancelButtonIndex = options.length - 1
    //   this.context.actionSheet().showActionSheetWithOptions(
    //     {options, cancelButtonIndex},
    //     (buttonIndex) => {
    //       if (buttonIndex === 0) {
    //         Clipboard.setString(this.props.currentMessage.text)
    //       }
    //     })
    // }
  }

  renderMessageText() {
    if (this.props.currentMessage.text) {
      const { containerStyle, wrapperStyle, messageTextStyle, ...messageTextProps } = this.props
      if (this.props.renderMessageText) {
        return this.props.renderMessageText(messageTextProps)
      }
      return (
        <MessageText
          {...messageTextProps}
          textStyle={{
            left: [styles.standardFont, styles.slackMessageText, messageTextProps.textStyle, messageTextStyle]
          }}
        />
      )
    }
    return null
  }

  renderMessageImage() {
    if (this.props.currentMessage.image) {
      const { containerStyle, wrapperStyle, ...messageImageProps } = this.props
      if (this.props.renderMessageImage) {
        return this.props.renderMessageImage(messageImageProps)
      }
      return <MessageImage {...messageImageProps} imageStyle={[styles.slackImage, messageImageProps.imageStyle]}/>
    }
    return null
  }

  renderTicks() {
    const { currentMessage } = this.props
    if (this.props.renderTicks) {
      return this.props.renderTicks(currentMessage)
    }
    if (currentMessage.user._id !== this.props.user._id) {
      return null
    }
    if (currentMessage.sent || currentMessage.received) {
      return (
        <View style={[styles.headerItem, styles.tickView]}>
          {currentMessage.sent && <Text style={[styles.standardFont, styles.tick, this.props.tickStyle]}>✓</Text>}
          {currentMessage.received && <Text style={[styles.standardFont, styles.tick, this.props.tickStyle]}>✓</Text>}
        </View>
      )
    }
    return null
  }

  renderUsername() {
    const username = this.props.currentMessage.user.name
    if (username) {
      const { containerStyle, wrapperStyle, ...usernameProps } = this.props
      if (this.props.renderUsername) {
        return this.props.renderUsername(usernameProps)
      }
      return (
        <Text style={[styles.standardFont, styles.headerItem, styles.username, this.props.usernameStyle]}>
          {username}
        </Text>
      )
    }
    return null
  }

  renderTime() {
    if (this.props.currentMessage.createdAt) {
      const { containerStyle, wrapperStyle, ...timeProps } = this.props
      if (this.props.renderTime) {
        return this.props.renderTime(timeProps)
      }
      return (
        <Time
          {...timeProps}
          timeFormat="HH:mm"
          containerStyle={{ left: [styles.timeContainer] }}
          textStyle={{ left: [styles.standardFont, styles.headerItem, styles.time, timeProps.textStyle] }}
        />
      )
    }
    return null
  }

  renderCustomView() {
    if (this.props.renderCustomView) {
      return this.props.renderCustomView(this.props)
    }
    return null
  }

  render() {
    const isSameThread = isSameUser(this.props.currentMessage, this.props.previousMessage)
      && isSameDay(this.props.currentMessage, this.props.previousMessage)

    const messageHeader = (
      <View style={styles.headerView}>
        {this.renderUsername()}
        {this.renderTime()}
        {this.renderTicks()}
      </View>
    )

    return (
      <View style={[
        {
          flex: 1,
          alignItems: 'flex-start'
        }, this.props.containerStyle]}>
        <TouchableOpacity
          onLongPress={this.onLongPress}
          accessibilityTraits="text"
          {...this.props.touchableProps}
        >
          <View
            style={[
              styles.wrapper,
              this.props.wrapperStyle
            ]}
          >
            <View>
              {this.renderCustomView()}
              {messageHeader}
              {this.renderMessageImage()}
              {this.renderMessageText()}
            </View>
          </View>
        </TouchableOpacity>
      </View>
    )
  }
}

class Message extends React.Component {
  getInnerComponentProps() {
    const { containerStyle, ...props } = this.props
    return {
      ...props,
      position: 'left',
      isSameUser,
      isSameDay
    }
  }

  renderDay() {
    if (this.props.currentMessage.createdAt) {
      const dayProps = this.getInnerComponentProps()
      if (this.props.renderDay) {
        return this.props.renderDay(dayProps)
      }
      return <Day {...dayProps} />
    }
    return null
  }

  renderBubble() {
    const bubbleProps = this.getInnerComponentProps()
    if (this.props.renderBubble) {
      return this.props.renderBubble(bubbleProps)
    }
    return <Bubble {...bubbleProps} />
  }

  renderAvatar() {
    // let extraStyle
    // if (
    //   isSameUser(this.props.currentMessage, this.props.previousMessage)
    //   && isSameDay(this.props.currentMessage, this.props.previousMessage)
    // ) {
    //   // Set the invisible avatar height to 0, but keep the width, padding, etc.
    //   extraStyle = {height: 0}
    // }
    //
    // const avatarProps = this.getInnerComponentProps()
    // return (
    //   <Avatar
    //     {...avatarProps}
    //     imageStyle={{left: [styles.slackAvatar, avatarProps.imageStyle, extraStyle]}}
    //   />
    // )
  }

  render() {
    const marginBottom = isSameUser(this.props.currentMessage, this.props.nextMessage) ? 2 : 10

    return (
      <View>
        {this.renderDay()}
        <View
          style={[
            styles.container,
            { marginBottom },
            this.props.containerStyle
          ]}
        >
          {/*{this.renderAvatar()}*/}
          {this.renderBubble()}
        </View>
      </View>
    )
  }

}

class CommentsBox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalVisible: false,
      messages: []
    }
  }

  componentDidMount() {
    this.getData()

    let messages = this.generateComments()
    this.setState({ messages })
  }

  componentWillUnmount() {
    this.props.resetComments()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (JSON.stringify(prevProps.comments) !== JSON.stringify(this.props.comments)) {
      let messages = this.generateComments()
      this.setState({ messages })
    }
  }

  getData = () => {
    let url = this.getUrl()

    this.props.getObjects(url)
  }

  getUrl = () => {
    const data = {
      commentable_id: this.props.commentable_id,
      commentable_type: this.props.commentable_type
    }
    return `?filter{object_id}=${data.commentable_id}&filter{object_type_id}=${MODELTYPE_TYPE_ID[data.commentable_type]}&include[]=created_by`
  }

  generateComments = () => {
    let comments = []
    if (this.props.comments.length > 0) {
      for (let i of this.props.comments) {
        comments.push({
          _id: i.id,
          text: i.body,
          createdAt: moment(i.created_at),
          user: {
            _id: i.created_by.id,
            name: i.who
          }
        })
      }
    }
    return comments
  }

  onSend = (messages = []) => {
    let message = {
      body: messages[0].text,
      commentable: {
        id: this.props.commentable_id,
        type: this.props.commentable_type
      },
      type: 2,
      url: this.getUrl()
    }

    this.props.postObject(message)
    // this.setState(previousState => ({
    //   messages: GiftedChat.append(previousState.messages, messages),
    // }))
  }

  renderMessage = (props) => {
    return (
      <Message {...props}/>
    )
  }

  renderSend = (props) => {
    return (
      <Send{...props}      >
        <View style={{ marginRight: 10, marginBottom: 5 }}>
          <Ionicons name="ios-send" size={38} color={theme_app().colors.primary}/>
        </View>
      </Send>
    )
  }

  showModal = () => {
    this.getData()
    this.setState({ modalVisible: true })
  }

  hideModal = () => {
    this.props.resetComments()
    this.setState({ modalVisible: false })
  }

  render() {
    let { modalVisible } = this.state

    return (
      modalVisible === true ?
        <Portal>
          <Modal visible={modalVisible}>
            <SafeAreaInsetsContext.Consumer>
              {(insets) => (
                <View style={{ backgroundColor: '#ffffff', height: Style.DEVICE_HEIGHT, paddingBottom: insets.bottom }}>
                  <Appbar.Header statusBarHeight={insets.top}>
                    <Appbar.BackAction onPress={this.hideModal}/>
                    <Appbar.Content title={'Comentários'}/>
                  </Appbar.Header>

                  <GiftedChat
                    messages={this.state.messages}
                    onSend={messages => this.onSend(messages)}
                    user={{
                      _id: 0,
                      name: 'Usuário'
                    }}
                    renderMessage={this.renderMessage}
                    renderSend={this.renderSend}
                    locale={'pt'}
                    placeholder={'Digite a mensagem...'}
                  />
                  {/*{Platform.OS === 'android' ? <KeyboardSpacer/> : null}*/}
                </View>
              )}
            </SafeAreaInsetsContext.Consumer>
          </Modal>
        </Portal>
        :
        <Button icon={'android-messages'} mode="outlined" onPress={this.showModal}>
          Comentários
        </Button>
    )
  }
}

function mapStateToProps(state) {
  return {
    comments: state.comments.data
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getObjects: (data) => dispatch(actions.all(data)),
    resetComments: () => dispatch(actions.all_success([])),
    currentObject: (data) => dispatch(actions.current(data)),
    postObject: (data) => dispatch(actions.post(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentsBox)

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    marginLeft: 8,
    marginRight: 0
  },
  slackAvatar: {
    // The bottom should roughly line up with the first line of message text.
    height: 40,
    width: 40,
    borderRadius: 3
  },

  standardFont: {
    fontSize: 15
  },
  slackMessageText: {
    marginLeft: 0,
    marginRight: 0
  },
  wrapper: {
    marginRight: 60,
    minHeight: 20,
    justifyContent: 'flex-end'
  },
  username: {
    fontWeight: 'bold'
  },
  time: {
    textAlign: 'left',
    fontSize: 12
  },
  timeContainer: {
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0
  },
  headerItem: {
    marginRight: 10
  },
  headerView: {
    // Try to align it better with the avatar on Android.
    marginTop: Platform.OS === 'android' ? -2 : 0,
    flexDirection: 'row',
    alignItems: 'baseline'
  },
  /* eslint-disable react-native/no-color-literals */
  tick: {
    backgroundColor: 'transparent',
    color: 'white'
  },
  /* eslint-enable react-native/no-color-literals */
  tickView: {
    flexDirection: 'row'
  },
  slackImage: {
    borderRadius: 3,
    marginLeft: 0,
    marginRight: 0
  }
})
