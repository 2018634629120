import {FontAwesome} from '@expo/vector-icons'
import * as DocumentPicker from 'expo-document-picker'
import * as ImageManipulator from 'expo-image-manipulator'
import * as ImagePicker from 'expo-image-picker'
import * as mime from 'mime-types'
import React from 'react'
import {Alert, Dimensions, Image, ImageBackground, ScrollView, StyleSheet, View} from 'react-native'
import {Appbar, Banner, Button, FAB, Modal, Portal, Text, TouchableRipple} from 'react-native-paper'
import {SafeAreaInsetsContext} from 'react-native-safe-area-context'
import {connect} from 'react-redux'
import {MODELTYPE_TYPE_ID} from '../../constants/model_types'
import {sending_request} from '../../redux/actions'
import {actionMap as actions} from '../../redux/modules/files'
import {uploadS3} from '../../services/api'
import ToasterManager from '../../services/ToasterManager'
import Style from '../../styles/Style'
import {platformChecker} from '../../utils'
import Spacer from '../Spacer'

const win = Dimensions.get('window')

class Upploader extends React.Component {
  constructor() {
    super()
    this.state = {
      modalVisible: false,
      bannerVisible: true,
      upload_queue: [],

      // IMG CROPPER
      currentFile: null,
      currentImageB64: null,
      isFilePickerVisible: false,
      imageWidth: 1300,
      imageHeight: 953,
      initialImage: null,
      rectangleCoordinates: {
        topLeft: { x: 232, y: 192 }, topRight: { x: 1070, y: 166 }, bottomRight: { x: 1150, y: 883 }, bottomLeft: { x: 144, y: 883 }
      }
    }
  }

  componentWillUnmount() {
    this.props.clearAll()
  }

  componentDidMount() {
    this.props.clearAll()

    this.getModels()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.model_id !== prevProps.model_id) {
      this.getModels()
    }
  }

  getModels = () => {
    let id = this.props.model_id
    let type = this.props.model_type

    if (id && type) {
      let url = `?filter{object_id}=${id}&filter{object_type_id}=${MODELTYPE_TYPE_ID[type]}`
      this.props.getObjects(url)
    }
  }

  selectFile = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: '*/*'
    })

    if (result.type === 'success') {
      let queue = this.state.upload_queue
      queue.push(result)
      this.setState({ upload_queue: queue })
    }
  }

  manipulateImage = async (pickerResult) => {
    let result = await ImageManipulator.manipulateAsync(pickerResult.uri, [{ resize: { width: 1000 } }], { format: 'jpeg' })

    let content_type = mime.lookup(result.uri) || 'image/jpeg'
    let file_extension = mime.extension(content_type)

    result['name'] = `photo.${file_extension}`
    result['file_type'] = content_type
    result['ext'] = file_extension

    let queue = this.state.upload_queue
    queue.push(result)
    this.setState({ upload_queue: queue })
  }

  takePhoto = async () => {
    const { status } = await ImagePicker.requestCameraPermissionsAsync()
    await ImagePicker.requestMediaLibraryPermissionsAsync()

    if (status === 'granted') {
      let pickerResult = await ImagePicker.launchCameraAsync({
        allowsEditing: true,
        quality: 0.8
      })

      if (!pickerResult.cancelled) {
        await this.manipulateImage(pickerResult)
      }
    } else {
      Alert.alert('Por favor autorize o acesso a camera e tente novamente!')
    }
  }

  selectPhoto = async () => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync()
    await ImagePicker.requestCameraPermissionsAsync()

    if (status === 'granted') {
      let pickerResult = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        quality: 0.8
      })

      if (!pickerResult.cancelled) {
        await this.manipulateImage(pickerResult)
      }
    } else {
      Alert.alert('Por favor autorize o acesso a camera e tente novamente!')
    }
  }

  uploadFileAsync = async (file) => {
    let file_create_res

    if (platformChecker.isWeb) {
      file_create_res = await uploadS3(file.file, this.props.file_type, file.name)
    } else {
      file_create_res = await uploadS3(file.uri, this.props.file_type, file.name)
    }

    console.log('file_create_res', file_create_res)

    return file_create_res
  }

  uploadAll = async () => {
    let { upload_queue } = this.state
    let uploaded_names = []

    if (upload_queue.length > 0) {
      this.setModalVisible(false)

      for (let i of upload_queue) {
        this.props.sendingRequest(true)

        await this.uploadFileAsync(i)
          .then(res => {
            uploaded_names.push(i.name)
            this.props.uploadComplete(res)
          })
          .catch(err => {
            console.log(err)
            this.props.sendingRequest(false)
            ToasterManager.error('Erro ao enviar os arquivos!')
          })
      }

      let new_queue = upload_queue.filter(q => uploaded_names.indexOf(q.name) === -1)

      this.setState({ upload_queue: new_queue })

      this.props.sendingRequest(false)
      ToasterManager.success('Arquivos enviados, clique em salvar para finalizar!')
    }
  }

  setModalVisible = (visible) => this.setState({ modalVisible: visible })

  removeQueueFile = (index) => {
    let { upload_queue } = this.state
    upload_queue.splice(index, 1)
    this.setState({ upload_queue: upload_queue })
  }

  getFileNameAndExtension = (fullFileName) => {
    let re = /(?:\.([^.]+))?$/
    let fileExt = re.exec(fullFileName)[1]
    let fileName = fullFileName.replace('.' + fileExt, '')
    return {
      name: fileName, extension: fileExt
    }
  }

  render() {
    let { upload_queue } = this.state
    let { uploaded_files } = this.props

    const FileThumbnail = ({ item }) => {
      let { name, extension } = this.getFileNameAndExtension(item.name)

      if (['bmp', 'png', 'jpg', 'jpeg', 'gif'].includes(extension)) {
        return (<Image
          key={item.id}
          style={{ width: win.width * 0.3, height: win.width * 0.3, margin: win.width * 0.015 }}
          source={{ uri: item.file }}
        />)
      }

      return (<View style={{
        width: win.width * 0.3,
        height: win.width * 0.3,
        margin: win.width * 0.015,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 2,
        backgroundColor: '#e3e3e3'
      }}>
        <FontAwesome name="file-pdf-o" size={40} color="#BFBFBF"/>
      </View>)
    }

    return (this.state.modalVisible === true ? <Portal>
      <Modal visible={this.state.modalVisible}>
        <View style={{ height: Style.DEVICE_HEIGHT }}>
          <SafeAreaInsetsContext.Consumer>
            {(insets) => (<Appbar.Header statusBarHeight={insets.top}>
              <Appbar.Action icon="cancel" onPress={() => this.setModalVisible(false)}/>
              <Appbar.Content title=" "/>
              <Appbar.Action icon="check-circle" onPress={this.uploadAll}/>
            </Appbar.Header>)}
          </SafeAreaInsetsContext.Consumer>

          <ScrollView style={{ backgroundColor: '#ffffff' }}>
            {upload_queue.length === 0 && <Banner visible={this.state.bannerVisible}
                                                  actions={[{ label: 'Ok', onPress: () => this.setState({ bannerVisible: false }) }]}>
              Toque no botão redondo "+" para anexar arquivos. Após a seleção toque no ícone acima ✔️ para Finalizar ou ✖️ Cancelar a
              seleção. 😃👏
            </Banner>}

            {uploaded_files.length > 0 && <React.Fragment>
              <View style={{ backgroundColor: '#ededed', padding: 10 }}>
                <Text>ARQUIVOS ENVIADOS</Text>
              </View>
              <View style={{ backgroundColor: '#e1e1e1', padding: 1 }}/>

              <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                {uploaded_files.map((i, index) => (<View key={index}>
                  <FileThumbnail item={i} key={index}/>
                  <Text style={{ paddingLeft: 10 }}> {i.name}</Text>
                </View>))}
              </View>
            </React.Fragment>}

            {upload_queue.length > 0 && <React.Fragment>
              <View style={{ backgroundColor: '#ededed', padding: 10 }}>
                <Text>ARQUIVOS PENDENTES DE ENVIO</Text>
              </View>
              <View style={{ backgroundColor: '#e1e1e1', padding: 1 }}/>

              <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                {upload_queue.map((i, index) => (<TouchableRipple key={index} onPress={() => this.removeQueueFile(index)}>
                  <ImageBackground
                    key={index}
                    source={{ uri: i.uri }}
                    style={{
                      width: win.width * 0.3,
                      height: win.width * 0.3,
                      margin: win.width * 0.015,
                      overflow: 'hidden',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: 2
                    }}>
                    <View style={{ ...StyleSheet.absoluteFillObject, backgroundColor: '#000000', opacity: 0.2 }}/>
                    <Text style={{ margin: 20, color: 'white', textAlign: 'center', fontSize: 14 }}>Remover</Text>
                  </ImageBackground>
                </TouchableRipple>))}
              </View>
            </React.Fragment>}

            <Portal>
              <FAB.Group
                open={this.state.open}
                icon={this.state.open ? 'close' : 'plus'}
                actions={platformChecker.isNative ?
                  this.props.only_photos ?
                    [
                      { icon: 'camera', label: 'Tirar foto', onPress: () => this.takePhoto() },
                      { icon: 'image-search', label: 'Selecionar da galeria de fotos', onPress: () => this.selectPhoto() }
                    ]
                    :
                    [
                      { icon: 'camera', label: 'Tirar foto', onPress: () => this.takePhoto() },
                      { icon: 'image-search', label: 'Selecionar da galeria de fotos', onPress: () => this.selectPhoto() },
                      { icon: 'file-find', label: 'Selecionar arquivos', onPress: () => this.selectFile() }
                    ]
                  :
                  [
                    { icon: 'file-find', label: 'Selecionar arquivos', onPress: () => this.selectFile() }
                  ]
                }
                onStateChange={({ open }) => this.setState({ open })}
              />
            </Portal>

            <Spacer size={300}/>

          </ScrollView>
        </View>
      </Modal>
    </Portal> : <Button icon={this.props.buttonIcon} mode="outlined" onPress={() => this.setModalVisible(true)}>
      {this.props.buttonText}
    </Button>)
  }
}

function mapStateToProps(state) {
  return {
    uploaded_files: state.files.data
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getObjects: (data) => dispatch(actions.all(data)),
    clearAll: () => dispatch(actions.clear_all()),
    sendingRequest: (data) => dispatch(sending_request(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Upploader)

/*
uploadProcessPhoto = async () => {
  let coordinates = this.customCrop.crop()
  let file = this.state.currentFile

  file['name'] = `photo.${mime.extension(mime.lookup(file.uri))}`
  file['file_type'] = mime.lookup(file.uri)

  let form_data = new FormData()
  form_data.append('photo', {
    uri: file.uri,
    name: file.name,
    type: file.file_type,
  })
  form_data.append('coordinates', JSON.stringify((coordinates)))

  let res = await postFormApi('admin/expense/process-photo', form_data, {'File-Type': this.props.file_type})

  this.setState({currentImageB64: res})
}

showPhotoCropper = (file) => {
  let img_data = {
    imageWidth: file.width,
    imageHeight: file.height,
    initialImage: file.uri,
    rectangleCoordinates: {
      topLeft: {x: file.width * 0.15, y: file.height * 0.15},
      topRight: {x: file.width * 0.85, y: file.height * 0.15},
      bottomRight: {x: file.width * 0.85, y: file.height * 0.85},
      bottomLeft: {x: file.width * 0.15, y: file.height * 0.85},
    },
  }

  this.setState(img_data)
}

updateImage(image, newCoordinates) {
  this.setState({
    image,
    rectangleCoordinates: newCoordinates
  })
}


<View>
  {
    this.state.initialImage &&
    <CustomCrop
      updateImage={this.updateImage.bind(this)}
      rectangleCoordinates={this.state.rectangleCoordinates}
      initialImage={this.state.initialImage}
      height={this.state.imageHeight}
      width={this.state.imageWidth}
      ref={(ref) => this.customCrop = ref}
      overlayColor="rgba(18,190,210, 1)"
      overlayStrokeColor="rgba(20,190,210, 1)"
      handlerColor="rgba(20,150,160, 1)"
      enablePanStrict={false}
    />
  }
</View>
*/
