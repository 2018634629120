import {fetchApi} from '../../services/api'

export const getConsumptions = async () => {
  try {
    let {data} = await fetchApi('resident/consumptions?include[]=consumption_type_name&include[]=files.file')
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}
