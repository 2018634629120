import {Formik, FormikProps} from 'formik'
import React, {useState} from 'react'
import {FlatList, Linking, View} from 'react-native'
import {Button, Divider, HelperText, List, Paragraph, RadioButton, Subheading, Text, Title} from 'react-native-paper'
import {useMutation, useQuery} from 'react-query'
import * as Yup from 'yup'
import {MkRender} from '../../../components'
import AppModalSheet from '../../../components/layout/AppModalSheet'
import Spacer from '../../../components/Spacer'
import {postApi} from '../../../services/api'
import {apiService} from '../../../services/apiService'
import ToasterManager from '../../../services/ToasterManager'
import {MeetingQuestion, MeetingQuestionsWithAnswersResponse, MeetingSubscription} from '../typings'

type QuestionFormValues = {
  options_ids: number[],
  question_id: number | string,
  meeting_subscription_id: number | string
}

const getSubscription = async (meeting_subscription_id: number | string) => {
  const { data } = await apiService.get<MeetingSubscription>(`resident/meeting-subscriptions/${meeting_subscription_id}`)
  return data
}

const getQuestionsWithAnswers = async (meeting_subscription_id: number | string) => {
  const { data } = await apiService.get<MeetingQuestionsWithAnswersResponse>(
    `resident/meeting-subscriptions/${meeting_subscription_id}/questions-with-answers`)
  return data
}

const updateAnswer = async ({ meeting_subscription_id, ...values }: any) => {
  try {
    return await postApi(`resident/meeting-subscriptions/${meeting_subscription_id}/update_answer`, values)
  } catch (e) {
    ToasterManager.sendErrors(e)
    return Promise.reject(e)
  }
}

const Attachments = ({ question }: any) => {
  if (question.files.length > 0) {
    return (
      <View style={{ paddingTop: 15 }}>
        <Text style={{ fontWeight: 'bold' }}>Anexos</Text>

        {question.files.map((item: any, index: number) => (
          <List.Item
            style={{ padding: 0 }}
            key={index}
            title={item.name}
            titleStyle={{ fontSize: 15 }}
            left={props => <List.Icon color="#19D150" icon="cloud-download"/>}
            onPress={() => Linking.openURL(item.file)}
          />
        ))}
      </View>
    )
  }

  return null
}

export const QuestionTab = (props: { meeting_subscription_id: number | string }) => {
  const _subscription = useQuery(['getSubscription', props.meeting_subscription_id],
    () => getSubscription(props.meeting_subscription_id),
    { refetchInterval: 1000 * 30 })

  const _questions = useQuery(['getQuestionsWithAnswers', props.meeting_subscription_id],
    () => getQuestionsWithAnswers(props.meeting_subscription_id),
    { refetchInterval: 1000 * 30 })

  const mutation = useMutation(updateAnswer, {
    onSuccess: () => {
      ToasterManager.success('Parabéns, o seu voto foi registrado!')
      _questions.refetch()
      setCurrentQuestion(null)
    }
  })

  const [current_question, setCurrentQuestion] = useState<MeetingQuestion | null>(null)

  const renderListRow = ({ item }: { item: MeetingQuestion }) => {
    let all_question_answers_string = (item.all_question_answers?.map(i => i.meeting_option.name) ?? []).join(', ')

    let has_answer = all_question_answers_string.length > 0
    let not_has_answer = all_question_answers_string.length === 0

    return (
      <List.Item
        title={item?.name}
        style={{ paddingVertical: 20 }}
        titleNumberOfLines={10}
        description={() =>
          <React.Fragment>
            {not_has_answer && item.status === 1 && <Paragraph style={{ color: 'green' }}>Disponível para votar</Paragraph>}
            {not_has_answer && item.status === 0 && <Paragraph style={{ color: 'red' }}>Não disponível para votar</Paragraph>}
            {has_answer && <Paragraph style={{ fontWeight: 'bold' }}>Resposta: {all_question_answers_string}</Paragraph>}

            <React.Fragment>
              {not_has_answer && item.status === 1 &&
                <Button style={{ alignSelf: 'flex-start', marginTop: 15 }} mode="outlined" onPress={() => toDetail(item)}>Votar</Button>}

              {(has_answer || item.status === 0) &&
                <Button style={{ alignSelf: 'flex-start', marginTop: 15 }} mode="outlined" onPress={() => toDetail(item)}>
                  {has_answer && item.status === 1 && item.allow_change_answer ? 'Alterar voto' : 'Detalhes'}
                </Button>}
            </React.Fragment>

          </React.Fragment>
        }
        onPress={() => toDetail(item)}
      />
    )
  }

  const renderInner = () => {
    let all_question_answers_string = (current_question?.all_question_answers?.map(i => i.meeting_option.name) ?? []).join(', ')

    let has_answer = all_question_answers_string.length > 0
    let not_has_answer = all_question_answers_string.length === 0

    return (
      <React.Fragment>
        {current_question?.id &&
          <Formik<QuestionFormValues>
            initialValues={{
              options_ids: [],
              question_id: current_question.id,
              meeting_subscription_id: props.meeting_subscription_id
            }}
            validationSchema={Yup.object().shape({
              options_ids: Yup.array().required().min(1)
            })}
            enableReinitialize={true}
            onSubmit={(values) => {
              mutation.mutate(values)
            }}
          >
            {({ values, submitForm, setFieldValue, errors }: FormikProps<QuestionFormValues>) => {
              const changeVote = (option_id: number) => {
                let opt_id = String(option_id)

                // Reset refs
                let current_options = values.options_ids.map(x => String(x))
                let has_index = current_options.findIndex(x => x === opt_id)

                if (current_question.allow_multiple_choice) {
                  if (has_index > -1) {
                    current_options = current_options.filter(x => x !== opt_id)
                  } else {
                    current_options.push(opt_id)
                  }
                } else {
                  current_options = []
                  current_options.push(opt_id)
                }

                if (current_options.length > current_question?.max_multiple_choices) {
                  return
                }

                setFieldValue('options_ids', current_options)
              }

              return (
                <View>
                  <Title>{current_question.name}</Title>

                  <MkRender>{current_question.description}</MkRender>

                  <Attachments question={current_question}/>

                  <Spacer size={30}/>

                  {has_answer &&
                    <Paragraph style={{ fontWeight: 'bold' }}>Resposta: {all_question_answers_string}</Paragraph>}

                  {not_has_answer && current_question.status === 0 &&
                    <Paragraph style={{ color: 'red' }}>Não disponível para votar</Paragraph>}

                  <Text style={{ fontWeight: 'bold', paddingTop: 15, paddingBottom: 10 }}>
                    {current_question.allow_multiple_choice
                      ? `Opções (selecione até ${current_question.max_multiple_choices} opções):`
                      : 'Opções:'}
                  </Text>
                  <Divider/>
                  <Divider style={{ marginBottom: 15 }}/>

                  {
                    current_question?.meeting_options?.map((item) => (
                      <List.Item
                        key={item.id}
                        style={{
                          paddingLeft: 0,
                          paddingHorizontal: 12,
                          // paddingVertical: 16,
                          marginBottom: 20,
                          backgroundColor: '#f3f3f3',
                          borderBottomColor: '#e3e3e3',
                          borderBottomWidth: 1
                        }}
                        title={item.name}
                        titleNumberOfLines={12}
                        // titleStyle={{fontSize: 15}}
                        right={props =>
                          <RadioButton
                            value={String(item.id)}
                            status={values.options_ids.indexOf(String(item.id) as any) > -1 ? 'checked' : 'unchecked'}
                            onPress={() => changeVote(item.id)}
                          />
                        }
                        onPress={() => changeVote(item.id)}
                      />
                    ))
                  }

                  <HelperText type="error" visible={!!errors.options_ids}>
                    Selecione uma opção!
                  </HelperText>

                  <Spacer size={5}/>

                  {(
                      (current_question.status === 1 && not_has_answer) ||
                      (has_answer && current_question.status === 1 && current_question.allow_change_answer)
                    ) &&
                    <Button loading={mutation.isLoading} disabled={mutation.isLoading} mode="contained" onPress={submitForm}>Salvar
                      voto</Button>}

                  <Spacer size={100}/>
                </View>
              )
            }}
          </Formik>}
        <Spacer/>
      </React.Fragment>
    )
  }

  const toDetail = (item: MeetingQuestion) => {
    setCurrentQuestion(item)
  }

  return (
    <React.Fragment>
      <View style={{ backgroundColor: '#eccf6e' }}>
        <Subheading style={{ textAlign: 'center', padding: 8, color: '#665930' }}>
          Atenção! O voto não pode ser alterado.
        </Subheading>
      </View>

      {_subscription.data?.status !== 2 && <View style={{ backgroundColor: '#ec6e7d' }}>
        <Subheading style={{ textAlign: 'center', padding: 15, color: '#663036' }}>
          A administração ainda não aprovou a sua inscrição, o seu voto não será registrado.
        </Subheading>
      </View>}


      <FlatList
        ItemSeparatorComponent={Divider}
        renderItem={renderListRow}
        keyExtractor={(item: any) => item?.id}
        refreshing={_questions.isLoading}
        onRefresh={_questions.refetch}
        data={_questions.data?.questions ?? []}
        contentContainerStyle={{ paddingBottom: 50 }}
      />

      <AppModalSheet
        visible={!!current_question?.id}
        height_percent={80}
        onDismiss={() => setCurrentQuestion(null)}
        content={renderInner}
      />
    </React.Fragment>
  )
}
