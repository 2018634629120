import {call, put} from 'redux-saga/effects'
import {REQUEST_ERROR, SENDING_REQUEST} from '../constants'
import {deleteApi, fetchApi, postApi, putApi} from '../services/api'
import ToasterManager from '../services/ToasterManager'
import createAction from 'redux-actions/es/createAction'
import navigationService from '../services/navigationService'
import {gv} from '../utils'

/*
* @deprecated
* */
export function* genericAllSagasBase(action_success, route) {
  yield put({type: SENDING_REQUEST, sending: true})

  try {
    const response = yield call(async () => {
      return await fetchApi(`resident/${route}`)
        .then(res => res.data)
        .catch(err => Promise.reject(err))
    })

    yield put(action_success(response))
    return response
  } catch (error) {
    ToasterManager.sendErrors(error)
    yield put({type: REQUEST_ERROR, error: error.message})
    return false
  } finally {
    yield put({type: SENDING_REQUEST, sending: false})
  }
}

/*
* @deprecated
* */
export function* genericGetSagas(route, options = {}) {
  const {action_success = null, msg = null, return_error = false, run_loading = true} = options

  if (run_loading) {
    yield put({type: SENDING_REQUEST, sending: true})
  }

  try {
    const response = yield call(async () => {
      return await fetchApi(`resident/${route}`)
        .then(res => res)
        .catch(err => Promise.reject(err))
    })

    if (action_success) {
      yield put(action_success(response))
    }

    if (msg) {
      ToasterManager.success(msg)
    }

    return response
  } catch (error) {
    ToasterManager.sendErrors(error)
    console.error(error)
    yield put({type: REQUEST_ERROR, error: error.message})
    return false
  } finally {
    if (run_loading) {
      yield put({type: SENDING_REQUEST, sending: false})
    }
  }
}

/*
* @deprecated
* */
export function* genericPostSagas(route, data, options = {}) {
  const {action_success = null, msg = null, return_error = false, run_loading = true} = options

  if (run_loading) {
    yield put({type: SENDING_REQUEST, sending: true})
  }

  try {
    const response = yield call(async () => {
      return await postApi(`resident/${route}`, data)
        .then(res => res)
        .catch(err => Promise.reject(err))
    })

    if (action_success) {
      yield put(action_success(response))
    }

    if (msg) {
      ToasterManager.success(msg)
    }

    return response
  } catch (error) {
    ToasterManager.sendErrors(error)
    yield put({type: REQUEST_ERROR, error: error.message})
    return false
  } finally {
    if (run_loading) {
      yield put({type: SENDING_REQUEST, sending: false})
    }
  }
}

/*
* @deprecated
* */
export function* genericPutSagas(route, data, options = {}) {
  const {action_success = null, msg = null, return_error = false, run_loading = true} = options

  if (run_loading) {
    yield put({type: SENDING_REQUEST, sending: true})
  }

  try {
    const response = yield call(async () => {
      return await putApi(`resident/${route}`, data)
        .then(res => res)
        .catch(err => Promise.reject(err))
    })

    if (action_success) {
      yield put(action_success(response))
    }

    if (msg) {
      ToasterManager.success(msg)
    }

    return response
  } catch (error) {
    ToasterManager.sendErrors(error)
    yield put({type: REQUEST_ERROR, error: error.message})
    if (return_error) return error
    return false
  } finally {
    if (run_loading) {
      yield put({type: SENDING_REQUEST, sending: false})
    }
  }
}

/*
* @deprecated
* */
export function* allSagasBase(actionMap, route, action = null, options = {}) {
  const {loader = true} = options

  if (loader) yield put({type: SENDING_REQUEST, sending: true})

  try {
    const response = yield call(async () => {
      return await fetchApi(`resident/${route}`)
        .then(res => res.data)
        .catch(err => Promise.reject(err))
    })
    yield put(actionMap.all_success(response))

    if (gv(action, 'payload')) {
      let result = response.find(i => i.id === action.payload)
      if (result) {
        yield put(actionMap.current(result))
        yield put(actionMap.modal(true))
      }
    }

    return response
  } catch (error) {
    ToasterManager.sendErrors(error)
    yield put({type: REQUEST_ERROR, error: error.message})
    return false
  } finally {
    if (loader) yield put({type: SENDING_REQUEST, sending: false})
  }
}

/*
* @deprecated
* */
export function* getSagasBase(actionMap, route, action, params = null) {
  yield put({type: SENDING_REQUEST, sending: true})
  const data = action.payload

  try {
    const response = yield call(async () => {
      const full_route = params ? `resident/${route}/${data.id}${params}` : `resident/${route}/${data.id}`
      return await fetchApi(full_route)
        .then(res => res)
        .catch(err => Promise.reject(err))
    })

    yield put(actionMap.get_success(response))
    return response
  } catch (error) {
    ToasterManager.sendErrors(error)
    yield put({type: REQUEST_ERROR, error: error.message})
    return false
  } finally {
    yield put({type: SENDING_REQUEST, sending: false})
  }
}

/*
* @deprecated
* */
export function* postSagasBase(actionMap, route, action, options = {}) {
  const {redirect = null} = options

  yield put({type: SENDING_REQUEST, sending: true})
  const data = action.payload

  try {
    const response = yield call(async () => {
      return await postApi(`resident/${route}`, data).then(res => res).catch(err => Promise.reject(err))
    })

    yield put(actionMap.post_success(response))
    ToasterManager.success('Adicionado com sucesso')

    if (redirect) navigationService.navigate(redirect)

    return response
  } catch (error) {
    ToasterManager.sendErrors(error)
    yield put({type: REQUEST_ERROR, error: error.message})
    return false
  } finally {
    yield put({type: SENDING_REQUEST, sending: false})
  }
}

/*
* @deprecated
* */
export function* putSagasBase(actionMap, route, action) {
  yield put({type: SENDING_REQUEST, sending: true})
  const {id, data} = action.payload

  try {
    const response = yield call(async () => {
      return await putApi(`resident/${route}`, data, id).then(res => res).catch(err => Promise.reject(err))
    })

    yield put(actionMap.put_success(response))
    yield put(actionMap.current(response))
    yield put(actionMap.all())
    // yield put(actionMap.modal(false))

    ToasterManager.success('Atualizado com sucesso')
    return response
  } catch (error) {
    ToasterManager.sendErrors(error)
    yield put({type: REQUEST_ERROR, error: error.message})
    return false
  } finally {
    yield put({type: SENDING_REQUEST, sending: false})
  }
}

/*
* @deprecated
* */
export function* deleteSagasBase(actionMap, route, action) {
  yield put({type: SENDING_REQUEST, sending: true})

  try {
    const response = yield call(async () => {
      return await deleteApi(`resident/${route}`, action.payload).then(res => res).catch(err => Promise.reject(err))
    })

    yield put(actionMap.delete_success(response))
    yield put(actionMap.all())

    ToasterManager.success('Removido com sucesso')
    return response
  } catch (error) {
    ToasterManager.sendErrors(error)
    yield put({type: REQUEST_ERROR, error: error.message})
    return false
  } finally {
    yield put({type: SENDING_REQUEST, sending: false})
  }
}

/*
* @deprecated
* */
export function reducerBase(state, action, actionMap) {
  switch (action.type) {
    case actionMap.current().type:
      return {...state, current: action.payload}

    case actionMap.modal().type:
      return {...state, showModal: action.payload}

    case actionMap.get_success().type:
      return {...state, current: action.payload}

    case actionMap.all_success().type:
      return {...state, data: action.payload}

    case actionMap.put_success().type:
      const object = action.payload
      return {
        ...state,
        data: state.data.map(item => item.id === object.id ? object : item),
      }

    case actionMap.post_success().type:
      return {
        ...state,
        data: [action.payload, ...state.data],
      }

    default:
      return state
  }
}

/*
* @deprecated
* */
export function reducersMap(actionMap) {
  return {
    [actionMap.all_success]: (state, action) => ({...state, data: action.payload}),
    [actionMap.get_success]: (state, action) => ({...state, current: action.payload}),
    [actionMap.post_success]: (state, action) => ({...state, data: [action.payload, ...state.data]}),
    [actionMap.put_success]: (state, action) => {
      const object = action.payload
      return {
        ...state
        // data: state.data.map(item => item.id === object.id ? object : item),
      }
    },
    [actionMap.modal]: (state, action) => ({...state, showModal: action.payload}),
    [actionMap.current]: (state, action) => ({...state, current: action.payload}),
    [actionMap.clear_all]: (state, action) => ({...state, data: []}),
  }
}

/*
* @deprecated
* */
export function actionMapBase(domain) {
  return {
    all: createAction(`${domain}/ALL`),
    all_success: createAction(`${domain}/ALL_SUCCESS`),
    get: createAction(`${domain}/GET`),
    get_success: createAction(`${domain}/GET_SUCCESS`),
    post: createAction(`${domain}/POST`),
    post_success: createAction(`${domain}/POST_SUCCESS`),
    put: createAction(`${domain}/PUT`, (id, data) => ({id, data})),
    put_success: createAction(`${domain}/PUT_SUCCESS`),
    delete: createAction(`${domain}/DELETE`),
    delete_success: createAction(`${domain}/DELETE_SUCCESS`),
    modal: createAction(`${domain}/MODAL`),
    current: createAction(`${domain}/CURRENT`),
    clear_all: createAction(`${domain}/CLEAR_ALL`)
  }
}
