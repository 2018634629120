import React from 'react'
import {useMeQuery} from '../../features/Auth/hooks/useMeQuery'
import {isArrNotEmpty} from '../../utils'
import {hasPerm} from './index'

// https://github.com/brainhubeu/react-permissible
// https://github.com/IkoroVictor/react-permissions

type Props = {
  required: string[],
  hasAll?: boolean,
  fallback?: any
}

export const AclRender: React.FC<Props> = React.memo(({ children, hasAll, required, fallback }) => {
  const { data: meData } = useMeQuery()

  const userPermissions = meData?.permissions

  const checkPermissions = () => {
    return hasPerm(required, hasAll)
  }

  if (!children || !isArrNotEmpty(userPermissions) || !required) {
    return null
  }

  if (checkPermissions()) {
    return children
  } else if (fallback) {
    return fallback
  }
  return null
})