import {useIsFocused} from '@react-navigation/native'
import * as React from 'react'
import {SafeAreaView} from 'react-native-safe-area-context'
import ConstructionForm from '../components/ConstructionForm'

const ConstructionNewScreen = () => {
  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      <React.Fragment>
        <ConstructionForm data={null}/>
      </React.Fragment>
    </SafeAreaView>
  )
}

export default function (props: any) {
  const isFocused = useIsFocused()

  if (!isFocused) return null

  return (
    <ConstructionNewScreen/>
  )
}
