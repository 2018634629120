import {useFocusEffect, useNavigation} from '@react-navigation/native'
import moment from 'moment'
import * as React from 'react'
import {FlatList, StyleSheet, View} from 'react-native'
import {Caption, Title} from 'react-native-paper'
import {SafeAreaView} from 'react-native-safe-area-context'
import {useQuery} from 'react-query'
import {AclRender} from '../../../components/acl/components'
import AppFab from '../../../components/layout/AppFab'
import PlaceHolder from '../../../components/placeholder'
import {MOVING_STATUS} from '../../../constants/fields'
import {RootDrawerScreenProps} from '../../../navigation/types'
import {useAppTheme} from '../../../styles/theme'
import {qs} from '../../queries'
import {MovingModel} from '../../typings'

function MovingListScreen() {
  const theme = useAppTheme()
  const navigation = useNavigation<RootDrawerScreenProps<'MovingListScreen'>['navigation']>()

  const { data, isFetching, refetch, remove } = useQuery(
    qs.movings.all_key,
    () => qs.movings.all(),
    { enabled: false }
  )

  useFocusEffect(
    React.useCallback(() => {
      refetch()
      return () => { remove() }
    }, [])
  )

  const renderItem = ({ item }: { item: MovingModel }) => (
    <React.Fragment>
      <View style={styles.cardContainer}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View style={{ flex: 3 }}>
            <Caption>#{item.id} - {item?.moving_type?.name}</Caption>
            <Title>{moment(item.date).format('DD/MM/YYYY')}</Title>
          </View>
          <View style={{ flex: 1, alignSelf: 'center' }}>
            <Caption style={{ textAlign: 'right', fontWeight: 'bold', color: theme.colors.accent }}>
              {MOVING_STATUS[item.status]}
            </Caption>
          </View>
        </View>
      </View>
    </React.Fragment>
  )

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      <FlatList
        style={styles.list}
        contentContainerStyle={styles.listContent}
        data={data}
        renderItem={renderItem}
        keyExtractor={item => item.id + ''}
        refreshing={isFetching}
        onRefresh={refetch}
        ListEmptyComponent={() => (
          <PlaceHolder
            visible={true}
            emoji={'🚚'}
            description={'Aqui você pode solicitar autorização para realizar sua mudança.'}
          />
        )}
      />

      <AclRender required={['moving.add_moving']}>
        <AppFab icon="plus" onPress={() => navigation.navigate('MovingNewScreen')}/>
      </AclRender>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  list: {
    flex: 1
  },
  listContent: {
    paddingHorizontal: 8,
    paddingVertical: 14
  },
  cardContainer: {
    borderWidth: 1,
    borderColor: '#e8e8e8',
    borderRadius: 6,
    padding: 12,
    marginBottom: 14
  }
})

export default MovingListScreen
