import {atom, selector} from 'recoil'
import authService from '../services/authService'

export const userLoggedInAtom = atom<boolean | null>({
  key: 'userLoggedIn',
  default: selector({
    key: 'userLoggedIn/Default',
    get: async ({ get }) => {
      let data = await authService.getToken()

      return !!data
    }
  })
})
