import {all} from 'redux-saga/effects'
import {comments_sagas} from '../modules/comments'
import {files_sagas} from '../modules/files'

function* rootSaga() {
  yield all([
    ...files_sagas,
    ...comments_sagas,
    //END_SAGAS
  ])
}

export default rootSaga
