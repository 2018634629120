import AsyncStorage from '@react-native-async-storage/async-storage'
import {Dimensions, Linking, NativeEventEmitter, NativeModules, Platform} from 'react-native'
import tenantService from '../services/tenantService'

const { RNZohoSalesIQ } = NativeModules

let emitter: NativeEventEmitter | null = null
if (RNZohoSalesIQ?.initWithCallback) {
  emitter = new NativeEventEmitter(RNZohoSalesIQ)
}

type Chat = {
  attenderEmail: string;
  attenderID: string;
  attenderName: string;
  departmentName: string;
  id: string;
  isBotAttender: boolean;
  lastMessage: string;
  lastMessageSender: string;
  lastMessageTime: string;
  question: string;
  status: string;
  unreadCount: number;
}

const ZohoSalesIQSDK = {
  TYPE_OPEN: RNZohoSalesIQ?.TYPE_OPEN,
  TYPE_WAITING: RNZohoSalesIQ?.TYPE_WAITING,
  TYPE_CONNECTED: RNZohoSalesIQ?.TYPE_CONNECTED,
  TYPE_ENDED: RNZohoSalesIQ?.TYPE_ENDED,
  TYPE_CLOSED: RNZohoSalesIQ?.TYPE_CLOSED,
  TYPE_MISSED: RNZohoSalesIQ?.TYPE_MISSED,

  EVENT_SUPPORT_OPENED: RNZohoSalesIQ?.SUPPORT_OPENED,
  EVENT_SUPPORT_CLOSED: RNZohoSalesIQ?.SUPPORT_CLOSED,
  EVENT_VISITOR_IPBLOCKED: RNZohoSalesIQ?.VISITOR_IPBLOCKED,
  EVENT_OPERATORS_ONLINE: RNZohoSalesIQ?.OPERATORS_ONLINE,
  EVENT_OPERATORS_OFFLINE: RNZohoSalesIQ?.OPERATORS_OFFLINE,

  EVENT_CHATVIEW_OPENED: RNZohoSalesIQ?.CHATVIEW_OPENED,
  EVENT_CHATVIEW_CLOSED: RNZohoSalesIQ?.CHATVIEW_CLOSED,
  EVENT_CHAT_OPENED: RNZohoSalesIQ?.CHAT_OPENED,
  EVENT_CHAT_CLOSED: RNZohoSalesIQ?.CHAT_CLOSED,
  EVENT_CHAT_REOPENED: RNZohoSalesIQ?.CHAT_REOPENED,
  EVENT_CHAT_ATTENDED: RNZohoSalesIQ?.CHAT_ATTENDED,
  EVENT_CHAT_MISSED: RNZohoSalesIQ?.CHAT_MISSED,
  EVENT_CHAT_QUEUE_POSITION_CHANGED: RNZohoSalesIQ?.CHAT_QUEUE_POSITION_CHANGED,
  EVENT_FEEDBACK_RECEIVED: RNZohoSalesIQ?.FEEDBACK_RECEIVED,
  EVENT_RATING_RECEIVED: RNZohoSalesIQ?.RATING_RECEIVED,

  EVENT_ARTICLE_LIKED: RNZohoSalesIQ?.ARTICLE_LIKED,
  EVENT_ARTICLE_DISLIKED: RNZohoSalesIQ?.ARTICLE_DISLIKED,
  EVENT_ARTICLE_OPENED: RNZohoSalesIQ?.ARTICLE_OPENED,
  EVENT_ARTICLE_CLOSED: RNZohoSalesIQ?.ARTICLE_CLOSED,
  EVENT_PERFORM_CHATACTION: RNZohoSalesIQ?.PERFORM_CHATACTION,
  EVENT_CUSTOMTRIGGER: RNZohoSalesIQ?.CUSTOMTRIGGER,

  init: function (appKey: string, accessKey: string) {
    RNZohoSalesIQ.init(appKey, accessKey)
  },
  initWithCallback: function (appKey: string, accessKey: string, callback: any) {
    RNZohoSalesIQ.initWithCallback(appKey, accessKey, callback)
  },
  setChatTitle: function (title: string) {
    RNZohoSalesIQ.setChatTitle(title)
  },
  setLanguage: function (language_code: string) {
    RNZohoSalesIQ.setLanguage(language_code)
  },
  setDepartment: function (department: string) {
    RNZohoSalesIQ.setDepartment(department)
  },
  setOperatorEmail: function (email: string) {
    RNZohoSalesIQ.setOperatorEmail(email)
  },
  showOperatorImageInChat: function (show: boolean) {
    RNZohoSalesIQ.showOperatorImageInChat(show)
  },
  setFeedbackVisibility: function (visibility: boolean) {
    RNZohoSalesIQ.setFeedbackVisibility(visibility)
  },
  setRatingVisibility: function (visibility: boolean) {
    RNZohoSalesIQ.setRatingVisibility(visibility)
  },
  showOperatorImageInLauncher: function (show: boolean) {
    RNZohoSalesIQ.showOperatorImageInLauncher(show)
  },
  openChat: function () {
    RNZohoSalesIQ.openChat()
  },
  openChatWithID: function (id: string | number) {
    RNZohoSalesIQ.openChatWithID(id)
  },
  showOfflineMessage: function (show: boolean) {
    RNZohoSalesIQ.showOfflineMessage(show)
  },
  endChat: function (id: string | number) {
    RNZohoSalesIQ.endChat(id)
  },
  getChats: function (callback: any) {
    RNZohoSalesIQ.getChats(callback)
  },
  getChatsWithFilter: function (filter: any, callback: any) {
    RNZohoSalesIQ.getChatsWithFilter(filter, callback)
  },
  setLauncherVisibility: function (visibility: boolean) {
    RNZohoSalesIQ.setLauncherVisibility(visibility)
  },
  setVisitorName: function (name: string) {
    RNZohoSalesIQ.setVisitorName(name)
  },
  setVisitorEmail: function (email: string) {
    RNZohoSalesIQ.setVisitorEmail(email)
  },
  setVisitorContactNumber: function (number: string) {
    RNZohoSalesIQ.setVisitorContactNumber(number)
  },
  setVisitorAddInfo: function (key: string, value: string) {
    RNZohoSalesIQ.setVisitorAddInfo(key, value)
  },
  setQuestion: function (question: any) {
    RNZohoSalesIQ.setQuestion(question)
  },
  startChat: function (message: string) {
    RNZohoSalesIQ.startChat(message)
  },
  setConversationVisibility: function (visibility: boolean) {
    RNZohoSalesIQ.setConversationVisibility(visibility)
  },
  setConversationListTitle: function (title: string) {
    RNZohoSalesIQ.setConversationListTitle(title)
  },
  setFAQVisibility: function (visibility: boolean) {
    RNZohoSalesIQ.setFAQVisibility(visibility)
  },
  getArticles: function (callback: any) {
    RNZohoSalesIQ.getArticles(callback)
  },
  getArticlesWithCategoryID: function (id: string, callback: any) {
    RNZohoSalesIQ.getArticlesWithCategoryID(id, callback)
  },
  getCategories: function (callback: any) {
    RNZohoSalesIQ.getCategories(callback)
  },
  openArticle: function (id: any, callback: any) {
    RNZohoSalesIQ.openArticle(id, callback)
  },
  fetchAttenderImage: function (atttenderId: any, fetchDefaultImage: any, callback: any) {
    RNZohoSalesIQ.fetchAttenderImage(atttenderId, fetchDefaultImage, callback)
  },
  registerVisitor: function (visitorId: any) {
    RNZohoSalesIQ.registerVisitor(visitorId)
  },
  setThemeColorforAndroid: function (attribute: any, colorCode: any) {
    RNZohoSalesIQ.setThemeColorforAndroid(attribute, colorCode)
  },
  setThemeColorforiOS: function (colorCode: any) {
    RNZohoSalesIQ.setThemeColorforiOS(colorCode)
  },
  setVisitorNameVisibility: function (visible: boolean) {
    RNZohoSalesIQ.setVisitorNameVisibility(visible)
  },
  enablePreChatForms: function () {
    RNZohoSalesIQ.enablePreChatForms()
  },
  disablePreChatForms: function () {
    RNZohoSalesIQ.disablePreChatForms()
  },
  enableScreenshotOption: function () {
    RNZohoSalesIQ.enableScreenshotOption()
  },
  disableScreenshotOption: function () {
    RNZohoSalesIQ.disableScreenshotOption()
  },
  enableInAppNotification: function () {
    RNZohoSalesIQ.enableInAppNotification()
  },
  disableInAppNotification: function () {
    RNZohoSalesIQ.disableInAppNotification()
  },
  unregisterVisitor: function () {
    RNZohoSalesIQ.unregisterVisitor()
  },
  setPageTitle: function (title: string) {
    RNZohoSalesIQ.setPageTitle(title)
  },
  setCustomAction: function (actionName: any) {
    RNZohoSalesIQ.setCustomAction(actionName)
  },
  performCustomAction: function (actionName: any) {
    RNZohoSalesIQ.performCustomAction(actionName)
  },
  registerChatAction: function (chatActionName: any) {
    RNZohoSalesIQ.registerChatAction(chatActionName)
  },
  unregisterChatAction: function (chatActionName: any) {
    RNZohoSalesIQ.unregisterChatAction(chatActionName)
  },
  unregisterAllChatActions: function () {
    RNZohoSalesIQ.unregisterAllChatActions()
  },
  completeChatAction: function (uuid: any) {
    RNZohoSalesIQ.completeChatAction(uuid)
  },
  completeChatActionWithMessage: function (uuid: any, success: any, message: any) {
    RNZohoSalesIQ.completeChatActionWithMessage(uuid, success, message)
  },
  setChatActionTimeout: function (timeout: any) {
    RNZohoSalesIQ.setChatActionTimeout(timeout)
  },
  setVisitorLocation: function (location: any) {
    RNZohoSalesIQ.setVisitorLocation(location)
  },
  addEventListener: function (type: any, listener: any) {
    if (emitter) {
      listener = emitter.addListener(type, listener)
    }
  },
  syncThemeWithOS: function (sync: any) {
    RNZohoSalesIQ.syncThemeWithOS(sync)
  },
  getDepartments: function (callback: any) {
    RNZohoSalesIQ.getDepartments(callback)
  },
  isMultipleOpenChatRestricted: function (callback: any) {
    RNZohoSalesIQ.isMultipleOpenChatRestricted(callback)
  },
  enablePushAndroid: function (token: any, isTestDevice: any) {
    RNZohoSalesIQ.enablePushAndroid(token, isTestDevice)
  },
  setLauncherPropertiesAndroid: function (x: any, v: any, isFloating: any) {
    RNZohoSalesIQ.setLauncherPropertiesAndroid(x, v, isFloating)
  }
}

const ZohoSalesIQ = {
  isFeatureEnabled: async () => {
    const tenant_flags = await tenantService.getFlags()
    return tenant_flags.enable_chat_support
  },

  setup: async () => {
    if (!RNZohoSalesIQ?.initWithCallback) {
      return
    }

    if (!await ZohoSalesIQ.isFeatureEnabled()) {
      return
    }

    await ZohoSalesIQ.setNotInChat()

    let appKey
    let accessKey

    if (Platform.OS === 'ios') {
      appKey = '1Mc1aLVa1juvzCxtNkOg2t6S90UrrzXSCEu93LRpFKtGe%2F6ZhOyVgRPCANC9LnQa'
      accessKey = 'LVF2NRaq51W0zcTy2TSo%2BMl3iiRkWjfQvbL6wRxVJtEs7ATRPTZbUl%2F%2BSG7OruEKVqgaUyd%2BqxgpK44yaWMzuke157ulKfZq7B%2BBK18aoGDh1S8FkI1UuH8IddmWEUYSxkF75YtHX5RNuRYZn9KDRDQkEgPZMRLy'
    } else {
      appKey = '1Mc1aLVa1juvzCxtNkOg2t6S90UrrzXSCEu93LRpFKtGe%2F6ZhOyVgRPCANC9LnQa'
      accessKey = 'LVF2NRaq51W0zcTy2TSo%2BMl3iiRkWjfQvbL6wRxVJtEs7ATRPTZbUl%2F%2BSG7OruEK5l7RjBbUuJC0sOt5luVuCEMWuzbNGQU9Aw9vyYysHcTAPEEGwnuSJH8IddmWEUYSxkF75YtHX5RNuRYZn9KDRDQkEgPZMRLy'
    }

    ZohoSalesIQSDK.initWithCallback(appKey, accessKey, (success: boolean, error: any) => {
      if (success) {
      } else {
        console.error(error)
      }
    })

    // ZohoSalesIQSDK.addEventListener(ZohoSalesIQSDK.EVENT_SUPPORT_OPENED, () => {
    //   console.log('EVENT_SUPPORT_OPENED')
    //   // ZohoSalesIQSDK.setLauncherVisibility(false)
    // })
    // ZohoSalesIQSDK.addEventListener(ZohoSalesIQSDK.EVENT_SUPPORT_CLOSED, () => {
    //   console.log('EVENT_SUPPORT_CLOSED')
    //   // ZohoSalesIQSDK.setLauncherVisibility(true)
    // })

    await ZohoSalesIQ.config()

    ZohoSalesIQSDK.addEventListener(ZohoSalesIQSDK.EVENT_CHATVIEW_OPENED, (chat: string) => {
      console.log('EVENT_CHATVIEW_OPENED', chat)
      // ZohoSalesIQSDK.setLauncherVisibility(true)

      ZohoSalesIQ.setInChat()
    })
    ZohoSalesIQSDK.addEventListener(ZohoSalesIQSDK.EVENT_CHATVIEW_CLOSED, (chat: string) => {
      console.log('EVENT_CHATVIEW_CLOSED', chat)
      // ZohoSalesIQSDK.setLauncherVisibility(true)

      ZohoSalesIQ.setNotInChat()
    })
  },

  config: async () => {
    if (!RNZohoSalesIQ?.initWithCallback) {
      return
    }

    if (!await ZohoSalesIQ.isFeatureEnabled()) {
      return
    }

    ZohoSalesIQSDK.syncThemeWithOS(false)
    ZohoSalesIQSDK.setVisitorNameVisibility(true)
    ZohoSalesIQSDK.disableInAppNotification()
    ZohoSalesIQSDK.setLanguage('pt')
    ZohoSalesIQSDK.setThemeColorforiOS('#5cb85c')
    ZohoSalesIQSDK.setChatTitle('Conversa')
    ZohoSalesIQSDK.setConversationListTitle('Conversas')
  },

  setLauncherVisibility: async () => {
    if (!RNZohoSalesIQ?.initWithCallback) {
      return
    }

    if (!await ZohoSalesIQ.isFeatureEnabled()) {
      return
    }

    await ZohoSalesIQ.config()

    const win = Dimensions.get('window')
    let pos_y = win.height * win.scale
    ZohoSalesIQSDK.setLauncherPropertiesAndroid(0, pos_y * 0.90, true)

    ZohoSalesIQSDK.setLauncherVisibility(true)
  },

  showChatWindow: async () => {
    if (!await ZohoSalesIQ.isFeatureEnabled()) {
      return
    }

    if (!RNZohoSalesIQ?.initWithCallback) {
      await Linking.openURL('https://api.whatsapp.com/send?phone=+5562981802719')
      return
    }

    ZohoSalesIQSDK.openChat()
  },

  isInChat: async () => {
    return await AsyncStorage.getItem('IN_SUPPORT_CHAT') === 'true'
  },

  setInChat: async () => {
    await AsyncStorage.setItem('IN_SUPPORT_CHAT', 'true')
  },

  setNotInChat: async () => {
    await AsyncStorage.setItem('IN_SUPPORT_CHAT', 'false')
  },

  setUser: async (user: any) => {
    if (!RNZohoSalesIQ?.initWithCallback) {
      return
    }

    if (!await ZohoSalesIQ.isFeatureEnabled()) {
      return
    }

    if (user) {
      ZohoSalesIQSDK.registerVisitor(user?.id ? String(user.id) : '')
      ZohoSalesIQSDK.setVisitorName(user?.name ? String(user.name) : '')
      ZohoSalesIQSDK.setVisitorEmail(user?.email ? String(user.email) : '')

      ZohoSalesIQSDK.disablePreChatForms()

      ZohoSalesIQSDK.setVisitorAddInfo('Condomínio Atual', user?.team?.name ?? '')
      ZohoSalesIQSDK.setVisitorAddInfo('Código Condomínio', user?.team?.schema_name ?? '')

      //ZohoSalesIQSDK.setPageTitle('Teste')
      //ZohoSalesIQSDK.setChatTitle('Chat')
      //ZohoSalesIQSDK.setConversationListTitle('Convers')
    }
  },

  resetData: async () => {
    if (!RNZohoSalesIQ?.initWithCallback) {
      return
    }

    if (!await ZohoSalesIQ.isFeatureEnabled()) {
      return
    }

    ZohoSalesIQSDK.unregisterVisitor()
    await ZohoSalesIQ.config()
  },

  handleChatNotification: async (message: string) => {
    if (!RNZohoSalesIQ?.initWithCallback) {
      return
    }

    if (!await ZohoSalesIQ.isFeatureEnabled()) {
      return
    }

    let in_chat = await ZohoSalesIQ.isInChat()

    if (in_chat) return

    ZohoSalesIQSDK.getChats((error: any, chats: Chat[]) => {
      if (Array.isArray(chats)) {
        let chart_sorted = chats.sort((a, b) => parseInt(b.lastMessageTime) - parseInt(a.lastMessageTime))

        let by_last_message = chart_sorted.find(i => i.lastMessage.toLowerCase() === `${message}`.toLowerCase())

        if (by_last_message) {
          ZohoSalesIQSDK.openChatWithID(by_last_message.id)
          return
        }

        for (let c of chart_sorted) {
          ZohoSalesIQSDK.openChat()
        }
      }
    })
  }
}

export default ZohoSalesIQ