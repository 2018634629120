import {Camera, FaceDetectionResult} from 'expo-camera'
import * as FaceDetector from 'expo-face-detector'
import * as ImageManipulator from 'expo-image-manipulator'
import React, {createRef, useEffect, useState} from 'react'
import {Alert, Image, Modal, ScrollView, StyleSheet, Text, View} from 'react-native'
import {Appbar, Button, Colors, IconButton, List, Title} from 'react-native-paper'
import {useSafeAreaInsets} from 'react-native-safe-area-context'
import BottomSheet from 'reanimated-bottom-sheet'
import Spacer from '../../components/Spacer'
import {EventLogger} from '../../services'
import ToasterManager from '../../services/ToasterManager'
import Style from '../../styles/Style'
import {useAppTheme} from '../../styles/theme'
import {platformChecker} from '../../utils'

const MSG_ALREADY_TAKE = 'Ótimo, toque no botão para tirar a foto'
const MSG_CLOSE_TO_CAMERA = 'Aproxime o rosto da câmera'
const MSG_AWAY_TO_CAMERA = 'Afaste o rosto um pouco da câmera'

type Props = {
  handleImageUri: (uri: string) => void
  btnTitle: string,
  show: boolean,
  setShow: (show: boolean) => void
}

type CaptureProps = {
  handleImageUri: (uri: string) => void
}

function FaceCapture(props: CaptureProps) {
  // const DISABLE_FD = platformChecker.isIOS
  const DISABLE_FD = true
  const theme = useAppTheme()

  let bs_ref = React.createRef<BottomSheet>()

  const [hasPermission, setHasPermission] = useState<any>(null)
  const [message, setMessage] = useState('')
  const [currentImage, setCurrentImage] = useState('')
  const [processing, setProcessing] = useState(true)
  const cameraRef = createRef<Camera>()

  // const transX = useRef(new Animated.Value(0)).current
  // const transY = useRef(new Animated.Value(0)).current

  // const [boxSize, setBoxSize] = useState({ height: 0, width: 0 })
  // const [hasFaceInFrame, setHasFaceInFrame] = useState(false)

  const showBottomSheet = () => {
    bs_ref?.current?.snapTo(0)
  }

  const closeBottomSheet = () => {
    bs_ref?.current?.snapTo(1)
    setProcessing(false)
    setCurrentImage('')
  }

  useEffect(() => {
    (async () => {
      const { status } = await Camera.requestCameraPermissionsAsync()
      setHasPermission(status === 'granted')
    })()

    closeBottomSheet()
  }, [])

  if (hasPermission === null) {
    return <Text>Sem permissão para acessar a câmera</Text>
  }
  if (hasPermission === false) {
    return <Text>Sem permissão para acessar a câmera</Text>
  }

  const handleFacesDetected = (faces: FaceDetectionResult) => {
    try {
      // setHasFaceInFrame(faces.faces.length >= 1)
      setMessage(MSG_CLOSE_TO_CAMERA)

      if (faces.faces.length < 1) return

      let _face = faces.faces[0]

      let min_width = Style.DEVICE_WIDTH * 0.3
      let max_width = Style.DEVICE_WIDTH * 0.7

      if (_face.bounds.size.width < min_width) {
        setMessage(MSG_CLOSE_TO_CAMERA)
      } else if (_face.bounds.size.width > max_width) {
        setMessage(MSG_AWAY_TO_CAMERA)
      } else {
        setMessage(MSG_ALREADY_TAKE)
      }

      // let width = _face.bounds.size.width
      // let height = width * 1.33
      // let w_quarter = width / 4

      // let x = _face.bounds.origin.x
      // let y = _face.bounds.origin.y - w_quarter

      // Animated.timing(transX, { useNativeDriver: true, toValue: x }).start()
      // Animated.timing(transY, { useNativeDriver: true, toValue: y }).start()

      // setBoxSize({ height: height, width: width })
    } catch (e) {
      console.error(e)
    }
  }

  const takePicture = async () => {
    console.log('\n\n\ntakePicture =========================')

    try {
      if (cameraRef.current) {
        setProcessing(true)

        showBottomSheet()

        let result = await cameraRef.current.takePictureAsync({
          quality: 0.8
        })

        console.log(result)

        if (result.uri) {
          if (result.height > 1600) {
            result = await ImageManipulator.manipulateAsync(
              result.uri,
              [
                {
                  resize: {
                    height: 1600
                  }
                }],
              { format: ImageManipulator.SaveFormat.JPEG, base64: false }
            )
          }

          if (DISABLE_FD) {
            setCurrentImage(result.uri)
          } else {
            let res_detect = await FaceDetector.detectFacesAsync(result.uri, {
              mode: FaceDetector.FaceDetectorMode.fast,
              detectLandmarks: platformChecker.isIOS ? FaceDetector.FaceDetectorLandmarks.all : FaceDetector.FaceDetectorLandmarks.none,
              runClassifications: platformChecker.isIOS
                ? FaceDetector.FaceDetectorClassifications.all
                : FaceDetector.FaceDetectorClassifications.none,
              minDetectionInterval: 100
            })

            EventLogger.debugLog(`res_detect: ${res_detect}`)

            if (res_detect.faces.length > 0) {
              let _face = res_detect.faces[0]

              let width = _face.bounds.size.width + 50
              let height = width * 1.33
              let w_quarter = width / 6

              let x = _face.bounds.origin.x - 25
              let y = (_face.bounds.origin.y - w_quarter) - 25

              let result3 = await ImageManipulator.manipulateAsync(
                result.uri,
                [
                  {
                    crop: {
                      originX: x,
                      originY: y,
                      width: width,
                      height: height
                    }
                  }],
                { format: ImageManipulator.SaveFormat.JPEG, base64: false }
              )

              console.log(result3)

              setCurrentImage(result3.uri)
            } else {
              Alert.alert('Nenhuma face detectada, tente novamente!')
              closeBottomSheet()
            }
          }
        }
      }

      setProcessing(false)

    } catch (err) {
      Alert.alert('Posicione o rosto e tente novamente.')
      closeBottomSheet()
      console.error(err)
    }
  }

  // const camFlip = () => {
  //   setMessage('')
  //   setType(type === Camera.Constants.Type.back ? Camera.Constants.Type.front : Camera.Constants.Type.back)
  // }

  const renderHeader = () => (
    <View style={styles.header}>
      <View style={styles.panelHeader}>
        <View style={styles.panelHandle}/>
      </View>
    </View>
  )

  const renderInner = () => (
    <View style={styles.panel}>
      {!processing && !!currentImage &&
        <View>
          {!!currentImage && <Image source={{ uri: currentImage }} style={{ height: Style.DEVICE_HEIGHT * 0.3 }} resizeMode="contain"/>}

          <Spacer size={10}/>

          <Title style={{ textAlign: 'center' }}>
            Essa foto ficou boa?
          </Title>

          <Spacer size={15}/>

          <Button icon="check" mode="contained" onPress={() => props.handleImageUri(currentImage)}>
            Sim, enviar!
          </Button>

          <Spacer size={25}/>

          <Button icon="close" mode="outlined" onPress={closeBottomSheet}>
            Não, tirar outra!
          </Button>
        </View>}

      {processing &&
        <View style={styles.panel}>
          <Spacer size={10}/>

          <Title style={{ textAlign: 'center' }}>
            Processando...
          </Title>

          <Title style={{ textAlign: 'center' }}>
            Por favor, segure o celular firmemente e aguarde.
          </Title>
        </View>}
    </View>
  )

  return (
    <View style={{ flex: 1 }}>
      <View>
        <Camera
          ref={cameraRef}
          style={{ height: Style.DEVICE_WIDTH * 1.1 }}
          zoom={0}
          autoFocus={true}
          flashMode="off"
          type={Camera.Constants.Type.front}
          onFacesDetected={DISABLE_FD ? undefined : handleFacesDetected}
          // useCamera2Api={true}
          faceDetectorSettings={
            DISABLE_FD ? undefined : {
              mode: FaceDetector.FaceDetectorMode.fast,
              detectLandmarks: platformChecker.isIOS ? FaceDetector.FaceDetectorLandmarks.all : FaceDetector.FaceDetectorLandmarks.none,
              runClassifications: platformChecker.isIOS
                ? FaceDetector.FaceDetectorClassifications.all
                : FaceDetector.FaceDetectorClassifications.none,
              minDetectionInterval: 100,
              tracking: false
            }}
        >
          <View
            style={{
              flex: 1,
              backgroundColor: 'transparent',
              flexDirection: 'row'
            }}>

            {/*{hasFaceInFrame && <Animated.View style={{*/}
            {/*  position: 'absolute',*/}
            {/*  width: boxSize.width,*/}
            {/*  height: boxSize.height,*/}
            {/*  borderWidth: 3,*/}
            {/*  borderRadius: 6,*/}
            {/*  borderColor: 'green',*/}
            {/*  transform: [*/}
            {/*    {*/}
            {/*      translateX: transX*/}
            {/*    },*/}
            {/*    {*/}
            {/*      translateY: transY*/}
            {/*    }*/}
            {/*  ]*/}
            {/*}}/>}*/}
          </View>
        </Camera>
      </View>

      {!DISABLE_FD && !!message &&
        <Text style={{ fontWeight: 'bold', textAlign: 'center', fontSize: 20, color: theme.colors.primary, paddingTop: 15 }}>
          {message}
        </Text>}

      <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        {/*<IconButton*/}
        {/*  icon="sync"*/}
        {/*  color={Colors.blueGrey500}*/}
        {/*  size={60}*/}
        {/*  onPress={camFlip}*/}
        {/*/>*/}

        <IconButton
          icon="camera-outline"
          color={Colors.blueGrey600}
          size={60}
          disabled={DISABLE_FD ? false : message !== MSG_ALREADY_TAKE}
          onPress={takePicture}
        />
      </View>

      <BottomSheet
        ref={bs_ref}
        snapPoints={[Style.DEVICE_HEIGHT * 0.8, -40]}
        renderContent={renderInner}
        renderHeader={renderHeader}
        initialSnap={1}
        enabledInnerScrolling={true}
        enabledContentTapInteraction={false}
        onCloseEnd={() => closeBottomSheet()}
      />
    </View>
  )
}

export default function FaceCamera(props: Props) {
  const [step, setStep] = useState(0)
  const theme = useAppTheme()

  const showModal = () => {
    setStep(0)
    props.setShow(true)
  }

  const goBack = () => {
    props.setShow(false)
    setStep(0)
  }

  const goToCapture = async () => {
    const { status } = await Camera.requestCameraPermissionsAsync()

    if (platformChecker.isWeb) {
      if (await Camera.isAvailableAsync()) {
        if (status === 'granted') {
          setStep(1)
        } else {
          ToasterManager.error('Sem permissão para acessar a câmera! Por favor habilite as permissões.')
        }
      } else {
        ToasterManager.error('Nenhuma câmera disponível!.')
      }

    } else {
      if (status === 'granted') {
        setStep(1)
      } else {
        ToasterManager.error('Sem permissão para acessar a câmera! Por favor habilite as permissões.')
      }
    }
  }

  const insets = useSafeAreaInsets()

  return (
    <View style={{ flex: 1 }}>

      {!props.show &&
        <View style={{ flex: 1 }}>
          <Button icon="camera" mode="outlined" onPress={showModal}>
            {props.btnTitle}
          </Button>
        </View>}

      {props.show &&
        <Modal
          visible={true}
          animationType={'slide'}
          transparent={false}
        >
          <View style={{ flex: 1 }}>
            <Appbar.Header statusBarHeight={insets.top}>
              <Appbar.BackAction onPress={goBack}/>
              <Appbar.Content title={'Cadastro da Face'}/>
            </Appbar.Header>

            {step === 0 &&
              <ScrollView contentContainerStyle={{ padding: 20 }} style={{ backgroundColor: theme.colors.primary }}>
                <View>
                  <Spacer/>

                  <Title style={{ textAlign: 'center', color: '#9bd1ff', fontWeight: 'bold' }}>
                    Dicas para fotografar a Face:
                  </Title>

                  <Spacer/>

                  <List.Item
                    titleStyle={{ color: '#FFFFFF' }}
                    titleNumberOfLines={5}
                    title="Se posicione em uma parede com fundo claro e bem iluminada"
                    left={() => <Text style={{ fontSize: 30, paddingTop: 5 }}>🙂</Text>}
                  />

                  <List.Item
                    titleStyle={{ color: '#FFFFFF' }}
                    titleNumberOfLines={5}
                    title="Não utilize acessórios, como: bonés, máscara e outros"
                    left={() => <Text style={{ fontSize: 30, paddingTop: 5 }}>🧢</Text>}
                  />

                  <List.Item
                    titleStyle={{ color: '#FFFFFF' }}
                    titleNumberOfLines={5}
                    title="Fique atento as observações que irá aparecer para posicionar a face"
                    left={() => <Text style={{ fontSize: 30, paddingTop: 5 }}>⚠️</Text>}
                  />
                </View>

                <Spacer/>

                <Button
                  color="#9bd1ff"
                  mode="outlined" onPress={goToCapture}
                  contentStyle={{ padding: 20 }}
                  labelStyle={{ fontWeight: 'bold' }}
                  style={{ borderColor: '#9bd1ff', borderWidth: 2 }}
                >
                  Entendi, vamos lá!
                </Button>
                <Spacer/>
              </ScrollView>}

            {step === 1 && <FaceCapture handleImageUri={props.handleImageUri}/>}
          </View>
        </Modal>}
    </View>
  )
}

const styles = StyleSheet.create({
  panel: {
    minHeight: Style.DEVICE_HEIGHT,
    paddingTop: 10,
    paddingBottom: 40,
    backgroundColor: '#f8f8f8',
    paddingRight: 20,
    paddingLeft: 20

  },
  header: {
    backgroundColor: '#efefef',
    paddingTop: 20,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20
  },
  panelHeader: {
    alignItems: 'center'
  },
  panelHandle: {
    width: 40,
    height: 8,
    borderRadius: 4,
    backgroundColor: '#00000040',
    marginBottom: 10
  }
})
