import {useFocusEffect, useIsFocused, useNavigation, useRoute} from '@react-navigation/native'
import {Formik} from 'formik'
import moment from 'moment'
import * as React from 'react'
import {Share, StyleSheet, Text, View} from 'react-native'
import {Button, Button as ButtonPaper, Caption, Divider, IconButton, Text as TextPaper, Title} from 'react-native-paper'
import {SafeAreaView} from 'react-native-safe-area-context'
import {useMutation, useQuery} from 'react-query'
import * as Yup from 'yup'
import CommentsBox from '../../../components/CommentsBox'
import {FF, FormContainer, FormikInput} from '../../../components/form/FormInputs'
import AppFlatList from '../../../components/layout/AppFlatList'
import {AppGridCol, AppGridRow} from '../../../components/layout/AppGridView'
import AppScrollView from '../../../components/layout/AppScrollView'
import AppTabView from '../../../components/layout/AppTabView'
import ModalButton from '../../../components/layout/ModalButton'
import ActivityLoading from '../../../components/loading'
import PlaceHolder from '../../../components/placeholder'
import Spacer from '../../../components/Spacer'
import TermsModal from '../../../components/TermsModal'
import {BOOK_STATUS} from '../../../constants/fields'
import {RootDrawerScreenProps} from '../../../navigation/types'
import {EventLogger} from '../../../services'
import ToasterManager from '../../../services/ToasterManager'
import {dateTimeFormat} from '../../../utils'
import {useMeQuery} from '../../Auth/hooks/useMeQuery'
import {qs} from '../../queries'
import {BookModel} from '../../typings'

const styles = StyleSheet.create({
  submitButton: {
    marginTop: 10,
    marginBottom: 25
  }
})

const GuestListTab = ({ book }: { book: null | BookModel }) => {
  const route = useRoute<RootDrawerScreenProps<'BookDetailScreen'>['route']>()
  const { data: meData } = useMeQuery()

  let item_id = route.params?.item_id

  const bookGuestList = useQuery(
    ['guest_lists.byBook', item_id],
    () => qs.guest_lists.byBook(item_id),
    { enabled: false }
  )

  const createGuestList = useMutation((values: any) => qs.guest_lists.post(values), {
    onSuccess: () => {
      ToasterManager.success('Criado com sucesso!')
      bookGuestList.refetch()
    }
  })
  const deleteGuestList = useMutation((id: number | string) => qs.guest_lists.delete({ id: id }), {
    onSuccess: () => {
      ToasterManager.success('Excluído com sucesso!')
      bookGuestList.refetch()
    }
  })

  console.log('deleteGuestList', deleteGuestList)

  useFocusEffect(
    React.useCallback(() => {
      bookGuestList.refetch()
      return () => {
        bookGuestList.remove()
      }
    }, [item_id])
  )

  const AddGuestButton = () => {
    if (book?.id && book?.status !== 0 && moment(book.book_date).add(19, 'hour') >= moment()) {
      return (
        <React.Fragment>
          <ModalButton button_label="Adicionar convidado" title="Adicionar convidado">
            <Formik
              initialValues={{
                guests_in_line: ''
              }}
              validationSchema={Yup.object().shape({
                guests_in_line: Yup.string().required()
              })}
              validate={(values) => {
                let errs: any = {}

                const guests_length = bookGuestList?.data?.length ?? 0
                const total_names = values.guests_in_line.split('\n').filter(i => i.length > 0).length + guests_length

                if (book.space.guests_max - total_names < 0) {
                  errs.guests_in_line = 'Quantidade máxima excedida'
                }

                return errs
              }}
              onSubmit={(values) => {
                console.log(values)

                const guests_names = values.guests_in_line.split('\n').filter(i => i.length > 0)
                const guests_payload = guests_names.map(i => ({ name_temp: i, book: book.id, status: 1 }))

                createGuestList.mutate(guests_payload)
              }}
            >
              {({ values, errors, submitForm, setFieldValue }) => {
                const guests_length = bookGuestList?.data?.length ?? 0
                const qtd_in_input = values.guests_in_line.split('\n').filter(i => i.length > 0).length
                const available_qtd = Math.max(book.space.guests_max - (qtd_in_input + guests_length), 0)

                return (
                  <FormContainer>
                    <TextPaper style={{ fontWeight: 'bold' }}>
                      Digite o nome dos convidados linha por linha no campo a abaixo. Toque no botão "Enter" para uma nova linha.
                    </TextPaper>

                    <Spacer size={10}/>

                    <TextPaper>
                      Total de Convidados: {qtd_in_input + guests_length}
                      {'\n'}
                      Disponível: {available_qtd}
                    </TextPaper>

                    <Spacer size={15}/>

                    <FF
                      label="" name="guests_in_line"
                      placeholder="Digite o nome dos convidados aqui..."
                      component={FormikInput}
                      multiline={true}
                      scrollEnabled={false}
                    />

                    <Spacer size={30}/>

                    <ButtonPaper icon="content-save" mode="contained" loading={createGuestList.isLoading} onPress={() => submitForm()}>
                      Salvar
                    </ButtonPaper>

                    <Spacer size={60}/>

                  </FormContainer>
                )
              }}
            </Formik>
          </ModalButton>

          {meData?.team?.book_pin === true &&
            <View>
              <Text>Após adicionar os seus convidados, você deverá compartilhar com eles o Pin de acesso. O Pin será a chave de acesso do
                seu
                convidado ao seu evento, e deverá ser informado por ele na portaria.</Text>
            </View>
          }
        </React.Fragment>
      )
    } else {
      return null
    }
  }

  const DeleteGuestButton = ({ item }: any) => {
    if (book?.id && book.status !== 0 && moment(book.book_date).add(19, 'hour') >= moment()) {
      return (
        <View>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            {meData?.team?.book_pin === true &&
              <IconButton icon="share" onPress={() => {
                Share.share({
                  title: 'Seu pin de acesso:' + item.pin,
                  message: 'Seu pin de acesso: ' + item.pin + ', minha unidade: ' + book.unit.with_block + '. Ao chegar na portaria informe' +
                    ' esse pin. Data do evento: ' + dateTimeFormat(book.book_date)
                })
              }}/>}

            <IconButton color="red" icon="delete" onPress={() => { deleteGuestList.mutate(item.id) }} disabled={deleteGuestList.isLoading}/>
          </View>
        </View>
      )
    } else {
      return null
    }
  }

  const renderItem = ({ item }: { item: any }) => (
    <AppGridRow>
      <AppGridCol style={{ flex: 3 }}>
        <Text>{item.name_temp}</Text>
      </AppGridCol>

      <AppGridCol>
        <DeleteGuestButton item={item}/>
      </AppGridCol>
    </AppGridRow>
  )

  return (
    <>
      <View style={{ paddingHorizontal: 8, paddingTop: 10 }}>
        <AddGuestButton/>
      </View>

      <AppFlatList
        data={bookGuestList?.data ?? []}
        renderItem={renderItem}
        keyExtractor={item => item.id + ''}
        refreshing={bookGuestList.isFetching}
        onRefresh={bookGuestList.refetch}
        ItemSeparatorComponent={Divider}
        ListEmptyComponent={() => (
          <PlaceHolder
            visible={true}
            emoji={'🕺'}
            description={'Crie o seu primeiro convidado, tocando no botão acima.'}
          />
        )}
      />
    </>
  )
}

const BookDetailScreen = () => {
  const route = useRoute<RootDrawerScreenProps<'BookDetailScreen'>['route']>()
  const navigation = useNavigation<RootDrawerScreenProps<'BookDetailScreen'>['navigation']>()

  let item_id = route.params?.item_id

  const { data, isFetching, refetch, remove } = useQuery(
    [qs.books.get_key, item_id],
    () => qs.books.get(item_id),
    { enabled: false }
  )

  const cancelBook = useMutation((values: number | string) => qs.books.cancel(values), {
    onSuccess: () => {
      ToasterManager.success('Reserva cancelada com sucesso')
      navigation.navigate('BookListScreen')
    }
  })

  useFocusEffect(
    React.useCallback(() => {
      refetch()
      EventLogger.logEvent('SeeBookDetail', 'screen', item_id, 'book')
      return () => {
        remove()
      }
    }, [item_id])
  )

  const CancelButton = () => {
    if (data) {
      const deadline = moment(data.book_date).subtract(data.space.book_days_cancel, 'days').startOf('day')

      if (data.status !== 0 && deadline >= moment().startOf('day')) {
        return (
          <View>
            <Button style={styles.submitButton} onPress={() => {cancelBook.mutate(data.id)}}>
              <Text>Cancelar reserva</Text>
            </Button>
          </View>
        )
      } else {
        return null
      }
    }

    return null
  }

  const DetailTab = () => (
    <AppScrollView>
      {data && <View style={{ backgroundColor: '#eeeeee', padding: 15, borderRadius: 7 }}>
        <Title>{data.space.name}</Title>
        <Text style={{ marginVertical: 6 }}>{data.space.description}</Text>

        <View style={{ marginVertical: 12 }}>
          <AppGridRow>
            <AppGridCol>
              <Caption>Capacidade</Caption>
              <Text>{data.space.guests_max} pessoas</Text>
            </AppGridCol>
            <AppGridCol>
              <Caption>Taxa </Caption>
              <Text>R$ {data.tax}</Text>
            </AppGridCol>
          </AppGridRow>

          <AppGridRow style={{ marginTop: 30 }}>
            <AppGridCol>
              <Caption>Data </Caption>
              <Text>{dateTimeFormat(data.book_date)}</Text>
            </AppGridCol>
            <AppGridCol>
              <Caption>Status </Caption>
              <Text>{BOOK_STATUS[data.status]}</Text>
            </AppGridCol>
          </AppGridRow>

          <View style={{ marginTop: 30 }}>
            <Caption>Descrição do Evento</Caption>
            <Text>{data.event_description}</Text>
            <Text> </Text>
            <Caption>Observações</Caption>
            <Text>{data.description}</Text>
          </View>
        </View>
      </View>}

      {data && <View>
        <Spacer size={25}/>

        <CommentsBox commentable_id={data.id} commentable_type="book"/>

        <Spacer size={25}/>

        <TermsModal title={`Regimento ${data.space.name}`} terms={data.space.regiment}/>

        <Spacer size={25}/>

        <Text>Prazo máximo para cancelar a reserva: {data.space.book_days_cancel} dias antes da data do evento.</Text>
        <CancelButton/>
      </View>}
    </AppScrollView>
  )

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      {
        !isFetching && data?.id ?
          <AppTabView scenes={[
            { key: 'all', title: 'Detalhes', scene: DetailTab },
            { key: 'my', title: 'Lista de Convidados', scene: () => <GuestListTab book={data}/> }
          ]}/>
          :
          <ActivityLoading visible={isFetching}/>
      }
    </SafeAreaView>
  )
}

export default function (props: any) {
  const isFocused = useIsFocused()

  if (!isFocused) return null

  return (
    <BookDetailScreen/>
  )
}
