import * as React from 'react'
import {StyleSheet, View, ViewProps} from 'react-native'

const styles = StyleSheet.create({
  cardContainer: {
    // padding: 12,
    // marginBottom: 14
  },
  grid_row: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  grid_col: {
    flex: 1,
    alignSelf: 'flex-start'
  }
})

export const AppGridRow: React.FC<ViewProps> = ({ style, ...props }) => {
  return (
    <View
      style={[styles.grid_row, style]}
      {...props}
    >
      {props.children}
    </View>
  )
}

export const AppGridCol: React.FC<ViewProps> = ({ style, ...props }) => {
  return (
    <View
      style={[styles.grid_col, style]}
      {...props}
    >
      {props.children}
    </View>
  )
}
