import {useFocusEffect, useIsFocused, useNavigation, useRoute} from '@react-navigation/native'
import {Formik} from 'formik'
import React, {Fragment, useEffect} from 'react'
import {Linking, Text, TouchableOpacity, View} from 'react-native'
import {Button, Divider, Headline, List, Paragraph, RadioButton, Subheading, Title} from 'react-native-paper'
import {SafeAreaView} from 'react-native-safe-area-context'
import {useMutation, useQuery} from 'react-query'
import * as Yup from 'yup'
import {FormContainer} from '../../../components/form/FormInputs'
import ActivityLoading from '../../../components/loading'
import Spacer from '../../../components/Spacer'
import {RootDrawerScreenProps} from '../../../navigation/types'
import ToasterManager from '../../../services/ToasterManager'
import {qs} from '../../queries'

function SurveyDetailScreen() {
  const navigation = useNavigation<RootDrawerScreenProps<'SurveyDetailScreen'>['navigation']>()
  const route = useRoute<RootDrawerScreenProps<'SurveyDetailScreen'>['route']>()

  let item_id = route.params?.item_id

  const { data, isFetching, refetch, remove } = useQuery(
    [qs.surveys.get_key, item_id],
    () => qs.surveys.get(item_id),
    { enabled: false }
  )

  useFocusEffect(
    React.useCallback(() => {
      refetch()

      return () => {
        remove()
      }
    }, [item_id])
  )

  const mutation = useMutation((values: any) => qs.survey_votes.post(values), {
    onSuccess: () => {
      ToasterManager.success('Voto registrado com sucesso!')
      navigation.navigate('SurveyListScreen')
    }
  })

  useEffect(() => {
    refetch()
  }, [item_id])

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      {
        !isFetching && data?.id ?
          <React.Fragment>
            <Formik
              initialValues={{
                option_ids: data?.my_vote?.option_ids ?? [],
                existing_option_ids: data?.my_vote?.option_ids ?? []
              }}
              validationSchema={Yup.object().shape({
                option_ids: Yup.array().required().min(1)
              })}
              onSubmit={async (values: any) => {
                if (data.allow_multiple_choice) {
                  let post_data = values.option_ids.map((i: number) => ({ survey_option: i }))
                  mutation.mutate(post_data)
                } else {
                  let post_data = { survey_option: values.option_ids[0] }
                  mutation.mutate(post_data)
                }
              }}
            >
              {({ values, errors, submitForm, setFieldValue }) => {
                const radio_disabled = data.is_finished || ((values.existing_option_ids).length && !data.enable_change_vote)
                const show_chart = data.is_finished || data.enable_partial_result

                const changeVote = (option_id: number) => {
                  let current_options = values.option_ids.map(x => x)
                  let has_index = current_options.findIndex(x => x === option_id)

                  if (data.allow_multiple_choice) {
                    if (has_index > -1) {
                      current_options = current_options.filter(x => x !== option_id)
                    } else {
                      current_options.push(option_id)
                    }
                  } else {
                    current_options = []
                    current_options.push(option_id)
                  }

                  setFieldValue('option_ids', current_options)
                }

                const SubmitButton = () => {
                  if (data.is_finished) {
                    return null
                  }

                  if (!(values.existing_option_ids).length || (values.existing_option_ids && data.enable_change_vote)) {
                    return (
                      <Button
                        loading={mutation.isLoading} disabled={mutation.isLoading} icon="content-save" mode="contained"
                        onPress={() => submitForm()}
                      >
                        Registrar Voto!
                      </Button>
                    )
                  }

                  return (<Text> </Text>)
                }

                const Bar = ({ width }: { width: string }) => {

                  return (
                    <View style={{ backgroundColor: '#325de8', height: 30, width: width, marginBottom: 5 }}/>
                  )
                }

                const Chart = () => {
                  if (!show_chart) {
                    return null
                  }

                  if (!data.is_finished && !data?.my_vote?.votes) {
                    return (
                      <Fragment>
                        <Text>Após registrar o voto, poderá acompanhar o resultado parcial.</Text>
                        <Spacer/>
                      </Fragment>
                    )
                  }

                  if (data.charts && data.charts.length > 0) {
                    const max_votes = data.charts.map(i => i.count).reduce((a, b) => a + b, 0)

                    if (max_votes === 0) return (<Text>Nenhum resultado ainda</Text>)

                    return (
                      <React.Fragment>
                        <Subheading>Resultados:</Subheading>
                        <Divider/>
                        <Divider/>

                        {data.charts.map(i => {
                          let size = (100 * i.count) / max_votes
                          return (
                            <React.Fragment key={i.name}>
                              <Paragraph>{i.name} - {Math.round(size * 100) / 100}%</Paragraph>
                              <Bar width={`${size < 100 ? size + 2 : size}%`}/>
                            </React.Fragment>
                          )
                        })}

                        <Spacer/>
                      </React.Fragment>
                    )
                  }

                  return (
                    <Fragment>
                      <Text>Nenhum resultado ainda</Text>
                      <Spacer/>
                    </Fragment>
                  )
                }

                return (
                  <FormContainer>
                    {data?.is_finished && <Title>ENQUETE ENCERRADA</Title>}

                    <Headline>{data?.name ?? ' '}</Headline>

                    {data?.description &&
                      <React.Fragment>
                        <Spacer size={10}/>
                        <Subheading>{data.description}</Subheading>
                      </React.Fragment>}

                    {data?.files && data.files.length > 0 &&
                      <React.Fragment>
                        <Spacer size={10}/>
                        <Subheading>Anexos:</Subheading>
                        <Divider/>
                        <Divider/>

                        {data.files.map(i => (
                          <List.Item
                            key={i.file}
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                            onPress={() => Linking.openURL(i.file)}
                            left={props => <List.Icon {...props} icon="folder"/>}
                            title={i.name}
                          />
                        ))}
                      </React.Fragment>}

                    <Spacer size={50}/>

                    <Subheading>
                      {data.allow_multiple_choice ? `Opções (selecione até ${data.max_multiple_choices} opções):` : 'Opções:'}
                    </Subheading>
                    <Divider/>
                    <Divider/>

                    {data.options.map(i => (
                      <TouchableOpacity onPress={() => changeVote(i.id)} key={i.id}>
                        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: 15 }}>
                          <RadioButton
                            disabled={radio_disabled as boolean}
                            value={String(i.id)}
                            status={values.option_ids.indexOf(i.id) > -1 ? 'checked' : 'unchecked'}
                          />
                          <Title style={{ marginLeft: 15 }}>{i.name}</Title>
                        </View>
                      </TouchableOpacity>
                    ))}

                    <Spacer/>
                    <Chart/>

                    <SubmitButton/>
                  </FormContainer>
                )
              }}
            </Formik>


          </React.Fragment>
          : <ActivityLoading visible={isFetching}/>
      }

    </SafeAreaView>
  )
}

export default function (props: any) {
  const isFocused = useIsFocused()

  if (!isFocused) return null

  return (
    <SurveyDetailScreen/>
  )
}
