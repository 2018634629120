import {useMeQuery} from '../../features/Auth/hooks/useMeQuery'
import queryClient from '../../services/queryClient'
import {gv} from '../../utils'

export const hasPerm = (requiredPermissions: string[], hasAll = false) => {
  const user = queryClient.getQueryData('useMeQuery')
  let userPermissions = gv(user, 'permissions') as string[]

  if (!userPermissions) return false

  let filteredList = userPermissions.filter((permission: string) => {
    return requiredPermissions.filter(required => required === permission).length > 0
  })

  return hasAll ? filteredList.length === requiredPermissions.length : filteredList.length > 0
}

export const useHasPerm = (requiredPermissions: string[], hasAll = false) => {
  const { data: meData } = useMeQuery()
  const userPermissions = meData?.permissions

  if (!userPermissions) return false

  let filteredList = userPermissions.filter((permission: string) => {
    return requiredPermissions.filter(required => required === permission).length > 0
  })

  return hasAll ? filteredList.length === requiredPermissions.length : filteredList.length > 0
}
