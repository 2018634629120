import authService from './services/authService'

const prod = true

const dev_rootApi = 'http://localhost:8004/'
const dev_apiUrl = 'http://localhost:8004/api/v1/'

const prod_root_api_v1 = 'https://api.condoid.com.br/'
const prod_api_url_v1 = 'https://api.condoid.com.br/api/v1/'

const prod_root_api_v2 = 'https://apiv2.condoid.com.br/'
const prod_api_url_v2 = 'https://apiv2.condoid.com.br/api/v2/'

export default async () => {
  const team_version = await authService.getTeamVersionLocal()

  const prod_rootApi = team_version ? prod_root_api_v2 : prod_root_api_v1
  const prod_apiUrl = team_version ? prod_api_url_v2 : prod_api_url_v1

  return {
    rootApi: prod ? prod_rootApi : dev_rootApi,
    apiUrl: prod ? prod_apiUrl : dev_apiUrl
  }
}
