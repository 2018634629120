import React from 'react'
import { Dimensions, View } from 'react-native'

const win = Dimensions.get('window')

export default class Spacer extends React.Component {
  render() {
    let {size} = this.props
    return (
      <View style={{width: win.width, paddingBottom: size ? size : 50}}/>
    )
  }
}