import React from 'react'
import {Dimensions, ScrollView, StyleSheet, View} from 'react-native'
import Modal from 'react-native-modalbox'
import {Button, Headline, Text} from 'react-native-paper'

const win = Dimensions.get('window')

export default class TermsModal extends React.Component {
  static defaultProps = {
    title: 'Regimento',
    terms: '',
    button: 'Ver termos'
  }

  render() {
    return (
      <React.Fragment>

        <Button mode="outlined" style={{ marginBottom: 15 }}
                onPress={() => this.refs.modal_terms.open()}>{this.props.button}</Button>

        <Modal style={[styles.modal]} ref={'modal_terms'} swipeToClose={true} swipeArea={80} backdrop={true} coverScreen={true}
               position={'bottom'}>
          <View style={styles.modalRuleContainer}>
            <View style={styles.modalRule}>
            </View>
          </View>

          <ScrollView>
            <Headline>{this.props.title}</Headline>
            <Text> </Text>
            <Text>{this.props.terms}</Text>
            <Text> </Text>
            {this.props.children}
          </ScrollView>
        </Modal>

      </React.Fragment>
    )
  }
}

const styles = StyleSheet.create({
  label: {
    fontSize: 14,
    marginBottom: 7,
    fontWeight: '100',
    color: '#8B98A4'
  },
  modalRuleContainer: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalRule: {
    marginTop: 20,
    marginBottom: 18,
    borderRadius: 10,
    height: 5,
    backgroundColor: '#D6DBE1',
    width: win.width / 5
  },
  modal: {
    height: win.height / 1.1,
    backgroundColor: '#ffffff',
    padding: 10
  }
})
