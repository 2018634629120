import {MaterialCommunityIcons} from '@expo/vector-icons'
import React, {useEffect, useState} from 'react'
import {Dimensions} from 'react-native'
import {SceneMap, TabBar, TabView} from 'react-native-tab-view'
import {theme_app} from '../../styles/theme'

const renderTabBar = (props: any) => {
  return (
    <TabBar
      {...props}
      indicatorStyle={{ backgroundColor: 'white' }}
      style={{ backgroundColor: theme_app().colors.primary }}
      renderIcon={({ route, focused, color }) => (
        route.icon ?
          <MaterialCommunityIcons
            name={route.icon as any}
            color={color}
            size={20}
          /> :
          null
      )}
    />
  )
}

export type AppTabViewProps = {
  scenes: {
    key: string,
    title: string,
    icon?: keyof typeof MaterialCommunityIcons.glyphMap,
    scene: React.ComponentType,

  }[]
}

const AppTabView = React.memo((props: AppTabViewProps) => {
  const [index, setIndex] = useState(0)
  const [routes] = useState(props.scenes.map(i => (
    { key: i.key, title: i.title, icon: i.icon }
  )))
  const [scenes_map, setScenesMap] = useState<{ [key: string]: React.ComponentType }>({})

  useEffect(() => {
    let ok_scenes: any = {}
    props.scenes.forEach(i => ok_scenes[i.key] = i.scene)
    setScenesMap(ok_scenes)
  }, [props.scenes])

  if (Object.keys(scenes_map).length) {
    return (
      <TabView
        lazy
        initialLayout={{ width: Dimensions.get('window').width }}
        renderTabBar={renderTabBar}
        navigationState={{ index, routes }}
        renderScene={SceneMap(scenes_map)}
        onIndexChange={setIndex}
      />
    )
  }

  return null

})

export default AppTabView