// import * as Amplitude from 'expo-analytics-amplitude'
import Constants from 'expo-constants'
import * as Localization from 'expo-localization'
import {StatusBar} from 'expo-status-bar'
import * as moment from 'moment'
// import Reactotron from './ReactotronConfig'
import React, {Suspense} from 'react'
import {Platform, View} from 'react-native'
import {LocaleConfig} from 'react-native-calendars'
import DropdownAlert from 'react-native-dropdownalert'
import {Provider as PaperProvider} from 'react-native-paper'
import {pt, registerTranslation} from 'react-native-paper-dates'
import {SafeAreaProvider} from 'react-native-safe-area-context'
import {QueryClientProvider} from 'react-query'
import {Provider} from 'react-redux'
import {RecoilRoot} from 'recoil'
import * as Sentry from 'sentry-expo'
import InMessaging from './src/components/InMessaging'
import ActivityLoading from './src/components/loading'
import LoadingRedux from './src/components/LoadingRedux'
import useCachedResources from './src/hooks/useCachedResources'
import ZohoSalesIQ from './src/nativeModules/ZohoSalesIQ'
import AppNavigation from './src/navigation/AppNavigation'
import {store} from './src/redux'
import './src/services/i18nService'
import InMessagingManager from './src/services/InMessagingManager'
import queryClient from './src/services/queryClient'
import ToasterManager from './src/services/ToasterManager'
import {theme_app} from './src/styles/theme'
import {platformChecker, yupRules} from './src/utils'

moment.locale('pt-BR')
let moment2 = require('moment-timezone')
moment2.tz.setDefault('America/Sao_Paulo')

yupRules()

LocaleConfig.locales['br'] = {
  monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
  monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abri', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
  dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
  dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
  today: 'Hoje'
}
LocaleConfig.defaultLocale = 'br'

// Sentry.enableInExpoDevelopment = true;
Sentry.init({
  dsn: 'https://24d70fb9f20e40cea07930f187fdda54@sentry.io/253055',
  enableInExpoDevelopment: true,
  debug: false,
  release: Constants.manifest?.revisionId
})

if (Platform.OS === 'android') {
  require('@formatjs/intl-getcanonicallocales/polyfill')
  require('@formatjs/intl-locale/polyfill')

  require('@formatjs/intl-pluralrules/polyfill')
  require('@formatjs/intl-pluralrules/locale-data/pt.js')

  require('@formatjs/intl-displaynames/polyfill')
  require('@formatjs/intl-displaynames/locale-data/pt.js')

  require('@formatjs/intl-listformat/polyfill')
  require('@formatjs/intl-listformat/locale-data/pt.js')

  require('@formatjs/intl-numberformat/polyfill')
  require('@formatjs/intl-numberformat/locale-data/pt.js')

  require('@formatjs/intl-relativetimeformat/polyfill')
  require('@formatjs/intl-relativetimeformat/locale-data/pt.js')

  require('@formatjs/intl-datetimeformat/polyfill')
  require('@formatjs/intl-datetimeformat/locale-data/pt.js')

  require('@formatjs/intl-datetimeformat/add-golden-tz.js')

  // https://formatjs.io/docs/polyfills/intl-datetimeformat/#default-timezone

  if ('__setDefaultTimeZone' in Intl.DateTimeFormat) {
    Intl.DateTimeFormat.__setDefaultTimeZone(
      Localization.timezone
    )
  }
}

registerTranslation('pt', pt)

/*
*
*
*  NATIVE ONLY
*
* */
if (platformChecker.isNative) {
// Segment.initialize({androidWriteKey: 'X7qc09bZDdCWffKlXqpevr6zQ0l3nY80', iosWriteKey: 'VXniB0nmVO1NqvqxHzLiy3PyaDHQtGiz'});
//   Amplitude.initializeAsync('23653d6c9ecc2d18022730f5d701c73f')
}

ZohoSalesIQ.setup()

function App() {
  const { isLoadingComplete, isLoggedIn, theme } = useCachedResources()
  let _theme = theme_app()

  console.log('App theme', theme)
  console.log('App _theme', _theme)

  if (!isLoadingComplete || !theme?.colors?.primary) {
    return <View style={{ flex: 1, backgroundColor: '#333' }}>
      <ActivityLoading visible={true}/>
    </View>
  } else {
    return (
      <SafeAreaProvider>
        <StatusBar style="light" translucent={true} backgroundColor={theme.colors.primary}/>
        <Provider store={store}>
          <RecoilRoot>
            <QueryClientProvider client={queryClient}>
              <PaperProvider theme={theme}>

                <Suspense fallback={<></>}>
                  <AppNavigation isLoggedIn={isLoggedIn}/>
                </Suspense>

                <LoadingRedux/>
              </PaperProvider>
            </QueryClientProvider>
          </RecoilRoot>
        </Provider>
        <DropdownAlert
          ref={ref => ToasterManager.setDropDown(ref)}
          titleNumOfLines={2}
          translucent={true}
        />
        <InMessaging ref={ref => InMessagingManager.set(ref)}/>
      </SafeAreaProvider>
    )
  }
}

export default App
// export default Sentry.Native.wrap(App);