import {useIsFocused, useRoute} from '@react-navigation/native'
import React from 'react'
import {SafeAreaView} from 'react-native-safe-area-context'
import {RootDrawerScreenProps} from '../../../navigation/types'
import VehicleForm from '../components/VehicleForm'

function VehicleDetailScreen() {
  const route = useRoute<RootDrawerScreenProps<'VehicleDetailScreen'>['route']>()

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      <VehicleForm initialValues={route.params?.data}/>
    </SafeAreaView>
  )
}

export default function (props: any) {
  const isFocused = useIsFocused()

  if (!isFocused) return null

  return (
    <VehicleDetailScreen/>
  )
}
