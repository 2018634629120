import React, {Component} from 'react'
import {Dimensions, Image, ScrollView, StyleSheet} from 'react-native'
import Modal from 'react-native-modalbox'
import {Button, Text, Title} from 'react-native-paper'
import {giv, gv} from '../utils'

const win = Dimensions.get('window')

export default class InMessaging extends Component {
  styles = StyleSheet.create({
    scroll: {
      paddingHorizontal: 15,
      paddingVertical: 10,
      paddingBottom: 25
    },
    modal: {
      justifyContent: 'center',
      alignItems: 'center',
      height: win.height * 0.70,
      width: win.width * 0.80,
      borderRadius: 10
    },
    text: {
      marginBottom: 5
    },
    btn: {
      marginBottom: 10
    },
    bold: {
      marginBottom: 5,
      fontWeight: 'bold'
    }
  })

  constructor(props) {
    super(props)
    this.state = {
      visible: this.props.visible,
      message: {}
    }
  }

  handleAction = (cta) => {
    if (cta.action === 'DISMISS_ALERT') this.refs.modal.close()
  }

  show = (message) => {
    if (gv(message, 'template.image')) {
      this.styles.modal.height = win.height * 0.70
    } else {
      this.styles.modal.height = win.height * 0.50
    }

    this.setState({ message })
    this.refs.modal.open()
  }

  hide = () => {
    this.setState({ message: {} })
    this.refs.modal.close()
  }

  render() {
    let ctas = giv(this.state.message, 'template.ctas', [])
    let body = giv(this.state.message, 'template.body', [])

    return (
      <Modal style={[this.styles.modal]} swipeToClose={false} backButtonClose={true} position={'center'} ref={'modal'} onClosed={this.hide}>

        {gv(this.state.message, 'template.image') &&
          <Image
            source={{ uri: this.state.message.template.image }}
            resizeMode={'cover'}
            style={{
              width: win.width * 0.80,
              height: 120,
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10
            }}
          />}

        <ScrollView style={this.styles.scroll}>
          <Title>{gv(this.state.message, 'template.title')}</Title>
          <Text> </Text>

          {body.map(i => {
            if (i.elementType === 'TEXT') return <Text key={i.content} style={this.styles.text}>{i.content}</Text>
            if (i.elementType === 'BOLD') return <Text key={i.content} style={this.styles.bold}>{i.content}</Text>
          })}

          <Text> </Text>

          {ctas.map(i => {
            if (i.ctaType === 'CONTAINED') return (
              <Button key={i.copy} style={this.styles.btn} compact={true} color={i.color} mode="contained"
                      onPress={() => this.handleAction(i)}>{i.copy}</Button>
            )
            if (i.ctaType === 'OUTLINED') return (
              <Button key={i.copy} style={this.styles.btn} compact={true} color={i.color} mode="outlined"
                      onPress={() => this.handleAction(i)}>{i.copy}</Button>
            )
            if (i.ctaType === 'TEXT') return (
              <Button key={i.copy} style={this.styles.btn} compact={true} color={i.color} mode="text"
                      onPress={() => this.handleAction(i)}>{i.copy}</Button>
            )

          })}

          <Text> </Text>
          <Text> </Text>
        </ScrollView>
      </Modal>
    )
  }
}
