import {useIsFocused, useNavigation} from '@react-navigation/native'
import {Formik} from 'formik'
import React from 'react'
import {Button} from 'react-native-paper'
import {SafeAreaView} from 'react-native-safe-area-context'
import {useMutation} from 'react-query'
import * as Yup from 'yup'
import {HighlightBox} from '../../../components'
import {AclRender} from '../../../components/acl/components'
import DialogSelect from '../../../components/form/DialogSelect'
import {FormikDatePicker, FormikDateTimePicker, FormikTimePicker} from '../../../components/form/FormikInputs'
import {FF, FormContainer, FormikInput, FormikRadio} from '../../../components/form/FormInputs'
import {AccessReasonSelect} from '../../../components/form/selects'
import UnitSelect from '../../../components/form/UnitSelect'
import Spacer from '../../../components/Spacer'
import {AccessAuthTypesEnum} from '../../../constants/app-enums'
import {ACCESSAUTH_TYPES} from '../../../constants/fields'
import {RootDrawerScreenProps} from '../../../navigation/types'
import ToasterManager from '../../../services/ToasterManager'
import {qs} from '../../queries'

type Props = {
  initialValues: any,
}

function AccessAuthorizationForm({ initialValues }: Props) {
  const navigation = useNavigation<RootDrawerScreenProps<'AccessAuthorizationNewScreen'>['navigation']>()

  const create = useMutation((variables: any) => qs.access_authorizations.post(variables), {
    onSuccess: () => {
      navigation.navigate('AccessAuthorizationListScreen')
      ToasterManager.success('Criado com seucesso!')
    }
  })

  const update = useMutation((variables: any) => qs.access_authorizations.patch(variables), {
    onSuccess: () => {
      navigation.navigate('AccessAuthorizationListScreen')
      ToasterManager.success('Atualizado com seucesso!')
    }
  })

  return (
    <Formik
      initialValues={{
        unit: initialValues?.unit_id ?? '',
        access_reason: initialValues?.access_reason ?? '',
        type: initialValues?.type ?? '',
        description: initialValues?.description ?? '',
        name_temp: initialValues?.name_temp ?? '',
        cpf: initialValues?.cpf ?? '',
        date: initialValues?.date ?? '',
        start_date: initialValues?.start_date ?? '',
        end_date: initialValues?.end_date ?? '',
        start_time: initialValues?.start_time ?? '',
        end_time: initialValues?.end_time ?? '',
        intercom: initialValues?.intercom ?? false,
        vehicle_plate: initialValues?.vehicle_plate ?? '',
        vehicle_model: initialValues?.vehicle_model ?? '',
        vehicle_color: initialValues?.vehicle_color ?? '',
        enable_vehicle: false
      }}
      validationSchema={Yup.object().shape({
        unit: Yup.string().required(),
        access_reason: Yup.string().required(),
        type: Yup.string().required(),
        description: Yup.string().required(),
        name_temp: Yup.string().required(),
        // @ts-ignore
        vehicle_plate: Yup.string().validPlate(),

        date: Yup.string()
          .when('type', (type: AccessAuthTypesEnum) => type === AccessAuthTypesEnum.once ? Yup.string().required() : Yup.string()),

        start_date: Yup.string()
          .when('type', (type: AccessAuthTypesEnum) => type === AccessAuthTypesEnum.period ? Yup.string().required() : Yup.string()),
        end_date: Yup.string()
          .when('type', (type: AccessAuthTypesEnum) => type === AccessAuthTypesEnum.period ? Yup.string().required() : Yup.string()),
        start_time: Yup.string()
          .when('type', (type: AccessAuthTypesEnum) => type === AccessAuthTypesEnum.period ? Yup.string().required() : Yup.string()),
        end_time: Yup.string()
          .when('type', (type: AccessAuthTypesEnum) => type === AccessAuthTypesEnum.period ? Yup.string().required() : Yup.string())
      })}
      onSubmit={(values) => {
        create.mutate(values)
      }}
      render={({ values, errors, submitForm, setFieldValue }) => {
        if (!values.enable_vehicle) {
          if (values.vehicle_plate) setFieldValue('vehicle_plate', '')
          if (values.vehicle_model) setFieldValue('vehicle_model', '')
          if (values.vehicle_color) setFieldValue('vehicle_color', '')
        }

        return (
          <FormContainer>
            <UnitSelect/>
            <AccessReasonSelect/>

            <FF label={'Nome'} name="name_temp" component={FormikInput}/>
            <FF label={'CPF'} name="cpf" component={FormikInput}/>

            <FF
              label={'Validade da Autorização'} name="type" component={DialogSelect} local_options={ACCESSAUTH_TYPES}
              onChange={(name: string, value: number) => {
                setFieldValue('start_date', '')
                setFieldValue('end_date', '')
                setFieldValue('start_time', '')
                setFieldValue('end_time', '')

                setFieldValue('date', '')
              }}
            />

            {values.type === AccessAuthTypesEnum.once && <FormikDateTimePicker label={'Data'} name="date"/>}

            {values.type === AccessAuthTypesEnum.period &&
              <React.Fragment>
                <FormikDatePicker label={'Data de Início'} name="start_date"/>
                <FormikDatePicker label={'Data de Término'} name="end_date"/>
                <FormikTimePicker label={'Hora de Início'} name="start_time"/>
                <FormikTimePicker label={'Hora de Término'} name="end_time"/>
              </React.Fragment>}

            <FF label={'Interfonar'} name="intercom" component={FormikRadio}/>
            <FF label={'Motivo da autorização'} name="description" multiline={true} component={FormikInput}/>

            <Spacer size={20}/>

            <FF label={'Liberar veículo'} name="enable_vehicle" component={FormikRadio}/>

            {values.enable_vehicle && <>
              <HighlightBox title="Atenção!" body="Verifique com a administração a disponibilidade deste recurso."/>

              <FF label={'Placa do veículo'} name="vehicle_plate" component={FormikInput}/>
              <FF label={'Modelo do veículo'} name="vehicle_model" component={FormikInput}/>
              <FF label={'Cor do veículo'} name="vehicle_color" component={FormikInput}/>
            </>}

            <Spacer size={80}/>

            <AclRender required={['access_control.add_accessauthorization']}>
              <Button
                icon="content-save" mode="contained"
                loading={create.isLoading || update.isLoading}
                disabled={create.isLoading || update.isLoading}
                onPress={() => submitForm()}
              >
                Salvar
              </Button>
            </AclRender>
          </FormContainer>
        )
      }}
    />
  )
}

function AccessAuthorizationNewScreen() {
  const isFocused = useIsFocused()

  if (!isFocused) return null

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      <AccessAuthorizationForm initialValues={null}/>
    </SafeAreaView>
  )
}

export default AccessAuthorizationNewScreen
