const hasOwnProperty = Object.prototype.hasOwnProperty

export function shallowEqual(objA, objB) {
  if (Object.is(JSON.stringify(objA), JSON.stringify(objB))) {
    return true
  }

  if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
    return false
  }

  const keysA = Object.keys(objA)
  const keysB = Object.keys(objB)

  if (keysA.length !== keysB.length) {
    return false
  }

  // if (keysA.length === keysB.length) {
  //   return true
  // }

  for (let i = 0; i < keysA.length; i++) {
    let testA = !hasOwnProperty.call(objB, keysA[i])
    let aa = objA[keysA[i]]
    let bb = objB[keysA[i]]
    let testB = !Object.is(aa, bb)
    let testC = testA || testB

    // let is_blank = aa.text === '-- Vazio --' && bb.text === '-- Vazio --'

    if (testC) {
      return false
    }
  }

  return true
}
