import {useFocusEffect, useNavigation} from '@react-navigation/native'
import moment from 'moment'
import * as React from 'react'
import {useState} from 'react'
import {Alert, StyleSheet, TouchableOpacity} from 'react-native'
import {Button, Caption, Title} from 'react-native-paper'
import {SafeAreaView} from 'react-native-safe-area-context'
import {useQuery} from 'react-query'
import {AclRender} from '../../../components/acl/components'
import AppFlatList from '../../../components/layout/AppFlatList'
import AppListCard from '../../../components/layout/AppListCard'
import ActivityLoading from '../../../components/loading'
import NotPermitted from '../../../components/NotPermitted'
import PlaceHolder from '../../../components/placeholder'
import Spacer from '../../../components/Spacer'
import {RootDrawerScreenProps} from '../../../navigation/types'
import {EventLogger} from '../../../services'
import {postApi} from '../../../services/api'
import ToasterManager from '../../../services/ToasterManager'
import {useAppTheme} from '../../../styles/theme'
import {gv} from '../../../utils'
import {qs} from '../../queries'
import {MeetingSubscriptionModel} from '../../typings'

const MeetingListScreen = () => {
  const theme = useAppTheme()
  const navigation = useNavigation<RootDrawerScreenProps<'MeetingListScreen'>['navigation']>()

  const { isFetching: isFetchingMeeting, data: meetings, refetch: refetchMeetings, remove: removeMeetings } = useQuery(
    qs.meetings.all_key,
    () => qs.meetings.all(),
    { enabled: false }
  )

  const { isFetching: isFetchingSubs, data: subs, refetch: refetchSubs, remove: removeSubs } = useQuery(
    qs.meeting_subs.all_key,
    () => qs.meeting_subs.all(),
    { enabled: false }
  )

  const isFetching = isFetchingMeeting || isFetchingSubs
  const [loading, setLoading] = useState(false)
  let DATA: any[] = []

  if (meetings) {
    DATA = [...meetings]
  }

  if (subs) {
    DATA = [...DATA, ...subs]
  }

  useFocusEffect(
    React.useCallback(() => {
      refetchMeetings()
      refetchSubs()

      EventLogger.logEvent('SeeMeetingList', 'screen')

      return () => {
        refetchMeetings()
        removeSubs()
      }
    }, [])
  )

  const handleAssignMeeting = async (data: any) => {
    const device_data = await EventLogger.context()

    let sub_data = {
      meeting: data.id,
      device_uuid: device_data.device.device_id,
      device_name: device_data.device.device_name
    }

    setLoading(true)

    postApi('resident/meeting-subscriptions', sub_data)
      .then((res) => {
        let id:any = gv(res, 'id')
        EventLogger.logEvent('NewMeetingSubscription', 'tap', id, 'meeting_subscription')

        setLoading(false)
        refetchSubs()
        refetchMeetings()
        navigation.navigate('MeetingSubscriptionDetailScreen', { item_id: res.id })
        Alert.alert('Cadastrado com Sucesso!', 'Clique na aba "Pautas" para votar.')
      })
      .catch((error) => {
        setLoading(false)
        ToasterManager.sendErrors(error)
      })
  }

  const handleRenderItem = ({ item }: { item: MeetingSubscriptionModel }) => {
    if (item?.meeting_id) {
      return (
        <TouchableOpacity onPress={() => navigation.navigate('MeetingSubscriptionDetailScreen', { item_id: item.id })}>
          <AppListCard>
            <Title>
              {item.meeting.name}
            </Title>
            <Caption>Data de início: {moment(item.meeting.start_date).format('DD/MM - HH:mm')}</Caption>

            <Spacer size={10}/>

            <Button
              mode="outlined"
              onPress={() => navigation.navigate('MeetingSubscriptionDetailScreen', { item_id: item.id })}
            >
              Entrar
            </Button>
          </AppListCard>
        </TouchableOpacity>
      )
    }

    return (
      <TouchableOpacity onPress={() => handleAssignMeeting(item)}>
        <AppListCard>
          <Title>
            {/*// @ts-ignore*/}
            {item.name}
          </Title>
          {/*// @ts-ignore*/}
          <Caption>Data de início: {moment(item.start_date).format('DD/MM - HH:mm')}</Caption>

          <Spacer size={10}/>

          <Button
            mode="outlined"
            onPress={() => handleAssignMeeting(item)}
          >
            Entrar
          </Button>
        </AppListCard>
      </TouchableOpacity>
    )
  }

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      <AppFlatList<MeetingSubscriptionModel>
        style={styles.list}
        contentContainerStyle={styles.listContent}
        data={DATA}
        renderItem={handleRenderItem}
        keyExtractor={item => item?.id + ''}
        refreshing={isFetching}
        onRefresh={() => {
          refetchMeetings()
          refetchSubs()
        }}
        ListEmptyComponent={() => (
          <PlaceHolder
            visible={true}
            emoji={'🙋'}
            description={'Nenhuma assembleia disponível.'}
          />
        )}
      />

      <ActivityLoading visible={loading}/>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  list: {
    flex: 1
  },
  listContent: {
    paddingHorizontal: 8,
    paddingVertical: 14
  },
  cardContainer: {
    borderWidth: 1,
    borderColor: '#e8e8e8',
    borderRadius: 6,
    padding: 12,
    marginBottom: 14
  }
})

export default function (props: any) {
  return (
    <AclRender required={['meeting.view_meeting']} fallback={<NotPermitted/>}>
      <MeetingListScreen/>
    </AclRender>
  )
}
