import {FontAwesome} from '@expo/vector-icons'
import {useFocusEffect, useNavigation} from '@react-navigation/native'
import {Formik} from 'formik'
import moment from 'moment'
import React, {useState} from 'react'
import {Alert, Linking, StyleSheet, View} from 'react-native'
import {Appbar, Button, Caption, Colors, Headline, Title} from 'react-native-paper'
import {SafeAreaView} from 'react-native-safe-area-context'
import {useInfiniteQuery, useMutation} from 'react-query'
import {AclRender} from '../../../../components/acl/components'
import DialogSelect from '../../../../components/form/DialogSelect'
import {FormikDatePicker, FormikInputTS} from '../../../../components/form/FormikInputs'
import {FF} from '../../../../components/form/FormInputs'
import UnitSelect from '../../../../components/form/UnitSelect'
import AppFab from '../../../../components/layout/AppFab'
import AppFlatList from '../../../../components/layout/AppFlatList'
import AppModalSheet from '../../../../components/layout/AppModalSheet'
import PlaceHolder from '../../../../components/placeholder'
import Spacer from '../../../../components/Spacer'
import {ReservationStatusEnum} from '../../../../constants/app-enums'
import {RESERVATION_STATUS} from '../../../../constants/fields'
import {RootDrawerScreenProps} from '../../../../navigation/types'
import ToasterManager from '../../../../services/ToasterManager'
import {useAppTheme} from '../../../../styles/theme'
import {handleGetNextPageParam, hv, platformChecker} from '../../../../utils'
import {qs} from '../../../queries'
import {ReservationModel} from '../../../typings'

function ReservationListScreen() {
  const theme = useAppTheme()
  const navigation = useNavigation<RootDrawerScreenProps<'ReservationListScreen'>['navigation']>()
  const [qs_filters, setQsFilters] = useState<any>(null)
  const [show_filter, setShowFilter] = useState<boolean>(false)
  const [last_file_url, setLastFileUrl] = useState<any>(null)

  const {
    data,
    hasNextPage,
    isFetching,
    fetchNextPage,
    refetch,
    remove
  } = useInfiniteQuery(
    [qs.reservations.all_key, qs_filters],
    async (_params) => qs.reservations.all_paginated(_params.pageParam ?? 1, 15, qs_filters),
    {
      enabled: false,
      getNextPageParam: handleGetNextPageParam
    })

  const revokeMutation = useMutation((variables: number) => qs.reservations.cancel(variables), {
    onSuccess: () => {
      refetch()
      ToasterManager.success('Reserva cancelada com sucesso')
    }
  })

  const voucherMutation = useMutation((variables: number) => qs.reservations.voucher(variables), {
    onSuccess: (file_url) => {
      if (platformChecker.isWeb) {
        setLastFileUrl(file_url)
      } else {
        Linking.openURL(file_url)
      }
    }
  })

  useFocusEffect(
    React.useCallback(() => {
      refetch()
      setLastFileUrl(null)
      navigation.setOptions({ headerRight: () => <Appbar.Action icon="filter" onPress={() => setShowFilter(true)}/> })
      return () => {
        remove()
        setLastFileUrl(null)
      }
    }, [])
  )

  const handleUpdateFilter = (values: any) => {
    let payload: any = {}

    if (values.unit) {
      payload['unit'] = values.unit
    }
    if (values.main_guest_name) {
      payload['main_guest.person.name.icontains'] = values.main_guest_name
    }
    if (hv(values.status)) {
      payload['status'] = values.status
    }
    if (values.check_in_out_dates_gte) {
      payload['check_in_out_dates.startswith.gte'] = values.check_in_out_dates_gte
    }
    if (values.check_in_out_dates_lte) {
      payload['check_in_out_dates.startswith.lte'] = values.check_in_out_dates_lte
    }

    setQsFilters(payload)
    setShowFilter(false)
    setLastFileUrl(null)
    refetch()
  }

  const renderItem = ({ item }: { item: ReservationModel }) => (
    <React.Fragment>
      <View style={styles.cardContainer}>
        <View style={{ flex: 1 }}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <View style={{ flex: 3 }}>
              <Caption>#{item.id}</Caption>
              <Title>
                {moment(item.check_in_out_dates.in_date).format('DD/MM/YY')} à {moment(item.check_in_out_dates.out_date).format('DD/MM/YY')}
              </Title>

              <Caption style={{ fontWeight: 'bold' }}>
                Titular: {item.main_guest?.person?.name}
              </Caption>

              <Caption style={{ fontWeight: 'bold' }}>
                <FontAwesome name="home" size={12} color={theme.colors.accent}/>{' '}
                {item.unit.with_block} {item.unit.door_code && <>- Porta: {item.unit.door_code}</>}
              </Caption>

              {/*<Caption style={{ fontWeight: 'bold' }}>*/}
              {/*  <FontAwesome name="male" size={12} color={theme.colors.accent}/>{' '}*/}
              {/*  {item.adults_qty}*/}
              {/*  {' '}{' '}{' '}*/}
              {/*  <FontAwesome name="child" size={12} color={theme.colors.accent}/>{' '}*/}
              {/*  {item.children_qty}*/}
              {/*</Caption>*/}

            </View>
            <View style={{ flex: 1, alignSelf: 'center' }}>
              <Caption style={{ textAlign: 'right', fontWeight: 'bold', color: theme.colors.accent }}>
                {RESERVATION_STATUS[item.status]}
              </Caption>
            </View>
          </View>

          {item.status !== ReservationStatusEnum.CANCELED &&
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', marginTop: 15 }}>
              <Button
                mode="text" icon="file-document-outline" compact
                onPress={() => voucherMutation.mutate(item.id)}
                loading={voucherMutation.variables === item.id && voucherMutation.isLoading}
                disabled={voucherMutation.variables === item.id && voucherMutation.isLoading}
              >
                Voucher
              </Button>

              <Button
                color={Colors.red500} mode="text" icon="delete-outline" compact
                onPress={() => {
                  if (platformChecker.isWeb) {
                    let ok = confirm('Tem certeza que gostaria de cancelar?')
                    if (ok) {
                      revokeMutation.mutate(item.id)
                    }
                  } else {
                    Alert.alert('Tem certeza que gostaria de cancelar?', '', [
                        { text: 'Não!' },
                        { text: 'Sim!', onPress: () => revokeMutation.mutate(item.id) }
                      ]
                    )
                  }
                }}
                loading={revokeMutation.variables === item.id && revokeMutation.isLoading}
                disabled={revokeMutation.variables === item.id && revokeMutation.isLoading}
              >
                Cancelar
              </Button>
            </View>}


        </View>
      </View>
    </React.Fragment>
  )

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      <AppFlatList
        data={data?.pages.map(page => page.data).flat() ?? []}
        onEndReached={() => hasNextPage && fetchNextPage()}
        renderItem={renderItem}
        keyExtractor={item => item.id + ''}
        refreshing={isFetching}
        onRefresh={refetch}
        ListEmptyComponent={() => (
          <PlaceHolder
            visible={true}
            emoji={'🛏️'}
            description={'Aqui você pode realizar solicitações de hospedagens para as suas unidades.'}
          />
        )}
      />

      <AclRender required={['pm_reservation.add_reservation']}>
        <AppFab icon="plus" onPress={() => navigation.navigate('ReservationNewScreen')}/>
      </AclRender>

      <AppModalSheet
        visible={show_filter}
        height_percent={70}
        onDismiss={() => setShowFilter(false)}
        content={() => (
          <Formik
            enableReinitialize={true}
            initialValues={{
              unit: qs_filters?.unit ?? '',
              status: qs_filters?.status ?? '',
              main_guest_name: qs_filters?.['main_guest.person.name.icontains'] ?? '',
              check_in_out_dates_gte: qs_filters?.['check_in_out_dates.startswith.gte'] ?? '',
              check_in_out_dates_lte: qs_filters?.['check_in_out_dates.startswith.lte'] ?? ''
            }}
            onSubmit={handleUpdateFilter}
          >
            {({ values, errors, submitForm, ...formikBag }) => {
              return (
                <React.Fragment>
                  <UnitSelect/>

                  <FormikInputTS label={'Nome titular'} name="main_guest_name"/>

                  <FF label={'Status'} name="status" component={DialogSelect} local_options={RESERVATION_STATUS}/>

                  <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View style={{ flex: 1, paddingRight: 7 }}>
                      <FormikDatePicker label={'Check-in (ínicio)'} name="check_in_out_dates_gte"/>
                    </View>
                    <View style={{ flex: 1, paddingLeft: 7 }}>
                      <FormikDatePicker label={'Check-in (fim)'} name="check_in_out_dates_lte"/>
                    </View>
                  </View>

                  <Spacer size={40}/>

                  <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Button
                      mode="outlined"
                      onPress={() => {
                        setQsFilters(null)
                        formikBag.resetForm()
                      }}
                    >
                      Limpar Filtros
                    </Button>
                    <Button icon="filter" mode="contained" onPress={submitForm}>Filtrar</Button>
                  </View>
                </React.Fragment>
              )
            }}
          </Formik>
        )}
      />

      <AppModalSheet
        visible={!!last_file_url}
        height_percent={40}
        onDismiss={() => setLastFileUrl(null)}
        content={() => (
          <React.Fragment>
            <Headline style={{ textAlign: 'center' }}>Voucher gerado!</Headline>

            <Spacer size={30}/>

            <Button
              icon="open-in-new" mode="contained"
              onPress={() => {
                window.open(last_file_url, '_blank')
              }}
            >
              Abrir Voucher
            </Button>
          </React.Fragment>
        )}
      />
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  cardContainer: {
    borderWidth: 1,
    borderColor: '#e8e8e8',
    borderRadius: 6,
    padding: 12,
    marginBottom: 14
  }
})

export default ReservationListScreen
