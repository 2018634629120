import AsyncStorage from '@react-native-async-storage/async-storage'
import {gv, hv} from '../utils'
import navigationService from './navigationService'
import queryClient from './queryClient'
import {sdkSentry} from './sentry'
import ToasterManager from './ToasterManager'

const authService = {
  checkUserData(data: any) {
    // Used when has an request erro in "data". To avoid unnecessary logout of user, check if has an id field
    if (!hv(data, 'id')) {
      return
    }

    const person = gv(data, 'person')
    if (person === null || person === undefined || person === false) {
      this.logout()

      ToasterManager.error('Não foi possível recuperar o seu cadastro. Tente entrar novamente, ou procure o suporte.')
      throw new Error('Não foi possível recuperar o seu cadastro')
    }

    const units = gv(data, 'person.units')
    if (!units || (Array.isArray(units) && units.length < 1)) {
      this.logout()

      ToasterManager.error('Nenhuma unidade encontrada. Tente entrar novamente, ou procure o condomínio.')
      throw new Error('Nenhuma unidade vinculada!')
    }

    const team = gv(data, 'team.id')
    if (team === null || team === undefined || team === false) {
      this.logout()

      ToasterManager.error('Nenhum condomínio vinculado!')
      throw new Error('Nenhuma condomínio vinculado')
    }

    // this.checkIfMustUpdateProfile(data)
  },

  async checkIfMustUpdateProfile(user: any) {
    if (user && user.person) {
      const person = user.person

      if (
        !person.gender ||
        !person.birthday ||
        !person.mobile1
      ) {
        ToasterManager.error('Por favor complete o seu cadastro!')
        navigationService.navigate('EditProfile')

        return
      }

      if (
        !person.same_address_condominium &&
        (
          !person.address ||
          !person.district ||
          !person.city ||
          !person.state ||
          !person.cep
        )
      ) {
        ToasterManager.error('Por favor complete o seu cadastro!')
        navigationService.navigate('EditProfile')
      }
    }
  },

  async getToken() {
    const token1 = await AsyncStorage.getItem('@condoid_token')
    const token2 = await AsyncStorage.getItem('token')

    if (token2) {
      await this.setToken(token2)
    }

    return token1 || token2
  },

  async setToken(token: string) {
    return AsyncStorage.setItem('@condoid_token', token)
  },

  async getTeamVersionLocal() {
    let version = await AsyncStorage.getItem('@condoidv2')

    if (version) {
      version = JSON.parse(version)
    }

    return version
  },

  async setTeamVersionLocal(value: any) {
    return await AsyncStorage.setItem('@condoidv2', String(value))
  },

  async handleTeamVersion(use_v2: boolean | string) {
    let init_status = await authService.getTeamVersionLocal()

    await authService.setTeamVersionLocal(use_v2)

    if (use_v2 !== init_status) {
      await queryClient.cancelQueries()
      queryClient.removeQueries()
      queryClient.clear()

      // Não vai funcionar porque as rotas ainda não foram montadas
      // navigationService.navigate('HomeScreen')
    }
  },

  logout() {
    try {
      AsyncStorage.getAllKeys((err, keys) => {
        if (keys && Array.isArray(keys) && keys.length > 0) {
          AsyncStorage.multiRemove(keys, errs => {
            if (errs) {
              sdkSentry.sendSentry(errs, '')
            }
          })
        }
      })
    } catch (e) {
      console.log(JSON.stringify(e))
      sdkSentry.sendSentry(e, '')
      AsyncStorage.clear()
    }

    navigationService.navigate('Login')
  }
}

export default authService
