import {takeEvery} from 'redux-saga/effects'
import {handleActions} from 'redux-actions'
import {actionMapBase, allSagasBase, getSagasBase, postSagasBase, putSagasBase, reducersMap} from '../ducks_base'

const domain = 'comments'

// ACTIONS CREATORS
export const actionMap = actionMapBase(domain)

// REDUCER
const initialState = {
  data: [],
  current: {},
  showModal: false
}

let reducers = reducersMap(actionMap)
export default handleActions(reducers, initialState)

// SAGAS
const allSagas = (params) => (allSagasBase(actionMap, domain + params.payload, '', {loader: false}))
const getSagas = (action) => (getSagasBase(actionMap, domain, action))
const postSagas = (action) => (postSagasBase(actionMap, domain, action, false))
const putSagas = (action) => (putSagasBase(actionMap, domain, action))

export const comments_sagas = [
  takeEvery(actionMap.get().type, getSagas),
  takeEvery(actionMap.all().type, allSagas),
  takeEvery(actionMap.put().type, putSagas),
  takeEvery(actionMap.post().type, postSagas),
]
