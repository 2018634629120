import * as React from 'react'
import {StyleSheet, Text, View} from 'react-native'

type Props = {
  visible: boolean
  emoji?: string
  description: string
}

const PlaceHolder = ({ visible, emoji, description }: Props) => {
  if (visible) {
    return (
      <View style={styles.background}>
        <Text style={styles.emoji}>{emoji ? emoji : '🤦'}‍</Text>
        <Text style={styles.textContent}>Nada por aqui...</Text>
        <Text style={styles.description}>{description}</Text>
      </View>
    )
  }

  return null
}

const styles = StyleSheet.create({
  background: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  textContent: {
    fontSize: 35,
    fontWeight: 'bold',
    color: '#8B98A4',
    marginBottom: 10
  },
  emoji: {
    fontSize: 50,
    marginTop: 40,
    marginBottom: 25
  },
  description: {
    fontSize: 18,
    fontWeight: '200',
    color: '#8B98A4',
    paddingHorizontal: 40,
    textAlign: 'center'
  }
})

export default PlaceHolder
