import {useField} from 'formik'
import moment from 'moment'
import * as React from 'react'
import {TextInputProps, View} from 'react-native'
import {HelperText, TextInput} from 'react-native-paper'
import {DatePickerModal, TimePickerModal} from 'react-native-paper-dates'
import Style from '../../styles/Style'
import {dateFormat, dateTimeFormat} from '../../utils'
import TouchInput from './TouchInput'
import {NextAutoFocusInput} from './withNextInputAutoFocus'

type BaseProps = {
  label: string
  name: string
}

export const FormikDatePicker = React.memo((props: BaseProps) => {
  const [field, meta, helpers] = useField(props.name)

  const fields_error = meta.error
  const field_value = meta.value

  const [open, setOpen] = React.useState(false)

  const onDismiss = React.useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const onConfirm = React.useCallback(
    ({ date }) => {
      setOpen(false)

      helpers.setValue(moment(date).format('YYYY-MM-DD'))
    },
    [setOpen]
  )

  const getValueFriendly = () => {
    if (field_value) {
      return `${dateFormat(field_value)}`
    }

    return ''
  }

  return (
    <View style={Style.form.fieldContainer}>
      <TouchInput
        label={props.label}
        mode="outlined"
        value={getValueFriendly()}
        showPicker={() => setOpen(true)}
        error={!!fields_error}
      />

      {
        !!fields_error &&
        <HelperText type="error" visible={!!fields_error}>
          {fields_error}
        </HelperText>
      }

      <DatePickerModal
        locale="pt"
        mode="single"
        visible={open}
        onDismiss={onDismiss}
        date={field_value ? moment(field_value).toDate() : undefined}
        onConfirm={onConfirm}
        // onChange={} // same props as onConfirm but triggered without confirmed by user
        saveLabel="Ok" // optional
        // uppercase={false} // optional, default is true
        // label="Select period" // optional
        // startLabel="Check-in" // optional
        // endLabel="Check-out" // optional
        // animationType="slide" // optional, default is slide on ios/android and none on web
      />
    </View>
  )
})

export const FormikDateTimePicker = React.memo((props: BaseProps) => {
  const [field, meta, helpers] = useField(props.name)

  const fields_error = meta.error
  const field_value = meta.value

  const [open_date, setOpenDate] = React.useState(false)
  const [open_time, setOpenTime] = React.useState(false)
  const [temp_date, setTempDate] = React.useState('')

  const onDismiss = React.useCallback(() => {
    setOpenDate(false)
    setOpenTime(false)
    setTempDate('')
  }, [setOpenDate, setOpenTime, setTempDate])

  const onDateConfirm = React.useCallback(
    ({ date }) => {
      setTempDate(moment(date).format('YYYY-MM-DD'))

      setOpenDate(false)
      setOpenTime(true)
    },
    [setOpenDate, setOpenTime, setTempDate]
  )

  const onTimeConfirm = React.useCallback(
    ({ hours, minutes }) => {
      let _date = moment(temp_date)
      _date.hours(hours)
      _date.minutes(minutes)
      _date.seconds(0)

      helpers.setValue(_date.format('YYYY-MM-DDTHH:mm:ss'))

      onDismiss()
    },
    [setOpenDate, setOpenTime, temp_date]
  )

  const getValueFriendly = () => {
    if (field_value) {
      return `${dateTimeFormat(field_value)}`
    }

    return ''
  }

  return (
    <View style={Style.form.fieldContainer}>
      <TouchInput
        label={props.label}
        mode="outlined"
        value={getValueFriendly()}
        showPicker={() => setOpenDate(true)}
        error={!!fields_error}
      />

      {
        !!fields_error &&
        <HelperText type="error" visible={!!fields_error}>
          {fields_error}
        </HelperText>
      }

      <DatePickerModal
        locale="pt"
        mode="single"
        visible={open_date}
        onDismiss={onDismiss}
        date={field_value ? moment(field_value).toDate() : undefined}
        onConfirm={onDateConfirm}
        saveLabel="Ok"
      />

      <TimePickerModal
        locale="pt"
        visible={open_time}
        onDismiss={onDismiss}
        hours={field_value ? moment(field_value).hour() : undefined}
        minutes={field_value ? moment(field_value).minute() : undefined}
        onConfirm={onTimeConfirm}
        label="Selecione o horário"
        confirmLabel="Ok"
        cancelLabel="Fechar"
      />
    </View>
  )
})

export const FormikTimePicker = React.memo((props: BaseProps) => {
  const [field, meta, helpers] = useField(props.name)

  const fields_error = meta.error
  const field_value = meta.value

  const [open, setOpen] = React.useState(false)

  const onDismiss = React.useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const onConfirm = React.useCallback(
    ({ hours, minutes }) => {
      setOpen(false)

      helpers.setValue(moment(`${hours}:${minutes}:00`, 'HH:mm:ss').format('HH:mm:ss'))
    },
    [setOpen]
  )

  const getValueFriendly = () => {
    if (field_value) {
      return `${moment(field_value, 'HH:mm:ss').format('HH:mm')}`
    }

    return ''
  }

  return (
    <View style={Style.form.fieldContainer}>
      <TouchInput
        label={props.label}
        mode="outlined"
        value={getValueFriendly()}
        showPicker={() => setOpen(true)}
        error={!!fields_error}
      />

      {
        !!fields_error &&
        <HelperText type="error" visible={!!fields_error}>
          {fields_error}
        </HelperText>
      }

      <TimePickerModal
        locale="pt"
        visible={open}
        onDismiss={onDismiss}
        hours={field_value ? moment(field_value, 'HH:mm:ss').hour() : undefined}
        minutes={field_value ? moment(field_value, 'HH:mm:ss').minute() : undefined}
        onConfirm={onConfirm}
        label="Selecione o horário"
        confirmLabel="Ok"
        cancelLabel="Fechar"
      />
    </View>
  )
})

type TxtInputPropsType = BaseProps & Omit<TextInputProps, 'selectionColor'> & { with_next?: boolean }

export const FormikInputTS = (props: TxtInputPropsType) => {
  const [field, meta, helpers] = useField(props.name)

  const fields_error = meta.error
  const field_value = meta.value

  const TxtInput = props.with_next ? NextAutoFocusInput : TextInput

  // Temp fix avoid print undefined in field value, when field.value is undefined or the default value is not init
  let is_undefined = typeof (field_value) === 'undefined' || field_value === null

  return (
    <View style={Style.form.fieldContainer}>
      <TxtInput
        {...props}
        label={props.label}
        value={is_undefined ? '' : String(field_value)}
        error={!!fields_error}
        onChangeText={(txt) => helpers.setValue(txt)}
        onBlur={() => helpers.setTouched(true)}
        mode={'outlined'}
      />

      {
        !!fields_error &&
        <HelperText type="error" visible={true}>
          {fields_error}
        </HelperText>
      }
    </View>
  )
}
