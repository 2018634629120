import {useFocusEffect} from '@react-navigation/native'
import React, {useState} from 'react'
import {Image, StyleSheet, TouchableOpacity, View} from 'react-native'
import {Caption, Subheading} from 'react-native-paper'
import {SafeAreaView} from 'react-native-safe-area-context'
import {useInfiniteQuery} from 'react-query'
import AppFlatList from '../../../components/layout/AppFlatList'
import AppModalSheet from '../../../components/layout/AppModalSheet'
import PlaceHolder from '../../../components/placeholder'
import {MAILING_STATUS} from '../../../constants/fields'
import {useAppTheme} from '../../../styles/theme'
import {dateTimeFormat, handleGetNextPageParam} from '../../../utils'
import {qs} from '../../queries'
import {MailingModel} from '../../typings'

function MailingListScreen({ navigation, ...props }: any) {
  const theme = useAppTheme()

  const {
    data,
    hasNextPage,
    isFetching,
    fetchNextPage,
    refetch,
    remove
  } = useInfiniteQuery(qs.mailings.all_key, async (_params) =>
      qs.mailings.all_paginated(_params.pageParam ?? 1, 15),
    {
      enabled: false,
      getNextPageParam: handleGetNextPageParam
    })

  useFocusEffect(
    React.useCallback(() => {
      refetch()

      return () => {
        remove()
      }
    }, [])
  )

  const [current_item, setCurrentItem] = useState<MailingModel | null>(null)

  const handleShowDetails = (item: MailingModel) => {
    if (Array.isArray(item.files) && item.files.length > 0) {
      setCurrentItem(item)
    }
  }

  const renderItem = ({ item }: { item: MailingModel }) => (
    <TouchableOpacity onPress={() => handleShowDetails(item)}>
      <View style={styles.cardContainer}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View style={{ flex: 4 }}>
            <Caption style={{ fontWeight: 'bold' }}>{MAILING_STATUS[item.status]}</Caption>

            <Subheading>{item.from_field}</Subheading>

            <Caption>Unidade: {item.unit.with_block}</Caption>
            <Caption>Retirado por: {item.picked} em {dateTimeFormat(item.delivered_at)}</Caption>
            <Caption>Data de criação: {dateTimeFormat(item.created_at)}</Caption>
          </View>
          <View style={{ flex: 1, alignSelf: 'center' }}>
            {Array.isArray(item.files) && item.files.length > 0 &&
              <Caption style={{ textAlign: 'right', fontWeight: 'bold', color: theme.colors.accent }}>
                ver fotos
              </Caption>}
          </View>
        </View>
      </View>
    </TouchableOpacity>
  )

  const renderInner = () => (
    <View style={styles.panel}>

      {current_item?.files?.map((i: any) => (
        <Image
          key={i.file}
          source={{ uri: i.file }}
          resizeMode={'contain'}
          style={{ height: 300, marginBottom: 20 }}
        />
      ))}

    </View>
  )

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>

      <AppFlatList
        data={data?.pages.map(page => page.data).flat() ?? []}
        onEndReached={() => hasNextPage && fetchNextPage()}
        renderItem={renderItem}
        keyExtractor={item => item.id + ''}
        refreshing={isFetching}
        onRefresh={refetch}
        ListEmptyComponent={() => (
          <PlaceHolder
            visible={true}
            emoji={'✉️'}
            description={'Aqui você pode consultar as suas correspondências e o status.'}
          />
        )}
      />

      <AppModalSheet
        visible={!!current_item?.id}
        height_percent={70}
        onDismiss={() => setCurrentItem(null)}
        content={renderInner}
      />
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  cardContainer: {
    borderWidth: 1,
    borderColor: '#e8e8e8',
    borderRadius: 6,
    padding: 12,
    marginBottom: 14
  },
  panel: {
    minHeight: 700,
    paddingTop: 10,
    paddingBottom: 40,
    backgroundColor: '#f8f8f8',
    paddingRight: 20,
    paddingLeft: 20

  },
  header: {
    backgroundColor: '#efefef',
    paddingTop: 20,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20
  },
  panelHeader: {
    alignItems: 'center'
  },
  panelHandle: {
    width: 40,
    height: 8,
    borderRadius: 4,
    backgroundColor: '#00000040',
    marginBottom: 10
  }
})

export default MailingListScreen
