import {FontAwesome, Ionicons, MaterialCommunityIcons} from '@expo/vector-icons'
import * as Font from 'expo-font'
import * as SplashScreen from 'expo-splash-screen'
import {useEffect, useState} from 'react'
import authService from '../services/authService'
import {cacheAppTheme, setAppTheme} from '../styles/theme'

export default function useCachedResources() {
  const [isLoadingComplete, setLoadingComplete] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [theme, setTheme] = useState<any>(null)

  // Load any resources or data that we need prior to rendering the app
  useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHideAsync()

        const theme_cache = await cacheAppTheme()

        // Load fonts
        await Font.loadAsync({
          ...FontAwesome.font,

          // This is the font that we are using for our tab bar
          ...Ionicons.font,
          ...MaterialCommunityIcons.font,
          // We include SpaceMono because we use it in HomeScreen.js. Feel free
          // to remove this if you are not using it in your app
          // 'Roboto': require('native-base/Fonts/Roboto.ttf'),
          // 'Roboto_medium': require('native-base/Fonts/Roboto_medium.ttf'),
          // 'Ionicons': require('native-base/Fonts/Ionicons.ttf')

        })

        let user_token = await authService.getToken()
        if (user_token) {
          setIsLoggedIn(true)
        }

        setTheme(theme_cache)
        setAppTheme(theme_cache)

      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e)
      } finally {
        setLoadingComplete(true)
        SplashScreen.hideAsync()
      }
    }

    loadResourcesAndDataAsync()
  }, [])

  return { isLoadingComplete, isLoggedIn, theme }
}
