import {Field} from 'formik'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import {useQuery} from 'react-query'
import {qs} from '../../features/queries'
import DialogSelect from './DialogSelect'

const UnitSelect = (props: any) => {
  const { data } = useQuery(qs.units.my_units_key, () => qs.units.myUnits())
  const { t } = useTranslation()

  const getData = () => {
    const udata: any = []

    if (data?.data) {
      for (let o of data.data) {
        if (o.door_code) {
          udata.push(
            {
              id: o.id,
              with_block: `${o.with_block} - Porta: ${o.door_code}`
            }
          )
        } else {
          udata.push(
            {
              id: o.id,
              with_block: o.with_block
            }
          )
        }
      }
    }

    return udata
  }

  return (
    <Field
      label={props.label ? props.label : t('unit_name')}
      name={props.name ? props.name : 'unit'}
      component={DialogSelect}
      data={getData()}
      id_option="id"
      label_option="with_block"
      {...props}
    />
  )
}

export default UnitSelect