import axios from 'axios'
import Constants from 'expo-constants'
import enviroment from '../enviroment'
import {getAppVersion, getPlatformName} from '../utils'
import authService from './authService'
import {EventLogger} from './EventLogger'
import queryClient from './queryClient'
import {sdkSentry} from './sentry'

export const apiQsMetadata = async () => {
  const user = await EventLogger.user()

  const user_id = user?.id
  const schema_name = user?.team_schema
  const app_version = getAppVersion()

  return `platform=${getPlatformName()}&app_version=${app_version}&tin=${schema_name}&uad=${user_id}`
}

export const apiService = axios.create()

apiService.interceptors.request.use(
  async function (config) {
    const token = await authService.getToken()

    const metadata_qs = await apiQsMetadata()

    const APP_ENV = await enviroment()
    config.baseURL = APP_ENV.apiUrl

    config.url = config.url && config.url.indexOf('?') > -1
      ? `${config.url}&${metadata_qs}`
      : `${config.url}?${metadata_qs}`

    config.headers['Authorization'] = `Token ${token}`
    config.headers['Platform'] = getPlatformName()
    config.headers['AppVersion'] = getAppVersion()

    return config
  }
)

apiService.interceptors.response.use(
  function (response) {
    return response
  },
  async function (error) {
    let status = error?.response?.status

    let _data = error?.response?.data
    let url_status = `${status} - ${error?.response?.config?.url}`

    console.log('error_url_status', url_status)
    console.log('error_data', _data)

    sdkSentry.lib().addBreadcrumb({
      category: 'axios_error',
      data: {
        raw: JSON.stringify(_data),
        url_status: url_status
      }
    })

    if (status === 401 || status === 403) {
      // history.push('/login')
      queryClient.clear()
    }

    if (Constants.appOwnership !== 'expo') {
      const token = await authService.getToken()
      try {
        sdkSentry.sendSentry(error, token, _data, error?.response?.config?.url)
      } catch (e) {
        sdkSentry.sendSentry(e, null, null, null)
      }
    }

    return Promise.reject(error)
  }
)
