import AsyncStorage from '@react-native-async-storage/async-storage'
import * as Sentry from 'sentry-expo'
import {gv, platformChecker} from '../utils'
import {EventLogger} from './EventLogger'

let meData = ''

AsyncStorage.getItem('me').then((me: any) => {
  meData = JSON.parse(me)
})

export const sdkSentry = {
  sendSentry: (error: any, token: string | null, data = null, endpoint: string | null = null) => {
    sdkSentry.lib().addBreadcrumb({
      category: 'request_context',
      data: {
        token: token,
        user_context: meData,
        request_data: data,
        endpoint: endpoint,
        response_data: JSON.stringify(gv(error, 'response.data')),
        response_status: JSON.stringify(gv(error, 'response.status'))
      }
    })

    sdkSentry.lib().captureException(error)

    EventLogger.debugLog(error)
  },

  lib: () => {
    if (platformChecker.isWeb) {
      return Sentry.Browser
    }

    return Sentry.Native
  }
}
