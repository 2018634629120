import {useFocusEffect, useIsFocused, useNavigation, useRoute} from '@react-navigation/native'
import {Formik} from 'formik'
import moment from 'moment'
import * as React from 'react'
import {useEffect, useState} from 'react'
import {View} from 'react-native'
import {Button, Caption, Text} from 'react-native-paper'
import {SafeAreaView} from 'react-native-safe-area-context'
import {useMutation, useQuery} from 'react-query'
import * as Yup from 'yup'
import {MkRender} from '../../../components'
import {AclRender} from '../../../components/acl/components'
import {FormikInputTS} from '../../../components/form/FormikInputs'
import {FormContainer} from '../../../components/form/FormInputs'
import AppFlatList from '../../../components/layout/AppFlatList'
import {AppGridCol, AppGridRow} from '../../../components/layout/AppGridView'
import AppScrollView from '../../../components/layout/AppScrollView'
import ModalButton from '../../../components/layout/ModalButton'
import ActivityLoading from '../../../components/loading'
import Spacer from '../../../components/Spacer'
import TermsModal from '../../../components/TermsModal'
import {PENALTY_STATUS} from '../../../constants/fields'
import {RootDrawerScreenProps} from '../../../navigation/types'
import {EventLogger} from '../../../services/EventLogger'
import ToasterManager from '../../../services/ToasterManager'
import {qs} from '../../queries'

const PenaltyDetailScreen = () => {
  const route = useRoute<RootDrawerScreenProps<'PenaltyDetailScreen'>['route']>()
  const navigation = useNavigation<RootDrawerScreenProps<'PenaltyDetailScreen'>['navigation']>()

  let item_id = route.params?.item_id

  const { data, isFetching, refetch, remove } = useQuery(
    [qs.penalties.get_key, item_id],
    () => qs.penalties.get(item_id),
    { enabled: false }
  )

  const create = useMutation((variables: any) => qs.comments.post(variables), {
    onSuccess: () => {
      ToasterManager.success('Defesa enviada com sucesso!')
      navigation.navigate('PenaltyListScreen')
    }
  })

  const [expired, setExpired] = useState(true)

  useFocusEffect(
    React.useCallback(() => {
      refetch()
      EventLogger.logEvent('SeePenaltyDetail', 'screen', item_id, 'penalty')
      return () => {
        remove()
      }
    }, [item_id])
  )

  useEffect(() => {
    if (data) {
      const deadline = moment(data.created_at).add(data.penalty_category.deadline_appeal, 'days')

      if (moment().isBefore(deadline)) {
        setExpired(false)
      } else {
        setExpired(true)
      }
    }

  }, [item_id, data])

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      {
        !isFetching && data?.id ?
          <AppScrollView>
            <View style={{ backgroundColor: '#eeeeee', padding: 15, borderRadius: 7 }}>
              <AppGridRow style={{ marginBottom: 15 }}>
                <AppGridCol>
                  <Caption>Autuado por</Caption>
                  <Text>{data.penalty_type_name}</Text>
                </AppGridCol>
              </AppGridRow>

              <AppGridRow style={{ marginBottom: 15 }}>
                <AppGridCol>
                  <Caption>Categoria </Caption>
                  <Text>{data.penalty_category.name}</Text>
                </AppGridCol>
                <AppGridCol>
                  <Caption>Status </Caption>
                  <Text>{PENALTY_STATUS[data.status as number]}</Text>
                </AppGridCol>
              </AppGridRow>

              <AppGridRow>
                <AppGridCol>
                  <Caption>Data </Caption>
                  <Text>{moment(data.date).format('DD/MM/YYYY - HH:MM')}</Text>
                </AppGridCol>
                <AppGridCol>
                  <Caption>Taxa </Caption>
                  <Text>R$ {data.tax}</Text>
                </AppGridCol>
              </AppGridRow>
            </View>

            <Spacer size={30}/>

            <View>
              <TermsModal title="Visualizar Parecer" button="Visualizar Parecer">
                <MkRender>{data.description}</MkRender>
              </TermsModal>

              {data?.comments?.length > 0 && <Caption style={{ marginTop: 30, marginBottom: 15 }}>Defesa</Caption>}

              <AppFlatList
                data={data.comments}
                renderItem={({ item }: { item: any }) =>
                  <View>
                    <Caption>Data: {moment(item.created_at).format('DD/MM/YYYY - H:mm')}</Caption>
                    <Text>{item.body}</Text>
                  </View>
                }
              />

              <AclRender required={['penalty.change_penalty']}>
                {!expired && data.comments.length < 1 &&
                  <Formik
                    initialValues={{
                      body: ''
                    }}
                    validationSchema={Yup.object().shape({
                      body: Yup.string().required()
                    })}
                    onSubmit={(values) => {
                      create.mutate({
                        ...values,
                        ...{
                          type: 2,
                          commentable_id: data.id,
                          commentable_type: 'penalty'
                        }
                      })
                    }}
                  >
                    {({ submitForm }) => (
                      <ModalButton
                        button_label="Enviar defesa"
                        renderFooter={() => (
                          <Button
                            icon="content-save" mode="contained"
                            loading={create.isLoading} disabled={create.isLoading}
                            onPress={() => submitForm()}
                          >
                            Salvar
                          </Button>
                        )}
                      >
                        <FormContainer>
                          <FormikInputTS label={'Defesa'} name="body" multiline scrollEnabled={false}/>
                          <Spacer size={30}/>
                        </FormContainer>
                      </ModalButton>
                    )}
                  </Formik>
                }
              </AclRender>

              <Caption style={{ marginTop: 25 }}>
                Prazo máximo para enviar a defesa: {data.penalty_category.deadline_appeal} dias.
              </Caption>

            </View>
          </AppScrollView>
          :
          <ActivityLoading visible={isFetching}/>
      }
    </SafeAreaView>
  )
}

export default function (props: any) {
  const isFocused = useIsFocused()

  if (!isFocused) return null

  return (
    <PenaltyDetailScreen/>
  )
}
