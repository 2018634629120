import React from 'react'
import {SafeAreaView} from 'react-native-safe-area-context'
import AppTabView from '../../../components/layout/AppTabView'
import BookListTab from '../components/BookListTab'
import SpaceListTab from '../components/SpaceListTab'

function BookListScreen() {
  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      <AppTabView scenes={[
        { key: 'spaces', title: 'Espaços', scene: SpaceListTab },
        { key: 'books', title: 'Minhas Reservas', scene: BookListTab }
      ]}/>
    </SafeAreaView>
  )
}

export default BookListScreen
