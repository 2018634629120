import React, {useRef} from 'react'
import {ScrollView, StyleSheet, View} from 'react-native'
import Modal from 'react-native-modalbox'
import {Button, Headline, Text} from 'react-native-paper'
import {useSafeAreaInsets} from 'react-native-safe-area-context'
import Style from '../../styles/Style'

type Props = {
  children: React.ReactNode,
  button_label: string
  button_props?: any
  title?: string
  max_height?: number
  renderFooter?: () => React.ReactElement
}

const ModalButton: React.FC<Props> = (props) => {
  const modal_ref = useRef<any>(null)

  const modal_style: any = [styles.modal]
  if (props.max_height) {
    modal_style.push({ height: Style.DEVICE_HEIGHT * props.max_height })
  }

  const insets = useSafeAreaInsets()

  return (
    <React.Fragment>
      <Button
        mode="outlined" style={{ marginBottom: 15 }}
        onPress={() => modal_ref?.current?.open()}
        {...props.button_props}
      >
        {props.button_label}
      </Button>

      <Modal
        style={modal_style}
        ref={modal_ref} swipeToClose={true} swipeArea={80} backdrop={true} coverScreen={true}
        position={'bottom'}
      >
        <View style={styles.modalRuleContainer}>
          <View style={styles.modalRule}>
          </View>
        </View>

        <ScrollView>
          {!!props.title && <Headline>{props.title}</Headline>}
          {!!props.title && <Text> </Text>}
          {props.children}
        </ScrollView>

        {props.renderFooter &&
          <View style={{ marginBottom: insets.bottom, marginTop: 10, backgroundColor:'#CCCCCC' }}>
            {props.renderFooter()}
          </View>
        }
      </Modal>

    </React.Fragment>
  )
}

const styles = StyleSheet.create({
  modalRuleContainer: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalRule: {
    marginTop: 20,
    marginBottom: 18,
    borderRadius: 10,
    height: 5,
    backgroundColor: '#D6DBE1',
    width: Style.DEVICE_WIDTH / 5
  },
  modal: {
    height: Style.DEVICE_HEIGHT / 1.1,
    backgroundColor: '#ffffff',
    padding: 10
  }
})

export default ModalButton