import {useNavigation} from '@react-navigation/native'
import {Formik, FormikProps} from 'formik'
import moment from 'moment'
import React from 'react'
import {StyleSheet, View} from 'react-native'
import {Button, Caption, Headline, HelperText, Switch, Text, Title} from 'react-native-paper'
import {useMutation} from 'react-query'
import * as Yup from 'yup'
import {NewFeature} from '../../../components'
import {AclRender} from '../../../components/acl/components'
import CalendarPickr from '../../../components/form/CalendarPickr'
import DialogSelect from '../../../components/form/DialogSelect'
import FormikEffect from '../../../components/form/FormikEffect'
import {FormikTimePicker} from '../../../components/form/FormikInputs'
import {FF, FormContainer, FormikInput, FormikRadio} from '../../../components/form/FormInputs'
import UnitSelect from '../../../components/form/UnitSelect'
import NotPermitted from '../../../components/NotPermitted'
import Spacer from '../../../components/Spacer'
import TermsModal from '../../../components/TermsModal'
import {RootDrawerScreenProps} from '../../../navigation/types'
import {EventLogger} from '../../../services'
import ToasterManager from '../../../services/ToasterManager'
import Style from '../../../styles/Style'
import {BRL, currency, Dec2BRL} from '../../../utils'
import {qs} from '../../queries'
import {BookModel, HandleFormikChangeType, SpaceModel} from '../../typings'

type Props = {
  initialValues: any,
  space: SpaceModel,
}

type FormValues = { [K in keyof BookModel]?: BookModel[K] | string } & {
  accept_terms: boolean
  tax_add: any
  _date: string
  _period: string
  _period_id: string,
  additional: any[]
}

function BookForm(props: Props) {
  const navigation = useNavigation<RootDrawerScreenProps<'BookNewScreen'>['navigation']>()

  const create = useMutation((variables: any) => qs.books.post(variables), {
    onSuccess: (res) => {
      EventLogger.logEvent('NewBook', 'action', res.id, 'book')

      if (res.space.require_guest_list) {
        ToasterManager.warn(
          'Para a aprovação completa da reserva é necessário a lista de convidados. ' +
          'A lista de convidados pode ser preenchida na página de detalhes da reserva.'
        )
      } else {
        ToasterManager.success('Espaço reservado com sucesso!')
      }

      navigation.navigate('BookListScreen')
    }
  })

  const periods_availability = useMutation((values: any) => qs.spaces.periodsAvailability(values))

  const handleFormikChange = ({ values, _previous_values, setFieldValue }: HandleFormikChangeType<FormValues>) => {
    if (values.additional.length !== _previous_values.additional.length) {
      let additional = props.space.additional ?? []

      let total_add = additional.filter(
        i => values.additional.filter(s => s.includes(`${i.description} - R$ ${Dec2BRL(i.price)}`)).length > 0
      ).map(i => i.price)

      let total = currency('0')

      if (total_add && total_add.length > 0) {
        for (let i of total_add) {
          let c_value = currency(i)
          total = total.add(c_value)
        }
      }

      let full_tax = typeof (props.space.full_tax) === 'string' ? 0 : currency(props.space.full_tax)

      setFieldValue('tax', total.add(full_tax))
      setFieldValue('tax_add', total)
    }
  }

  if (!props.space?.id) {
    return <NotPermitted msg="..."/>
  }

  return (
    <Formik
      initialValues={{
        unit: '',
        event_description: '',
        space: props.space.id as any,
        accept_terms: false,
        additional: [],
        tax: typeof (props.space.full_tax) === 'string' ? 0 : currency(props.space.full_tax as any) as any,
        tax_add: '',
        book_date: '',
        _date: '',
        _period: '',
        _period_id: ''
      }}
      validationSchema={Yup.object().shape({
        unit: Yup.string().required(),
        _date: Yup.string().required(),
        _period: Yup.string().required(),
        guests_qtd: props.space.require_guests_qtd ? Yup.number().required() : Yup.number(),
        event_description: props.space.require_book_description ? Yup.string().required().min(8, 'Por favor descreva o evento.') : Yup.string(),
        space: Yup.string().required(),
        accept_terms: Yup.boolean().oneOf([true], 'Por favor aceite os termos.')
      })}
      onSubmit={(values) => {
        let d = moment(values._date)
        let h = moment(values._period, 'HH:mm:ss')
        d.hour(h.hour())
        d.minute(h.minute())

        values['book_date'] = d.format('YYYY-MM-DD HH:mm')
        create.mutate(values)
      }}
    >
      {(formikBag: FormikProps<FormValues>) => {
        let early_min = moment().add(props.space.book_early_min, 'days').format('YYYY-MM-DD')
        let early_max = moment().add(props.space.book_early_max, 'days').format('YYYY-MM-DD')

        let pretty_tax = typeof (props.space.full_tax) === 'string' ? props.space.full_tax : BRL(props.space.full_tax)
        let additional = props.space.additional ?? []

        return (
          <FormContainer>
            <FormikEffect onChange={handleFormikChange}/>

            <View style={{ backgroundColor: '#eeeeee', padding: 15, borderRadius: 7 }}>
              <Headline>{props.space.name}</Headline>
              <Text style={{ marginVertical: 6 }}>{props.space.description}</Text>

              <View style={styles.tableContainer}>
                <View style={styles.tableCol}>
                  <Caption>Capacidade</Caption>
                  <Text>{props.space.guests_max} pessoas</Text>
                </View>
                <View style={styles.tableCol}>
                </View>
              </View>
            </View>

            {props.space.generate_invoice &&
              <NewFeature
                title="⚠Dica!"
                body="Ao realizar uma reserva, chegará via email o boleto pronto para ser pago! Não precisa esperar mais o registro do boleto. Ufa!! 👏🙌😁"
              />}

            <Spacer size={15}/>

            <UnitSelect onChange={(field: string, value: string) => {
              periods_availability.reset()

              formikBag.setFieldValue('_date', '')
              formikBag.setFieldValue('_period', '')
              formikBag.setFieldValue('_period_id', '')
            }}/>

            <FF
              label={'Data'} name="_date"
              component={CalendarPickr}
              includeDates={props.space.availability}
              minDate={early_min}
              maxDate={early_max}
              onChange={(field: string, value: string) => {
                periods_availability.reset()
                formikBag.setFieldValue('_period', '')
                formikBag.setFieldValue('_period_id', '')

                if (props.space.book_type === 2) {
                  periods_availability.mutate({ id: props.space.id, book_date: value })
                }
              }}
              showValidator={() => {
                if (formikBag.values.unit) {
                  return true
                } else {
                  ToasterManager.error('Selecione a unidade primeiro!')
                }
              }}
            />

            {props.space.book_type === 1 &&
              <FormikTimePicker label={'Horário'} name="_period"/>}

            {!periods_availability.isLoading && props.space.book_type === 2 && Array.isArray(periods_availability?.data) &&
              <>
                {periods_availability?.data.length > 0 ?
                  <FF
                    label={'Horário'}
                    name="_period"
                    component={DialogSelect}
                    id_option="start"
                    label_option="label"
                    data={periods_availability?.data}
                    onChange={(field: string, value: string) => {
                      let period = periods_availability.data?.find(i => i.start === value)

                      formikBag.setFieldValue('_period', value)
                      formikBag.setFieldValue('_period_id', period?.period_id ?? '')
                    }}
                    showValidator={() => {
                      if (formikBag.values._date) {
                        return true
                      } else {
                        ToasterManager.error('Selecione o data primeiro!')
                      }
                    }}
                  /> :
                  <HelperText type="error" visible={true} style={{ marginVertical: 15, fontWeight: 'bold' }}>
                    Nenhum horário disponível!
                  </HelperText>
                }
              </>}

            {props.space.require_guests_qtd &&
              <FF label={'Quantidade de pessoas que utilizarão'}
                  name="guests_qtd"
                  component={DialogSelect}
                  id_option="value"
                  label_option="label"
                  data={[...Array(props.space.guests_max + 1).keys()].filter(i => i > 0).map((i, idx) => ({ value: i, label: i }))}
              />}

            <FF label={'Descrição do Evento'} name="event_description" multiline={true} component={FormikInput}/>

            <Spacer size={15}/>

            <View style={{ backgroundColor: '#f7f7f7', padding: 15, borderRadius: 7 }}>
              {additional.length > 0 && <Caption>Adicionais:</Caption>}

              {additional.map(i => {
                let item_name = `${i.description} - R$ ${Dec2BRL(i.price)}`

                return (
                  <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingVertical: 5 }}>
                    <Text>{item_name}</Text>
                    <Switch value={formikBag.values.additional.filter(s => s.includes(item_name)).length > 0} onValueChange={() => {
                      let has = formikBag.values.additional.filter(s => s.includes(item_name)).length > 0

                      if (has) {
                        formikBag.setFieldValue('additional', formikBag.values.additional.filter(f => f.includes(item_name) === false))
                      } else {
                        formikBag.setFieldValue('additional', [...formikBag.values.additional, item_name])
                      }
                    }}/>
                  </View>
                )
              })}

              {additional.length > 0 && <Spacer size={15}/>}

              <Caption>Taxa:</Caption>
              <Title>{pretty_tax}</Title>
              {formikBag.values.tax_add > 0 && <Title>+ {BRL(formikBag.values.tax_add)}</Title>}
            </View>

            <Spacer size={30}/>

            <FF label={'Li e estou de acordo com os termos e taxas'} name="accept_terms" component={FormikRadio}/>
            <TermsModal title={`Regimento ${props.space.name}`} terms={props.space.regiment}/>

            <AclRender required={['book.add_book']}>
              <Button
                icon="content-save" mode="contained"
                loading={create.isLoading}
                disabled={create.isLoading}
                onPress={() => formikBag.submitForm()}
              >
                Salvar
              </Button>
            </AclRender>
          </FormContainer>
        )
      }}
    </Formik>
  )
}

const styles = StyleSheet.create({
  tableContainer: {
    width: Style.DEVICE_WIDTH,
    paddingVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  tableCol: {
    flex: 1
  }
})

export default BookForm
