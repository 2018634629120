import {useFocusEffect, useIsFocused, useNavigation} from '@react-navigation/native'
import {Formik} from 'formik'
import * as React from 'react'
import {Button, Text} from 'react-native-paper'
import {SafeAreaView} from 'react-native-safe-area-context'
import {useMutation, useQuery} from 'react-query'
import * as Yup from 'yup'
import CalendarPickr from '../../../components/form/CalendarPickr'
import DialogSelect from '../../../components/form/DialogSelect'
import {FF, FormContainer, FormikInput, FormikRadio} from '../../../components/form/FormInputs'
import MovingTypeSelect from '../../../components/form/MovingTypeSelect'
import UnitSelect from '../../../components/form/UnitSelect'
import Spacer from '../../../components/Spacer'
import TermsModal from '../../../components/TermsModal'
import {RootDrawerScreenProps} from '../../../navigation/types'
import ToasterManager from '../../../services/ToasterManager'
import {gv} from '../../../utils'
import {qs} from '../../queries'

const MovingNewScreen = () => {
  const navigation = useNavigation<RootDrawerScreenProps<'MovingNewScreen'>['navigation']>()

  const moving_types = useQuery(
    qs.moving_types.all_key,
    () => qs.moving_types.all(),
    { enabled: false }
  )

  const create = useMutation((variables: any) => qs.movings.post(variables), {
    onSuccess: () => {
      ToasterManager.success('Solicitação de Mudança criada com sucesso!')
      navigation.navigate('MovingListScreen')
    }
  })

  const dates_availability = useMutation((values: any) => qs.moving_types.datesAvailability(values))
  const periods_availability = useMutation((values: any) => qs.moving_types.periodsAvailability(values))

  useFocusEffect(
    React.useCallback(() => {
      moving_types.refetch()
      return () => {
        moving_types.remove()
        dates_availability.reset()
        periods_availability.reset()
      }
    }, [])
  )

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      <FormContainer>
        <Formik
          initialValues={{
            unit: '',
            moving_type: '',
            date: '',
            accept_terms: false,
            period: '',
            description: ''
          }}
          validationSchema={
            Yup.object().shape({
              unit: Yup.string().required(),
              moving_type: Yup.string().required(),
              date: Yup.string().required(),
              accept_terms: Yup.boolean().oneOf([true], 'Por favor aceite os termos.'),
              description: Yup.string()
                .when('moving_type',
                  (moving_type: number) => {
                    // @ts-ignore
                    let req = moving_types.data?.find(i => String(moving_type) === String(i.id))?.require_description
                    return req ? Yup.string().required() : Yup.string()
                  }
                )
            })
          }
          onSubmit={(values) => {
            create.mutate(values)
          }}
        >
          {({ values, submitForm, setFieldValue }) => {
            // @ts-ignore
            let moving_type = moving_types.data?.find(i => values.moving_type === i.id)

            return (
              <React.Fragment>
                <UnitSelect onChange={(field: string, value: string) => {
                  dates_availability.reset()
                  periods_availability.reset()

                  setFieldValue('moving_type', '')
                  setFieldValue('date', '')
                  setFieldValue('period', '')
                }}/>

                <MovingTypeSelect
                  onChange={(field: string, value: string) => {
                    dates_availability.reset()
                    periods_availability.reset()

                    setFieldValue('date', '')
                    setFieldValue('period', '')

                    if (value && values.unit) {
                      dates_availability.mutate({ id: value, unit_id: values.unit })
                    }
                  }}
                  showValidator={() => {
                    if (values.unit) {
                      return true
                    } else {
                      ToasterManager.error('Selecione a unidade primeiro!')
                    }
                  }}
                />

                {!dates_availability.isLoading &&
                  <FF
                    label={'Data'} name="date"
                    component={CalendarPickr}
                    includeDates={dates_availability?.data ?? []}
                    onChange={(field: string, value: string) => {
                      periods_availability.reset()
                      setFieldValue('period', '')

                      if (moving_type && moving_type?.moving_book_type === 2) {
                        periods_availability.mutate({ id: moving_type.id, book_date: value })
                      }
                    }}
                    showValidator={() => {
                      if (values.moving_type) {
                        return true
                      } else {
                        ToasterManager.error('Selecione o tipo primeiro!')
                      }
                    }}
                  />}

                {!dates_availability.isLoading && moving_type && moving_type?.moving_book_type === 2 &&
                  <FF
                    label={'Horário'}
                    name="period"
                    component={DialogSelect}
                    id_option="id"
                    label_option="label"
                    data={periods_availability?.data ?? []}
                    showValidator={() => {
                      if (values.date) {
                        return true
                      } else {
                        ToasterManager.error('Selecione o data primeiro!')
                      }
                    }}
                  />}

                <FF label={'Observações'} name="description" component={FormikInput}/>

                <FF label={'Li e estou de acordo com os termos e taxas'} name="accept_terms" component={FormikRadio}/>

                <TermsModal title="Regimento para Mudança" terms={gv(moving_type, 'terms')}>
                  <Text>Taxa: R$ {moving_type?.full_tax}</Text>
                </TermsModal>

                <Spacer/>

                <Button
                  icon="content-save" mode="contained"
                  loading={create.isLoading} disabled={create.isLoading}
                  onPress={() => submitForm()}
                >
                  Salvar
                </Button>
              </React.Fragment>
            )
          }}
        </Formik>
      </FormContainer>
    </SafeAreaView>
  )
}

export default function (props: any) {
  const isFocused = useIsFocused()

  if (!isFocused) return null

  return (
    <MovingNewScreen/>
  )
}
