import {Alert, Linking} from 'react-native'
import {apiService, EventLogger} from '../services'
import {hv, platformChecker} from '../utils'

const checkAppUpdate = async () => {
  try {
    if (platformChecker.isWeb) return

    let values = await EventLogger.context()
    let { data } = await apiService.post(`resident/devices/check_app_update`, values)

    console.log(data)

    if (hv(data, 'message')) {
      Alert.alert('Nova atualização disponível!', data.message, [
        {
          text: 'Atualizar!',
          onPress: () => Linking.openURL(data.open_url)
        }
      ])
    }

  } catch (e) {
    console.log(e)
  }
}

export const custom_qs = {
  checkAppUpdate
}