import type {DrawerNavigationProp} from '@react-navigation/drawer'
import {createDrawerNavigator} from '@react-navigation/drawer'
import {getHeaderTitle} from '@react-navigation/elements'
import type {LinkingOptions} from '@react-navigation/native'
import {NavigationContainer, useFocusEffect} from '@react-navigation/native'
import {createNativeStackNavigator} from '@react-navigation/native-stack'
import * as React from 'react'
import {useEffect, useState} from 'react'
import {Appbar} from 'react-native-paper'
import {useMediaQuery} from 'react-responsive'
import {useRecoilState} from 'recoil'
import ActivityLoading from '../components/loading'
import SideBar from '../components/SideBar'
import AccessAuthorizationListScreen from '../features/AccessAuthorization/screens/AccessAuthorizationListScreen'
import AccessAuthorizationNewScreen from '../features/AccessAuthorization/screens/AccessAuthorizationNewScreen'
import ChangePasswordScreen from '../features/Auth/screens/ChangePasswordScreen'
import LoginScreen from '../features/Auth/screens/LoginScreen'
import {MyTeamsScreen} from '../features/Auth/screens/MyTeamsScreen'
import ResetPasswordScreen from '../features/Auth/screens/ResetPasswordScreen'
import SignRequestScreen from '../features/Auth/screens/SignRequestScreen'
import BookDetailScreen from '../features/Book/screens/BookDetailScreen'
import BookListScreen from '../features/Book/screens/BookListScreen'
import BookNewScreen from '../features/Book/screens/BookNewScreen'
import ClassifiedListScreen from '../features/Classified/screens/ClassifiedListScreen'
import ClassifiedNewScreen from '../features/Classified/screens/ClassifiedNewScreen'
import ClassifiedViewScreen from '../features/Classified/screens/ClassifiedViewScreen'
import ConstructionDetailScreen from '../features/Construction/screens/ConstructionDetailScreen'
import ConstructionListScreen from '../features/Construction/screens/ConstructionListScreen'
import ConstructionNewScreen from '../features/Construction/screens/ConstructionNewScreen'
import ConsumptionListScreen from '../features/Consumption/screens/ConsumptionListScreen'
import DocumentListScreen from '../features/Document/screens/DocumentListScreen'
import DrawListScreen from '../features/Draw/screens/DrawListScreen'
import HomeScreen from '../features/HomeScreen/screens/HomeScreen'
import PanicButtonScreen from '../features/HomeScreen/screens/PanicButtonScreen'
import InformationDetailScreen from '../features/Information/screens/InformationDetailScreen'
import InformationListScreen from '../features/Information/screens/InformationListScreen'
import InvoiceListScreen from '../features/Invoice/screens/InvoiceListScreen'
import LostListScreen from '../features/Lost/screens/LostListScreen'
import MailingListScreen from '../features/Mailing/screens/MailingListScreen'
import MeetingListScreen from '../features/Meeting/screens/MeetingListScreen'
import MeetingSubscriptionDetailScreen from '../features/Meeting/screens/MeetingSubscriptionDetailScreen'
import MovingListScreen from '../features/Moving/screens/MovingListScreen'
import MovingNewScreen from '../features/Moving/screens/MovingNewScreen'
import AnimalDetailScreen from '../features/MyData/screens/AnimalDetailScreen'
import AnimalNewScreen from '../features/MyData/screens/AnimalNewScreen'
import EditProfile from '../features/MyData/screens/EditProfile'
import MyDataScreen from '../features/MyData/screens/MyDataScreen'
import ResidentDetailScreen from '../features/MyData/screens/ResidentDetailScreen'
import ResidentNewScreen from '../features/MyData/screens/ResidentNewScreen'
import VehicleDetailScreen from '../features/MyData/screens/VehicleDetailScreen'
import VehicleNewScreen from '../features/MyData/screens/VehicleNewScreen'
import NoticeDetailScreen from '../features/Notice/screens/NoticeDetailScreen'
import NoticeListScreen from '../features/Notice/screens/NoticeListScreen'
import PenaltyDetailScreen from '../features/Penalty/screens/PenaltyDetailScreen'
import PenaltyListScreen from '../features/Penalty/screens/PenaltyListScreen'
import ReservationListScreen from '../features/PM/Reservation/screens/ReservationListScreen'
import ReservationNewScreen from '../features/PM/Reservation/screens/ReservationNewScreen'
import {qs} from '../features/queries'
import SpreadsheetScreen from '../features/Spreadsheet/screens/SpreadsheetScreen'
import SurveyDetailScreen from '../features/Survey/screens/SurveyDetailScreen'
import SurveyListScreen from '../features/Survey/screens/SurveyListScreen'
import TicketDetailScreen from '../features/Ticket/screens/TicketDetailScreen'
import TicketListScreen from '../features/Ticket/screens/TicketListScreen'
import TicketNewScreen from '../features/Ticket/screens/TicketNewScreen'
import {listenRouteChange, navigationRef} from '../services'
import {userLoggedInAtom} from '../state/atoms'
import {useAppTheme} from '../styles/theme'
import {platformChecker} from '../utils'
import {RootStackParamList} from './types'

const PrivateDrawer = createDrawerNavigator<RootStackParamList>()
const PublicStack = createNativeStackNavigator()

const linking: LinkingOptions<any> = {
  prefixes: [],
  config: {
    screens: {
      Login: 'login',
      ResetPasswordScreen: 'reset-password',
      SignRequestScreen: 'sign-request',

      HomeScreen: 'home',
      MyTeams: 'my-teams',
      ChangePasswordScreen: 'change-password',
      PanicButtonScreen: 'panic',

      MyDataScreen: 'my-data',
      EditProfile: 'profile',
      ResidentDetailScreen: 'people/:item_id',
      ResidentNewScreen: 'people-new',
      AnimalDetailScreen: 'animals/:item_id',
      AnimalNewScreen: 'animals-new',
      VehicleDetailScreen: 'vehicles/:item_id',
      VehicleNewScreen: 'vehicles-new',

      MailingListScreen: 'mailings',
      ConsumptionListScreen: 'consumptions',
      DocumentListScreen: 'documents',
      LostListScreen: 'losts',
      InformationListScreen: 'informations',
      InformationDetailScreen: 'informations/:item_id',
      NoticeListScreen: 'notices',
      NoticeDetailScreen: 'notices/:item_id',
      InvoiceListScreen: 'invoices',

      DrawListScreen: 'draws',

      SurveyListScreen: 'surveys',
      SurveyDetailScreen: 'surveys/:item_id',
      AccessAuthorizationListScreen: 'access-authorizations',
      AccessAuthorizationNewScreen: 'access-authorizations-new',
      PenaltyListScreen: 'penalties',
      PenaltyDetailScreen: 'penalties/:item_id',
      TicketListScreen: 'tickets',
      TicketDetailScreen: 'tickets/:item_id',
      TicketNewScreen: 'tickets-new',
      ConstructionListScreen: 'constructions',
      ConstructionDetailScreen: 'constructions/:item_id',
      ConstructionNewScreen: 'constructions-new',
      MovingListScreen: 'movings',
      MovingNewScreen: 'movings-new',

      BookListScreen: 'books',
      BookDetailScreen: 'books/:item_id',
      BookNewScreen: 'books-new/:item_id',

      SpreadsheetScreen: 'spreadsheets/:item_id',

      MeetingListScreen: 'meetings',
      MeetingSubscriptionDetailScreen: 'meetings/:item_id',

      ClassifiedListScreen: 'classifieds',
      ClassifiedViewScreen: 'classifieds/:item_id',
      ClassifiedNewScreen: 'classifieds-new',

      ReservationListScreen: 'reservations',
      ReservationNewScreen: 'reservations-new'
    }
  }
}

const PublicArea = () => {
  return (
    <PublicStack.Navigator
      screenOptions={({ navigation }) => {
        return {
          detachPreviousScreen: !navigation.isFocused(),
          headerBackTitleVisible: false,
          header: ({ navigation, route, options, back }) => {
            const title = getHeaderTitle(options, route.name)
            const headerBackTitle = options.headerBackTitle

            return (
              <Appbar.Header>
                {
                  back || headerBackTitle ?
                    <Appbar.BackAction onPress={() => headerBackTitle ? navigation.navigate(headerBackTitle) : navigation.goBack()}/>
                    :
                    (navigation as any).openDrawer ?
                      (
                        <Appbar.Action
                          icon="menu"
                          onPress={() => (navigation as any as DrawerNavigationProp<{}>).openDrawer()
                          }
                        />
                      )
                      :
                      null
                }
                <Appbar.Content title={title}/>
              </Appbar.Header>
            )
          }
        }
      }}
    >

      <PublicStack.Screen name="Login" component={LoginScreen} options={{ headerShown: false, presentation: 'modal' }}/>
      <PublicStack.Screen
        name="ResetPasswordScreen"
        component={ResetPasswordScreen}
        options={{ title: 'Esqueci minha senha', headerBackTitle: 'Login', presentation: 'modal' }}
      />

      <PublicStack.Screen name="MyTeams" component={MyTeamsScreen} options={{ headerShown: false, presentation: 'modal' }}/>
      <PublicStack.Screen
        name="SignRequestScreen"
        component={SignRequestScreen}
        options={{ title: 'Primeiro Acesso', headerBackTitle: 'Login', presentation: 'modal' }}
      />

    </PublicStack.Navigator>
  )
}

const PrivateArea = () => {
  const [my_account_settings, setMyAccountSettings] = useState<any>(null)

  useFocusEffect(
    React.useCallback(() => {
      qs.user.getMyAccountSettings().then(ress => {
        setMyAccountSettings(ress)
      })
    }, [])
  )

  const isTabletOrMobileDevice = useMediaQuery({
    maxDeviceWidth: 1024
    // alternatively...
    // query: "(max-device-width: 1224px)"
  })

  let drawer_type: 'front' | 'back' | 'slide' | 'permanent' = 'front'

  if (platformChecker.isWeb && !isTabletOrMobileDevice) {
    drawer_type = 'permanent'
  }

  if (typeof my_account_settings?.use_condoid_v2 !== 'boolean') {
    return (
      <ActivityLoading visible={true}/>
    )
  }

  return (
    <PrivateDrawer.Navigator
      screenOptions={({ navigation }) => {
        return {
          drawerType: drawer_type,
          headerBackTitleVisible: false,
          header: ({ navigation, route, options }) => {
            const title = getHeaderTitle(options, route.name)
            // @ts-ignore
            const backRoute = options.backRoute

            return (
              <Appbar.Header>
                {
                  backRoute ?
                    <Appbar.BackAction onPress={() => navigation.navigate(backRoute)}/>
                    :
                    (navigation as any).openDrawer ?
                      (
                        isTabletOrMobileDevice &&
                        <Appbar.Action icon="menu" onPress={() => (navigation as any as DrawerNavigationProp<{}>).openDrawer()}/>
                      )
                      :
                      null
                }
                <Appbar.Content title={title}/>

                {options.headerRight && options.headerRight({})}
              </Appbar.Header>
            )
          }
        }
      }}

      drawerContent={(prop) => <SideBar {...prop}/>}
    >

      <PrivateDrawer.Screen name="HomeScreen" component={HomeScreen} options={{ title: 'Início' }}/>
      <PrivateDrawer.Screen
        name="PanicButtonScreen" component={PanicButtonScreen}
        options={() => ({ title: 'Botão de Apoio', backRoute: 'MyDataScreen' })}
      />
      <PrivateDrawer.Screen name="MyTeams" component={MyTeamsScreen} options={{ headerShown: false }}/>
      <PrivateDrawer.Screen name="ChangePasswordScreen" component={ChangePasswordScreen} options={{ title: 'Alterar senha' }}/>
      <PrivateDrawer.Screen name="Login" component={LoginScreen} options={{ headerShown: false }}/>

      <PrivateDrawer.Screen name="MyDataScreen" component={MyDataScreen} options={{ title: 'Cadastros' }}/>
      <PrivateDrawer.Screen
        name="EditProfile" component={EditProfile}
        options={() => ({ title: 'Editar perfil', backRoute: 'MyDataScreen' })}
      />
      <PrivateDrawer.Screen
        name="ResidentDetailScreen" component={ResidentDetailScreen}
        options={() => ({ title: 'Editar condômino', backRoute: 'MyDataScreen' })}
      />
      <PrivateDrawer.Screen
        name="ResidentNewScreen" component={ResidentNewScreen}
        options={() => ({ title: 'Novo condômino', backRoute: 'MyDataScreen' })}
      />
      <PrivateDrawer.Screen
        name="AnimalDetailScreen" component={AnimalDetailScreen}
        options={() => ({ title: 'Editar pet', backRoute: 'MyDataScreen' })}
      />
      <PrivateDrawer.Screen
        name="AnimalNewScreen" component={AnimalNewScreen}
        options={() => ({ title: 'Novo pet', backRoute: 'MyDataScreen' })}
      />
      <PrivateDrawer.Screen
        name="VehicleDetailScreen" component={VehicleDetailScreen}
        options={() => ({ title: 'Editar veículo', backRoute: 'MyDataScreen' })}
      />
      <PrivateDrawer.Screen
        name="VehicleNewScreen" component={VehicleNewScreen}
        options={() => ({ title: 'Novo veículo', backRoute: 'MyDataScreen' })}
      />

      {/* ONLY READER */}
      <PrivateDrawer.Screen name="MailingListScreen" component={MailingListScreen} options={{ title: 'Correspondências' }}/>
      <PrivateDrawer.Screen name="ConsumptionListScreen" component={ConsumptionListScreen} options={{ title: 'Consumos' }}/>
      <PrivateDrawer.Screen name="DocumentListScreen" component={DocumentListScreen} options={{ title: 'Documentos' }}/>
      <PrivateDrawer.Screen name="LostListScreen" component={LostListScreen} options={{ title: 'Achados e Perdidos' }}/>
      <PrivateDrawer.Screen name="InformationListScreen" component={InformationListScreen} options={{ title: 'Informações Importantes' }}/>
      <PrivateDrawer.Screen
        name="InformationDetailScreen" component={InformationDetailScreen}
        options={() => ({ title: 'Informação Importante', backRoute: 'InformationListScreen' })}
      />
      <PrivateDrawer.Screen name="NoticeListScreen" component={NoticeListScreen} options={{ title: 'Comunicados' }}/>
      <PrivateDrawer.Screen
        name="NoticeDetailScreen" component={NoticeDetailScreen}
        options={() => ({ title: 'Comunicado', backRoute: 'NoticeListScreen' })}
      />
      <PrivateDrawer.Screen name="InvoiceListScreen" component={InvoiceListScreen} options={{ title: 'Boletos' }}/>

      {/* BASIC CRUD */}
      <PrivateDrawer.Screen name="DrawListScreen" component={DrawListScreen} options={{ title: 'Sorteios' }}/>

      {/* HAS CRUD */}
      <PrivateDrawer.Screen name="SurveyListScreen" component={SurveyListScreen} options={{ title: 'Enquetes' }}/>
      <PrivateDrawer.Screen
        name="SurveyDetailScreen" component={SurveyDetailScreen}
        options={() => ({ title: 'Enquete', backRoute: 'SurveyListScreen' })}
      />

      <PrivateDrawer.Screen name="AccessAuthorizationListScreen" component={AccessAuthorizationListScreen} options={{ title: 'Autorizações' }}/>
      <PrivateDrawer.Screen
        name="AccessAuthorizationNewScreen" component={AccessAuthorizationNewScreen}
        options={() => ({ title: 'Nova autorização', backRoute: 'AccessAuthorizationListScreen' })}
      />

      <PrivateDrawer.Screen name="PenaltyListScreen" component={PenaltyListScreen} options={{ title: 'Infrações' }}/>
      <PrivateDrawer.Screen
        name="PenaltyDetailScreen" component={PenaltyDetailScreen}
        options={() => ({ title: 'Detalhes da infração', backRoute: 'PenaltyListScreen' })}
      />

      <PrivateDrawer.Screen name="TicketListScreen" component={TicketListScreen} options={{ title: 'Ocorrências' }}/>
      <PrivateDrawer.Screen
        name="TicketDetailScreen" component={TicketDetailScreen}
        options={() => ({ title: 'Detalhes da ocorrência', backRoute: 'TicketListScreen' })}
      />
      <PrivateDrawer.Screen
        name="TicketNewScreen" component={TicketNewScreen}
        options={() => ({ title: 'Nova ocorrência', backRoute: 'TicketListScreen' })}
      />

      <PrivateDrawer.Screen name="ConstructionListScreen" component={ConstructionListScreen} options={{ title: 'Obras' }}/>
      <PrivateDrawer.Screen
        name="ConstructionDetailScreen" component={ConstructionDetailScreen}
        options={() => ({ title: 'Detalhes da obra', backRoute: 'ConstructionListScreen' })}
      />
      <PrivateDrawer.Screen
        name="ConstructionNewScreen" component={ConstructionNewScreen}
        options={() => ({ title: 'Nova obra', backRoute: 'ConstructionListScreen' })}
      />

      <PrivateDrawer.Screen name="MovingListScreen" component={MovingListScreen} options={{ title: 'Mudanças' }}/>
      <PrivateDrawer.Screen
        name="MovingNewScreen" component={MovingNewScreen}
        options={() => ({ title: 'Nova mudança', backRoute: 'MovingListScreen' })}
      />

      <PrivateDrawer.Screen name="BookListScreen" component={BookListScreen} options={{ title: 'Reservas de Espaços' }}/>
      <PrivateDrawer.Screen
        name="BookDetailScreen" component={BookDetailScreen}
        options={() => ({ title: 'Detalhes da reserva', backRoute: 'BookListScreen' })}
      />
      <PrivateDrawer.Screen
        name="BookNewScreen" component={BookNewScreen}
        options={() => ({ title: 'Nova reserva', backRoute: 'BookListScreen' })}
      />

      <PrivateDrawer.Screen name="ReservationListScreen" component={ReservationListScreen} options={{ title: 'Hospedagens' }}/>
      <PrivateDrawer.Screen
        name="ReservationNewScreen" component={ReservationNewScreen}
        options={() => ({ title: 'Nova hospedagem', backRoute: 'ReservationListScreen' })}
      />

      <PrivateDrawer.Screen name="SpreadsheetScreen" component={SpreadsheetScreen} options={{ title: '' }}/>

      <PrivateDrawer.Screen name="MeetingListScreen" component={MeetingListScreen} options={{ title: 'Assembleias' }}/>
      <PrivateDrawer.Screen
        name="MeetingSubscriptionDetailScreen" component={MeetingSubscriptionDetailScreen}
        options={() => ({ title: 'Detalhes da assembleia', backRoute: 'MeetingListScreen' })}
      />

      <PrivateDrawer.Screen name="ClassifiedListScreen" component={ClassifiedListScreen} options={{ title: 'Classificados' }}/>
      <PrivateDrawer.Screen
        name="ClassifiedViewScreen" component={ClassifiedViewScreen}
        options={() => ({ title: 'Detalhes do anúncio', backRoute: 'ClassifiedListScreen' })}
      />
      {/*<PrivateDrawer.Screen*/}
      {/*  name="ClassifiedDetailScreen" component={ClassifiedDetailScreen}*/}
      {/*  options={() => ({ title: 'Editar anúncio', backRoute: 'ClassifiedListScreen' })}*/}
      {/*/>*/}
      <PrivateDrawer.Screen
        name="ClassifiedNewScreen" component={ClassifiedNewScreen}
        options={() => ({ title: 'Novo anúncio', backRoute: 'ClassifiedListScreen' })}
      />

    </PrivateDrawer.Navigator>
  )
}

function AppNavigation({ isLoggedIn }: { isLoggedIn: boolean }) {
  const theme = useAppTheme()
  const [hasLoggedIn, setUserLoggedIn] = useRecoilState(userLoggedInAtom)

  console.log('hasLoggedIn', hasLoggedIn)
  console.log('isLoggedIn', isLoggedIn)

  useEffect(() => {
    setUserLoggedIn(isLoggedIn)
  }, [isLoggedIn])

  return (
    <NavigationContainer
      ref={navigationRef}
      linking={linking}
      theme={{
        dark: false,
        colors: theme.colors as any
      }}
      documentTitle={{
        formatter: (options, route) =>
          `${options?.title ?? route?.name} - Condo Id`
      }}

      onStateChange={listenRouteChange}
    >

      {hasLoggedIn && <PrivateArea/>}
      {!hasLoggedIn && <PublicArea/>}

    </NavigationContainer>
  )
}

export default AppNavigation