import {useFocusEffect, useNavigation} from '@react-navigation/native'
import moment from 'moment'
import * as React from 'react'
import {FlatList, StyleSheet, TouchableOpacity, View} from 'react-native'
import {Caption, Title} from 'react-native-paper'
import {SafeAreaView} from 'react-native-safe-area-context'
import {useQuery} from 'react-query'
import {AclRender} from '../../../components/acl/components'
import AppFab from '../../../components/layout/AppFab'
import PlaceHolder from '../../../components/placeholder'
import {CONSTRUCTION_STATUS} from '../../../constants/fields'
import {RootDrawerScreenProps} from '../../../navigation/types'
import {useAppTheme} from '../../../styles/theme'
import {qs} from '../../queries'

function ConstructionListScreen() {
  const theme = useAppTheme()
  const navigation = useNavigation<RootDrawerScreenProps<'ConstructionListScreen'>['navigation']>()

  const { data, isFetching, refetch, remove } = useQuery(qs.constructions.all_key, () => qs.constructions.all(), { enabled: false })

  useFocusEffect(
    React.useCallback(() => {
      refetch()
      return () => { remove() }
    }, [])
  )

  const renderItem = ({ item }: any) => (
    <React.Fragment>
      <TouchableOpacity onPress={() => navigation.navigate('ConstructionDetailScreen', { item_id: item.id })}>
        <View style={styles.cardContainer}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <View style={{ flex: 3 }}>
              <Caption>#{item.id}</Caption>
              <Title>{item.unit?.with_block}</Title>

              <Caption>
                Data
              </Caption>
              <Caption style={{ fontWeight: 'bold' }}>
                {item.start_date && moment(item.start_date).format('DD/MM/YY')}
                {' '}{' '}-{' '}{' '}
                {item.end_date && moment(item.end_date).format('DD/MM/YY')}
              </Caption>
            </View>
            <View style={{ flex: 1, alignSelf: 'center' }}>
              <Caption style={{ textAlign: 'right', fontWeight: 'bold', color: theme.colors.accent }}>
                {CONSTRUCTION_STATUS[item.status]}
              </Caption>
            </View>
          </View>
        </View>
      </TouchableOpacity>
    </React.Fragment>
  )

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      <FlatList
        style={styles.list}
        contentContainerStyle={styles.listContent}
        data={data}
        renderItem={renderItem}
        keyExtractor={item => item.id + ''}
        refreshing={isFetching}
        onRefresh={refetch}
        ListEmptyComponent={() => (
          <PlaceHolder
            visible={true}
            emoji={'🛠'}
            description={'Aqui você pode solicitar autorização para realizar uma obra na sua unidade.'}
          />
        )}
      />

      <AclRender required={['construction.add_construction']}>
        <AppFab icon="plus" onPress={() => navigation.navigate('ConstructionNewScreen')}/>
      </AclRender>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  list: {
    flex: 1
  },
  listContent: {
    paddingHorizontal: 8,
    paddingVertical: 14
  },
  cardContainer: {
    borderWidth: 1,
    borderColor: '#e8e8e8',
    borderRadius: 6,
    padding: 12,
    marginBottom: 14
  }
})

export default ConstructionListScreen
