import {useFocusEffect} from '@react-navigation/native'
import moment from 'moment'
import React from 'react'
import {FlatList, StyleSheet, View} from 'react-native'
import {Caption, Title} from 'react-native-paper'
import {SafeAreaView} from 'react-native-safe-area-context'
import {useQuery} from 'react-query'
import {AclRender} from '../../../components/acl/components'
import NotPermitted from '../../../components/NotPermitted'
import PlaceHolder from '../../../components/placeholder'
import {getLosts} from '../queries'

export default function LostListScreen() {
  const { data, isFetching, refetch, remove } = useQuery(['getLosts'], getLosts, { enabled: false })

  useFocusEffect(
    React.useCallback(() => {
      refetch()

      return () => {
        remove()
      }
    }, [])
  )

  const renderItem = ({ item }: any) => (
    <React.Fragment>
      <View style={styles.cardContainer}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View style={{ flex: 1 }}>
            <Caption>{moment(item.created_at).format('DD/MM/YYYY - HH:mm')}</Caption>
            <Title>{item.name}</Title>
            <Caption>Local: {item.found_place}</Caption>
          </View>
        </View>
      </View>
    </React.Fragment>
  )

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      <AclRender required={['unit.view_lost']} fallback={<NotPermitted/>}>
        <FlatList
          style={styles.list}
          contentContainerStyle={styles.listContent}
          data={data}
          renderItem={renderItem}
          keyExtractor={item => item.id + ''}
          refreshing={isFetching}
          onRefresh={refetch}
          ListEmptyComponent={() => (
            <PlaceHolder
              visible={true}
              emoji={'👀'}
              description={''}
            />
          )}
        />
      </AclRender>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  list: {
    flex: 1
  },
  listContent: {
    paddingHorizontal: 8,
    paddingVertical: 14
  },
  cardContainer: {
    borderWidth: 1,
    borderColor: '#e8e8e8',
    borderRadius: 6,
    padding: 12,
    marginBottom: 14
  }
})