import {hv} from '../utils'

export default class ToasterManager {
  static dropDown

  static setDropDown(dropDown) {
    this.dropDown = dropDown
  }

  static get() {
    return this.dropDown
  }

  static error(msg) {
    this.dropDown.alertWithType('error', 'Ops!', String(msg))
  }

  static success(msg) {
    this.dropDown.alertWithType('success', 'Sucesso', msg)
  }

  static warn(msg) {
    this.dropDown.alertWithType('warn', 'Atenção!', msg)
  }

  static sendErrors(error, default_msg = 'Ocorreu algum erro!') {
    if (hv(error, 'response.data.errors')) {
      /*
            {
              "code": "1000",
              "message": "Dados inválidos",
              "errors": [
              {
                "code": "2012",
                "field": "Email",
                "message": "Insira um endereço de email válido."
              }
            ]
            }
      */
      // let all_erros = []
      // error.response.data.errors.forEach(item => all_erros.push(item.message))
      // Alert.alert('Ocorreram os seguintes erros:', all_erros.join('\n'))

      const messages = error.response.data.errors
      messages.forEach((item) => {
        this.error(item.message)
      })
    } else if (hv(error, 'response.data.message')) {
      /*
            {
              "message": "Condomínio não existe!",
              "success": false
            }
      */
      this.error(error.response.data.message)
    } else if (hv(error, 'response.data.detail')) {
      /*
            {
              "detail": "Condomínio não existe!",
            }
      */
      this.error(error.response.data.detail)
    } else if (hv(error, 'response.data') && Array.isArray(error.response.data)) {
      error.response.data.forEach((item) => {
        Object.values(item).forEach((item) => {
          this.error(item[0])
        })
      })
    } else if (hv(error, 'response.data') && typeof (error.response.data) === 'object') {
      Object.values(error.response.data).forEach((item) => {
        this.error(item[0])
      })
    } else {
      this.error(default_msg)
    }
  }
}
