import {Field} from 'formik'
import * as React from 'react'
import {useQuery} from 'react-query'
import {qs} from '../../features/queries'
import DialogSelect from './DialogSelect'

const ParkingSelect = (props: any) => {
  const { data } = useQuery(qs.parkings.all_key, () => qs.parkings.all())

  return (
    <Field
      label={props.label ? props.label : 'Vaga'}
      name={props.name ? props.name : 'parking'}
      component={DialogSelect}
      data={data ?? []}
      id_option="id"
      label_option="with_pavement"
      {...props}
    />
  )
}

export default ParkingSelect