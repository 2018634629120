import {useFocusEffect, useNavigation} from '@react-navigation/native'
import moment from 'moment'
import React from 'react'
import {FlatList, StyleSheet, TouchableOpacity, View} from 'react-native'
import {Caption, Title} from 'react-native-paper'
import {SafeAreaView} from 'react-native-safe-area-context'
import {useQuery} from 'react-query'
import PlaceHolder from '../../../components/placeholder'
import {RootDrawerScreenProps} from '../../../navigation/types'
import {useAppTheme} from '../../../styles/theme'
import {qs} from '../../queries'
import {SurveyModel} from '../../typings'

export default function SurveyListScreen() {
  const theme = useAppTheme()
  const navigation = useNavigation<RootDrawerScreenProps<'SurveyListScreen'>['navigation']>()

  const { data, isFetching, refetch, remove } = useQuery(qs.surveys.all_key, () => qs.surveys.all(), { enabled: false })

  useFocusEffect(
    React.useCallback(() => {
      refetch()

      return () => {
        remove()
      }
    }, [])
  )

  const renderItem = ({ item }: { item: SurveyModel }) => (
    <React.Fragment>
      <TouchableOpacity onPress={() => navigation.navigate('SurveyDetailScreen', { item_id: item.id })}>
        <View style={styles.cardContainer}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <View style={{ flex: 3 }}>
              <Title>{item.name}</Title>
              <Caption>Início da Votação: {moment(item.start_date).format('DD/MM - HH:mm')}</Caption>
              <Caption>Término da Votação: {moment(item.end_date).format('DD/MM - HH:mm')}</Caption>
            </View>
            <View style={{ flex: 1, alignSelf: 'center' }}>
              <Caption style={{ textAlign: 'right', fontWeight: 'bold', color: theme.colors.accent }}>
                visualizar
              </Caption>
            </View>
          </View>
        </View>
      </TouchableOpacity>
    </React.Fragment>
  )

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      <FlatList<SurveyModel>
        style={styles.list}
        contentContainerStyle={styles.listContent}
        data={data}
        renderItem={renderItem}
        keyExtractor={item => item.id + ''}
        refreshing={isFetching}
        onRefresh={refetch}
        ListEmptyComponent={() => (
          <PlaceHolder
            visible={true}
            emoji={'🙋'}
            description={'Nenhuma enquete disponível.'}
          />
        )}
      />
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  list: {
    flex: 1
  },
  listContent: {
    paddingHorizontal: 8,
    paddingVertical: 14
  },
  cardContainer: {
    borderWidth: 1,
    borderColor: '#e8e8e8',
    borderRadius: 6,
    padding: 12,
    marginBottom: 14
  }
})