import {useIsFocused, useNavigation} from '@react-navigation/native'
import axios from 'axios'
import * as ImagePicker from 'expo-image-picker'
import {Formik, getIn} from 'formik'
import * as mime from 'mime-types'
import React, {FC, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Alert, Image, ScrollView, View} from 'react-native'
import {Button, Subheading, Title} from 'react-native-paper'
import {SafeAreaView} from 'react-native-safe-area-context'
import * as Yup from 'yup'
import DialogSelect from '../../../components/form/DialogSelect'
import FaceCamera from '../../../components/form/FaceCamera'
import {FormikDatePicker} from '../../../components/form/FormikInputs'
import {FF, FormContainer, FormikInput, FormikRadio} from '../../../components/form/FormInputs'
import SingleInputFile from '../../../components/form/SingleFileInput'
import Spacer from '../../../components/Spacer'
import {BR_STATES, GENDERS} from '../../../constants/fields'
import enviroment from '../../../enviroment'
import {RootDrawerScreenProps} from '../../../navigation/types'
import authService from '../../../services/authService'
import {sdkSentry} from '../../../services'
import ToasterManager from '../../../services/ToasterManager'
import {gv} from '../../../utils'

type CheckerDataType = {
  has_cpf: boolean
  has_email: boolean
  has_user: boolean
  team_configs_general: {
    classified__auto_approve: boolean
    gater__privacy_fields__person__cpf: boolean
    gater__privacy_fields__person__phone: boolean
    gater__privacy_fields__person__rg: boolean
    gater__required_fields__mailing__delivery_place: boolean
    gater__required_fields__mailing__doc: boolean
    gater__work_shifts__description__template: string
    invoice__import_from_email__from_email: string
    invoice__import_from_email__pwd: string
    invoice__import_from_email__user: string
    mailing__delivery_place__enum: { [key: string]: string }
    notice__show_content_in_email: boolean
    resident__sign_request__require__contact: boolean
    sign_request__custom_fields: { key: string, label: string, input_type: string }[]
    welcome_resident_email: string
  }
}

function SignRequestScreen() {
  const navigation = useNavigation<RootDrawerScreenProps<'SignRequestScreen'>['navigation']>()
  const { t } = useTranslation()

  const [step, setStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const [checker_data, setCheckerData] = useState<CheckerDataType | null>(null)
  const [image, setImage] = useState<string | null>(null)
  const [showFace, setShowFace] = useState(false)

  const UNIT_PERSON_TYPES = {
    1: t('owner_name'),
    2: t('renter_name')
  }

  useEffect(() => {
    (async () => {
      if (step === 3) {
        const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync()
        if (status !== 'granted') {
          ToasterManager.error('Precisamos de permissões para acessar a sua camera para tirar a foto.')
        }
      }
    })()
  }, [step])

  useEffect(() => {
    authService.setTeamVersionLocal('')
  }, [])

  const setTeamVersion = async (team_code: string) => {
    try {
      const APP_ENV = await enviroment()

      const payload = {
        team_code: team_code
      }

      let { data: res } = await axios.post(APP_ENV.apiUrl + 'public/team-version', payload)
      await authService.setTeamVersionLocal(res.is_v2)
    } catch (err) {
      throw err
    }
  }

  const Step: FC = ({ children }) => (
    <ScrollView>
      {children}
    </ScrollView>
  )

  const custom_fields = checker_data?.team_configs_general.sign_request__custom_fields
  const custom_fields_keys = custom_fields?.map(i => i.key)
  const custom_fields_init_values = custom_fields_keys?.reduce<{ [key: string]: string }>((prev, curr) => {
    prev[curr] = ''
    return prev
  }, {}) ?? {}
  const custom_fields_validation = custom_fields_keys?.reduce<{ [key: string]: any }>((prev, curr) => {
    prev[curr] = Yup.string().required()
    return prev
  }, {}) ?? {}

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      <Formik
        initialValues={{
          subdomain: '',
          cpf: '',
          email: '',
          unit_code: '',
          block_name: '',
          person_role: '',
          name: '',
          gender: '',
          birthday: '',
          mobile1: '',
          phone: '',
          same_address_condominium: false,
          address: '',
          district: '',
          city: '',
          state: '',
          cep: '',
          contact_name: '',
          contact_phone: '',
          contact_kinship: '',
          custom_fields: custom_fields_init_values
        }}
        validationSchema={Yup.object().shape({
          subdomain: Yup.string().required(),
          // @ts-ignore
          cpf: Yup.string().required(),
          email: Yup.string().required().email(),
          unit_code: Yup.string().required(),
          block_name: Yup.string().required(),
          person_role: Yup.string().required(),
          name: Yup.string().required(),
          gender: Yup.string().required(),
          birthday: Yup.string().required(),
          // @ts-ignore
          mobile1: Yup.string().validMobile().required(),
          same_address_condominium: Yup.boolean().required(),
          address: Yup.string()
            .when('same_address_condominium',
              (same_address_condominium: boolean) => same_address_condominium ? Yup.string() : Yup.string().required()),
          district: Yup.string()
            .when('same_address_condominium',
              (same_address_condominium: boolean) => same_address_condominium ? Yup.string() : Yup.string().required()),
          city: Yup.string()
            .when('same_address_condominium',
              (same_address_condominium: boolean) => same_address_condominium ? Yup.string() : Yup.string().required()),
          state: Yup.string()
            .when('same_address_condominium',
              (same_address_condominium: boolean) => same_address_condominium ? Yup.string() : Yup.string().required()),
          cep: Yup.string()
            .when('same_address_condominium',
              // @ts-ignore
              (same_address_condominium: boolean) => same_address_condominium ? Yup.string() : Yup.string().validCEP().required()),
          phone: Yup.string(),
          contact_name: checker_data?.team_configs_general.resident__sign_request__require__contact ? Yup.string().required() : Yup.string(),
          contact_phone: checker_data?.team_configs_general.resident__sign_request__require__contact ? Yup.string().required() : Yup.string(),
          contact_kinship: checker_data?.team_configs_general.resident__sign_request__require__contact ? Yup.string().required() : Yup.string(),
          custom_fields: Yup.object(custom_fields_validation)
        })}
        onSubmit={async (values) => {
          try {
            setLoading(true)
            // @ts-ignore
            let filename = `photo.${mime.extension(mime.lookup(image))}`
            let file_base_data = {
              filename: filename,
              key: ''
            }

            const APP_ENV = await enviroment()

            let { data: sign_res } = await axios.post(APP_ENV.apiUrl + 'public/sign-url', file_base_data)

            let s3_form_data = new FormData()
            for (let i of Object.keys(sign_res.fields)) {
              s3_form_data.append(i, sign_res.fields[i])
            }

            s3_form_data.append('file', {
              // @ts-ignore
              uri: image,
              name: filename,
              // @ts-ignore
              type: mime.lookup(filename)
            })

            await axios.post(sign_res.url, s3_form_data, { headers: { 'Content-Type': 'multipart/form-data' } })

            // @ts-ignore
            values['temp_file_key'] = sign_res.key

            axios({
              method: 'post',
              url: APP_ENV.apiUrl + 'public/sign-requests',
              data: values
            })
              .then((res) => {
                setLoading(false)

                let warning = gv(res, 'data.warning') as string
                Alert.alert('Socilitação enviada com sucesso!', warning)
                navigation.navigate('Login')
              })
              .catch((error) => {
                setLoading(false)

                console.log(error)
                ToasterManager.sendErrors(error)
                // sdkSentry.sendSentry(error, null, null, null)
              })
          } catch (e) {
            console.log(e)
            setLoading(false)
            // sdkSentry.sendSentry(e, null, null, null)
            ToasterManager.error('Erro ao enviar os dados!')
          }

        }}
      >
        {({ values, submitForm, validateForm, setFieldValue, ...formikBag }) => {
          const checkPerson = async () => {
            try {
              setLoading(true)

              await setTeamVersion(values.subdomain)

              const APP_ENV = await enviroment()

              const res: any = await axios({
                method: 'post',
                url: APP_ENV.apiUrl + 'public/sign-requests-checker',
                data: values
              })

              setLoading(false)

              setCheckerData(res.data)

              if (res.data.has_cpf || res.data.has_email) {
                setStep(10)
              } else {
                setStep(2)
              }
            } catch (error) {
              setLoading(false)
              ToasterManager.sendErrors(error)
              sdkSentry.sendSentry(error, null, null)
            }
          }

          const resetPassword = async () => {
            setLoading(true)

            const APP_ENV = await enviroment()

            axios({
              method: 'post',
              url: APP_ENV.rootApi + 'auth/password_reset',
              data: { email: values.email }
            })
              .then((res) => {
                setLoading(false)
                Alert.alert('', 'Nós te enviamos por e-mail as instruções para redefinição de sua senha!')
                navigation.navigate('Login')
              })
              .catch((error) => {
                setLoading(false)
                ToasterManager.sendErrors(error)
                sdkSentry.sendSentry(error, null, null)
              })
          }

          return (
            <FormContainer>
              {step === 0 &&
                <Step>
                  <View>
                    <Spacer/>

                    <Title style={{ textAlign: 'center' }}>
                      ⚠ {t('sign_request_screen__txt__2')}
                    </Title>

                    <Spacer/>

                    <Subheading style={{ textAlign: 'center' }}>
                      {t('sign_request_screen__txt__3')}
                    </Subheading>
                    <Subheading style={{ textAlign: 'center' }}>
                      Por favor para evitar duplicidade, prencher esse formulário somente uma vez!
                    </Subheading>
                  </View>

                  <Spacer/>

                  <Button mode="contained" onPress={() => setStep(1)}>
                    Entendi, avançar!
                  </Button>
                  <Spacer/>
                </Step>}

              {step === 1 &&
                <Step>
                  <Subheading style={{ textAlign: 'center', fontWeight: 'bold' }}>
                    {t('sign_request_screen__txt__1')}
                  </Subheading>

                  <Spacer size={20}/>

                  <FF label={`Código ${t('condominio')}`} name="subdomain" component={FormikInput}/>

                  <FF label={'CPF'} name="cpf" component={FormikInput} keyboardType="numeric"/>

                  <FF label={'E-mail'} name="email" component={FormikInput} keyboardType="email-address"/>

                  <Spacer size={30}/>

                  <Button
                    mode="contained"
                    loading={loading}
                    onPress={async () => {
                      let errs = await validateForm()
                      if (errs.subdomain || errs.cpf || errs.email) {
                        return
                      }

                      await checkPerson()
                    }}
                  >
                    Continuar
                  </Button>
                  <Spacer/>

                </Step>}

              {step === 2 &&
                <Step>
                  <Subheading style={{ textAlign: 'center', fontWeight: 'bold' }}>
                    Agora precisamos de mais algumas informações para finalizar a sua solicitação de cadastro.
                  </Subheading>

                  <Spacer size={20}/>

                  <FF label={t('sign_request_screen__form__unit_code__label')} name="unit_code" component={FormikInput}/>
                  <FF label={t('sign_request_screen__form__block_name__label')} name="block_name" component={FormikInput}/>
                  <FF label={'Você é'} name="person_role" component={DialogSelect} local_options={UNIT_PERSON_TYPES}/>

                  <FF label={'Nome'} name="name" component={FormikInput}/>
                  <FF label={'Sexo'} name="gender" component={DialogSelect} local_options={GENDERS}/>
                  <FormikDatePicker label={'Data de Nascimento'} name="birthday"/>

                  <FF label={'Celular'} name="mobile1" component={FormikInput} keyboardType="numeric"/>
                  <FF label={'Telefone'} name="phone" component={FormikInput} keyboardType="numeric"/>

                  <FF label={t('sign_request_screen__form__same_address_condominium')} name="same_address_condominium" component={FormikRadio}/>

                  {!values.same_address_condominium &&
                    <View>
                      <FF label={'Endereço'} name="address" component={FormikInput}/>
                      <FF label={'Bairro'} name="district" component={FormikInput}/>
                      <FF label={'Cidade'} name="city" component={FormikInput}/>
                      <FF label={'Estado'} name="state" component={DialogSelect} local_options={BR_STATES}/>
                      <FF label={'CEP'} name="cep" component={FormikInput} keyboardType="numeric"/>
                    </View>}

                  {checker_data?.team_configs_general.resident__sign_request__require__contact &&
                    <React.Fragment>
                      <Spacer size={20}/>

                      <Subheading style={{ textAlign: 'center', fontWeight: 'bold' }}>
                        Por favor, informe um pessoa para contato. Esse contato será usado para casos de urgência.
                      </Subheading>

                      <Spacer size={10}/>

                      <FF label={'Nome do Contato'} name="contact_name" component={FormikInput}/>
                      <FF label={'Telefone do Contato'} name="contact_phone" component={FormikInput} keyboardType="numeric"/>
                      <FF label={'Parentesco do Contato'} name="contact_kinship" component={FormikInput}/>
                    </React.Fragment>}

                  {custom_fields &&
                    <React.Fragment>
                      <Spacer size={20}/>

                      {custom_fields.map(i => {
                        if (i.input_type === 'text') return (
                          <FF key={i.key} label={i.label} name={`custom_fields.${i.key}`} component={FormikInput}/>
                        )

                        if (i.input_type === 'file') return (
                          <SingleInputFile
                            key={i.key}
                            label={i.label}
                            error_msg={getIn(formikBag.errors, `custom_fields.${i.key}`)}
                            handleNew={(s3_file_key) => setFieldValue(`custom_fields.${i.key}`, s3_file_key)}
                            handleRemoveUri={(uri) => setFieldValue(`custom_fields.${i.key}`, '')}
                          />
                        )

                        return <FF key={i.key} label={i.label} name={`custom_fields.${i.key}`} component={FormikInput}/>
                      })}
                    </React.Fragment>}

                  <Spacer size={30}/>

                  <Button mode="outlined" onPress={() => setStep(1)}>
                    Voltar
                  </Button>

                  <Spacer size={10}/>

                  <Button
                    mode="contained"
                    loading={loading}
                    onPress={async () => {
                      let errs = await validateForm()

                      if (Object.keys(errs).length > 0) {
                        return
                      }

                      setStep(3)
                    }}
                  >
                    Continuar
                  </Button>
                  <Spacer/>

                </Step>}

              {step === 3 &&
                <Step>
                  <Subheading style={{ textAlign: 'center', fontWeight: 'bold' }}>
                    Agora pra finalizar, tire uma foto do seu rosto.
                  </Subheading>

                  <Spacer size={40}/>

                  <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                    <FaceCamera
                      show={showFace}
                      setShow={setShowFace}
                      btnTitle={!image ? 'Tirar foto' : 'Alterar foto'}
                      handleImageUri={(uri) => {
                        setImage(uri)
                        setShowFace(false)
                      }}
                    />

                    <Spacer size={20}/>

                    {!!image && <Image source={{ uri: image }} style={{ width: 200, height: 200 }}/>}
                  </View>

                  <Spacer size={30}/>

                  <Button mode="outlined" onPress={() => setStep(2)}>
                    Voltar
                  </Button>

                  <Spacer size={30}/>

                  {!!image &&
                    <Button
                      mode="contained"
                      loading={loading}
                      onPress={async () => {
                        submitForm()
                      }}
                    >
                      Enviar solicitação!
                    </Button>}

                  <Spacer/>
                </Step>}

              {step === 10 &&
                <Step>
                  <Spacer size={40}/>

                  <Title style={{ textAlign: 'center', fontSize: 30 }}>
                    Oops!
                  </Title>

                  <Spacer size={30}/>

                  {checker_data?.has_cpf &&
                    <Subheading style={{ textAlign: 'center' }}>
                      - O CPF informado já está em uso.
                    </Subheading>}
                  {checker_data?.has_email &&
                    <Subheading style={{ textAlign: 'center' }}>
                      - O E-mail informado já está em uso.
                    </Subheading>}

                  <Spacer size={60}/>

                  {!checker_data?.has_user &&
                    <View>
                      <Subheading style={{ textAlign: 'center', fontWeight: 'bold' }}>
                        Você já está cadastrado no condomínio atual, mas ainda não tem acesso ao app.
                        Para mais informações procure a administração do condomínio.
                      </Subheading>
                    </View>}

                  {checker_data?.has_user &&
                    <View>
                      <Subheading style={{ textAlign: 'center', fontWeight: 'bold' }}>
                        Percebemos que você já tem o acesso liberado, gostaria que enviasse uma senha para o seu e-mail?
                      </Subheading>

                      <Spacer size={40}/>

                      <Button
                        mode="outlined"
                        onPress={() => {
                          setFieldValue('cpf', '')
                          setFieldValue('email', '')
                          setStep(1)
                        }}
                      >
                        Tentar outros dados
                      </Button>

                      <Spacer size={15}/>

                      <Button mode="contained" loading={loading} onPress={resetPassword}>
                        Enviar uma nova senha
                      </Button>
                    </View>}

                  <Spacer/>
                </Step>}
            </FormContainer>
          )
        }}
      </Formik>
    </SafeAreaView>
  )
}

export default function (props: any) {
  const isFocused = useIsFocused()

  if (!isFocused) return null

  return (
    <SignRequestScreen/>
  )
}