import {FontAwesome} from '@expo/vector-icons'
import moment from 'moment'
import React, {useState} from 'react'
import {Image, View} from 'react-native'
import {Button, Caption, Colors, Headline, Title} from 'react-native-paper'
import {useMutation} from 'react-query'
import tw from 'twrnc'
import AppListCard from '../../../components/layout/AppListCard'
import AppModalSheet from '../../../components/layout/AppModalSheet'
import {Pill} from '../../../components/layout/Pill'
import {CLASSIFIED_TYPES} from '../../../constants/fields'
import ToasterManager from '../../../services/ToasterManager'
import {useAppTheme} from '../../../styles/theme'
import {gv} from '../../../utils'
import {qs} from '../../queries'

const AdItemCard = ({ item, refetch, show_inactive_btn }: any) => {
  const theme = useAppTheme()

  const [show_sheet, setShowSheet] = useState(false)

  const cancel = useMutation((values: number | string) => qs.classifieds.inactive(values), {
    onSuccess: () => {
      ToasterManager.success('Desativado com sucesso')
      refetch()
    }
  })

  return (
    <AppListCard>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <View style={{ flex: 1 }}>
          {gv(item, 'files[0].file') &&
            <Image
              source={{ uri: item.files[0].file }}
              style={{ height: 200, width: '100%', flex: 1, borderRadius: 6, marginBottom: 8 }}
            />}

          <View style={{ flexDirection: 'row' }}>
            <Pill color={Colors.blueGrey600}>
              {CLASSIFIED_TYPES[item.type]}
            </Pill>

            {!item.active &&
              <Pill color={Colors.red600}>
                Inativo
              </Pill>}
          </View>

          <Title>
            {item.title}
          </Title>

          <Caption style={{ fontWeight: 'bold' }}>
            <FontAwesome name="calendar-o" size={12} color={theme.colors.primary}/>{' '}
            {moment(item.created_at).format('DD/MM - HH:mm')}
          </Caption>


          {show_inactive_btn && item.active &&
            <>
              <Button
                mode="outlined"
                onPress={() => setShowSheet(true)}
                loading={cancel.isLoading}
                disabled={cancel.isLoading}
                compact={true}
                color={'#d73e57'}
                style={tw`mt-4 mb-2`}
              >
                Desativar anúncio
              </Button>

              <AppModalSheet
                visible={show_sheet && !cancel.isLoading}
                height_percent={40}
                onDismiss={() => setShowSheet(false)}
                content={() => (
                  <>
                    <Headline style={{ fontWeight: 'bold', textAlign: 'center' }}>
                      Tem certeza que gostaria de desativar esse anúncio?
                    </Headline>

                    <Button
                      mode="outlined" compact={true} color="#d73e57"
                      style={tw`mt-3`}
                      onPress={() => {cancel.mutate(item.id)}}
                      loading={cancel.isLoading}
                      disabled={cancel.isLoading}
                    >
                      SIM, desativar o anúncio!
                    </Button>
                  </>
                )}
              />

            </>

          }
        </View>
      </View>
    </AppListCard>
  )
}

export default AdItemCard