import {useIsFocused, useNavigation} from '@react-navigation/native'
import {Formik} from 'formik'
import * as React from 'react'
import {useEffect, useState} from 'react'
import {Button, Text} from 'react-native-paper'
import {SafeAreaView} from 'react-native-safe-area-context'
import {useMutation} from 'react-query'
import * as Yup from 'yup'
import DialogSelect from '../../../components/form/DialogSelect'
import {FormikDateTimePicker} from '../../../components/form/FormikInputs'
import {FF, FormContainer, FormikInput} from '../../../components/form/FormInputs'
import UnitSelect from '../../../components/form/UnitSelect'
import Uploader from '../../../components/form/Uploader'
import Spacer from '../../../components/Spacer'
import {TICKET_TYPES} from '../../../constants/fields'
import {RootDrawerScreenProps} from '../../../navigation/types'
import {fetchApi} from '../../../services/api'
import ToasterManager from '../../../services/ToasterManager'
import {qs} from '../../queries'

const TicketNewScreen = () => {
  const navigation = useNavigation<RootDrawerScreenProps<'TicketNewScreen'>['navigation']>()
  const [categories, setCategories] = useState([])

  const create = useMutation((variables: any) => qs.tickets.post(variables), {
    onSuccess: () => {
      ToasterManager.success('Ocorrência criada com sucesso!')
      navigation.navigate('TicketListScreen')
    }
  })

  useEffect(() => {
    fetchApi('resident/categories')
      .then((res) => {
        setCategories(res.data)
      })
      .catch(() => {})
  }, [])

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      <Formik
        enableReinitialize={true}
        initialValues={{
          unit: '',
          type: '',
          category: '',
          date: '',
          description: '',
          files_attach: []
        }}
        validationSchema={Yup.object().shape({
          unit: Yup.string().required(),
          type: Yup.string().required(),
          category: Yup.string().required(),
          date: Yup.string().required(),
          description: Yup.string().required()
        })}
        onSubmit={(values) => {
          create.mutate(values)
        }}
        render={({ values, submitForm, setFieldValue }) => {
          return (
            <FormContainer>
              <UnitSelect/>
              <FF label={'Tipo'} name="type" component={DialogSelect} local_options={TICKET_TYPES}/>
              <FF label={'Categoria'} name="category" component={DialogSelect} data={categories} id_option="id" label_option="name"/>

              <FormikDateTimePicker label={'Data'} name="date"/>
              <FF label={'Descrição'} name="description" component={FormikInput} multiline={true}/>

              <Text> </Text>
              <Text> </Text>

              <Uploader
                model_id={null}
                model_type="ticket"
                file_type="attachments"
                buttonIcon="attachment"
                buttonText="Anexar Fotos"
                filesUploaded={[]}
                uploadComplete={(data: any) => {
                  let tempFile: any[] = values.files_attach
                  tempFile.push(data.id)
                  setFieldValue('files_attach', tempFile)
                }}
              />

              <Spacer size={15}/>

              <Button
                icon="content-save" mode="contained"
                loading={create.isLoading} disabled={create.isLoading}
                onPress={() => submitForm()}
              >
                Salvar
              </Button>
            </FormContainer>
          )
        }}
      />
    </SafeAreaView>
  )
}

export default function (props: any) {
  const isFocused = useIsFocused()

  if (!isFocused) return null

  return (
    <TicketNewScreen/>
  )
}
