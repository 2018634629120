import {useIsFocused, useRoute} from '@react-navigation/native'
import React from 'react'
import {SafeAreaView} from 'react-native-safe-area-context'
import {RootDrawerScreenProps} from '../../../navigation/types'
import AnimalForm from '../components/AnimalForm'

function AnimalDetailScreen() {
  const route = useRoute<RootDrawerScreenProps<'AnimalDetailScreen'>['route']>()

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      <AnimalForm initialValues={route.params?.data}/>
    </SafeAreaView>
  )
}

export default function (props: any) {
  const isFocused = useIsFocused()

  if (!isFocused) return null

  return (
    <AnimalDetailScreen/>
  )
}
