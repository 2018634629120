import * as Updates from 'expo-updates'
import {platformChecker} from '../utils'
import {sdkSentry} from './sentry'

const updateManager = {
  checkForUpdate: async () => {
    try {
      if (platformChecker.isNative) {
        const update = await Updates.checkForUpdateAsync()
        if (update.isAvailable) {
          await Updates.fetchUpdateAsync()
          await Updates.reloadAsync()
        }
      }
    } catch (e) {
      sdkSentry.sendSentry(e, null)
    }
  }
}

export default updateManager
