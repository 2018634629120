import axios from 'axios'
import * as mime from 'mime-types'
import React from 'react'
import {Platform} from 'react-native'
import enviroment from '../enviroment'
import {getAppVersion, getPlatformName, gv, platformChecker} from '../utils'
import {apiQsMetadata} from './apiService'
import authService from './authService'
import {sdkSentry} from './sentry'
import ToasterManager from './ToasterManager'

const apiAxios = axios.create()

apiAxios.interceptors.request.use(
  async function (config) {
    const token = await authService.getToken()

    const metadata_qs = await apiQsMetadata()

    config.url = config.url && config.url.indexOf('?') > -1
      ? `${config.url}&${metadata_qs}`
      : `${config.url}?${metadata_qs}`

    config.headers['Authorization'] = `Token ${token}`
    config.headers['Platform'] = getPlatformName()
    config.headers['AppVersion'] = getAppVersion()

    return config
  }
)

apiAxios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    let status = gv(error, 'response.status')
    let _data = gv(error, 'response.data')
    let url_status = `${status} - ${gv(error, 'response.config.url')}`

    console.log('error_url_status', url_status)
    console.log('error_data', _data)

    sdkSentry.lib().addBreadcrumb({
      category: 'axios_error',
      data: {
        raw: JSON.stringify(_data),
        url_status: url_status
      }
    })

    if (status === 401 || status === 403) {
      authService.logout()
      ToasterManager.error('Sessão expirou. Entre novamente!')
      return Promise.reject(error)
    } else {
      return Promise.reject(error)
    }
  }
)

/*
*  API HTTP
*/

export const fetchApi = async (endpoint, method = 'get') => {
  const token = await authService.getToken()
  const APP_ENV = await enviroment()

  return await apiAxios({
    method: method,
    url: APP_ENV.apiUrl + endpoint,
    headers: { 'Authorization': `Token ${token}` }
  })
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      sdkSentry.sendSentry(error, token, null, endpoint)
      return Promise.reject(error)
    })
}

export const postApi = async (endpoint, data) => {
  const token = await authService.getToken()
  const APP_ENV = await enviroment()

  return await apiAxios({
    method: 'post',
    url: APP_ENV.apiUrl + endpoint,
    data: data,
    headers: { 'Authorization': `Token ${token}` }
  })
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      sdkSentry.sendSentry(error, token, data, endpoint)
      return Promise.reject(error)
    })
}

export const postApiRootUrl = async (endpoint, data) => {
  const token = await authService.getToken()
  const APP_ENV = await enviroment()

  return await apiAxios({
    method: 'post',
    url: APP_ENV.rootApi + endpoint,
    data: data,
    headers: { 'Authorization': `Token ${token}` }
  })
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      // sendSentry(token, data)
      return Promise.reject(error)
    })
}

// User to send FormData files
export const postFormApi = async (endpoint, data, headers = null) => {
  const token = await authService.getToken()
  let custom_headers = {
    'Authorization': `Token ${token}`,
    'Accept': 'application/json',
    'Content-Type': 'multipart/form-data'
  }

  if (headers !== null) {
    custom_headers = {
      ...custom_headers,
      ...headers
    }
  }

  const APP_ENV = await enviroment()

  return await apiAxios({
    method: 'post',
    url: APP_ENV.apiUrl + endpoint,
    data: data,
    headers: custom_headers
  })
    .then((res) => {
      return res
    })
    .catch((error) => {
      sdkSentry.sendSentry(error, token, data, endpoint)

      ToasterManager.sendErrors(error)
      return Promise.reject(error)
    })
}

export const putApi = async (endpoint, data, id) => {
  const token = await authService.getToken()
  const APP_ENV = await enviroment()

  return await apiAxios({
    method: 'patch',
    url: APP_ENV.apiUrl + endpoint + '/' + id,
    data: data,
    headers: { 'Authorization': `Token ${token}` }
  })
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      sdkSentry.sendSentry(error, token, data, endpoint)

      return Promise.reject(error)
    })
}

export const deleteApi = async (endpoint, id) => {
  const token = await authService.getToken()
  const APP_ENV = await enviroment()

  return await apiAxios({
    method: 'delete',
    url: APP_ENV.apiUrl + endpoint + '/' + id,
    headers: { 'Authorization': `Token ${token}` }
  })
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export const uploadS3 = async (image_upload, type, filename) => {
  try {
    console.log('original file_uri', image_upload)

    // Fix on upgrade 42
    // https://stackoverflow.com/questions/68249940/expo-document-picker-does-not-give-back-the-correct-uri
    if (Platform.OS === 'android' && image_upload.indexOf('file:') === -1) {
      image_upload = encodeURI(`file://${image_upload}`)
      console.log('android file_uri', image_upload)
    }

    let file_base_data = {
      type: type,
      filename: filename,
      key: ''
    }

    let sign_res = await postApi('resident/files/sign_url', file_base_data)
    file_base_data['key'] = sign_res.key

    console.log(sign_res)

    let form_data = new FormData()
    for (let i of Object.keys(sign_res.fields)) {
      form_data.append(i, sign_res.fields[i])
    }

    if (platformChecker.isWeb) {
      form_data.append('file', image_upload)
    } else {
      form_data.append('file', {
        // @ts-ignore
        uri: image_upload,
        name: filename,
        type: mime.lookup(filename)
      })
    }

    let file_res = await axios.post(sign_res.url, form_data, { headers: { 'Content-Type': 'multipart/form-data' } })

    console.log('File URL: ', file_res?.headers?.location)

    return await postApi('resident/files/create_from_sign_url', file_base_data)

  } catch (e) {
    sdkSentry.sendSentry(e, null, null, null)
    return Promise.reject(e)
  }
}

export const uploadS3Public = async (file_uri) => {
  try {
    console.log('original file_uri', file_uri)

    // Fix on upgrade 42
    // https://stackoverflow.com/questions/68249940/expo-document-picker-does-not-give-back-the-correct-uri
    if (Platform.OS === 'android' && file_uri.indexOf('file:') === -1) {
      file_uri = encodeURI(`file://${file_uri}`)
      console.log('android file_uri', file_uri)
    }

    let filename = `temp_file.${mime.extension(mime.lookup(file_uri))}`

    let file_base_data = {
      filename: filename,
      key: ''
    }

    const APP_ENV = await enviroment()

    let { data: sign_res } = await axios.post(APP_ENV.apiUrl + 'public/sign-url', file_base_data)

    console.log('sign_res', sign_res)

    let form_data = new FormData()
    for (let i of Object.keys(sign_res.fields)) {
      form_data.append(i, sign_res.fields[i])
    }

    form_data.append('file', {
      // @ts-ignore
      uri: file_uri,
      name: filename,
      type: mime.lookup(filename)
    })

    let file_res = await axios.post(sign_res.url, form_data, { headers: { 'Content-Type': 'multipart/form-data' } })

    console.log('file_res', file_res)
    console.log('File URL: ', file_res?.headers?.location)

    return sign_res.key
  } catch (e) {
    sdkSentry.sendSentry(e, null, null, null)
    ToasterManager.error('Erro ao enviar o arquivo!')
    return Promise.reject(e)
  }
}
