import {useIsFocused} from '@react-navigation/native'
import React from 'react'
import {SafeAreaView} from 'react-native-safe-area-context'
import VehicleForm from '../components/VehicleForm'

function VehicleNewScreen() {
  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      <VehicleForm initialValues={null}/>
    </SafeAreaView>
  )
}

export default function (props: any) {
  const isFocused = useIsFocused()

  if (!isFocused) return null

  return (
    <VehicleNewScreen/>
  )
}