import * as Application from 'expo-application'
import {Platform} from 'react-native'
import {v5 as uuidv5} from 'uuid'
import * as ExpoManifest from '../../app.json'
import platformChecker from './platformChecker'

export function swipeobj(obj: { [key: string]: any }) {
  const ret: any = {}

  Object.keys(obj).forEach((key) => {
    ret[obj[key]] = key
  })

  return ret
}

export function map2array(obj: { [key: string]: any }) {
  const ret: any = []

  Object.keys(obj).forEach((key) => {
    ret.push({ id: key, name: obj[key] })
  })

  return ret
}

function getNestedObject(obj: any, nestedKeys: string) {
  if (arguments.length > 1 && typeof nestedKeys !== 'string') return undefined

  if (typeof obj !== 'undefined' && typeof nestedKeys === 'string') {
    const pathArr = nestedKeys.split('.')

    pathArr.forEach((key, idx, arr) => {
      if (typeof key === 'string' && key.includes('[')) {
        // extract the array index as string
        // @ts-ignore
        const pos = /\[([^)]+)\]/.exec(key)[1]
        // get the index string length (i.e. '21'.length === 2)
        const posLen = pos.length
        // @ts-ignore
        arr.splice(idx + 1, 0, Number(pos))

        // keep the key (array name) without the index comprehension:
        // (i.e. key without [] (string of length 2)
        // and the length of the index (posLen))
        arr[idx] = key.slice(0, (-2 - posLen))
      }
    })

    // @ts-ignore
    obj = pathArr.reduce((o, key) => (o && o[key] !== 'undefined' ? o[key] : undefined), obj)
  }
  return obj
}

export const hv = (obj: any, nestedKeys?: string): boolean => {
  let input: object | undefined | null | string | unknown = obj

  if (nestedKeys) {
    input = getNestedObject(obj, nestedKeys)
  }
  // @ts-ignore
  if (typeof input !== 'undefined' && input !== null && input !== '') return true
  return false
}

export const gv = (obj: any, nestedKeys?: string): null | string | number | boolean | any[] | object => {
  let input: unknown = obj

  if (nestedKeys) {
    input = getNestedObject(obj, nestedKeys)
  }
  if (typeof input !== 'undefined' && input !== null && input !== '') {
    // @ts-ignore
    return input
  }
  return null
}

export const giv = (obj: object | undefined | null, nestedKeys: string, default_value: any = '') => {
  let value = gv(obj, nestedKeys)
  // @ts-ignore
  if (typeof value !== 'undefined' && value !== null && value !== '') {
    if (Array.isArray(value) && value.length === 0) {
      return default_value
    }

    return value
  } else {
    return default_value
  }
}

export const getAppVersion = () => {
  return ExpoManifest.expo?.version
}

export const getPlatformName = () => {
  return platformChecker.isWeb ? 'portal_resident' : 'mobile_resident'
}

export async function getInstallationId() {
  const UUID_NAMESPACE = '29cc8a0d-747c-5f85-9ff9-f2f16636d963'
  let installationId
  let identifierForVendor

  if (platformChecker.isNative) {
    if (Platform.OS === 'android') {
      identifierForVendor = Application.androidId
    } else { // ios
      identifierForVendor = await Application.getIosIdForVendorAsync()
    }
  }

  const bundleIdentifier = Application.applicationId

  if (identifierForVendor) {
    installationId = uuidv5(`${bundleIdentifier}-${identifierForVendor}`, UUID_NAMESPACE)
  } else {
    const installationTime = await Application.getInstallationTimeAsync()
    installationId = uuidv5(`${bundleIdentifier}-${installationTime.getTime()}`, UUID_NAMESPACE)
  }

  return String(installationId)
}

export const b64ToFile: (url: string) => Promise<File> = (url) => fetch(url)
  .then(response => response.blob())
  .then(blob => {
      let file_type = extractB64ContentType(url)
      return new File([blob], 'attachment', { type: file_type })
    }
  )

export const extractB64ContentType = (url: string) => {
  if (url.indexOf('base64') > -1) {
    // data:image/jpeg;base64,/9j/4AAQSkZ
    return url.split(';')[0].split(':')[1]
  } else {
    throw Error('Is not Base64')
  }
}

export const isArrNotEmpty = (value: any[] | undefined | null, dft = null) => {
  if (Array.isArray(value) && value.length) {
    return value
  }

  return dft
}