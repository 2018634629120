import {useFocusEffect} from '@react-navigation/native'
import * as Clipboard from 'expo-clipboard'
import moment from 'moment'
import React, {useState} from 'react'
import {Linking, StyleSheet, TouchableOpacity, View, Text} from 'react-native'
import {Button, Caption, Title} from 'react-native-paper'
import {SafeAreaView} from 'react-native-safe-area-context'
import {useQuery} from 'react-query'
import AppFlatList from '../../../components/layout/AppFlatList'
import AppListCard from '../../../components/layout/AppListCard'
import AppModalSheet from '../../../components/layout/AppModalSheet'
import AppTabView from '../../../components/layout/AppTabView'
import PlaceHolder from '../../../components/placeholder'
import Spacer from '../../../components/Spacer'
import ToasterManager from '../../../services/ToasterManager'
import {useAppTheme} from '../../../styles/theme'
import {qs} from '../../queries'
import {InvoiceModel} from '../../typings'

function UnpaidTab() {
  const theme = useAppTheme()

  const { data, isFetching, remove, refetch } = useQuery(
    'getUnpaid',
    () => qs.invoices.getUnpaid(),
    { enabled: false }
  )

  const [current_item, setCurrentItem] = useState<InvoiceModel | null>(null)

  useFocusEffect(
    React.useCallback(() => {
      refetch()

      return () => {
        remove()
      }
    }, [])
  )

  const setClipboard = (text: string) => {
    Clipboard.setString(text)
    ToasterManager.success('Copiado!')
  }

  const renderItem = ({ item }: { item: InvoiceModel }) => (
    <TouchableOpacity onPress={() => item.last_transaction && setCurrentItem(item)}>
      <AppListCard>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View style={{ flex: 3 }}>
            <Title>{item.description}</Title>
            <Caption>Fatura: #{item.id}</Caption>
            <Caption>Vencimento: {moment(item.due_date).format('DD/MM/YYYY')}</Caption>
            <Caption style={{ fontWeight: 'bold' }}>Valor: {item.total_value}</Caption>
          </View>
          <View style={{ flex: 1, alignSelf: 'center' }}>
            {item.last_transaction &&
              <Caption style={{ textAlign: 'right', fontWeight: 'bold', color: theme.colors.accent }}>
                Detalhes
              </Caption>}
          </View>
        </View>
      </AppListCard>
    </TouchableOpacity>
  )

  const renderInner = () => (
    <View>
      <Text style={styles.panelSubtitle}>Valor: {current_item?.total_value ?? ''}</Text>

      <Text style={styles.panelSubtitle}>Linha digitável (toque em "copiar linha digitável ou pressione em cima do texte e copie.")</Text>
      <Text style={styles.panelTitle} selectable>{current_item?.last_transaction?.payment_barcode ?? ''}</Text>

      <Button mode="outlined" onPress={() => setClipboard(current_item?.last_transaction?.payment_barcode ?? '')}>
        Copiar linha digitável
      </Button>
      <Spacer size={15}/>

      {current_item?.last_transaction?.file &&
        <Button mode="contained" onPress={() => Linking.openURL(current_item?.last_transaction?.file ?? '')}>
          Abrir boleto
        </Button>}

      {!current_item?.last_transaction?.file &&
        <Button mode="contained" onPress={() => Linking.openURL(current_item?.public_link ?? '')}>
          Abrir boleto
        </Button>}
      <Spacer/>
    </View>
  )

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      <AppFlatList<InvoiceModel>
        data={data}
        renderItem={renderItem}
        keyExtractor={item => item.id + ''}
        refreshing={isFetching}
        onRefresh={refetch}
        ListEmptyComponent={() => (<PlaceHolder visible={true} emoji={'💰‍'} description={'Nenhuma boleto em aberto.'}/>)}
      />

      <AppModalSheet
        visible={!!current_item?.id}
        height_percent={60}
        onDismiss={() => setCurrentItem(null)}
        content={renderInner}
      />
    </SafeAreaView>
  )
}

function PaidTab() {
  const { data, isFetching, remove, refetch } = useQuery(
    'getPaid',
    () => qs.invoices.getPaid(),
    { enabled: false }
  )

  useFocusEffect(
    React.useCallback(() => {
      refetch()

      return () => {
        remove()
      }
    }, [])
  )

  const renderItem = ({ item }: { item: InvoiceModel }) => (
    <AppListCard>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <View style={{ flex: 1 }}>
          <Title>{item.description}</Title>
          <Caption>Fatura: #{item.id}</Caption>
          <Caption>Vencimento: {moment(item.due_date).format('DD/MM/YYYY')}</Caption>
          <Caption style={{ fontWeight: 'bold' }}>Valor: {item.total_value}</Caption>
        </View>
      </View>
    </AppListCard>
  )

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      <AppFlatList<InvoiceModel>
        data={data}
        renderItem={renderItem}
        keyExtractor={item => item.id + ''}
        refreshing={isFetching}
        onRefresh={refetch}
        ListEmptyComponent={() => (<PlaceHolder visible={true} emoji={'💰‍'} description={'Nenhuma boleto pago ainda.'}/>)}
      />
    </SafeAreaView>
  )
}

function InvoiceListScreen() {
  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      <AppTabView scenes={[
        { key: 'unpaid', title: 'Em aberto', scene: UnpaidTab },
        { key: 'paid', title: 'Pago', scene: PaidTab }
      ]}/>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  panelTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 30
  },
  panelSubtitle: {
    fontSize: 14,
    color: 'gray',
    marginBottom: 10
  }
})

export default InvoiceListScreen