import {fetchApi} from '../../services/api'

export const getLosts = async () => {
  try {
    let { data } = await fetchApi('resident/losts')
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}
