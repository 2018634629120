import {useNavigation} from '@react-navigation/native'
import {Formik} from 'formik'
import React from 'react'
import {Button} from 'react-native-paper'
import {useMutation} from 'react-query'
import * as Yup from 'yup'
import {AclRender} from '../../../components/acl/components'
import DialogSelect from '../../../components/form/DialogSelect'
import {FF, FormContainer, FormikInput} from '../../../components/form/FormInputs'
import UnitSelect from '../../../components/form/UnitSelect'
import Uploader from '../../../components/form/Uploader'
import Spacer from '../../../components/Spacer'
import {ANIMAL_GENDERS, ANIMAL_KINDS} from '../../../constants/fields'
import {RootDrawerScreenProps} from '../../../navigation/types'
import {EventLogger} from '../../../services/EventLogger'
import ToasterManager from '../../../services/ToasterManager'
import {qs} from '../../queries'

type Props = {
  // submitAction: (values: any) => Promise<any>,
  initialValues: any,
}

function AnimalForm({ initialValues }: Props) {
  const navigation = useNavigation<RootDrawerScreenProps<'AnimalDetailScreen'>['navigation']>()

  const create = useMutation((variables: any) => qs.animals.post(variables), {
    onSuccess: (res) => {
      EventLogger.logEvent('NewAnimal', 'action', res?.id, 'animal')
      ToasterManager.success('Pet criado com sucesso!')
      navigation.navigate('MyDataScreen')
    }
  })

  const update = useMutation((variables: any) => qs.animals.patch(variables), {
    onSuccess: () => {
      ToasterManager.success('Pet atualizado com sucesso!')
      navigation.navigate('MyDataScreen')
    }
  })

  return (
    <Formik
      initialValues={{
        id: initialValues?.id ?? '',
        unit: initialValues?.unit_id ?? '',
        name: initialValues?.name ?? '',
        kind: initialValues?.kind ?? '',
        breed: initialValues?.breed ?? '',
        color: initialValues?.color ?? '',
        gender: initialValues?.gender ?? '',

        files_attach: []
      }}
      validationSchema={Yup.object().shape({
        unit: Yup.string().required(),
        name: Yup.string().required(),
        kind: Yup.string().required(),
        breed: Yup.string().required(),
        color: Yup.string().required(),
        gender: Yup.string().required()
      })}
      onSubmit={(values) => {
        if (initialValues?.id) {
          update.mutate(values)
        } else {
          create.mutate(values)
        }
      }}
      render={({ values, errors, submitForm, setFieldValue }) => {
        return (
          <FormContainer>
            <UnitSelect/>
            <FF label={'Nome'} name="name" component={FormikInput}/>
            <FF label={'Tipo'} name="kind" component={DialogSelect} local_options={ANIMAL_KINDS}/>
            <FF label={'Raça'} name="breed" component={FormikInput}/>
            <FF label={'Sexo'} name="gender" component={DialogSelect} local_options={ANIMAL_GENDERS}/>
            <FF label={'Cor'} name="color" component={FormikInput}/>

            <Spacer size={40}/>

            <Uploader
              model_id={initialValues?.id}
              model_type="animal"
              file_type="attachments"
              buttonIcon="attachment"
              buttonText="Fotos/Documentos do Pet"
              filesUploaded={[]}
              uploadComplete={(_data: any) => {
                let tempFile: number[] = values.files_attach
                tempFile.push(_data.id)
                setFieldValue('files_attach', tempFile)
              }}/>

            <Spacer size={40}/>

            <AclRender required={['unit.add_animal', 'unit.change_animal']}>
              <Button
                icon="content-save" mode="contained"
                loading={create.isLoading || update.isLoading}
                disabled={create.isLoading || update.isLoading}
                onPress={() => submitForm()}
              >
                Salvar
              </Button>
            </AclRender>
          </FormContainer>
        )
      }}
    />
  )
}

export default AnimalForm
