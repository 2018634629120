// Blacklist common values.
const BLACKLIST = [
  '00000000000000',
  '11111111111111',
  '22222222222222',
  '33333333333333',
  '44444444444444',
  '55555555555555',
  '66666666666666',
  '77777777777777',
  '88888888888888',
  '99999999999999'
]

/**
 * Compute the Verifier Digit (or "Dígito Verificador (DV)" in portuguese) for CNPJ.
 *
 * You can learn more about this on [wikipedia (pt-br)](https://pt.wikipedia.org/wiki/D%C3%ADgito_verificador)
 *
 * @export
 * @param {string} numbers the CNPJ string with only numbers.
 * @returns {number} the verifier digit.
 */

function verifierDigit(numbers: any) {
  let index = 2
  const reverse = numbers.split('').reduce(function (buffer: any, number: any) {
    return [parseInt(number, 10)].concat(buffer)
  }, [])

  const sum = reverse.reduce(function (buffer: any, number: any) {
    buffer += number * index
    index = (index === 9 ? 2 : index + 1)
    return buffer
  }, 0)

  const mod = sum % 11
  return (mod < 2 ? 0 : 11 - mod)
}

function isValid(cnpj: any) {
  const stripped = (cnpj || '').toString().replace(/[-\/.]/g, '')

  // CNPJ must be defined
  if (!stripped) { return false }

  // CNPJ must have 14 chars
  if (stripped.length !== 14) { return false }

  // CNPJ can't be blacklisted
  if (BLACKLIST.includes(stripped)) { return false }

  let numbers = stripped.substr(0, 12)
  numbers += verifierDigit(numbers)
  numbers += verifierDigit(numbers)

  return numbers.substr(-2) === stripped.substr(-2)
}

const cpfCalcChecker1 = (firstNineDigits: any) => {
  let sum: any = null

  for (let j = 0; j < 9; ++j) {
    sum += firstNineDigits.toString().charAt(j) * (10 - j)
  }

  const lastSumChecker1 = sum % 11
  const checker1 = (lastSumChecker1 < 2) ? 0 : 11 - lastSumChecker1

  return checker1
}

const cpfCalcChecker2 = (cpfWithChecker1: any) => {
  let sum: any = null

  for (let k = 0; k < 10; ++k) {
    sum += cpfWithChecker1.toString().charAt(k) * (11 - k)
  }

  const lastSumChecker2 = sum % 11
  const checker2 = (lastSumChecker2 < 2) ? 0 : 11 - lastSumChecker2

  return checker2
}

const cpf_validate = (value: any):boolean => {
  if (!value || value === true) {
    return false
  }

  if (typeof value === 'number') {
    value = String(value)
  }

  const cleanCPF = value.replace(/\.|-|\s/g, '')
  const firstNineDigits = cleanCPF.substring(0, 9)
  const checker = cleanCPF.substring(9, 11)

  // Check if has dots
  if (value.length !== 11) {
    return false
  }

  if (cleanCPF.length !== 11) {
    return false
  }

  // Checking if all digits are equal
  for (let i = 0; i < 10; i++) {
    // @ts-ignore
    if (`${firstNineDigits}${checker}` === Array(12).join(i)) {
      return false
    }
  }

  const checker1 = cpfCalcChecker1(firstNineDigits)
  const checker2 = cpfCalcChecker2(`${firstNineDigits}${checker1}`)

  if (checker.toString() === checker1.toString() + checker2.toString()) {
    return true
  } else {
    return false
  }
}

export function validate_cpf_cpnj(value: any): boolean {
  if (!value || value === true) {
    return false
  }

  const cleaned = value.replace(/\.|-|\s/g, '')
  if (cleaned.length < 12) {
    return cpf_validate(value)
  }
  return isValid(value)
}
