import {MaterialCommunityIcons} from '@expo/vector-icons'
import {useFocusEffect, useIsFocused, useRoute} from '@react-navigation/native'
import moment from 'moment'
import React from 'react'
import {Linking, Text, View} from 'react-native'
import {Button, Caption, Title} from 'react-native-paper'
import {SafeAreaView} from 'react-native-safe-area-context'
import {useQuery} from 'react-query'
import {AclRender} from '../../../components/acl/components'
import {AppGridCol, AppGridRow} from '../../../components/layout/AppGridView'
import AppScrollView from '../../../components/layout/AppScrollView'
import ActivityLoading from '../../../components/loading'
import NotPermitted from '../../../components/NotPermitted'
import Spacer from '../../../components/Spacer'
import {CLASSIFIED_TYPES} from '../../../constants/fields'
import {RootDrawerScreenProps} from '../../../navigation/types'
import {EventLogger} from '../../../services/EventLogger'
import {qs} from '../../queries'

const ClassifiedViewScreen = () => {
  const route = useRoute<RootDrawerScreenProps<'ClassifiedViewScreen'>['route']>()

  let item_id = route.params?.item_id

  const { data: classified, isFetching, refetch, remove } = useQuery(
    [qs.classifieds.get_key, item_id],
    () => qs.classifieds.get(item_id),
    { enabled: false }
  )

  useFocusEffect(
    React.useCallback(() => {
      refetch()
      EventLogger.logEvent('SeeClassifiedView', 'screen', item_id, 'classified')
      return () => {
        remove()
      }
    }, [item_id])
  )

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      {
        !isFetching && classified?.id ?
          <AppScrollView>
            <View style={{ backgroundColor: '#eeeeee', padding: 15, borderRadius: 7 }}>
              <AppGridRow style={{ marginBottom: 15 }}>
                <AppGridCol>
                  <Title>{classified.title}</Title>
                </AppGridCol>
              </AppGridRow>

              <AppGridRow>
                <AppGridCol>
                  <Caption>Tipo</Caption>
                  <Text>{CLASSIFIED_TYPES[classified.type]}</Text>
                </AppGridCol>

                <AppGridCol>
                  <Caption>Data</Caption>
                  <Text>{moment(classified.created_at).format('DD/MM/YYYY - HH:mm')}</Text>
                </AppGridCol>
              </AppGridRow>
            </View>

            <Spacer size={30}/>

            <View>
              <View style={{ paddingHorizontal: 10 }}>
                <Caption style={{ marginBottom: 10 }}>Descrição</Caption>
                <Text>{classified.description}</Text>
                <Spacer size={10}/>
                <Caption style={{ marginBottom: 10 }}>Criado por: {classified.user_name}</Caption>
                <Spacer size={30}/>

                {classified.phone &&
                  <Button
                    color="green"
                    mode="outlined"
                    icon={(iconProps) => (<MaterialCommunityIcons name={'whatsapp'} {...iconProps}/>)}
                    onPress={() => {
                      EventLogger.logEvent('TapClassifiedAdWhatsApp', 'action', classified.id, 'classified')
                      Linking.openURL(
                        `https://wa.me/55${classified.phone}?text=Olá estou interessado no seu anúncio "${classified.title}" publicado no app Condo Id`)
                    }}
                  >
                    WhatsApp
                  </Button>
                }
              </View>
            </View>
          </AppScrollView>
          :
          <ActivityLoading visible={isFetching}/>
      }
    </SafeAreaView>
  )
}

export default function (props: any) {
  const isFocused = useIsFocused()

  if (!isFocused) return null

  return (
    <AclRender required={['classified.view_classified']} fallback={<NotPermitted/>}>
      <ClassifiedViewScreen/>
    </AclRender>
  )
}
