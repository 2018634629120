import {Ionicons} from '@expo/vector-icons'
import * as DocumentPicker from 'expo-document-picker'
import * as ImageManipulator from 'expo-image-manipulator'
import {SaveFormat} from 'expo-image-manipulator/src/ImageManipulator.types'
import * as ImagePicker from 'expo-image-picker'
import React, {useEffect, useState} from 'react'
import {Alert, View} from 'react-native'
import {Button, HelperText, Text} from 'react-native-paper'
import tw from 'twrnc'
import {uploadS3Public} from '../../services/api'
import Style from '../../styles/Style'
import AppModalSheet from '../layout/AppModalSheet'
import Spacer from '../Spacer'

type Props = {
  handleNew: (s3_file_key: string) => void
  handleRemoveUri: (uri: string) => void
  label: string,
  error_msg?: string,
}

export default function SingleInputFile(props: Props) {
  const [file_uri, setFileUri] = useState('')
  const [file_name, setFileName] = useState('')
  const [loading, setLoading] = useState(false)
  const [show_sheet, setShowSheet] = useState(false)

  const takePhoto = async () => {
    const { status } = await ImagePicker.requestCameraPermissionsAsync()
    await ImagePicker.requestMediaLibraryPermissionsAsync()

    if (status === 'granted') {
      let pickerResult = await ImagePicker.launchCameraAsync({
        quality: 0.8
      })

      if (!pickerResult.cancelled) {
        let result = await ImageManipulator.manipulateAsync(pickerResult.uri, [], { format: SaveFormat.JPEG })

        // result['name'] = `photo.${mime.extension(mime.lookup(result.uri))}`
        // result['file_type'] = mime.lookup(result.uri)
        // result['ext'] = mime.extension(mime.lookup(result.uri))

        handleNew(result.uri, 'foto.jpg')
      }
    } else {
      Alert.alert('Por favor autorize o acesso a camera e tente novamente!')
    }
  }

  const selectPhoto = async () => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync()
    await ImagePicker.requestCameraPermissionsAsync()

    if (status === 'granted') {
      let pickerResult = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        quality: 0.8
      })

      if (!pickerResult.cancelled) {
        let result = await ImageManipulator.manipulateAsync(pickerResult.uri, [], { format: SaveFormat.JPEG })

        // result['name'] = `photo.${mime.extension(mime.lookup(result.uri))}`
        // result['file_type'] = mime.lookup(result.uri)
        // result['ext'] = mime.extension(mime.lookup(result.uri))

        handleNew(result.uri, 'foto.jpg')
      }
    } else {
      Alert.alert('Por favor autorize o acesso a camera e tente novamente!')
    }
  }

  const selectFile = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: '*/*'
    })

    if (result.type === 'success') {
      console.log(result)
      handleNew(result.uri, result.name)
    }
  }

  const handleNew = async (uri: string, name: string) => {
    try {
      setLoading(true)
      let res = await uploadS3Public(uri)

      if (res) {
        console.log('Novo arquivo')
        props.handleNew(res)

        setFileUri(uri)
        setFileName(name)
      }

      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.error(e)
    }
  }

  useEffect(() => {
    if (!file_uri) {
      console.log('Arquivo removido')
      props.handleRemoveUri(file_uri)
    }

  }, [file_uri])

  return (
    <View style={tw.style('py-4')}>
      <Text style={tw`text-gray-500 font-bold pb-2`}>
        {props.label}
      </Text>

      <View style={tw.style('p-4 rounded-md flex-row items-center bg-gray-200')}>
        <Text style={tw.style(!!file_name && 'font-bold', !file_name && 'text-gray-600', 'flex-3 mr-5')}>
          {!!file_name ? file_name : 'Toque no botão ao lado para selecionar um arquivo'}
        </Text>

        {!file_name ?
          <Button
            icon="attachment" mode="outlined" style={{ flex: 1 }}
            onPress={() => {
              setShowSheet(true)
            }}
            loading={loading}
            disabled={loading}
          >
            SELECIONE
          </Button> :
          <Button
            mode="outlined"
            onPress={() => {
              setFileUri('')
              setFileName('')
            }}
          >
            <Ionicons name="ios-trash" color={Style.colors.danger} size={16}/>
          </Button>
        }

      </View>

      <HelperText type="error" visible={!!props.error_msg}>
        {props.error_msg}
      </HelperText>

      <AppModalSheet
        visible={show_sheet}
        height_percent={35}
        onDismiss={() => setShowSheet(false)}
        content={() => (
          <View>
            <Button icon="camera" mode="outlined" onPress={takePhoto}>Tirar foto</Button>
            <Spacer size={10}/>
            <Button icon="camera-burst" mode="outlined" onPress={selectPhoto}>Selecionar da galeria de fotos</Button>
            <Spacer size={10}/>
            <Button icon="file" mode="outlined" onPress={selectFile}>Selecionar arquivo</Button>
          </View>
        )}
      />
    </View>
  )
}
