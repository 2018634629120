import {PersonDocTypeEnum, ReservationGuestAgeGroupEnum, ReservationStatusEnum} from './app-enums'

export const BOOK_STATUS: { [index: number]: string } = {
  0: 'Cancelada',
  1: 'Aprovada',
  2: 'Pendente'
}

export const CONSTRUCTION_STATUS: { [index: number]: string } = {
  0: 'Recusado',
  1: 'Aprovada',
  2: 'Pendente'
}

export const CONSTRUCTION_MANAGER: { [index: number]: string } = {
  0: 'Nenhum',
  1: 'Arquiteto',
  2: 'Engenheiro'
}

export const CONTRACT_STATUS = {
  1: 'Ativo',
  0: 'Inativo'
}

export const CONTRACT_TYPES = {
  1: 'Mensal',
  2: 'Avulso'
}

export const DOCUMENT_STATUS = {
  1: 'Publicado',
  2: 'Rascunho'
}

export const INFORMATION_STATUS = {
  1: 'Publicado',
  2: 'Rascunho'
}

export const LOCKER_STATUS = {
  0: 'Inativo',
  1: 'Ativo',
  2: 'Alugado',
  3: 'Disponível para aluguel'
}

export const MAILING_STATUS = {
  1: 'Aguardando',
  2: 'Entregue'
}

export const MAINTENANCE_STATUS = {
  1: 'Ativo',
  2: 'Concluído'
}

export const MOVING_STATUS: { [index: number]: string } = {
  1: 'Aprovada',
  2: 'Pendente',
  3: 'Recusada'
}

export const RESERVATION_STATUS: { [index: number]: string } = {
  [ReservationStatusEnum.CANCELED]: 'Cancelada',
  [ReservationStatusEnum.PENDENT]: 'Pendente',
  [ReservationStatusEnum.CONFIRMED]: 'Confirmada',
  [ReservationStatusEnum.CHECKED_IN]: 'Check-in realizado',
  [ReservationStatusEnum.CHECKED_OUT]: 'Check-out realizado',
  [ReservationStatusEnum.NO_SHOW]: 'No-show'
}

export const RESERVATION_GUEST_AGE_GROUPS: { [index: number]: string } = {
  [ReservationGuestAgeGroupEnum.ADULT]: 'Adulto',
  [ReservationGuestAgeGroupEnum.CHILD]: 'Criança'
}

export const MOVING_TYPES = {
  1: 'Entrada',
  2: 'Saída'
}

export const NOTICE_STATUS = {
  1: 'Publicado',
  2: 'Rascunho'
}

export const PARKING_TYPES = {
  1: 'Simples',
  2: 'Dupla'
}

export const PARKING_STATUS = {
  1: 'Ativo',
  2: 'Alugado',
  3: 'Disponível para Aluguel'
}

export const PATRIMONY_STATUS = {
  1: 'Quitado',
  2: 'Em pagamento'
}

export const PENALTY_STATUS: { [index: number]: string } = {
  1: 'Emitida',
  2: 'Em Análise',
  3: 'Defesa Aceita',
  4: 'Defesa Recusada'
}

export const SPACE_STATUS = {
  1: 'Ativo',
  0: 'Inativo',
  2: 'Em manutenção'
}

export const SPACE_TAX_TYPES = {
  1: 'Percentual Salário Mínino',
  2: 'Valor Fixo',
  3: 'Valor não calculável'
}

export const TICKET_STATUS: { [index: number]: string } = {
  1: 'Finalizada',
  2: 'Pendente',
  3: 'Em andamento'
}

export const TICKET_TYPES: { [index: number]: string } = {
  1: 'Problema',
  2: 'Solicitação / Dúvida',
  3: 'Sugestão'
}

export const TICKET_PRIORITIES = {
  1: 'Normal',
  2: 'Baixa',
  3: 'Alta',
  4: 'Urgente'
}

export const VEHICLE_TYPES: { [index: number]: string } = {
  1: 'Carro',
  2: 'Moto',
  3: 'SUV',
  4: 'Picape'
}

export const OBJECT_CONTROL_STATUS = {
  1: 'Retirado',
  2: 'Devolvido'
}

export const ACCESSLOG_STATUS = {
  0: 'Recusado',
  1: 'Concluído',
  2: 'Aguardando',
  3: 'Entrou',
  4: 'Saiu'
}

export const ACCESSLOG_TYPES: { [index: number]: string } = {
  1: 'Entrada',
  2: 'Saída'
}

export const ACCESSLOG_PERSON_TYPES = {
  1: 'Visitante',
  2: 'Prestador de Serviços'
}

export const ACCESSAUTH_TYPES = {
  1: 'Apenas um dia',
  2: 'Período',
  3: 'Sempre liberado'
}

export const ACCESSAUTH_STATUS: { [index: number]: string } = {
  0: 'Recusado',
  1: 'Ativo',
  2: 'Aguardando',
  3: 'Concluído'
}

export const GUESTLIST_STATUS = {
  1: 'Aguardando',
  2: 'Entrou',
  3: 'Saiu'
}

export const ACCESSDRIVER_TYPES = {
  1: 'Controle',
  // 2: 'TAG Ativo',
  // 3: 'Cartão',
  // 5: 'Biometria',
  6: 'TAG Passivo'
  // 7: 'Senha',
}

export const PENALTYTYPE_STATUS = {
  1: 'Ativo',
  0: 'Inativo'
}

export const PENALTYTYPE_TAX_TYPES = {
  1: 'Percentual Salário Mínino',
  2: 'Valor Fixo'
}

export const SIGNREQUEST_STATUS = {
  0: 'Rejeitado',
  1: 'Pendente',
  2: 'Processado'
}

export const PANIC_STATUS = {
  1: 'Pendente',
  2: 'Em andamento',
  3: 'Concluído'
}

export const PANIC_TYPES = {
  1: 'Aviso de Chegada',
  2: 'Emergência de Segurança',
  3: 'Emergência Médica',
  4: 'Outro'
}

export const ACCESSDEVICE_STATUS = {
  0: 'Desativado',
  1: 'Ativo'
}

export const ACCESSDEVICE_MODELS = {
  1: 'Id Flex',
  2: 'Guarita Ip',
  3: 'Receptor TX',
  5: 'Bio ZK',
  6: 'Id Box'
}

export const ACCESSDEVICE_BRANDS = {
  1: 'Control Id',
  2: 'Linear-HCS',
  5: 'Bio ZK'
}

export const UNIT_STATUS = {
  1: 'Habitada',
  2: 'Desabitada',
  3: 'Disponível para aluguel',
  4: 'Em reforma'
}

export const UNIT_PERSON_STATUS = {
  1: 'Ativo',
  0: 'Inativo'
}

export const UNIT_PERSON_TYPES: { [index: number]: string } = {
  1: 'Proprietário',
  2: 'Inquilino',
  3: 'Dependente'
}

export const PERSON_TYPES = {
  1: 'Fisíca',
  2: 'Jurídica'
}

export const ACCESSRELAY_STATUS = {
  0: 'Desativado',
  1: 'Ativo'
}

export const ACCESSRELAY_TYPES = {
  1: 'Geral',
  2: 'Guarita'
}

export const ACCESSRELAY_WAYS = {
  1: 'Entrada',
  2: 'Saída'
}

export const ACCESSRELAY_ACCESS_TYPES = {
  1: 'Veículos',
  2: 'Pedestres'
}

export const ACCESSRELAY_RECEPTOR_TYPES = {
  0: 'Geral',
  1: 'TX',
  2: 'TA',
  3: 'CT-CTW',
  4: 'TP'
}

export const ACCESSGROUP_STATUS = {
  0: 'Desativado',
  1: 'Ativo'
}

export const STATES_SIGLAS = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO']

export const MEETING_TYPES = {
  1: 'Ordinária',
  2: 'Extraordinária'
}

export const MEETINGSUBSCRIPTION_STATUS: { [index: number]: string } = {
  1: 'Aguardando',
  2: 'Em andamento',
  3: 'Concluída'
}

export const DRAW_STATUS = {
  1: 'Ativo',
  0: 'Inativo',
  2: 'Concluída'
}

export const EXPENSE_STATUS = {
  1: 'Pendente',
  2: 'Pago'
}

export const EXPENSE_PAYMENT_TYPES = {
  1: 'Boleto',
  2: 'Cheque',
  3: 'Transf. Bancária',
  4: 'Cartão de Débito',
  5: 'Cartão de Credito',
  6: 'Débito automático',
  7: 'Dinheiro',
  8: 'DOC/TED',
  9: 'Outros'
}

export const EXPENSE_DOC_TYPES = {
  1: 'NFS-e',
  2: 'NF-e',
  3: 'Recibo',
  4: 'Recibo Fiscal',
  5: 'Outros'
}

export const BANK_ACCOUNT_TYPES = {
  1: 'Conta corrente',
  2: 'Investimento',
  3: 'Caixa'
}

export const PRORATE_STATUS = {
  1: 'Aberto',
  2: 'Fechado'
}

export const FRACTION_TYPES = {
  1: 'Porcentagem',
  2: 'Valor fixo'
}

export const CHARGE_TYPES = {
  1: 'Normal',
  2: 'Extra'
}

export const CHARGE_VALUE_FROM = {
  1: 'Total despesas adicionadas',
  3: 'Valor fixo rateado',
  4: 'Valor para cada unidade',
  5: 'Fundo de reserva',
  6: 'Reservas de Espaços',
  7: 'Multas',
  8: 'Consumo',
  9: 'Mt² unidade'
}

export const CHARGE_WHO_CHARGE = {
  1: 'Todas unidades',
  2: 'Única unidade',
  3: 'Único bloco'
}

export const INVOICE_TYPES = {
  1: 'Normal',
  2: 'Extraordinária'
}

export const INVOICE_STATUS = {
  1: 'Pendente',
  2: 'Pago',
  3: 'Pago Manual'
}

export const INVOICE_PAYMENT_TYPES = {
  1: 'Boleto',
  2: 'Cheque',
  3: 'Transf. Bancária',
  4: 'Cartão de Débito',
  5: 'Cartão de Credito',
  6: 'Débito automático',
  7: 'Dinheiro',
  8: 'DOC/TED',
  9: 'Condômino Escolhe'
}

export const ZOOP_TRANSACTION_STATUS = {
  'new': 'Aguardando registro',
  'pending': 'Aguardando pagamento',
  'succeeded': 'Pago',
  'failed': 'Falha',
  'canceled': 'Cancelado',
  'refunded': 'Estornado'
}

export const NOTIFICATION_TO = {
  1: 'Proprietário',
  2: 'Inquilino',
  3: 'Proprietário e Inquilino'
}

export const BANK_TRANSACTION_TYPES = {
  0: 'Débito',
  1: 'Crédito'
}

export const REINF_CONSTRUCTIONS = {
  0: 'Não está sujeita',
  1: 'Empreitada total',
  2: 'Empreitada parcial'
}

export const ISS_TAXABLES = {
  1: 'Exigível',
  2: 'Não incidente',
  3: 'Isenção',
  4: 'Exportação',
  5: 'Imunidade',
  6: 'Exig. Susp. Judicial',
  7: 'Exig. Susp. ADM'
}

export const NF_TYPES = {
  0: 'Entrada',
  1: 'Saída'
}

export const NF_COSTUMER = {
  0: 'Não',
  1: 'Sim'
}

export const NF_FINALITIES = {
  1: 'NFe normal',
  2: 'NFe complementar',
  3: 'NFe de ajuste',
  4: 'Devolução/Retorno'
}

export const MONTHLY_CLOSING_STATUS = {
  1: 'Aberto',
  2: 'Fechado'
}

export const MONTHLY_CLOSING_MONTHS = {
  1: 'Janeiro',
  2: 'Fevereiro',
  3: 'Março',
  4: 'Abril',
  5: 'Maio',
  6: 'Junho',
  7: 'Julho',
  8: 'Agosto',
  9: 'Setembro',
  10: 'Outubro',
  11: 'Novemro',
  12: 'Dezembro'
}

export const INVOICE_NOTIFICATION_TYPES = {
  1: 'Antes do vencimento',
  2: 'Depois do vencimento'
}

export const LEGAL_PERSON_ROLES = {
  1: 'Síndico',
  2: 'Subsíndico',
  3: 'Conselho',
  4: 'Conselho Consultivo',
  5: 'Conselho Fiscal'
}

export const COMMENT_WHO_NOTIFY = {
  1: 'Síndico',
  2: 'Subsíndico',
  3: 'Conselho',
  4: 'Conselho Consultivo',
  5: 'Conselho Fiscal'
}

export const WORKSHIFT_STATUS = {
  1: 'Aberto',
  0: 'Encerrado'
}

export const GUEST_STATUS = {
  1: 'Livre',
  0: 'Bloqueado'
}

export const CHART_ACCOUNT_TYPES = {
  1: 'Sintético',
  2: 'Analítico'
}

export const ACCESS_DOC_STATUS = {
  1: 'Ativo',
  0: 'Inativo'
}

export const ACCESS_DOC_TYPES = {
  1: 'Acesso espaço',
  2: 'Atestado médico'
}

export const MOVING_TAX_TYPES = {
  1: 'Percentual Salário Mínino',
  2: 'Valor Fixo',
  3: 'Valor não calculável'
}

export const MOVING_TYPE_STATUS = {
  1: 'Ativo',
  0: 'Inativo'
}

export const MOVING_TYPE_AVAILABILITY = {
  1: '1 reserva por Bloco',
  2: '1 reserva para todo o condomínio',
  3: 'Data livre'
}

export const GENDERS = {
  'm': 'Masculino',
  'f': 'Feminino',
  'o': 'Outro'
}
export const BR_STATES = {
  'AC': 'Acre',
  'AL': 'Alagoas',
  'AP': 'Amapá',
  'AM': 'Amazonas',
  'BA': 'Bahia',
  'CE': 'Ceará',
  'DF': 'Distrito Federal',
  'ES': 'Espírito Santo',
  'GO': 'Goiás',
  'MA': 'Maranhão',
  'MT': 'Mato Grosso',
  'MS': 'Mato Grosso do Sul',
  'MG': 'Minas Gerais',
  'PA': 'Pará',
  'PB': 'Paraíba',
  'PR': 'Paraná',
  'PE': 'Pernambuco',
  'PI': 'Piauí',
  'RJ': 'Rio de Janeiro',
  'RN': 'Rio Grande do Norte',
  'RS': 'Rio Grande do Sul',
  'RO': 'Rondônia',
  'RR': 'Roraima',
  'SC': 'Santa Catarina',
  'SP': 'São Paulo',
  'SE': 'Sergipe',
  'TO': 'Tocantins'
}

export const ANIMAL_KINDS: { [index: number]: string } = {
  1: 'Cachorro',
  2: 'Gato',
  3: 'Aquático',
  4: 'Aves',
  5: 'Roedor',
  6: 'Réptil'
}

export const ANIMAL_GENDERS: { [index: number]: string } = {
  1: 'Macho',
  2: 'Femea'
}

export const PERSON_DOC_TYPES: { [index: string]: string } = {
  [PersonDocTypeEnum.cpf]: 'CPF',
  [PersonDocTypeEnum.driver_license]: 'CNH',
  [PersonDocTypeEnum.passport]: 'Passaporte'
}

export const CLASSIFIED_TYPES: { [index: number]: string } = {
  1: 'Pedir Emprestado',
  2: 'Vaga de Garagem',
  3: 'Outros'
}
