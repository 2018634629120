import {useIsFocused} from '@react-navigation/native'
import React, {useEffect, useRef} from 'react'
import {ScrollView, StyleSheet, View} from 'react-native'
import Modal from 'react-native-modalbox'
import Style from '../../styles/Style'

type Props = {
  onDismiss?: () => any
  visible: boolean
  height_percent: number
  content: () => React.ReactElement
}

const AppModalSheet = React.memo((props: Props) => {
  /*
  * Usando props.visible && props.content() para ter o efeito de fade out
  * */

  const isFocused = useIsFocused()

  const modal_ref = useRef<Modal>(null)

  useEffect(() => {
    // Open modal
    if (props.visible && modal_ref.current) {
      modal_ref.current?.open()
    }

    // Close modal
    if (!props.visible && modal_ref.current) {
      modal_ref.current?.close()
    }
  }, [props.visible])

  useEffect(() => {
    if (!isFocused && props.onDismiss) {
      props.onDismiss()
    }
  }, [isFocused])

  const modal_height = Style.DEVICE_HEIGHT * (props.height_percent / 100)

  return (
    <Modal
      style={{ ...styles.modal, height: modal_height }} ref={modal_ref} swipeToClose={true} swipeArea={80} backdrop={true} coverScreen={true}
      position={'bottom'}
      onClosed={() => {
        if (props.onDismiss) {
          props.onDismiss()
        }
      }}
    >
      <View style={styles.modalRuleContainer}>
        <View style={styles.modalRule}>
        </View>
      </View>

      <ScrollView contentContainerStyle={styles.content_container}>
        {props.visible && props.content()}
      </ScrollView>
    </Modal>
  )
})

const styles = StyleSheet.create({
  modalRuleContainer: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalRule: {
    marginTop: 16,
    marginBottom: 16,
    borderRadius: 10,
    height: 8,
    backgroundColor: '#bfbfbf',
    width: Style.DEVICE_WIDTH / 5,
    maxWidth: 150
  },
  modal: {
    height: Style.DEVICE_HEIGHT / 1.5,
    backgroundColor: '#ffffff',
    padding: 10,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20
  },
  content_container: {
    paddingTop: 10,
    paddingRight: 20,
    paddingLeft: 20,
    paddingBottom: 50,
    backgroundColor: '#ffffff'
  }
})

export default AppModalSheet