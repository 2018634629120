import {useIsFocused} from '@react-navigation/native'
import * as React from 'react'
import {useEffect, useState} from 'react'
import {SafeAreaView} from 'react-native-safe-area-context'
import {useHasPerm} from '../../../components/acl'
import AppTabView, {AppTabViewProps} from '../../../components/layout/AppTabView'
import AnimalListTab from '../components/AnimalListTab'
import ResidentListTab from '../components/ResidentListTab'
import UnitListTab from '../components/UnitListTab'
import VehicleListTab from '../components/VehicleListTab'

const MyDataScreen = () => {
  const [scenes, setScenes] = useState<AppTabViewProps['scenes']>([])

  let can_view_vehicle = useHasPerm(['unit.view_vehicle'])
  let can_view_animal = useHasPerm(['unit.view_animal'])

  useEffect(() => {
    let _scenes: AppTabViewProps['scenes'] = [
      { key: 'account-group', title: '', scene: ResidentListTab, icon: 'account-group' },
      { key: 'home', title: '', scene: UnitListTab, icon: 'home' }
    ]

    if (can_view_vehicle) {
      _scenes.push({ key: 'car', title: '', scene: VehicleListTab, icon: 'car' })
    }

    if (can_view_animal) {
      _scenes.push({ key: 'dog', title: '', scene: AnimalListTab, icon: 'dog' })
    }

    setScenes(_scenes)

  }, [can_view_vehicle])

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      {scenes.length > 0 && <AppTabView scenes={scenes}/>}
    </SafeAreaView>
  )
}

export default function () {
  const isFocused = useIsFocused()

  if (!isFocused) return null

  return (
    <MyDataScreen/>
  )
}