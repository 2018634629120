import {NativeModules} from 'react-native'
import {EventLogger} from '../services/EventLogger'
import {sdkSentry} from '../services/sentry'

const { AwesomeZoomSDK } = NativeModules

// const zoomEmitter = new NativeEventEmitter(AwesomeZoomSDK)
//
// // Handle Auth events
// zoomEmitter.addListener('AuthEvent', (authEvent) => {
//   // Note that the listener will receive an object with an `event` property
//   console.log('AuthEvent: "' + authEvent.event + '"')
//   console.log('Event Full: "' + JSON.stringify(authEvent) + '"')
//
//   // Will print one of the following:
//   //   Event: "clientIncompatible"
//   //   Event: "success"
//   //   Event: "accountNotEnableSDK"
//   //   Event: "accountNotSupport"
//   //   Event: "keyOrSecretEmpty"
//   //   Event: "keyOrSecretWrong"
//   //   Event: "networkIssue"
//   //   Event: "none"
//   //   Event: "overTime"
//   //   Event: "serviceBusy"
//   //   Event: "unknown"
//   //   Event: "deviceNotSupported"
//   //   Event: "illegalAppKeyOrSecret"
//   //   Event: "invalidArguments"
//   //   Event: "networkUnavailable"
// })
//
// // Handle Meeting events
// zoomEmitter.addListener('MeetingEvent', (meetingEvent) => {
//   // Note that the listener will receive an object with an `event` property
//   console.log('MeetingEvent: "' + meetingEvent.event + '"')
//   console.log('Event Full: "' + JSON.stringify(meetingEvent) + '"')
//
//   // Will print one of the following ("ended*" events specifically identify the reason for a meeting
//   // ending):
//   //   Event: "invalidArguments"
//   //   Event: "meetingClientIncompatible"
//   //   Event: "meetingLocked"
//   //   Event: "meetingNotExist"
//   //   Event: "meetingOver"
//   //   Event: "meetingRestricted"
//   //   Event: "meetingRestrictedJBH"
//   //   Event: "meetingUserFull"
//   //   Event: "mmrError"
//   //   Event: "networkError"
//   //   Event: "noMMR"
//   //   Event: "registerWebinarDeniedEmail"
//   //   Event: "registerWebinarEnforceLogin"
//   //   Event: "registerWebinarFull"
//   //   Event: "registerWebinarHostRegister"
//   //   Event: "registerWebinarPanelistRegister"
//   //   Event: "removedByHost"
//   //   Event: "sessionError"
//   //   Event: "success"
//   //   Event: "audioAutoStartError"
//   //   Event: "cannotEmitWebRequest"
//   //   Event: "cannotStartTokenExpire"
//   //   Event: "inAnotherMeeting"
//   //   Event: "invalidUserType"
//   //   Event: "joinWebinarWithSameEmail"
//   //   Event: "meetingNotStart"
//   //   Event: "passwordError"
//   //   Event: "reconnectError"
//   //   Event: "vanityNotExist"
//   //   Event: "vbMaximumNum"
//   //   Event: "vbNoSupport"
//   //   Event: "vbRemoveNone"
//   //   Event: "vbSaveImage"
//   //   Event: "vbSetError"
//   //   Event: "videoError"
//   //   Event: "writeConfigFile"
//   //   Event: "zcCertificateChanged"
//   //   Event: "unknown"
//   //   Event: "exitWhenWaitingHostStart"
//   //   Event: "incorrectMeetingNumber"
//   //   Event: "invalidStatus"
//   //   Event: "networkUnavailable"
//   //   Event: "timeout"
//   //   Event: "webServiceFailed"
//   //   Event: "endedByHost"
//   //   Event: "endedByHostForAnotherMeeting"
//   //   Event: "endedBySelf"
//   //   Event: "endedConnectBroken"
//   //   Event: "endedFreeMeetingTimeout"
//   //   Event: "endedJBHTimeout"
//   //   Event: "endedRemovedByHost"
//   //   Event: "endedUnknownReason"
//   //   Event: "endedNoAttendee"
//   //   Event: "userJoin"
//   //   Event: "userLeave"
//   //   Event: "askUnMuteAudio"
//   //   Event: "askUnMuteVideo"
//   //   Event: "hostChanged"
//
//   //   ANDROID ONLY EVENTS
//   //   Event: "myAudioStatusChanged", {userRole: string, audioType: number, isMutedAudio: boolean, isTalking: boolean, isMutedVideo: boolean}
//   //   Event: "myVideoStatusChanged", {userRole: string, audioType: number, isMutedAudio: boolean, isTalking: boolean, isMutedVideo: boolean}
//   //   Event: "screenShareSuccess" called on custom ui when share screen is successful
//   //   Event: "screenShareError" called on custom ui when there was an error sharing screen
// })

const ZOOM_CONFIG = {
  ZOOM_PUBLIC_KEY: 'iSxduJidDYydQQwvgIs9G1o0lqHfomJlXVmX',
  ZOOM_PRIVATE_KEY: 'DK2RxnVg2nYuQAHhhbCwsBRAvnZD8SK0gt0V',
  ZOOM_DOMAIN: 'zoom.us',
  JWT_API_KEY: '',
  JWT_API_SECRET_KEY: ''
}

async function initZoom(publicKey: string, privateKey: string, domain: string) {
  try {
    console.log('calling zoom - init', AwesomeZoomSDK)

    const response = await AwesomeZoomSDK.initZoom(publicKey, privateKey, domain)
    console.log('Response - Init Zoom', response)
    return response

  } catch (e) {
    sdkSentry.sendSentry(e, null)
  }
}

async function joinMeeting(displayName: string, meetingNo: string, password: string) {
  try {
    console.log('calling zoom - join meeting', displayName, meetingNo, password)

    const response = await AwesomeZoomSDK.joinMeeting(displayName, meetingNo, password)
    EventLogger.debugLog(response)
    console.log('Response - Join Meeting', response)

  } catch (e) {
    sdkSentry.sendSentry(e, null)
  }
}

// async function startMeeting(meetingNumber: string, username: string, userId: string, jwtAccessToken: string, jwtApiKey: string) {
//   console.log('calling zoom', meetingNumber, username, userId, jwtAccessToken, jwtApiKey)
//   const response = await AwesomeZoomSDK.startMeeting(meetingNumber, username, userId, jwtAccessToken, jwtApiKey)
//
//   console.log('Response - Start Meeting', response)
// }

async function fullNativeZoomJoin(displayName: string, meetingNo: string, password: string) {
  let init = await initZoom(ZOOM_CONFIG.ZOOM_PUBLIC_KEY, ZOOM_CONFIG.ZOOM_PRIVATE_KEY, ZOOM_CONFIG.ZOOM_DOMAIN)

  if (init === 'ZOOM_INITIALIZED') {
    joinMeeting(displayName, meetingNo, password)
  }
}

export {fullNativeZoomJoin}