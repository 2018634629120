import {useIsFocused, useNavigation} from '@react-navigation/native'
import React from 'react'
import {ScrollView} from 'react-native'
import {Button as PaperButton, Headline} from 'react-native-paper'
import {SafeAreaView} from 'react-native-safe-area-context'
import {useMutation} from 'react-query'
import Spacer from '../../../components/Spacer'
import {RootDrawerScreenProps} from '../../../navigation/types'
import ToasterManager from '../../../services/ToasterManager'
import {qs} from '../../queries'

const PanicButtonScreen = () => {
  const navigation = useNavigation<RootDrawerScreenProps<'PanicButtonScreen'>['navigation']>()

  const type_one = useMutation((values: any) => qs.panics.post(values), {
    onSuccess: () => {
      ToasterManager.success('Solicitação enviada com sucesso! Você será notificado assim que recebermos sua solicitação.')
      navigation.navigate('HomeScreen')
    }
  })

  const type_two = useMutation((values: any) => qs.panics.post(values), {
    onSuccess: () => {
      ToasterManager.success('Solicitação enviada com sucesso! Você será notificado assim que recebermos sua solicitação.')
      navigation.navigate('HomeScreen')
    }
  })

  const type_three = useMutation((values: any) => qs.panics.post(values), {
    onSuccess: () => {
      ToasterManager.success('Solicitação enviada com sucesso! Você será notificado assim que recebermos sua solicitação.')
      navigation.navigate('HomeScreen')
    }
  })

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      <ScrollView style={{ flex: 1, backgroundColor: '#FFFFFF' }} contentContainerStyle={{ paddingHorizontal: 20 }}>

        <Headline style={{ marginTop: 60, marginBottom: 50, textAlign: 'center' }}>O que está acontecendo?</Headline>

        <PaperButton
          icon="alert-circle-outline" mode="outlined"
          color="#d9534f" style={{ borderColor: '#d9534f', borderWidth: 1, marginBottom: 30 }}
          loading={type_one.isLoading}
          onPress={() => type_one.mutate({ type: 1 })}
        >
          Aviso de Chegada
        </PaperButton>

        <PaperButton
          icon="shield" mode="outlined"
          color="#d9534f" style={{ borderColor: '#d9534f', borderWidth: 1, marginBottom: 30 }}
          loading={type_two.isLoading}
          onPress={() => type_two.mutate({ type: 2 })}
        >
          Emergência de Segurança
        </PaperButton>

        <PaperButton
          icon="medical-bag" mode="outlined"
          color="#d9534f" style={{ borderColor: '#d9534f', borderWidth: 1, marginBottom: 30 }}
          loading={type_three.isLoading}
          onPress={() => type_three.mutate({ type: 3 })}
        >
          Emergência Médica
        </PaperButton>

        <Spacer size={40}/>
      </ScrollView>
    </SafeAreaView>
  )
}

export default function (props: any) {
  const isFocused = useIsFocused()

  if (!isFocused) return null

  return (
    <PanicButtonScreen/>
  )
}
