import {useFocusEffect, useIsFocused, useNavigation, useRoute} from '@react-navigation/native'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {Linking, StyleSheet, TouchableOpacity, View} from 'react-native'
import {Caption, List, Title} from 'react-native-paper'
import {SafeAreaView} from 'react-native-safe-area-context'
import {useQuery} from 'react-query'
import AppFlatList from '../../../components/layout/AppFlatList'
import AppListCard from '../../../components/layout/AppListCard'
import AppModalSheet from '../../../components/layout/AppModalSheet'
import PlaceHolder from '../../../components/placeholder'
import Spacer from '../../../components/Spacer'
import {RootDrawerScreenProps} from '../../../navigation/types'
import {useAppTheme} from '../../../styles/theme'
import {qs} from '../../queries'
import {SpreadsheetRowModel} from '../../typings'

type Item = {
  headline: string
  date: string
}

type AgendaProps = {
  // items: any
  spreadsheet_id: number | string
}

const styles = StyleSheet.create({
  item: {
    backgroundColor: 'white',
    flex: 1,
    borderRadius: 5,
    padding: 10,
    marginRight: 10,
    marginTop: 0
  },
  emptyDate: {
    height: 15,
    flex: 1,
    paddingTop: 30
  }
})

// class AgendaScreen extends Component<AgendaProps> {
//   render() {
//     return (
//       <Agenda<Item>
//         items={this.props.items}
//         pastScrollRange={1}
//         selected={moment().format('YYYY-MM-DD')}
//         minDate={moment().format('YYYY-MM-DD')}
//         renderItem={this.renderItem.bind(this)}
//         renderEmptyDate={this.renderEmptyDate.bind(this)}
//         renderEmptyData={() => {return (<View><Spacer/><Caption style={{ textAlign: 'center' }}>Nada aqui...</Caption></View>)}}
//         rowHasChanged={this.rowHasChanged.bind(this)}
//         theme={{
//           'stylesheet.agenda.list': {
//             container: {
//               flexDirection: 'row',
//               marginTop: 10,
//               marginBottom: 10
//             },
//             day: {
//               width: 63,
//               alignItems: 'center',
//               justifyContent: 'flex-start',
//               marginTop: 0
//             }
//           }
//         }}
//       />
//     )
//   }
//
//   renderItem(item: Item) {
//     return (
//       <View style={[styles.item]}>
//         <Caption>{moment(item.date).format('HH:mm')}</Caption>
//         <Subheading>{item.headline}</Subheading>
//       </View>
//     )
//   }
//
//   renderEmptyDate() {
//     return (
//       <View style={styles.emptyDate}><Text> </Text></View>
//     )
//   }
//
//   rowHasChanged(r1: any, r2: any) {
//     return r1.name !== r2.name
//   }
// }

const ListScreen = (props: AgendaProps) => {
  const theme = useAppTheme()

  const { data, isFetching, refetch, remove } = useQuery(
    ['qs.spreadsheets_rows', props.spreadsheet_id],
    () => qs.spreadsheets_rows.bySpreadsheet(props.spreadsheet_id)
  )

  const [current_item, setCurrentItem] = useState<SpreadsheetRowModel | null>(null)

  const renderItem = ({ item }: { item: SpreadsheetRowModel }) => {
    return (
      <TouchableOpacity onPress={() => setCurrentItem(item)}>
        <AppListCard>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <View style={{ flex: 3 }}>
              <Title>{item.headline_value}</Title>
            </View>
            <View style={{ flex: 1, alignSelf: 'center' }}>
              <Caption style={{ textAlign: 'right', fontWeight: 'bold', color: theme.colors.accent }}>
                Detalhes
              </Caption>
            </View>
          </View>
        </AppListCard>
      </TouchableOpacity>
    )
  }

  const renderInner = () => {
    if (current_item) {
      return (
        <View>
          <Title>{current_item.headline_value}</Title>

          <Spacer size={15}/>

          {
            current_item.spreadsheet_datas.filter(i => !i.field_headline).sort((a, b) => a.field_id > b.field_id ? 1 : -1).map(d => (
              <View key={d.id} style={{ marginBottom: 12 }}>
                {['line_text', 'select', 'phone', 'email', 'url'].includes(d.field_type) &&
                  <View>
                    <Caption>{d.field_name}</Caption>
                    <Caption style={{ fontWeight: 'bold' }}>{d.line_text}</Caption>
                  </View>}

                {d.field_type === 'long_text' &&
                  <View>
                    <Caption>{d.field_name}</Caption>
                    <Caption style={{ fontWeight: 'bold' }}>{d.long_text}</Caption>
                  </View>}

                {d.field_type === 'checkbox' &&
                  <View>
                    <Caption>{d.field_name}</Caption>
                    <Caption style={{ fontWeight: 'bold' }}>{d.checkbox}</Caption>
                  </View>}

                {d.field_type === 'dt' &&
                  <View>
                    <Caption>{d.field_name}</Caption>
                    <Caption style={{ fontWeight: 'bold' }}>{moment(d.dt).format('DD/MM/YYYY')}</Caption>
                  </View>}

                {d.field_type === 'dt_time' &&
                  <View>
                    <Caption>{d.field_name}</Caption>
                    <Caption style={{ fontWeight: 'bold' }}>{moment(d.dt_time).format('DD/MM/YYYY HH:mm')}</Caption>
                  </View>}

                {d.field_type === 'time' &&
                  <View>
                    <Caption>{d.field_name}</Caption>
                    <Caption style={{ fontWeight: 'bold' }}>{moment(d.time).format('HH:mm')}</Caption>
                  </View>}

                {['dropdown', 'dropdown_multiple'].includes(d.field_type) &&
                  <View>
                    <Caption>{d.field_name}</Caption>
                    <Caption style={{ fontWeight: 'bold' }}>{d.dropdown}</Caption>
                  </View>}

                {d.field_type === 'files' &&
                  <View>
                    <Caption>{d.field_name}</Caption>

                    {d.files?.map((f: any) => (
                      <List.Item
                        style={{ padding: 0 }}
                        key={f.id}
                        title={f.name}
                        titleStyle={{ fontSize: 12 }}
                        right={props => <List.Icon color={theme.colors.primary} icon="cloud-download"/>}
                        onPress={() => Linking.openURL(f.file)}
                      />
                    ))}
                  </View>}
              </View>
            ))
          }
        </View>
      )
    }
    return <></>
  }

  return (
    <>
      <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
        <AppFlatList<SpreadsheetRowModel>
          data={data}
          renderItem={renderItem}
          keyExtractor={item => item.id + ''}
          refreshing={isFetching}
          onRefresh={refetch}
          ListEmptyComponent={() => (<PlaceHolder visible={true} emoji={''} description={'Nada por aqui.'}/>)}
        />

        <AppModalSheet
          visible={!!current_item?.id}
          height_percent={80}
          onDismiss={() => setCurrentItem(null)}
          content={renderInner}
        />
      </SafeAreaView>
    </>
  )
}

const SpreadsheetScreen = () => {
  const route = useRoute<RootDrawerScreenProps<'SpreadsheetScreen'>['route']>()
  const navigation = useNavigation<RootDrawerScreenProps<'SpreadsheetScreen'>['navigation']>()

  let item_id = route.params?.item_id

  const { data, isFetching, refetch, remove } = useQuery(
    [qs.spreadsheets.get_key, item_id],
    () => qs.spreadsheets.get(item_id),
    { enabled: false }
  )

  useFocusEffect(
    React.useCallback(() => {
      refetch()
      return () => { remove() }
    }, [item_id])
  )

  useEffect(() => {
    if (data?.name) {
      navigation.setOptions({ title: data.name })
    }
  }, [data?.name])

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      <ListScreen spreadsheet_id={item_id}/>
    </SafeAreaView>
  )
}

export default function (props: any) {
  const isFocused = useIsFocused()

  if (!isFocused) return null

  return (
    <SpreadsheetScreen/>
  )
}
