import {useFocusEffect, useNavigation} from '@react-navigation/native'
import React from 'react'
import {ScrollView, StyleSheet, View} from 'react-native'
import {Button as PaperButton, Text} from 'react-native-paper'
import {SafeAreaView} from 'react-native-safe-area-context'
import {NewFeature} from '../../../components'
import {AclRender} from '../../../components/acl/components'
import ActivityLoading from '../../../components/loading'
import NotPermitted from '../../../components/NotPermitted'
import Spacer from '../../../components/Spacer'
import useTenantConfigQuery from '../../../hooks/useTenantConfig'
import ZohoSalesIQ from '../../../nativeModules/ZohoSalesIQ'
import {RootDrawerScreenProps} from '../../../navigation/types'
import {EventLogger} from '../../../services/EventLogger'
import InMessagingManager from '../../../services/InMessagingManager'
import PushNotificationService from '../../../services/PushNotificationService'
import updateManager from '../../../services/updateManager'
import {useMeQuery} from '../../Auth/hooks/useMeQuery'
import {custom_qs} from '../../custom-queries'
import {HomeNotificationList} from '../components/HomeNotificationList'
import HomeShortcuts from '../components/HomeShortcuts'

PushNotificationService.setNotificationHandle()

const HomeScreen = () => {
  const navigation = useNavigation<RootDrawerScreenProps<'HomeScreen'>['navigation']>()
  const { data: tenant_configs } = useTenantConfigQuery()

  const { data: meData } = useMeQuery()

  const initSetup = async () => {
    updateManager.checkForUpdate().then()

    try {
      await EventLogger.logEvent('SeeHome', 'screen')
    } catch { }

    try {
      await PushNotificationService.registerForNativePushNotificationsAsync()
    } catch { }

    await custom_qs.checkAppUpdate()
    // this.props.getUnpaid()

    InMessagingManager.getMessages()
      .then(() => {
        InMessagingManager.show('SeeScreen', 'HomeScreen')
      })
  }

  const schema_name = meData?.team?.schema_name

  useFocusEffect(
    React.useCallback(() => {
      initSetup().then()
    }, [])
  )

  if (!meData) {
    return (
      <View>
        <NotPermitted msg={'...'}/>
        <ActivityLoading visible={true}/>
      </View>
    )
  }

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      <ScrollView style={{ flex: 1, backgroundColor: '#FFFFFF' }}>
        {meData?.name &&
          <Text style={styles.user}>
            Olá, {meData?.name}
            {'\n'}
            {meData?.team.name}
          </Text>
        }

        <View style={{ padding: 9 }}>
          {tenant_configs?.flags?.enable_chat_support &&
            <PaperButton
              icon="help-circle-outline"
              mode="contained"
              color="#5cb85c"
              onPress={() => ZohoSalesIQ.showChatWindow()}
            >
              Falar com o Suporte!
            </PaperButton>}

          <Spacer size={10}/>

          {meData?.team?.panic_button === true &&
            <PaperButton
              icon="alert-circle-outline"
              mode="contained"
              color="#d9534f"
              onPress={() => navigation.navigate('PanicButtonScreen')}
            >
              Botão de Apoio
            </PaperButton>}

          {/*<PaperButton mode='outlined' color={'white'}*/}
          {/*             onPress={() => this.props.navigation.navigate('InvoiceListScreen')}>*/}
          {/*  {this.props.unpaid?.length ?? '0'} boletos em aberto*/}
          {/*</PaperButton>*/}

          <Spacer size={30}/>

          <AclRender required={['meeting.view_meeting']}>
            <PaperButton
              icon="account-group-outline"
              mode="outlined"
              onPress={() => navigation.navigate('MeetingListScreen')}
            >
              Acessar Assembleia
            </PaperButton>
          </AclRender>

          {(
              schema_name === 'brisa' ||
              schema_name === 'dunas' ||
              schema_name === 'maresia' ||
              schema_name === 'salinas' ||
              schema_name === 'espaco_tropicale'
            ) &&
            <NewFeature
              title="⚠ Você pode acessar os dados do seu residencial ou do Espaço Tropicale!"
              body='Acesse o menu lateral e clique em "Trocar de condomínio". Exemplo, para reservas da churrasqueira acesse o Espaço Tropicale, para o salão de festas acesse o seu residencial. Caso queira fazer uma solicitação, ver documentos, comunicados... selecione o remetente; que pode ser o seu residencial ou o Espaço Tropicale. 😁👍'
            />}
        </View>

        <HomeShortcuts/>

        <HomeNotificationList/>

        <Spacer size={40}/>
      </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  user: {
    fontSize: 20,
    paddingTop: 30,
    paddingBottom: 25,
    paddingHorizontal: 15
  }
})

export default function () {
  return (
    <HomeScreen/>
  )
}

