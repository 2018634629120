import {Field, getIn} from 'formik'
import React, {useState} from 'react'
import {View} from 'react-native'
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view'
import {HelperText, Subheading, Switch, TextInput} from 'react-native-paper'
import Style from '../../styles/Style'
import {NextAutoFocusInput} from './withNextInputAutoFocus'

export class FormContainer extends React.Component {

  render() {
    return (
      <View style={styles.container}>
        <KeyboardAwareScrollView style={styles.container} contentContainerStyle={{ paddingHorizontal: 13, paddingTop: 25, paddingBottom: 70 }}>
          <View style={styles.container}>
            {this.props.children}
          </View>
        </KeyboardAwareScrollView>
      </View>
    )
  }
}

export const FF = (props) => {
  return (
    <Field {...props}/>
  )
}

export const FormikInput = ({ field, form: { touched, errors, values, handleChange, handleBlur }, ...props }) => {
  const TxtInput = props.with_next ? NextAutoFocusInput : TextInput
  let has_error = getIn(errors, field.name)

  // Temp fix avoid print undefined in field value, when field.value is undefined or the default value is not init
  let is_undefined = typeof (field.value) === 'undefined' || field.value === null

  return (
    <View style={Style.form.fieldContainer}>
      <TxtInput
        {...props}
        label={props.label}
        value={is_undefined ? '' : String(field.value)}
        error={!!has_error}
        onChangeText={handleChange(field.name)}
        onBlur={handleBlur(field.name)}
        mode={'outlined'}
      />

      {
        !!has_error &&
        <HelperText type="error" visible={!!has_error}>
          {has_error}
        </HelperText>
      }
    </View>
  )
}

export const FormikSecureInput = ({ field, form: { touched, errors, values, handleChange, handleBlur }, ...props }) => {
  const [secure, setSecure] = useState(true)

  return (
    <View style={Style.form.fieldContainer}>
      <TextInput
        {...props}
        label={props.label}
        value={String(values[field.name])}
        error={!!errors[field.name]}
        onChangeText={handleChange(field.name)}
        onBlur={handleBlur(field.name)}
        mode={'outlined'}
        secureTextEntry={secure}
        right={
          <TextInput.Icon
            name={secure ? 'eye' : 'eye-off'}
            onPress={() => setSecure(!secure)}
            color={props.icon_color}
          />
        }
      />

      {
        !!errors[field.name] &&
        <HelperText type="error" visible={!!errors[field.name]}>
          {errors[field.name]}
        </HelperText>
      }
    </View>
  )
}

export const FormikRadio = ({ field, form: { touched, errors, values, handleChange, handleBlur, setFieldValue }, ...props }) => {
  return (
    <View style={styles.radio_row}>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Subheading style={{ flex: 3 }}>{props.label}</Subheading>
        <View style={{ flex: 1 }}>
          <Switch value={values[field.name] === true} onValueChange={() => setFieldValue(field.name, !values[field.name])}/>
        </View>
      </View>

      {
        !!errors[field.name] &&
        <HelperText type="error" visible={!!errors[field.name]}>
          {errors[field.name]}
        </HelperText>
      }
    </View>
  )
}

const styles = {
  container: {
    flex: 1
  },
  radio_row: {
    // flexDirection: 'row',
    // alignItems: 'center',
    // justifyContent: 'space-between',
    paddingVertical: 20,
    paddingHorizontal: 2,
    marginBottom: 10
  }
}
