export const InvoiceTypesEnum = {
  normal: 1,
  extra: 2,
  condo_fee: 3,
  automatic_import: 4
} as const
export type InvoiceTypesEnum = typeof InvoiceTypesEnum[keyof typeof InvoiceTypesEnum]

export const InvoiceStatusEnum = {
  pendent: 1,
  paid: 2,
  manual_paid: 3,
  in_agreement: 4
} as const
export type InvoiceStatusEnum = typeof InvoiceStatusEnum[keyof typeof InvoiceStatusEnum]

export const InvoicePaymentTypesEnum = {
  bank_slip: 1,
  check: 2,
  bank_transfer: 3,
  debit_card: 4,
  credit_card: 5,
  automatic_debit: 6,
  money: 7,
  doc_ted: 8,
  client_choose: 9
} as const
export type InvoicePaymentTypesEnum = typeof InvoicePaymentTypesEnum[keyof typeof InvoicePaymentTypesEnum]

export const InvoiceItemTypesEnum = {
  normal: 1,
  payment_fine: 5,
  payment_interest: 6,
  payment_discount: 7,
  payment_bank_tax: 8
} as const
export type InvoiceItemTypesEnum = typeof InvoiceItemTypesEnum[keyof typeof InvoiceItemTypesEnum]

export const ProrateStatusEnum = {
  open: 1,
  closed: 2
} as const
export type ProrateStatusEnum = typeof ProrateStatusEnum[keyof typeof ProrateStatusEnum]

export const ChargeTypesEnum = {
  normal: 1,
  extra: 2
} as const
export type ChargeTypesEnum = typeof ChargeTypesEnum[keyof typeof ChargeTypesEnum]

export const ChargeValuesFromEnum = {
  expense_rate: 1,
  budget_forecast: 2,
  total_value_rate: 3,
  same_value: 4,
  fund: 5,
  book: 6,
  penalty: 7,
  consumption_type: 8,
  unit_area: 9,
  budget: 10,
  total_bucket: 11
} as const
export type ChargeValuesFromEnum = typeof ChargeValuesFromEnum[keyof typeof ChargeValuesFromEnum]

export const ChargeWhoChargesEnum = {
  all_units: 1,
  single_unit: 2,
  single_block: 3,
  single_unit_group: 4
} as const
export type ChargeWhoChargesEnum = typeof ChargeWhoChargesEnum[keyof typeof ChargeWhoChargesEnum]

export const AuditEventTypesEnum = {
  create: 1,
  update: 2,
  delete: 3
} as const
export type AuditEventTypesEnum = typeof AuditEventTypesEnum[keyof typeof AuditEventTypesEnum]

export const AccessAuthTypesEnum = {
  once: 1,
  period: 2,
  forever: 3
} as const
export type AccessAuthTypesEnum = typeof AccessAuthTypesEnum[keyof typeof AccessAuthTypesEnum]

export const ReservationStatusEnum = {
  CANCELED: 0,
  PENDENT: 1,
  CONFIRMED: 2,
  CHECKED_IN: 3,
  CHECKED_OUT: 4,
  NO_SHOW: 5
} as const
export type ReservationStatusEnum = typeof ReservationStatusEnum[keyof typeof ReservationStatusEnum]

export const ReservationGuestAgeGroupEnum = {
  ADULT: 1,
  CHILD: 2
} as const
export type ReservationGuestAgeGroupEnum = typeof ReservationGuestAgeGroupEnum[keyof typeof ReservationGuestAgeGroupEnum]

export const PersonDocTypeEnum = {
  cpf: 'cpf',
  dni: 'dni',
  driver_license: 'driver_license',
  na: 'na',
  nie: 'nie',
  passport: 'passport',
  social_security_card: 'social_security_card',
  student_id: 'student_id'
} as const
export type PersonDocTypeEnum = typeof PersonDocTypeEnum[keyof typeof PersonDocTypeEnum]
