import moment from 'moment'
import React from 'react'
import {Dimensions, StyleSheet, View} from 'react-native'
import {CalendarList} from 'react-native-calendars'
import Modal from 'react-native-modalbox'
import {HelperText} from 'react-native-paper'
import Style from '../../styles/Style'
import {gv} from '../../utils'
import {shallowEqual} from '../../utils/shallowEqual'
import TouchInput from './TouchInput'

const win = Dimensions.get('window')

export default class CalendarPickr extends React.Component {
  static defaultProps = {
    dateFormat: 'DD/MM/YYYY',
    minDate: moment().format('YYYY-MM-DD'),
    maxDate: moment().add(3, 'month').format('YYYY-MM-DD'),
    includeDates: []
  }

  constructor(props) {
    super(props)

    let { value_input, initial_date, include_dates } = this.generateOptions()

    this.state = {
      value_input: value_input,
      initial_date: initial_date,
      include_dates: include_dates
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let { value_input, initial_date, include_dates } = this.generateOptions()
    const prev_options = gv(prevState, 'include_dates')
    let result_state = !shallowEqual(include_dates, prev_options)

    if (result_state) {
      this.setState({ include_dates: include_dates })
    }
  }

  generateOptions = (props = null) => {
    if (props === null) {
      props = this.props
    }

    let initial_date = new Date()
    let value_input = ''
    let value = gv(this.props, 'field.value')

    if (value) {
      let new_date = moment(this.props.field.value)

      if (new_date.isValid()) {
        value_input = new_date.format(this.props.dateFormat)
        initial_date = new_date.toDate()
      }
    }

    let include_dates = {}
    props.includeDates.map((item) => {
      include_dates = { ...include_dates, [item]: { disabled: false } }
    })

    if (value) {
      include_dates = { ...include_dates, [this.props.field.value]: { disabled: false, selected: true } }
    }

    return { value_input, initial_date, include_dates }
  }

  showDateTimePicker = () => {
    if (this.props.showValidator !== undefined) {
      if (this.props.showValidator()) {
        this.refs.modalDate.open()
      }
    } else {
      this.refs.modalDate.open()
    }
  }

  hideDateTimePicker = () => this.refs.modalDate.close()

  handleDatePicked = (date) => {
    if (this.props.includeDates.indexOf(date.dateString) < 0) {
      return
    }

    date = moment(date.dateString)
    let { value_input, initial_date, include_dates } = this.generateOptions()
    let marked = { include_dates, [date.format('YYYY-MM-DD')]: { disabled: false, selected: true } }

    this.setState({
      value_input: date.format(this.props.dateFormat),
      initial_date: date.toDate(),
      include_dates: marked
    })

    this.props.form.setFieldValue(this.props.field.name, date.format('YYYY-MM-DD'))
    if (this.props.onChange) this.props.onChange(this.props.field.name, date.format('YYYY-MM-DD'))
    this.hideDateTimePicker()
  }

  render() {
    let future_scroll_max_months = 3

    if (this.props.maxDate) {
      let _diff_a = moment()
      let _diff_b = moment(this.props.maxDate)
      let _diff = _diff_b.diff(_diff_a, 'months', true)

      future_scroll_max_months = Math.ceil(_diff) + 1
    }

    return (
      <View style={Style.form.fieldContainer}>
        <TouchInput
          label={this.props.label}
          mode="outlined"
          value={this.state.value_input}
          showPicker={this.showDateTimePicker}
          error={!!this.props.form.errors[this.props.field.name]}
        />

        {
          !!this.props.form.errors[this.props.field.name] &&
          <HelperText type="error" visible={!!this.props.form.errors[this.props.field.name]}>
            {this.props.form.errors[this.props.field.name]}
          </HelperText>
        }

        <Modal style={[styles.modalDate]} ref={'modalDate'} swipeToClose={true} swipeArea={80} backdrop={true} coverScreen={true}
               position={'bottom'}>

          <View style={styles.modalRuleContainer}>
            <View style={styles.modalRule}>
            </View>
          </View>

          <CalendarList markedDates={this.state.include_dates} current={this.state.initial_date}
                        minDate={this.props.minDate}
                        maxDate={this.props.maxDate}
                        onDayPress={(day) => this.handleDatePicked(day)}
                        pastScrollRange={1}
                        futureScrollRange={future_scroll_max_months}
                        scrollEnabled={true}
                        disabledByDefault={true}
                        showScrollIndicator={true}
          />
        </Modal>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  modalRuleContainer: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalRule: {
    marginTop: 20,
    marginBottom: 18,
    borderRadius: 10,
    height: 5,
    backgroundColor: '#D6DBE1',
    width: win.width / 5
  },
  modalDate: {
    height: win.height / 1.5,
    backgroundColor: '#ffffff'
  }
})
