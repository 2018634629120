import {useFocusEffect, useNavigation} from '@react-navigation/native'
import React from 'react'
import {FlatList, StyleSheet, TouchableOpacity, View} from 'react-native'
import {Caption, Title} from 'react-native-paper'
import {useQuery} from 'react-query'
import {useHasPerm} from '../../../components/acl'
import {AclRender} from '../../../components/acl/components'
import AppFab from '../../../components/layout/AppFab'
import PlaceHolder from '../../../components/placeholder'
import {ANIMAL_KINDS} from '../../../constants/fields'
import {RootDrawerScreenProps} from '../../../navigation/types'
import {qs} from '../../queries'

function AnimalListTab() {
  const navigation = useNavigation<RootDrawerScreenProps<'MyDataScreen'>['navigation']>()

  const { data, isFetching, refetch, remove } = useQuery(
    qs.animals.all_key,
    () => qs.animals.all(),
    { enabled: false }
  )

  useFocusEffect(
    React.useCallback(() => {
      refetch()
      return () => { remove() }
    }, [])
  )

  let can_add = useHasPerm(['unit.add_animal'])
  let can_edit = useHasPerm(['unit.change_animal'])

  const renderItem = ({ item }: any) => (
    <React.Fragment>
      <TouchableOpacity
        onPress={() => can_edit && navigation.navigate('AnimalDetailScreen', { data: item, item_id: item.id })}
      >
        <View style={styles.cardContainer}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <View style={{ flex: 1 }}>
              <Title>{item.name} - {item.breed}</Title>
              <Caption>{ANIMAL_KINDS[item.kind]}</Caption>
            </View>
          </View>
        </View>
      </TouchableOpacity>
    </React.Fragment>
  )

  return (
    <View style={{ flex: 1 }}>
      <FlatList
        style={styles.list}
        contentContainerStyle={styles.listContent}
        data={data}
        renderItem={renderItem}
        keyExtractor={item => item.id + ''}
        refreshing={isFetching}
        onRefresh={refetch}
        ListEmptyComponent={() => (
          <PlaceHolder
            visible={true}
            emoji={'🐶'}
            description={can_add ? 'Clique no botão "+" para adicionar os seus Pets.' : 'Nenhum Pet cadastrado'}
          />
        )}
      />

      <AclRender required={['unit.add_animal']}>
        <AppFab icon="plus" onPress={() => navigation.navigate('AnimalNewScreen')}/>
      </AclRender>
    </View>
  )
}

const styles = StyleSheet.create({
  list: {
    flex: 1
  },
  listContent: {
    paddingHorizontal: 8,
    paddingVertical: 14
  },
  cardContainer: {
    borderWidth: 1,
    borderColor: '#e8e8e8',
    borderRadius: 6,
    padding: 12,
    marginBottom: 14
  }
})

export default AnimalListTab
