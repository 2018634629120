import React from 'react'
import {FlatList, Modal, StyleSheet, View} from 'react-native'
import {Button, Dialog, Divider, HelperText, List} from 'react-native-paper'
import Style from '../../styles/Style'
import {gv} from '../../utils'
import TouchInput from './TouchInput'

export default class DialogSelect extends React.Component {
  static defaultProps = {
    label_option: 'name',
    id_option: 'id',
    local_options: null
  }

  constructor(props) {
    super(props)

    let { value_input, options } = this.generateOptions()

    this.state = {
      modalVisible: false,
      value_input: value_input,
      options: options
    }
  }

  componentDidMount() {
    let { value_input, options } = this.generateOptions()
    this.setState({ value_input, options })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let { value_input, options } = this.generateOptions()

    let check_input = JSON.stringify(prevState.value_input) === JSON.stringify(value_input)
    let check_options = JSON.stringify(prevState.options) === JSON.stringify(options)

    if (check_input === false || check_options === false) {
      this.setState({ value_input, options })
    }
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   console.log('DialogSelect =>>>>>>>> shouldComponentUpdate')
  //   console.log(nextProps)
  //   let check_props = JSON.stringify(this.generateOptions(nextProps)) === JSON.stringify(this.generateOptions())
  //   let check_state = JSON.stringify(nextState) === JSON.stringify(this.state)
  //
  //   if (check_props === false) return true
  //   if (check_state === false) return true
  //   return false
  // }

  generateOptions = (props = null) => {
    if (props === null) {
      props = this.props
    }

    // Get options
    let options = []
    const empty = { [props.label_option]: '-- Vazio --', [props.id_option]: '' }
    options.push(empty)

    if (gv(props, 'local_options') !== null) {
      let formated = Object.keys(props.local_options)

      for (let item of formated) {
        let _k = isNaN(parseInt(item)) ? item : parseInt(item)
        const i = { [props.id_option]: _k, [props.label_option]: props.local_options[item] }
        options.push(i)
      }
    } else {
      options = props.data
    }

    // Get initial value
    let value_input = ''
    let value = gv(props, 'field.value')

    if (value !== null) {
      const searchTerm = String(value).toUpperCase()
      const testString = obj => String(obj).toUpperCase() === searchTerm

      for (let item of options) {
        if (testString(item[props.id_option])) {
          value_input = String(item[props.label_option])
        }
      }
    }

    return { value_input, options }
  }

  showModal = () => {
    if (this.props.showValidator !== undefined) {
      if (this.props.showValidator()) {
        this.setState({ modalVisible: true })
      }
    } else {
      this.setState({ modalVisible: true })
    }
  }

  hideModal = () => this.setState({ modalVisible: false })

  handleSelect = (item) => {
    this.setState({ value_input: '' + item[this.props.label_option] })
    this.props.form.setFieldValue(this.props.field.name, item[this.props.id_option])
    if (this.props.onChange) this.props.onChange(this.props.field.name, item[this.props.id_option])
    this.hideModal()
  }

  listRow = ({ item }) => (
    <List.Item
      title={item[this.props.label_option]}
      onPress={() => this.handleSelect(item)}
    />
  )

  render() {
    let { modalVisible } = this.state

    return (
      <React.Fragment>
        <Modal visible={modalVisible} onDismiss={this.hideModal} onRequestClose={this.hideModal} animationType={'fade'} transparent={true}>

          <View
            importantForAccessibility="yes"
            accessibilityViewIsModal={true}
            style={[styles.transparent, styles.absolute]}
            pointerEvents={'box-none'}
          >
            <View style={{ flex: 1 }} pointerEvents={'box-none'}>
              <Dialog onDismiss={this.hideModal} visible={modalVisible} style={{ alignSelf: 'center', zIndex: 100000 }}>
                <Dialog.Title>Selecione uma opção {' '}{' '}{' '}{' '}{' '}{' '}{' '}{' '}</Dialog.Title>
                <Dialog.ScrollArea style={{ maxHeight: 270, paddingHorizontal: 0 }}>

                  <FlatList
                    ItemSeparatorComponent={Divider}
                    renderItem={this.listRow}
                    keyExtractor={(item) => '' + item[this.props.id_option]}
                    data={this.state.options}
                  />

                </Dialog.ScrollArea>
                <Dialog.Actions>
                  <Button onPress={this.hideModal}>Cancelar</Button>
                  <Button onPress={this.hideModal}>Ok</Button>
                </Dialog.Actions>
              </Dialog>
            </View>
          </View>

        </Modal>
        <View style={Style.form.fieldContainer}>
          <TouchInput
            label={this.props.label}
            mode="outlined"
            value={this.state.value_input}
            showPicker={this.showModal}
            error={!!this.props.form.errors[this.props.field.name]}
          />

          {
            !!this.props.form.errors[this.props.field.name] &&
            <HelperText type="error" visible={!!this.props.form.errors[this.props.field.name]}>
              {this.props.form.errors[this.props.field.name]}
            </HelperText>
          }
        </View>
      </React.Fragment>
    )
  }
}

const styles = StyleSheet.create({
  transparent: {
    zIndex: 2,
    backgroundColor: 'rgba(0,0,0,0)'
  },
  absolute: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  }
})
