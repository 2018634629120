import {useFocusEffect, useIsFocused, useRoute} from '@react-navigation/native'
import React from 'react'
import {Linking, View} from 'react-native'
import {Headline, List, Text} from 'react-native-paper'
import {SafeAreaView} from 'react-native-safe-area-context'
import {useQuery} from 'react-query'
import {MkRender} from '../../../components'
import {AclRender} from '../../../components/acl/components'
import AppScrollView from '../../../components/layout/AppScrollView'
import ActivityLoading from '../../../components/loading'
import NotPermitted from '../../../components/NotPermitted'
import Spacer from '../../../components/Spacer'
import {RootDrawerScreenProps} from '../../../navigation/types'
import {EventLogger} from '../../../services/EventLogger'
import {qs} from '../../queries'

const NoticeDetailScreen = () => {
  const route = useRoute<RootDrawerScreenProps<'NoticeDetailScreen'>['route']>()

  let item_id = route.params?.item_id

  const { data: notice, isFetching, refetch, remove } = useQuery(
    [qs.notices.get_key, item_id],
    () => qs.notices.get(item_id),
    { enabled: false }
  )

  useFocusEffect(
    React.useCallback(() => {
      refetch()

      EventLogger.logEvent('SeeNoticeDetail', 'screen', item_id, 'notice')
      return () => {
        remove()
      }
    }, [item_id])
  )

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      {
        !isFetching && notice?.id ?
          <AppScrollView>
            <Headline style={{ fontWeight: 'bold' }}>
              {notice.name}
            </Headline>

            <Spacer size={15}/>

            <MkRender>{notice.body}</MkRender>

            <Spacer size={15}/>

            {notice?.files?.length > 0 &&
              <View style={{ paddingTop: 15 }}>
                <Text style={{ fontWeight: 'bold' }}>Anexos</Text>
                {notice?.files?.map((item: any, index: any) => (
                  <List.Item
                    style={{ padding: 0 }}
                    key={index}
                    title={item.name}
                    titleStyle={{ fontSize: 15 }}
                    left={props => <List.Icon color="#19D150" icon="cloud-download"/>}
                    onPress={() => Linking.openURL(item.file)}
                  />
                ))}
              </View>}

            <Spacer size={15}/>

          </AppScrollView>
          :
          <ActivityLoading visible={isFetching}/>
      }
    </SafeAreaView>
  )
}

export default function (props: any) {
  const isFocused = useIsFocused()

  if (!isFocused) return null

  return (
    <AclRender required={['notice.view_notice']} fallback={<NotPermitted/>}>
      <NoticeDetailScreen/>
    </AclRender>
  )
}
