import {useFocusEffect} from '@react-navigation/native'
import moment from 'moment'
import React from 'react'
import {View} from 'react-native'
import {Button, Caption, Colors, Title} from 'react-native-paper'
import {SafeAreaView} from 'react-native-safe-area-context'
import {useMutation, useQuery} from 'react-query'
import {AclRender} from '../../../components/acl/components'
import AppFlatList from '../../../components/layout/AppFlatList'
import AppListCard from '../../../components/layout/AppListCard'
import {Pill} from '../../../components/layout/Pill'
import NotPermitted from '../../../components/NotPermitted'
import PlaceHolder from '../../../components/placeholder'
import Spacer from '../../../components/Spacer'
import {EventLogger} from '../../../services/EventLogger'
import ToasterManager from '../../../services/ToasterManager'
import {useMeQuery} from '../../Auth/hooks/useMeQuery'
import {qs} from '../../queries'

function DrawListScreen() {
  const { data: meData } = useMeQuery()

  const { data, isFetching, refetch, remove } = useQuery(qs.draws.all_key, () => qs.draws.all(), { enabled: false })

  const create = useMutation((variables: any) => qs.draws.patch(variables), {
    onSuccess: (_, variables) => {
      refetch()

      if (variables.people_action === 'add') {
        EventLogger.logEvent('NewDrawSubscription', 'action', create.variables.id, 'draw')
        ToasterManager.success('Cadastrado com Sucesso! Aguarde encerrar o sorteio para saber o resultado.')
      } else {
        EventLogger.logEvent('RemoveDrawSubscription', 'action', create.variables.id, 'draw')
        ToasterManager.success('Removido com Sucesso!')
      }
    }
  })

  useFocusEffect(
    React.useCallback(() => {
      refetch()

      EventLogger.logEvent('SeeDrawList', 'screen')

      return () => {
        remove()
      }
    }, [])
  )

  const handleAssignDraw = (item: any) => {
    let values = {
      id: item.id,
      people: [meData.person.id],
      people_action: 'add'
    }

    create.mutate(values)
  }

  const handleRemoveDraw = (item: any) => {
    let values = {
      id: item.id,
      people: [meData.person.id],
      people_action: 'remove'
    }

    create.mutate(values)
  }

  const isAssigned = (item: any) => {
    const person = meData?.person?.id
    const result = item?.people.map((i: any) => i.id).indexOf(person)

    return result > -1
  }

  const handleStatus = (item: any) => {
    const person = meData?.person?.id
    const result = item?.people.map((i: any) => i.id).indexOf(person)

    return result > -1 ? 'Participando' : null
  }

  const renderItem = ({ item }: { item: any }) => {
    let is_assigned = isAssigned(item)
    const item_status = handleStatus(item)

    return (
      <AppListCard>
        {item_status && <Pill color={Colors.green600}>{item_status}</Pill>}

        <Title>{item.name}</Title>

        {!!item.description && <Caption>{item.description}</Caption>}

        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 8 }}>
          <Caption style={{ fontWeight: 'bold' }}>
            Início:{' '}
            {moment(item.start_date).format('DD/MM - HH:mm')}
          </Caption>

          <Caption style={{ fontWeight: 'bold' }}>
            Término:{' '}
            {moment(item.end_date).format('DD/MM - HH:mm')}
          </Caption>
        </View>

        <Spacer size={15}/>

        {!is_assigned &&
          <Button
            mode="outlined" onPress={() => handleAssignDraw(item)}
            loading={create.isLoading && create.variables.id === item.id}
            disabled={create.isLoading && create.variables.id === item.id}
          >
            Participar
          </Button>}

        {is_assigned &&
          <Button mode="outlined" onPress={() => handleRemoveDraw(item)}
                  loading={create.isLoading && create.variables.id === item.id}
                  disabled={create.isLoading && create.variables.id === item.id}
          >
            Sair do sorteio
          </Button>}
      </AppListCard>
    )
  }

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      <AppFlatList
        data={data}
        renderItem={renderItem}
        keyExtractor={item => item.id + ''}
        refreshing={isFetching}
        onRefresh={refetch}
        ListEmptyComponent={() => (
          <PlaceHolder
            visible={true}
            emoji={'🤞'}
            description={'Nenhum sorteio disponível.'}
          />
        )}
      />
    </SafeAreaView>
  )
}

export default function (props: any) {
  return (
    <AclRender required={['draw.view_draw']} fallback={<NotPermitted/>}>
      <DrawListScreen/>
    </AclRender>
  )
}
