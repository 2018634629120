import {useNavigation} from '@react-navigation/native'
import {Formik} from 'formik'
import React from 'react'
import {Button} from 'react-native-paper'
import {useMutation} from 'react-query'
import * as Yup from 'yup'
import {AclRender} from '../../../components/acl/components'
import DialogSelect from '../../../components/form/DialogSelect'
import {FF, FormContainer, FormikInput} from '../../../components/form/FormInputs'
import ParkingSelect from '../../../components/form/ParkingSelect'
import UnitSelect from '../../../components/form/UnitSelect'
import Spacer from '../../../components/Spacer'
import {VEHICLE_TYPES} from '../../../constants/fields'
import {RootDrawerScreenProps} from '../../../navigation/types'
import {EventLogger} from '../../../services/EventLogger'
import ToasterManager from '../../../services/ToasterManager'
import {qs} from '../../queries'

type Props = {
  // submitAction: (values: any) => Promise<any>,
  initialValues: any,
}

function VehicleForm({ initialValues }: Props) {
  const navigation = useNavigation<RootDrawerScreenProps<'VehicleDetailScreen'>['navigation']>()

  const create = useMutation((variables: any) => qs.vehicles.post(variables), {
    onSuccess: (res) => {
      ToasterManager.success('Veiculo cadatrado com sucesso!')
      EventLogger.logEvent('NewVehicle', 'action', res.id, 'vehicle')
      navigation.navigate('MyDataScreen')
    }
  })

  const update = useMutation((variables: any) => qs.vehicles.patch(variables), {
    onSuccess: () => {
      ToasterManager.success('Veiculo atualizado com sucesso!')
      navigation.navigate('MyDataScreen')
    }
  })

  return (
    <Formik
      initialValues={{
        id: initialValues?.id ?? '',
        unit: initialValues?.unit_id ?? '',
        parking: initialValues?.parking_id ?? '',
        type: initialValues?.type ?? '',
        brand: initialValues?.brand ?? '',
        model: initialValues?.model ?? '',
        color: initialValues?.color ?? '',
        plate: initialValues?.plate ?? ''
      }}
      validationSchema={Yup.object().shape({
        unit: Yup.string().required(),
        parking: Yup.string().required(),
        type: Yup.string().required(),
        brand: Yup.string().required(),
        model: Yup.string().required(),
        color: Yup.string().required(),
        plate: Yup.string().required()
      })}
      onSubmit={(values) => {
        if (initialValues?.id) {
          update.mutate(values)
        } else {
          create.mutate(values)
        }
      }}
      render={({ values, errors, submitForm, setFieldValue }) => {
        return (
          <FormContainer>
            <UnitSelect/>
            <ParkingSelect/>
            <FF label={'Tipo'} name="type" component={DialogSelect} local_options={VEHICLE_TYPES}/>
            <FF label={'Marca'} name="brand" component={FormikInput}/>
            <FF label={'Modelo'} name="model" component={FormikInput}/>
            <FF label={'Cor'} name="color" component={FormikInput}/>
            <FF label={'Placa'} name="plate" component={FormikInput}/>

            <Spacer/>

            <AclRender required={['unit.add_vehicle', 'unit.change_vehicle']}>
              <Button
                icon="content-save" mode="contained"
                loading={create.isLoading || update.isLoading}
                disabled={create.isLoading || update.isLoading}
                onPress={() => submitForm()}
              >
                Salvar
              </Button>
            </AclRender>
          </FormContainer>
        )
      }}
    />
  )
}

export default VehicleForm
