import * as Application from 'expo-application'

export const getAppId = () => {
  let _app_id = Application.applicationId

  if (typeof window !== 'undefined' && window && window?.location) {
    _app_id = window.location.hostname
  }

  return _app_id || ''
}

const app_id = getAppId()
console.log('app_id', app_id)

const tenantService = {
  getStyles: async () => {
    let primary_color = '#043F78'
    let accent_color = '#046ab7'

    if (app_id?.indexOf('targethotels') > -1) {
      return {
        primary_color: '#222222',
        accent_color: '#b92f2f',

        login_primary: '#222222',
        login_accent: null,
        login_text: '#222222',
        login_placeholder: null,
        login_background: '#FFFFFF'
      }
    }

    if (app_id?.indexOf('cooperluxo') > -1) {
      return {
        primary_color: '#232850',
        accent_color: '#db3045',

        login_primary: '#db3045',
        login_accent: '#b92f2f',
        login_text: '#ffffff',
        login_placeholder: '#ffffff',
        login_background: '#232850'
      }
    }

    return {
      primary_color: primary_color,
      accent_color: accent_color,

      login_primary: '#043F78',
      login_accent: '#0173D5',
      login_text: '#0173D5',
      login_placeholder: '#046ab7',
      login_background: '#002442'
    }
  },
  getLogos: async () => {
    if (app_id?.indexOf('targethotels') > -1) {
      return {
        login: 'https://condoid.s3.amazonaws.com/media/test/attachments/0c9894e11d5549a2b281b889dc871a68.png',
        sidebar: 'https://condoid.s3.amazonaws.com/media/test/attachments/df568aa0c38b47af961cfc35438e5955.png'
      }
    }

    if (app_id?.indexOf('cooperluxo') > -1) {
      return {
        login: 'https://condoid.s3.amazonaws.com/media/test/attachments/ac9c223975c0401ea206cd8d015ee82c.png',
        sidebar: 'https://condoid.s3.amazonaws.com/media/test/attachments/ac9c223975c0401ea206cd8d015ee82c.png'
      }
    }

    return {
      login: null,
      sidebar: null
    }
  },
  getFlags: async () => {

    let base_flags = {
      enable_chat_support: true
    }

    if (app_id?.indexOf('targethotels') > -1) {
      return {
        ...base_flags,
        enable_chat_support: false

      }
    }

    if (app_id?.indexOf('cooperluxo') > -1) {
      return {
        ...base_flags,
        enable_chat_support: false

      }
    }
    return base_flags
  },
  configs: async () => {
    return {
      styles: await tenantService.getStyles(),
      logos: await tenantService.getLogos(),
      flags: await tenantService.getFlags()
    }
  }
}

export default tenantService