import {REQUEST_ERROR, SENDING_REQUEST} from '../../constants'

const initialState = {
  error: false,
  currentlySending: false
}

export default function dataReducer(state = initialState, action) {
  switch (action.type) {
    case SENDING_REQUEST:
      return { ...state, currentlySending: action.sending }

    case REQUEST_ERROR:
      return { ...state, error: action.error }

    default:
      return state
  }
}
