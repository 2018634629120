import AsyncStorage from '@react-native-async-storage/async-storage'
import {useIsFocused, useNavigation} from '@react-navigation/native'
import {Formik} from 'formik'
import Animation from 'lottie-react-native'
import React, {useEffect, useRef} from 'react'
import {Animated, Image, StyleSheet, Text, TouchableOpacity, View} from 'react-native'
import {Button} from 'react-native-paper'
import {SafeAreaView} from 'react-native-safe-area-context'
import {useMutation} from 'react-query'
import {useRecoilState} from 'recoil'
import * as Yup from 'yup'
import {FF, FormContainer, FormikInput, FormikSecureInput} from '../../../components/form/FormInputs'
import Spacer from '../../../components/Spacer'
import useTenantConfigQuery from '../../../hooks/useTenantConfig'
import ZohoSalesIQ from '../../../nativeModules/ZohoSalesIQ'
import {RootDrawerScreenProps} from '../../../navigation/types'
import {authService, queryClient, updateManager} from '../../../services'
import ToasterManager from '../../../services/ToasterManager'
import {userLoggedInAtom} from '../../../state/atoms'
import Style from '../../../styles/Style'
import {useAppTheme} from '../../../styles/theme'
import {getAppVersion, platformChecker} from '../../../utils'
import {postLogin} from '../queries'

const AnimatedLogo = () => {
  const progress = useRef(new Animated.Value(0)).current

  useEffect(() => {
    Animated.timing(progress, {
      useNativeDriver: false,
      toValue: 1,
      duration: 6000
    }).start()
  }, [])

  return (
    <View style={{
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 0
    }}>
      <View>
        <Animation
          style={{
            width: Style.LOGO_ANIMATION_WIDTH,
            height: Style.LOGO_ANIMATION_HEIGHT
          }}
          source={require('../../../assets/logoAnimation.json')}
          progress={progress}
          enableMergePathsAndroidForKitKatAndAbove
        />
      </View>
    </View>
  )
}

function LoginScreen() {
  const theme = useAppTheme()
  const { data: tenant_configs } = useTenantConfigQuery()

  const navigation = useNavigation<RootDrawerScreenProps<'Login'>['navigation']>()

  const [hasLoggedIn, setUserLoggedIn] = useRecoilState(userLoggedInAtom)

  const mutation = useMutation((variables: any) => postLogin(variables), {
    onSuccess: async (res) => {
      await authService.setToken(res.auth_token)
      await authService.setToken(res.auth_token)

      navigation.navigate('MyTeams')
    },
    onError: () => {
      ToasterManager.error('Verifique os seus dados de acesso!')
    }
  })

  useEffect(() => {
    const _init = async () => {
      try {
        await updateManager.checkForUpdate().then()

        await ZohoSalesIQ.resetData()
        await ZohoSalesIQ.setLauncherVisibility()

        await AsyncStorage.clear()

        setUserLoggedIn(false)

        await queryClient.cancelQueries()
        queryClient.removeQueries()
        queryClient.clear()
      } catch (e) {
        console.error(e)
      }
    }

    _init().then()
  }, [])

  const screen_theme = {
    ...theme,
    roundness: 10,
    colors: {
      ...theme.colors,
      primary: tenant_configs?.styles?.login_primary,
      accent: tenant_configs?.styles?.login_accent || theme.colors.accent,
      text: tenant_configs?.styles?.login_text || theme.colors.accent,
      placeholder: tenant_configs?.styles?.login_placeholder || theme.colors.placeholder,
      background: tenant_configs?.styles.login_background || theme.colors.background
    }
  }

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: tenant_configs?.styles.login_background || '#FFFFFF'
    },
    button: {
      marginTop: 5,
      marginBottom: 10
    },
    button_inner: {
      marginTop: 5,
      marginBottom: 5
    },
    input: {
      paddingTop: 1,
      paddingBottom: 1
    },
    lost_pass: {
      color: screen_theme.colors.text,
      fontSize: 15,
      marginTop: 15,
      marginBottom: 15,
      textAlign: 'center'
    },
    first_access: {
      color: screen_theme.colors.text,
      fontSize: 15,
      marginTop: 0,
      marginBottom: 20,
      textAlign: 'center'
    },
    version: {
      color: screen_theme.colors.text,
      fontSize: 12,
      paddingTop: 10,
      paddingBottom: 2,
      textAlign: 'center',
      opacity: 0.8
    }
  })

  return (
    <SafeAreaView style={styles.container}>

      {tenant_configs?.logos &&
        <View style={styles.container}>
          <FormContainer>

            {tenant_configs?.logos?.login
              ?

              <View style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 30,
                marginBottom: 60
              }}>
                <Image
                  style={{
                    height: 130,
                    maxWidth: Style.DEVICE_WIDTH * 0.6,
                    width: '100%'
                  }}
                  source={{ uri: tenant_configs.logos.login }} resizeMode={'contain'}
                />
              </View>
              :
              <AnimatedLogo/>
            }

            <View style={[
              platformChecker.isWeb && {
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 60
              }]}>
              <View style={[platformChecker.isWeb && { maxWidth: 600 }]}>
                <Formik
                  initialValues={{
                    username: '',
                    password: ''
                  }}
                  validationSchema={Yup.object().shape({
                    username: Yup.string().required(),
                    password: Yup.string().required()
                  })}
                  onSubmit={(values) => {
                    let _values = {
                      username: values.username.replace(/\s/g, ''),
                      password: values.password.replace(/\s/g, '')
                    }

                    mutation.mutate(_values)
                  }}
                >
                  {({ submitForm }) => {
                    return (
                      <React.Fragment>
                        <FF
                          placeholder={'Digite o seu E-mail'}
                          name="username"
                          autoCompleteType="username"
                          keyboardType="email-address"
                          textContentType="username"
                          component={FormikInput}
                          theme={screen_theme}
                          style={styles.input}
                        />

                        <Spacer size={10}/>

                        <FF
                          placeholder={'Digite a sua Senha'}
                          name="password"
                          autoCompleteType="password"
                          textContentType="password"
                          component={FormikSecureInput}
                          theme={screen_theme}
                          style={styles.input}
                          icon_color={screen_theme.colors.primary}
                        />

                        <Spacer size={30}/>

                        <Button
                          mode="contained"
                          style={styles.button}
                          contentStyle={styles.button_inner}
                          onPress={submitForm}
                          theme={screen_theme}
                          loading={mutation.isLoading}
                        >
                          Entrar
                        </Button>
                      </React.Fragment>
                    )
                  }}
                </Formik>

                <TouchableOpacity onPress={() => navigation.navigate('ResetPasswordScreen')}>
                  <Text style={styles.lost_pass}>Esqueceu sua senha?</Text>
                </TouchableOpacity>
                {platformChecker.isNative &&
                  <TouchableOpacity onPress={() => navigation.navigate('SignRequestScreen')}>
                    <Text style={styles.first_access}>Ainda não tem cadastro?</Text>
                  </TouchableOpacity>}

                <Text style={styles.version}>v. {getAppVersion()}</Text>
              </View>
            </View>
          </FormContainer>
        </View>
      }

    </SafeAreaView>
  )
}

export default function (props: any) {
  const isFocused = useIsFocused()

  // if (!isFocused) return null

  return (
    <LoginScreen/>
  )
}