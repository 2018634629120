import {useFocusEffect, useNavigation, useRoute} from '@react-navigation/native'
import moment from 'moment'
import React from 'react'
import {TouchableOpacity, View} from 'react-native'
import {Caption, Title} from 'react-native-paper'
import {SafeAreaView} from 'react-native-safe-area-context'
import {useInfiniteQuery} from 'react-query'
import AppFlatList from '../../../components/layout/AppFlatList'
import AppListCard from '../../../components/layout/AppListCard'
import PlaceHolder from '../../../components/placeholder'
import {PENALTY_STATUS} from '../../../constants/fields'
import {RootDrawerScreenProps} from '../../../navigation/types'
import {useAppTheme} from '../../../styles/theme'
import {handleGetNextPageParam} from '../../../utils'
import {qs} from '../../queries'

function PenaltyListScreen(props: any) {
  const theme = useAppTheme()
  const navigation = useNavigation<RootDrawerScreenProps<'PenaltyListScreen'>['navigation']>()
  const route = useRoute<RootDrawerScreenProps<'PenaltyListScreen'>['route']>()

  const {
    data,
    hasNextPage,
    isFetching,
    fetchNextPage,
    refetch,
    remove
  } = useInfiniteQuery(qs.penalties.all_key, async (_params) =>
      qs.penalties.all_paginated(_params.pageParam ?? 1, 15),
    {
      enabled: false,
      getNextPageParam: handleGetNextPageParam
    })

  useFocusEffect(
    React.useCallback(() => {
      refetch()

      return () => {
        remove()
      }
    }, [])
  )

  const renderItem = ({ item }: any) => (
    <React.Fragment>
      <TouchableOpacity onPress={() => navigation.navigate('PenaltyDetailScreen', { item_id: item.id })}>
        <AppListCard>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <View style={{ flex: 3 }}>
              <Caption>#{item.id} - {item.penalty_category_name}</Caption>
              <Title>{item.penalty_type_name}</Title>
              <Caption>{moment(item.date).format('DD/MM/YYYY - HH:mm')}</Caption>
            </View>
            <View style={{ flex: 1, alignSelf: 'center' }}>
              <Caption style={{ textAlign: 'right', fontWeight: 'bold', color: theme.colors.accent }}>
                {PENALTY_STATUS[item.status]}
              </Caption>
            </View>
          </View>
        </AppListCard>
      </TouchableOpacity>
    </React.Fragment>
  )

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      <AppFlatList
        data={data?.pages.map(page => page.data).flat() ?? []}
        onEndReached={() => hasNextPage && fetchNextPage()}
        renderItem={renderItem}
        keyExtractor={item => item.id + ''}
        refreshing={isFetching}
        onRefresh={refetch}
        ListEmptyComponent={() => (
          <PlaceHolder
            visible={true}
            emoji={'😇'}
            description={'Se você for multado ou advertido (esperamos que não), é aqui que você poderá consultar.'}
          />
        )}
      />
    </SafeAreaView>
  )
}

export default PenaltyListScreen
