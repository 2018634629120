import {combineReducers} from 'redux'
import comments_reducer from '../modules/comments'
import files_reducer from '../modules/files'
import dataReducer from './dataReducer'

const rootReducer = () => combineReducers({
  app: dataReducer,

  files: files_reducer,
  comments: comments_reducer,
  //END_REDUCERS
})

export default rootReducer
