import {DefaultTheme, useTheme} from 'react-native-paper'
import {Theme} from 'react-native-paper/src/types'
import tenantService from '../services/tenantService'

let basic_color = {
  text: '#22272b',
  error: '#F71E42',
  background: '#FFFFFF'
}

// @ts-ignore
let __theme_app: Theme = {
  ...DefaultTheme,
  roundness: 8,
  colors: {
    ...DefaultTheme.colors,
    ...basic_color
  }
}

export function setAppTheme(them: any) {
  __theme_app = them
}

export const theme_app = () => {
  return __theme_app
}

export const cacheAppTheme = async () => {
  let tenant_colors = await tenantService.getStyles()

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      // AsyncStorage.setItem('@APP_THEME@', JSON.stringify({
      //   primary: '#c57e00',
      //   accent: '#d2ba27'
      // }))

      let primary_color = tenant_colors.primary_color
      let accent_color = tenant_colors.primary_color

      resolve({
        ...DefaultTheme,
        roundness: 8,
        colors: {
          ...DefaultTheme.colors,
          ...basic_color,

          primary: primary_color,
          accent: accent_color
        }
      })
    }, 1)
  })
}

export const useAppTheme = useTheme
