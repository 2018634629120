import {FontAwesome5, Ionicons} from '@expo/vector-icons'
import {DrawerContentScrollView} from '@react-navigation/drawer'
import {useNavigation} from '@react-navigation/native'
import color from 'color'
import * as Notifications from 'expo-notifications'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {Image, StyleSheet, TouchableOpacity, View} from 'react-native'
import {Divider, Drawer, Text} from 'react-native-paper'
// @ts-ignore
import UserAvatar from 'react-native-user-avatar'
import {useQuery} from 'react-query'
import {useMeQuery} from '../features/Auth/hooks/useMeQuery'
import {qs} from '../features/queries'
import {SpreadsheetModel} from '../features/typings'
import useTenantConfigQuery from '../hooks/useTenantConfig'
import ZohoSalesIQ from '../nativeModules/ZohoSalesIQ'
import {RootDrawerScreenProps} from '../navigation/types'
import PushNotificationService from '../services/PushNotificationService'
import {useAppTheme} from '../styles/theme'
import {getAppVersion, platformChecker} from '../utils'
import {hasPerm} from './acl'
import {AclRender} from './acl/components'
import Spacer from './Spacer'

const DrawerItem = (drawer_props: { title: string, route?: string, icon?: any, fa?: boolean, onPress?: () => void }) => {
  const theme = useAppTheme()
  const navigation = useNavigation<RootDrawerScreenProps<'HomeScreen'>['navigation']>()

  const curr_route = navigation.getCurrentRoute()
  let is_in_page = curr_route?.name === drawer_props.route

  const bg_color = color(theme.colors.primary).darken(0.3).rgb().string()

  const is_bg_txt_dark =
    color(theme.colors.primary).darken(0.3).isDark() &&
    color(theme.colors.primary).lighten(1.2).desaturate(0.8).isDark()

  const txt_color = is_bg_txt_dark ?
    color(theme.colors.primary).white(300).rgb().string() :
    color(theme.colors.primary).lighten(1.2).desaturate(0.8).rgb().string()

  return (
    <Drawer.Item
      key={drawer_props.route + ''}
      style={{ backgroundColor: is_in_page ? txt_color : '#FFFFFF' }}
      icon={() => (
        drawer_props.icon && drawer_props.fa ?
          <FontAwesome5 name={drawer_props.icon} size={14} style={{ color: bg_color }}/> :
          <Ionicons name={drawer_props.icon} size={18} style={{ color: bg_color }}/>
      )}
      label={drawer_props.title}
      onPress={() => drawer_props.onPress ? drawer_props.onPress() : navigation.navigate(drawer_props.route as any)}
      active={is_in_page}
    />

  )
}

const SidebarHeader = ({ me }: any) => {
  const navigation = useNavigation<RootDrawerScreenProps<'HomeScreen'>['navigation']>()
  const { data: tenant_configs } = useTenantConfigQuery()
  const { t } = useTranslation()

  const theme = useAppTheme()
  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.colors.primary,
      borderColor: color(theme.colors.primary).darken(0.2).rgb().string()
    },
    logo_container: {
      paddingTop: 20,
      paddingBottom: 15,
      paddingHorizontal: 25,
      backgroundColor: color(theme.colors.primary).darken(0.1).rgb().string()
    },
    headerContainer: {
      paddingTop: 20,
      paddingBottom: 15,
      paddingHorizontal: 25,
      borderBottomWidth: 3
    },
    headerUser: {
      color: '#FFFFFF',
      paddingTop: 15
    }
  })

  let name = ''
  if (me?.name) {
    name = me.name.split(' ')
    name = `${name[0] ? name[0] : ''} ${name[1] ? name[1] : ''} ${name[2] ? name[2] : ''}`
  }

  return (
    <View style={styles.container}>
      {tenant_configs?.logos?.sidebar &&
        <View style={styles.logo_container}>
          <Image style={{ height: 80 }} source={{ uri: tenant_configs.logos.sidebar }} resizeMode={'contain'}/>
        </View>}

      <TouchableOpacity onPress={() => navigation.navigate('EditProfile')}>
        <View style={styles.headerContainer}>
          {me?.name &&
            <React.Fragment>
              <UserAvatar style={{ width: 80 }} size={80} name={name} src={me?.person?.photo?.file}/>
              <Text style={styles.headerUser}>
                {me.name}
                {'\n'}
                {'\n'}
                {t('condominio')}:
                {'\n'}
                {me?.team?.name}
              </Text>
            </React.Fragment>}
        </View>
      </TouchableOpacity>
    </View>
  )
}

const SidebarSpreadsheet = React.memo(() => {
  const navigation = useNavigation<RootDrawerScreenProps<'HomeScreen'>['navigation']>()

  const { data: spreadsheets } = useQuery(qs.spreadsheets.all_key, () => qs.spreadsheets.all(), {
    cacheTime: 1000 * 60 * 2,
    staleTime: 1000 * 60 * 2,
    refetchOnWindowFocus: false
  })

  const handleSpreadNavigate = (item: SpreadsheetModel) => {
    navigation.navigate('SpreadsheetScreen', { item_id: item.id })
  }

  return (
    <>
      {spreadsheets?.map((item: any) => {
        return (
          <DrawerItem
            key={item.id + ''}
            title={item.name}
            icon={item.icon_name}
            fa
            onPress={() => handleSpreadNavigate(item)}
          />
        )
      })}
    </>
  )
})

const SideBar = ({ navigation, ...props }: any) => {
  const theme = useAppTheme()
  const { t } = useTranslation()

  const is_bg_txt_dark =
    color(theme.colors.primary).darken(0.3).isDark() &&
    color(theme.colors.primary).lighten(1.2).desaturate(0.8).isDark()

  const txt_color = is_bg_txt_dark ?
    color(theme.colors.primary).white(300).rgb().string() :
    color(theme.colors.primary).lighten(1.2).desaturate(0.8).rgb().string()

  const styles = StyleSheet.create({
    footerContainer: {
      paddingTop: 20,
      paddingBottom: 15,
      paddingHorizontal: 25,
      borderBottomWidth: 3,
      borderColor: color(theme.colors.primary).darken(0.2).rgb().string(),
      backgroundColor: color(theme.colors.primary).darken(0.4).rgb().string()
    },
    version: {
      color: txt_color,
      fontSize: 10,
      paddingTop: 10,
      paddingBottom: 2
    }
  })

  const { data: me, isLoading } = useMeQuery()

  const lastNotificationResponse = Notifications.useLastNotificationResponse()

  useEffect(() => {
    PushNotificationService.handleNotificationListener(lastNotificationResponse)

  }, [lastNotificationResponse])

  useEffect(() => {
    const _init = async () => {
      try {
        Notifications.addNotificationResponseReceivedListener(PushNotificationService.handleNotificationListener)

        try {
          await PushNotificationService.registerForNativePushNotificationsAsync()
        } catch (e) {
          console.error(e)
        }

        try {
          await Notifications.setBadgeCountAsync(0)
        } catch (e) {
          console.error(e)
        }

        await ZohoSalesIQ.setLauncherVisibility()
      } catch (e) {
        console.error(e)
      }
    }

    _init().then()
  }, [])

  const routes = [
    { route: 'HomeScreen', title: 'Início', icon: 'ios-home' },
    hasPerm(['revenue.view_invoice']) ? { route: 'InvoiceListScreen', title: 'Boletos', icon: 'ios-cash' } : false,
    hasPerm(['unit.view_person', 'unit.view_vehicle', 'unit.view_animal']) ? {
      route: 'MyDataScreen',
      title: 'Cadastros',
      icon: 'ios-people-circle-outline'
    } : false,
    hasPerm(['classified.view_classified']) ? { route: 'ClassifiedListScreen', title: 'Classificados', icon: 'ios-list' } : false,
    hasPerm(['notice.view_notice']) ? { route: 'NoticeListScreen', title: 'Comunicados', icon: 'ios-megaphone' } : false,
    hasPerm(['finance_common.view_consumption']) ? { route: 'ConsumptionListScreen', title: 'Consumos', icon: 'ios-pie-chart' } : false,
    hasPerm(['mailing.view_mailing']) ? { route: 'MailingListScreen', title: 'Correspondências', icon: 'ios-mail' } : false,
    hasPerm(['book.view_book']) ? { route: 'BookListScreen', title: 'Reservas de Espaços', icon: 'ios-calendar' } : false,
    hasPerm(['pm_reservation.view_reservation']) ? { route: 'ReservationListScreen', title: 'Hospedagens', icon: 'bed', fa: true } : false,

    hasPerm(['notice.view_survey']) ? { route: 'SurveyListScreen', title: 'Enquetes', icon: 'ios-chatbubbles' } : false,
    hasPerm(['draw.view_draw']) ? { route: 'DrawListScreen', title: 'Sorteios', icon: 'ios-trophy' } : false,
    hasPerm(['meeting.view_meeting']) ? { route: 'MeetingListScreen', title: 'Assembleias', icon: 'ios-people' } : false,

    hasPerm(['access_control.view_accessauthorization']) ? {
      route: 'AccessAuthorizationListScreen',
      title: 'Autorizações',
      icon: 'ios-key'
    } : false,
    hasPerm(['penalty.view_penalty']) ? { route: 'PenaltyListScreen', title: 'Infrações', icon: 'ios-warning' } : false,
    hasPerm(['ticket.view_ticket']) ? { route: 'TicketListScreen', title: 'Ocorrências', icon: 'ios-flag' } : false,
    hasPerm(['construction.view_construction']) ? { route: 'ConstructionListScreen', title: 'Obras', icon: 'ios-construct' } : false,
    hasPerm(['moving.view_moving']) ? { route: 'MovingListScreen', title: 'Mudanças', icon: 'truck', fa: true } : false,
    hasPerm(['unit.view_lost']) ? { route: 'LostListScreen', title: 'Achados e Perdidos', icon: 'logo-dropbox' } : false,
    hasPerm(['document.view_document']) ? { route: 'DocumentListScreen', title: 'Documentos', icon: 'ios-document' } : false,
    hasPerm(['notice.view_information']) ? {
      route: 'InformationListScreen',
      title: 'Informações Importantes',
      icon: 'ios-information-circle'
    } : false
  ]

  if (isLoading) return null

  return (
    <DrawerContentScrollView {...props} style={[
      { marginTop: -5, backgroundColor: theme.colors.primary }, platformChecker.isWeb && { maxHeight: '100vh' }
    ]}
    >

      <SidebarHeader me={me}/>

      <View style={{ backgroundColor: '#FFFFFF' }}>
        <Spacer size={20}/>

        <AclRender required={['unit.change_person']}>
          <DrawerItem
            title="Editar Perfil"
            route="EditProfile"
            icon="smile"
            fa={true}
          />
        </AclRender>

        <DrawerItem
          title={`Trocar de ${t('condominio')}`}
          route="MyTeams"
          icon="building"
          fa={true}
        />

        <Spacer size={20}/>

        {routes.filter(i => i !== false).map((item: any) => {
          return (
            <DrawerItem
              key={item.route + ''}
              title={item.title}
              route={item.route}
              icon={item.icon}
              fa={item.fa}
            />
          )
        })}

        <SidebarSpreadsheet/>

        <Spacer size={15}/>
        <Divider/>
        <Spacer size={15}/>

        <DrawerItem
          title="Alterar Senha"
          icon="ios-lock-closed"
          route="ChangePasswordScreen"
        />

        <DrawerItem
          title="Sair"
          icon="ios-log-out"
          route="Login"
        />

        <Spacer size={40}/>

        <View style={styles.footerContainer}>
          <Image style={{ height: 50, width: 200 }} source={require('../assets/logo-header.png')}/>
          <Text style={styles.version}>v. {getAppVersion()}</Text>
        </View>
      </View>

    </DrawerContentScrollView>
  )
}

// export default React.memo(SideBar)
export default SideBar
