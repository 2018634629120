import {useFocusEffect, useIsFocused, useRoute} from '@react-navigation/native'
import moment from 'moment'
import * as React from 'react'
import {Linking, Text, View} from 'react-native'
import {Caption, List} from 'react-native-paper'
import {SafeAreaView} from 'react-native-safe-area-context'
import {useQuery} from 'react-query'
import {AclRender} from '../../../components/acl/components'
import CommentsBox from '../../../components/CommentsBox'
import {AppGridCol, AppGridRow} from '../../../components/layout/AppGridView'
import AppScrollView from '../../../components/layout/AppScrollView'
import ActivityLoading from '../../../components/loading'
import Spacer from '../../../components/Spacer'
import {TICKET_STATUS, TICKET_TYPES} from '../../../constants/fields'
import {RootDrawerScreenProps} from '../../../navigation/types'
import {EventLogger} from '../../../services/EventLogger'
import {qs} from '../../queries'

const TicketDetailScreen = () => {
  const route = useRoute<RootDrawerScreenProps<'PenaltyDetailScreen'>['route']>()

  let item_id = route.params?.item_id

  const { data, isFetching, refetch, remove } = useQuery(
    [qs.tickets.get_key, item_id],
    () => qs.tickets.get(item_id),
    { enabled: false }
  )

  useFocusEffect(
    React.useCallback(() => {
      refetch()
      EventLogger.logEvent('SeeTicketDetail', 'screen', item_id, 'ticket')
      return () => {
        remove()
      }
    }, [item_id])
  )

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      {
        !isFetching && data?.id ?
          <AppScrollView>
            <View style={{ backgroundColor: '#eeeeee', padding: 15, borderRadius: 7 }}>
              <AppGridRow style={{ marginBottom: 15 }}>
                <AppGridCol>
                  <Caption>Tipo</Caption>
                  <Text>{TICKET_TYPES[data.type]}</Text>
                </AppGridCol>
              </AppGridRow>
              <AppGridRow>
                <AppGridCol>
                  <Caption>Status</Caption>
                  <Text>{TICKET_STATUS[data.status]}</Text>
                </AppGridCol>
                <AppGridCol>
                  <Caption>Data</Caption>
                  <Text>{moment(data.date).format('DD/MM/YYYY - HH:mm')}</Text>
                </AppGridCol>
              </AppGridRow>
            </View>

            <Spacer size={30}/>

            <View>
              <Caption style={{ marginBottom: 10 }}>Descrição</Caption>
              <Text>{data.description}</Text>

              <Spacer size={40}/>

              <Text style={{ fontWeight: 'bold' }}>Anexos</Text>

              {data?.files.map((item: any, index: number) => (
                <List.Item
                  style={{ padding: 0 }}
                  key={index}
                  title={item.name}
                  titleStyle={{ fontSize: 15 }}
                  left={props => <List.Icon color="#19D150" icon="cloud-download"/>}
                  onPress={() => Linking.openURL(item.file)}
                />
              ))}

              <Spacer size={40}/>

              <AclRender required={['ticket.change_ticket']}>
                {data && data.id && <CommentsBox commentable_id={data.id} commentable_type="ticket"/>}
              </AclRender>

              <Spacer size={30}/>
            </View>
          </AppScrollView>
          :
          <ActivityLoading visible={isFetching}/>
      }
    </SafeAreaView>
  )
}

export default function (props: any) {
  const isFocused = useIsFocused()

  if (!isFocused) return null

  return (
    <TicketDetailScreen/>
  )
}
