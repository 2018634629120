import * as React from 'react'
import {ActivityIndicator, StyleSheet, Text, View} from 'react-native'

type Props = {
  visible: boolean
}

const ActivityLoading = ({ visible }: Props) => {
  if (visible) {
    return (
      <View style={styles.background}>
        <ActivityIndicator
          color="white"
          size="large"
          style={{ flex: 1 }}
        />
        <View style={styles.textContainer}>
          <Text style={styles.textContent}>Processando...</Text>
        </View>
      </View>
    )
  }

  return null
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'transparent',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  background: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.6)'
  },
  textContainer: {
    flex: 1,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute'
  },
  textContent: {
    top: 80,
    height: 50,
    fontSize: 20,
    fontWeight: 'bold',
    color: '#FFFFFF'
  }

  // container: {
  //     position: 'absolute',
  //     left: 0,
  //     right: 0,
  //     top: 0,
  //     bottom: 0,
  //     alignItems: 'center',
  //     justifyContent: 'center',
  // },
  // containerOverlay: {
  //     position: 'absolute',
  //     left: 0,
  //     right: 0,
  //     top: 0,
  //     bottom: 0,
  //     alignItems: 'center',
  //     justifyContent: 'center',
  //     backgroundColor: 'rgba(52, 52, 52, 0.8)'
  // },
  // activityIndicator: {
  //     flex: 1,
  //     justifyContent: 'center',
  //     alignItems: 'center',
  //     height: 80
  // }

})

export default ActivityLoading