import {FontAwesome} from '@expo/vector-icons'
import {useFocusEffect, useNavigation} from '@react-navigation/native'
import moment from 'moment'
import React from 'react'
import {Alert, Share, View} from 'react-native'
import {Avatar, Button, Caption, Colors, Title} from 'react-native-paper'
import {SafeAreaView} from 'react-native-safe-area-context'
import {useInfiniteQuery, useMutation} from 'react-query'
import {AclRender} from '../../../components/acl/components'
import AppFab from '../../../components/layout/AppFab'
import AppFlatList from '../../../components/layout/AppFlatList'
import AppListCard from '../../../components/layout/AppListCard'
import AppTabView from '../../../components/layout/AppTabView'
import {Pill} from '../../../components/layout/Pill'
import NotPermitted from '../../../components/NotPermitted'
import PlaceHolder from '../../../components/placeholder'
import {ACCESSAUTH_STATUS, ACCESSLOG_TYPES} from '../../../constants/fields'
import {RootDrawerScreenProps} from '../../../navigation/types'
import ToasterManager from '../../../services/ToasterManager'
import {useAppTheme} from '../../../styles/theme'
import {handleGetNextPageParam} from '../../../utils'
import {useMeQuery} from '../../Auth/hooks/useMeQuery'
import {qs} from '../../queries'
import {AccessAuthorizationModel} from '../../typings'

function AccessAuthListTab() {
  const theme = useAppTheme()
  const navigation = useNavigation<RootDrawerScreenProps<'AccessAuthorizationListScreen'>['navigation']>()

  const {
    data,
    hasNextPage,
    isFetching,
    fetchNextPage,
    refetch,
    remove
  } = useInfiniteQuery(qs.access_authorizations.all_key, async (_params) =>
      qs.access_authorizations.all_paginated(_params.pageParam ?? 1, 15),
    {
      enabled: false,
      getNextPageParam: handleGetNextPageParam
    })

  useFocusEffect(
    React.useCallback(() => {
      refetch()
      return () => { remove() }
    }, [])
  )

  const { data: meData } = useMeQuery()
  const allow_pin = meData?.team?.access_authorization_pin

  const revokeMutation = useMutation((variables: number) => qs.access_authorizations.patch({ id: variables, status: 0 }), {
    onSuccess: () => {
      refetch()
      ToasterManager.success('Autorização revogada com sucesso')
    }
  })

  const renderItem = ({ item }: { item: AccessAuthorizationModel }) => (
    <AppListCard>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <View style={{ flex: 1 }}>
          <Caption>
            <FontAwesome name="calendar-o" size={12} color={theme.colors.primary}/>

            {item.date && moment(item.date).format(' DD/MM/YYYY')}

            {item.start_date && ` ${moment(item.start_date).format('DD/MM')} à ${moment(item.end_date).format('DD/MM')}`}

            {item.type === 3 && ' Sempre liberado'}

            {` - ${item.access_reason_name}`}
          </Caption>

          <Title>{item.person?.name ?? item.name_temp}</Title>

          <Pill color={Colors.blueGrey600}>
            {ACCESSAUTH_STATUS[item.status]}
          </Pill>

          <Caption>Motivo: {item.description}</Caption>

          {(item.vehicle_plate || item.vehicle_model || item.vehicle_color) &&
            <Caption>Veículo: {item.vehicle_plate} {item.vehicle_model} {item.vehicle_color}</Caption>}

          <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 15 }}>
            {item.status !== 0 &&
              <Button
                color={Colors.red500} mode="text" icon="delete-outline" compact
                onPress={() => Alert.alert('Tem certeza que gostaria de revogar?', '', [
                    { text: 'Não!' },
                    { text: 'Sim!', onPress: () => revokeMutation.mutate(item.id) }
                  ]
                )}
                loading={revokeMutation.variables === item.id && revokeMutation.isLoading}
                disabled={revokeMutation.variables === item.id && revokeMutation.isLoading}
              >
                Revogar
              </Button>}

            {allow_pin &&
              <Button
                mode="text" icon="share" compact
                onPress={() => {
                  Share.share({
                    title: 'Seu pin de acesso:' + item.pin,
                    message: 'Seu pin de acesso: ' + item.pin + ', minha unidade: ' + item?.unit?.with_block +
                      '. Ao chegar na portaria informe esse pin.'
                  })
                }}
              >
                Enviar pin
              </Button>}
          </View>
        </View>
      </View>
    </AppListCard>
  )

  return (
    <View style={{ flex: 1 }}>
      <AclRender required={['access_control.view_accessauthorization']} fallback={<NotPermitted/>}>
        <AppFlatList
          data={data?.pages.map(page => page.data).flat() ?? []}
          onEndReached={() => hasNextPage && fetchNextPage()}
          renderItem={renderItem}
          keyExtractor={item => item.id + ''}
          refreshing={isFetching}
          onRefresh={refetch}
          ListEmptyComponent={() => (
            <PlaceHolder
              visible={true}
              emoji={'🔑'}
              description={'Aqui você pode pré-autorizar visitantes, fornecedores, funcionários... E assim que chegar será enviado pra você uma notificação!'}
            />
          )}
        />
      </AclRender>

      <AclRender required={['access_control.add_accessauthorization']}>
        <AppFab icon="plus" onPress={() => navigation.navigate('AccessAuthorizationNewScreen')}/>
      </AclRender>
    </View>
  )
}

function AccessLogListTab() {
  const theme = useAppTheme()

  const {
    data,
    hasNextPage,
    isFetching,
    fetchNextPage,
    refetch,
    remove
  } = useInfiniteQuery(qs.access_logs.all_key, async (_params) =>
      qs.access_logs.all_paginated(_params.pageParam ?? 1, 15),
    {
      enabled: false,
      getNextPageParam: handleGetNextPageParam
    })

  useFocusEffect(
    React.useCallback(() => {
      refetch()

      return () => {
        remove()
      }
    }, [])
  )

  const renderItem = ({ item }: { item: AccessAuthorizationModel }) => {
    const avatar_url = item?.person?.photo?.file ? { uri: item?.person?.photo?.file } : require('../../../assets/anonymous-avatar-icon.png')

    return (
      <AppListCard>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <Avatar.Image
            source={avatar_url}
            size={80} style={{ marginRight: 10 }}
          />
          <View style={{ flex: 1 }}>
            <Title>{item.person?.name ?? item.name_temp}</Title>

            <Caption>
              <FontAwesome name="calendar-o" size={12} color={theme.colors.primary}/>
              {moment(item.date).format(' DD/MM/YY HH:mm')}
              {` - `}
              {ACCESSLOG_TYPES[item.type]}
            </Caption>

            <Pill color={Colors.blueGrey600}>
              {item.access_reason_name}
            </Pill>

            {!!item.description && <Caption>Descrição: {item.description}</Caption>}
          </View>
        </View>
      </AppListCard>
    )
  }

  return (
    <View style={{ flex: 1 }}>
      <AppFlatList
        data={data?.pages.map(page => page.data).flat() ?? []}
        onEndReached={() => hasNextPage && fetchNextPage()}
        renderItem={renderItem}
        keyExtractor={item => item.id + ''}
        refreshing={isFetching}
        onRefresh={refetch}
        ListEmptyComponent={() => (
          <PlaceHolder
            visible={true}
            emoji={'🔑'}
            description={'Aqui você pode acompanhar o histórico de acesso dos seus visitantes, fornecedores, funcionários...'}
          />
        )}
      />
    </View>
  )
}

function AccessAuthorizationListScreen() {
  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      <AppTabView scenes={[
        { key: 'auths', title: 'Autorizações', scene: AccessAuthListTab },
        { key: 'logs', title: 'Histórico', scene: AccessLogListTab }
      ]}/>
    </SafeAreaView>
  )
}

export default AccessAuthorizationListScreen
