import {useQuery} from 'react-query'
import tenantService from '../services/tenantService'

export default function useTenantConfigQuery() {
  return useQuery(
    ['useTenantConfigQuery'],
    async () => {
      try {
        return await tenantService.configs()
      } catch (e) {
        return null
      }
    },
    {
      cacheTime: 1000 * 60 * 2,
      staleTime: 1000 * 60 * 2,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      // refetchInterval: 1000 * 2,
      retry: false,
      retryOnMount: false
    }
  )
}
