import {applyMiddleware, compose, createStore, Store} from 'redux'

import createSagaMiddleware from 'redux-saga'
import rootSaga from '../sagas/index'
import rootReducer from '../reducers/index'

const sagaMiddleware = createSagaMiddleware()
const middlewares = [
  sagaMiddleware
]

const composeEnhancer = compose

function configureStore(initialState: any, helpersConfig: any) {
  const store = createStore(
    rootReducer(),
    composeEnhancer(applyMiddleware(...middlewares))
  )
  sagaMiddleware.run(rootSaga)
  return store
}

export const store: Store<any> = configureStore('', '')


