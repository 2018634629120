import {FontAwesome} from '@expo/vector-icons'
import {useFocusEffect, useNavigation} from '@react-navigation/native'
import React from 'react'
import {FlatList, StyleSheet, TouchableOpacity, View} from 'react-native'
import {Caption, Title} from 'react-native-paper'
import {useQuery} from 'react-query'
import {useHasPerm} from '../../../components/acl'
import {AclRender} from '../../../components/acl/components'
import NotPermitted from '../../../components/NotPermitted'
import PlaceHolder from '../../../components/placeholder'
import {RootDrawerScreenProps} from '../../../navigation/types'
import {useAppTheme} from '../../../styles/theme'
import {qs} from '../../queries'
import {SpaceModel} from '../../typings'

function SpaceListTab() {
  const theme = useAppTheme()
  const navigation = useNavigation<RootDrawerScreenProps<'BookListScreen'>['navigation']>()

  const { data, isFetching, refetch, remove } = useQuery(
    qs.spaces.all_key,
    () => qs.spaces.all(),
    { enabled: false }
  )

  useFocusEffect(
    React.useCallback(() => {
      refetch()
      return () => { remove() }
    }, [])
  )

  let can_add = useHasPerm(['book.add_book'])

  const renderItem = ({ item }: { item: SpaceModel }) => (
    <TouchableOpacity onPress={() => can_add && navigation.navigate('BookNewScreen', { item_id: item.id })}>
      <View style={styles.cardContainer}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View style={{ flex: 1 }}>
            <Title>{item.name}</Title>

            <Caption style={{ fontWeight: 'bold' }}>
              <FontAwesome name="user-o" size={12} color={theme.colors.primary}/>{' '}
              Capacidade: {item.guests_max}
            </Caption>
            <Caption style={{ fontWeight: 'bold' }}>
              <FontAwesome name="money" size={12} color={theme.colors.primary}/>{' '}
              Taxa: {item.full_tax}
            </Caption>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  )

  return (
    <View style={{ flex: 1 }}>
      <AclRender required={['book.add_book']} fallback={<NotPermitted/>}>
        <FlatList
          style={styles.list}
          contentContainerStyle={styles.listContent}
          data={data}
          renderItem={renderItem}
          keyExtractor={item => item.id + ''}
          refreshing={isFetching}
          onRefresh={refetch}
          ListEmptyComponent={() => (
            <PlaceHolder
              visible={true}
              emoji={'🕺'}
              description={'Ops... Nenhum espaço cadastrado ainda!'}
            />
          )}
        />
      </AclRender>
    </View>
  )
}

const styles = StyleSheet.create({
  list: {
    flex: 1
  },
  listContent: {
    paddingHorizontal: 8,
    paddingVertical: 14
  },
  cardContainer: {
    borderWidth: 1,
    borderColor: '#e8e8e8',
    borderRadius: 6,
    padding: 12,
    marginBottom: 14
  }
})

export default SpaceListTab