import AsyncStorage from '@react-native-async-storage/async-storage'
import {useFocusEffect, useNavigation} from '@react-navigation/native'
import * as React from 'react'
import {useTranslation} from 'react-i18next'
import {Alert, FlatList, StyleSheet, Text, TouchableOpacity, View} from 'react-native'
import {Button, Subheading, Title} from 'react-native-paper'

import {useMutation, useQuery} from 'react-query'
import {useRecoilState} from 'recoil'
import ActivityLoading from '../../../components/loading'
import PlaceHolder from '../../../components/placeholder'
import Spacer from '../../../components/Spacer'
import {UNIT_PERSON_TYPES} from '../../../constants/fields'
import {RootDrawerScreenProps} from '../../../navigation/types'
import authService from '../../../services/authService'
import queryClient from '../../../services/queryClient'
import {userLoggedInAtom} from '../../../state/atoms'
import {getAppVersion, platformChecker} from '../../../utils'
import {qs} from '../../queries'
import {MyTeamResponse} from '../../typings'
import {getMyTeams} from '../queries'

export const MyTeamsScreen = () => {
  const navigation = useNavigation<RootDrawerScreenProps<'MyTeams'>['navigation']>()
  const { t } = useTranslation()

  const { isFetching, status, data, refetch } = useQuery<MyTeamResponse[], string>('getMyTeams', getMyTeams, { retry: false, enabled: false })
  const [isLoggedIn, setUserLoggedIn] = useRecoilState(userLoggedInAtom)

  const mutation = useMutation((variables: any) => qs.user.changeTeam(variables), {
    onSuccess: async (data, variables) => {
      await queryClient.cancelQueries()
      queryClient.removeQueries()
      queryClient.clear()
      setUserLoggedIn(true)

      await authService.setTeamVersionLocal(variables.use_condoid_v2)

      navigation.navigate('HomeScreen')
    }
  })

  useFocusEffect(
    React.useCallback(() => {
      Alert.alert(`Agora escolha qual ${t('condominio')} deseja entrar!`, '', [
        {
          text: 'Entendi!',
          onPress: (value) => refetch()
        }
      ])

      if (platformChecker.isWeb) {
        refetch()
      }
    }, [])
  )

  const handleSelect = async (item: MyTeamResponse) => {
    let units = item.units

    if (units.length > 0) {
      mutation.mutate({ new_team: item.team_id, use_condoid_v2: item.team_use_condoid_v2 })
    }
  }

  const handleRenderItem = (item: MyTeamResponse) => {
    let units = item.units

    return (
      <TouchableOpacity onPress={() => handleSelect(item)}>
        <View style={styles.cardContainer}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <View style={{ flex: 3 }}>
              <Subheading>{item.team_name}</Subheading>
              <View style={{ flexDirection: 'column' }}>
                <Text>{t('unit_plural_name')}:</Text>

                {
                  units.length > 0 && units.map((i) => (
                    <Text key={i.with_block}>{i.with_block}. Vínculo: {UNIT_PERSON_TYPES[i.unit_pivot_type]}</Text>
                  ))
                }

                {units.length === 0 &&
                  <Text style={{ color: '#d9534f' }}>
                    Nenhuma {t('unit_name')} ativa, não será possível acessar esse condomínio. Por favor procure a administração.
                  </Text>}

              </View>
            </View>
          </View>
        </View>
      </TouchableOpacity>
    )
  }

  return (
    <View style={{ flex: 1 }}>
      <Spacer size={80}/>

      <Title style={{ textAlign: 'center' }}>
        Selecione para continuar:
      </Title>

      <Spacer size={15}/>

      <FlatList
        style={styles.list}
        contentContainerStyle={styles.listContent}
        keyExtractor={(item: any) => item?.team_name}
        refreshing={status === 'loading'}
        onRefresh={() => {
          refetch()
        }}
        data={data}
        renderItem={({ item }) => handleRenderItem(item)}
        ListEmptyComponent={() => (
          <PlaceHolder visible={!data?.length} emoji={''} description={'Nenhuma condomínio disponível para acessar.'}/>
        )}
      />

      <Spacer size={40}/>

      <View style={styles.footer}>
        <Button icon="refresh" mode="outlined" onPress={refetch}>
          Atualizar {t('condominio_plural')}
        </Button>

        <Spacer size={30}/>

        <Button icon="cancel" mode="outlined" color="#d9534f" onPress={() => {
          AsyncStorage.clear()
          navigation.navigate('Login')
        }}>
          Sair
        </Button>

        <Spacer size={60}/>

        <Text style={styles.version}>v. {getAppVersion()}</Text>
      </View>

      <ActivityLoading visible={isFetching || mutation.isLoading}/>
    </View>
  )
}

const styles = StyleSheet.create({
  list: {
    flex: 1
  },
  listContent: {
    paddingHorizontal: 8,
    paddingVertical: 14
  },
  footer: {
    paddingHorizontal: 8,
    paddingVertical: 14
  },
  version: {
    color: '#005898',
    fontSize: 10,
    paddingTop: 10,
    paddingBottom: 2,
    textAlign: 'center'
  },
  cardContainer: {
    borderWidth: 1,
    borderColor: '#d9d9d9',
    borderRadius: 6,
    padding: 12,
    marginBottom: 14,
    backgroundColor: '#ffffff'
  }
})
