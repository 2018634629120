import color from 'color'
import React from 'react'
import {StyleSheet, View} from 'react-native'
import {Text} from 'react-native-paper'

const styles = StyleSheet.create({
  container: {
    borderRadius: 8,
    paddingHorizontal: 8,
    paddingVertical: 5,
    marginVertical: 4,
    alignSelf: 'flex-start',
    marginRight: 5
  },
  text: {
    fontWeight: '500',
    fontSize: 12,
    letterSpacing: 0.5,
    lineHeight: 13
  }
})

export const Pill = (props: any) => {
  const { color: prop_color = '#000000' } = props

  const bg_color = color(prop_color).alpha(0.20).rgb().string()

  return (
    <View style={[styles.container, { backgroundColor: bg_color }]} key={'topp'}>
      <Text style={[styles.text, { color: prop_color }]}>{props.children}</Text>
    </View>
  )
}
