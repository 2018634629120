import {useFocusEffect, useIsFocused, useRoute} from '@react-navigation/native'
import * as React from 'react'
import {Headline, Text} from 'react-native-paper'
import {SafeAreaView} from 'react-native-safe-area-context'
import {useQuery} from 'react-query'
import {AclRender} from '../../../components/acl/components'
import AppScrollView from '../../../components/layout/AppScrollView'
import ActivityLoading from '../../../components/loading'
import NotPermitted from '../../../components/NotPermitted'
import Spacer from '../../../components/Spacer'
import {RootDrawerScreenProps} from '../../../navigation/types'
import {EventLogger} from '../../../services/EventLogger'
import {qs} from '../../queries'

const InformationDetailScreen = () => {
  const route = useRoute<RootDrawerScreenProps<'InformationDetailScreen'>['route']>()

  let item_id = route.params?.item_id

  const { data: information, isFetching, refetch, remove } = useQuery(
    [qs.informations.get_key, item_id],
    () => qs.informations.get(item_id),
    { enabled: false }
  )

  useFocusEffect(
    React.useCallback(() => {
      refetch()

      EventLogger.logEvent('SeeInformationDetail', 'screen', item_id, 'information')
      return () => {
        remove()
      }
    }, [item_id])
  )

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      {
        !isFetching && information?.id ?
          <AppScrollView>
            <Headline style={{ fontWeight: 'bold' }}>
              {information.name}
            </Headline>

            <Spacer size={15}/>

            <Text>{information.body}</Text>

            <Spacer size={15}/>
          </AppScrollView>
          :
          <ActivityLoading visible={isFetching}/>
      }
    </SafeAreaView>
  )
}

export default function (props: any) {
  const isFocused = useIsFocused()

  if (!isFocused) return null

  return (
    <AclRender required={['notice.view_information']} fallback={<NotPermitted/>}>
      <InformationDetailScreen/>
    </AclRender>
  )
}
