import color from 'color'
import React from 'react'
import {Text, View} from 'react-native'
import FitImage from 'react-native-fit-image'
import Markdown, {getUniqueID} from 'react-native-markdown-renderer'
import Style from '../styles/Style'
import {useAppTheme} from '../styles/theme'

export const HighlightBox = (props: { title: string, body: string }) => {
  const theme = useAppTheme()

  return (
    <View style={
      {
        backgroundColor: color(theme.colors.accent).alpha(0.05).rgb().string(),
        borderRadius: 10,
        borderWidth: 3,
        borderColor: theme.colors.accent,
        padding: 15,
        marginVertical: 10
      }
    }>
      <Text style={{ fontWeight: 'bold' }}>
        {props.title}
      </Text>
      <Text>
      </Text>
      <Text>
        {props.body}
      </Text>
    </View>
  )
}

const parse_text = (content: any, idx: any, styles: any) => {
  if (content === '<br>') {
    return <Text key={idx} style={styles}> </Text>
  }

  if (content.indexOf('color:') > -1) {
    let splited = content.split('</span>')
    content = splited.map((c: any, idxx: any) => {
      let font_color = c.substring(c.indexOf('color:') + 6, c.indexOf('”>'))
      c = c.substring(c.indexOf('”>') + 2)
      styles = { ...styles, color: font_color }
      return <Text key={idx + '' + idxx} style={styles}> {c}</Text>
    })
  }

  return <Text key={idx} style={styles}>{content}</Text>
}
const rules = {
  text: (node: any, children: any, parent: any, styles: any) => {
    let content = node.content
    return content.split('<span ').map((i: any, idx: any) => parse_text(i, idx, {}))
  },

  heading1: (node: any, children: any, parent: any, styles: any) =>
    <Text key={getUniqueID()} style={[styles.heading, styles.heading1]}>
      {children}
    </Text>,
  heading2: (node: any, children: any, parent: any, styles: any) =>
    <Text key={getUniqueID()} style={[styles.heading, styles.heading2]}>
      {children}
    </Text>,
  heading3: (node: any, children: any, parent: any, styles: any) =>
    <Text key={getUniqueID()} style={[styles.heading, styles.heading3]}>
      {children}
    </Text>,
  heading4: (node: any, children: any, parent: any, styles: any) =>
    <Text key={getUniqueID()} style={[styles.heading, styles.heading4]}>
      {children}
    </Text>,
  image: (node: any, children: any, parent: any, styles: any) => {
    return (
      <View>
        <View style={{ width: Style.DEVICE_WIDTH }}>
          <FitImage indicator={true} key={node.key} style={styles.image} source={{ uri: node.attributes.src }}/>
        </View>
      </View>
    )
  }

}

export const MkRender = (props: any) => (
  <Markdown rules={rules}>{props.children}</Markdown>
)

export const NewFeature = (props: { title: string, body: string, url?: string }) => {
  const theme = useAppTheme()

  return (
    <View style={{
      backgroundColor: color(theme.colors.accent).alpha(0.05).rgb().string(),
      borderRadius: 10,
      borderWidth: 4,
      borderColor: theme.colors.accent,
      padding: 15,
      marginVertical: 15,
      marginHorizontal: 15
    }}
    >
      <Text style={{ fontWeight: 'bold' }}>
        {props.title}
      </Text>

      <Text>
      </Text>

      <Text>
        {props.body}
      </Text>

      {/*
    {props.url &&
    <Button bordered style={{ marginTop: 10 }} onPress={() => {}}>
      <Text>Ver agora!</Text>
    </Button>
    }*/}
    </View>
  )
}

