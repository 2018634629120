import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import {getAppId} from './tenantService'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  condoid: {
    translation: {
      'condomino': 'Condômino',
      'condomino_plural': 'Condôminos',
      'condominio': 'Condominio',
      'condominio_plural': 'Condomínios',
      'owner_name': 'Proprietário',
      'renter_name': 'Inquilino',
      'unit_name': 'unidade',
      'unit_plural_name': 'unidades',
      'sign_request_screen__txt__1': 'Vamos começar com o código do condomínio, e o seus dados de identificação. Se não sabe o código do condomínio, procure a administração.',
      'sign_request_screen__txt__2': 'Procedimento somente para o Proprietário ou Inquilino reponsável!',
      'sign_request_screen__txt__3': 'Após o envio do formulário aguarde a APROVAÇÃO da administração. Após aprovado chegará uma senha por email para acessar o app e cadastrar os outros condôminos da unidade.',
      'sign_request_screen__form__same_address_condominium': 'Atualmente mora no condomínio?',
      'sign_request_screen__form__unit_code__label': 'Número da Unidade (apartamento, lote, casa...)',
      'sign_request_screen__form__block_name__label': 'Bloco da Unidade (torre, quadra...)'

    }
  },
  cooperluxo: {
    translation: {
      'condomino': 'Cooperado',
      'condomino_plural': 'Cooperados',
      'condominio': 'Cooperativa',
      'condominio_plural': 'Cooperativas',
      'owner_name': 'Cooperado',
      'renter_name': '--',
      'unit_name': 'matrícula',
      'unit_plural_name': 'matrículas',
      'sign_request_screen__txt__1': 'Vamos começar com o código da cooperativa, e o seus dados de identificação. Se não sabe o código da cooperativa, procure a administração.',
      'sign_request_screen__txt__2': 'Procedimento somente para cooperados!',
      'sign_request_screen__txt__3': 'Após o envio do formulário aguarde a APROVAÇÃO da administração. Após aprovado chegará uma senha por email para acessar o app',
      'sign_request_screen__form__same_address_condominium': 'Não informar endereço',
      'sign_request_screen__form__unit_code__label': 'Número da Matrícula',
      'sign_request_screen__form__block_name__label': 'Bloco da Matrícula (coloque um zero se não existir)'

    }
  }
}

const app_id = getAppId()
console.log('app_id', app_id)

let language_code = 'condoid'
if (app_id?.indexOf('cooperluxo') > -1) {
  language_code = 'cooperluxo'
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'condoid', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    lng: language_code, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

i18n.changeLanguage(language_code)

export default i18n