import * as React from 'react'
import {StyleSheet, View, ViewProps} from 'react-native'

const styles = StyleSheet.create({
  cardContainer: {
    borderWidth: 1,
    borderColor: '#e8e8e8',
    borderRadius: 6,
    padding: 12,
    marginBottom: 14
  }
})

const AppListCard: React.FC<ViewProps> = (props) => {
  return (
    <View
      style={styles.cardContainer}
      {...props}
    >
      {props.children}
    </View>
  )
}

export default AppListCard