import axios from 'axios'
import {Alert} from 'react-native'
import enviroment from '../../enviroment'
import {fetchApi, postApiRootUrl} from '../../services/api'
import authService from '../../services/authService'
import {EventLogger} from '../../services/EventLogger'
import ToasterManager from '../../services/ToasterManager'

export const postChangePassword = async (values: any) => {
  try {
    let res = await postApiRootUrl('auth/password', values)
    EventLogger.logEvent('ChangedPassword', 'action')
    ToasterManager.success('Senha alterada com sucesso!')

    return res
  } catch (e) {
    ToasterManager.sendErrors(e)
    return Promise.reject(e)
  }
}

export const postResetPassword = async (values: any) => {
  try {
    const APP_ENV = await enviroment()

    let res = await axios({
      method: 'post',
      url: APP_ENV.rootApi + 'auth/password_reset',
      data: values
    })

    EventLogger.logEvent('PasswordReset', 'action')
    ToasterManager.success('Nós te enviamos por e-mail as instruções para redefinição de sua senha!')

    return res
  } catch (e) {
    ToasterManager.sendErrors(e)
    return Promise.reject(e)
  }
}

export const postLogin = async (values: any) => {
  try {
    const APP_ENV = await enviroment()

    let { data } = await axios({
      method: 'post',
      url: APP_ENV.rootApi + 'auth/token/login',
      data: values
    })

    if (data.user_role !== 1) {
      ToasterManager.error(
        'Para acessar esse app use as credenciais de Condômino! Caso queira acessar o painel administrativo use o app Condo Id - Admin')

      return Promise.reject('Função inválida!')
    }

    await authService.setToken(data.auth_token)
    await authService.setTeamVersionLocal(data.use_condoid_v2)

    return data
  } catch (e) {
    // ToasterManager.sendErrors(e)
    return Promise.reject(e)
  }
}

export const getMyTeams = async () => {
  try {
    await authService.getToken()
    return await fetchApi('public/my-teams')
  } catch (e) {
    Alert.alert('Ops!', 'Erro ao recuperar os seus condomínios. Tente novamente ou procure o suporte.')
    await Promise.reject('Error')
  }
}
