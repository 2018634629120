import moment from 'moment'
import {MaskService} from 'react-native-masked-text'
import {gv} from './common'

export function dateFormat(params: any, path = null) {
  let formated = params

  if (path !== null) {
    formated = gv(params, path)
  }

  if (typeof formated !== 'undefined' && formated !== null && formated !== '') {
    formated = moment(formated).format('DD/MM/YYYY')
  }
  return formated
}

export function dateTimeFormat(params: any, path = null) {
  let formated = params

  if (path !== null) {
    formated = gv(params, path)
  }

  if (typeof formated !== 'undefined' && formated !== null && formated !== '') {
    formated = moment(formated).format('DD/MM/YY HH:mm')
  }
  return formated
}

export function removeAccents(params: any) {
  const accentMap: any = {
    'á': 'a',
    'ã': 'a',
    'â': 'a',
    'é': 'e',
    'í': 'i',
    'ó': 'o',
    'ô': 'o',
    'ú': 'u'
  }

  const accent_fold = (s: any) => {
    if (!s) {
      return ''
    }
    let ret = ''
    for (let i = 0; i < s.length; i++) {
      ret += accentMap[s.charAt(i)] || s.charAt(i)
    }
    return ret
  }

  return accent_fold(params.value)
}

export function formatPhone(phone: any) {
  if (typeof phone !== 'undefined' && phone !== null && phone !== '') {
    return MaskService.toMask('cel-phone', phone)
  }
  return null
}

export function formatMobile(phone: any) {
  if (typeof phone !== 'undefined' && phone !== null && phone !== '') {
    return MaskService.toMask('cel-phone', phone)
  }
  return null
}

export function getRandomInt(min: any, max: any) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min)) + min
}
