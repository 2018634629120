import {useFocusEffect} from '@react-navigation/native'
import React, {useState} from 'react'
import {FlatList, Image, StyleSheet, TouchableOpacity, View} from 'react-native'
import {Caption, Title} from 'react-native-paper'
import {SafeAreaView} from 'react-native-safe-area-context'
import {useQuery} from 'react-query'
import AppModalSheet from '../../../components/layout/AppModalSheet'
import PlaceHolder from '../../../components/placeholder'
import {useAppTheme} from '../../../styles/theme'
import {Dec2BRL} from '../../../utils'
import {getConsumptions} from '../queries'

function ConsumptionListScreen({ navigation, ...props }: any) {
  const theme = useAppTheme()
  const { data, isFetching, refetch, remove } = useQuery(['getConsumptions'], getConsumptions, { enabled: false })

  useFocusEffect(
    React.useCallback(() => {
      refetch()

      return () => {
        remove()
      }
    }, [])
  )

  const [current_item, setCurrentItem] = useState<any>({})

  const handleShowDetails = (item: any) => {
    if (Array.isArray(item.files) && item.files.length > 0) {
      setCurrentItem(item)
    }
  }

  const renderItem = ({ item }: any) => (
    <React.Fragment>
      <TouchableOpacity onPress={() => handleShowDetails(item)}>
        <View style={styles.cardContainer}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <View style={{ flex: 3 }}>
              <Caption>{item.consumption_type_name} - {item.unit_name}</Caption>
              <Title>R$ {Dec2BRL(item.total)}</Title>
              <Caption>Consumo: {item.amount_calculated}</Caption>
              <Caption>Data Leitura: {item.month}/{item.year}</Caption>
            </View>
            <View style={{ flex: 1, alignSelf: 'center' }}>

              {Array.isArray(item.files) && item.files.length > 0 &&
                <Caption style={{ textAlign: 'right', fontWeight: 'bold', color: theme.colors.accent }}>
                  ver fotos
                </Caption>}

            </View>
          </View>
        </View>
      </TouchableOpacity>
    </React.Fragment>
  )

  const renderInner = () => (
    <View style={styles.panel}>

      {current_item?.files?.map((i: any) => (
        <Image
          key={i.file}
          source={{ uri: i.file }}
          resizeMode={'contain'}
          style={{ height: 300, marginBottom: 20 }}
        />
      ))}

    </View>
  )

  return (
    <React.Fragment>
      <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
        <FlatList
          style={styles.list}
          contentContainerStyle={styles.listContent}
          data={data}
          renderItem={renderItem}
          keyExtractor={item => item.id + ''}
          refreshing={isFetching}
          onRefresh={refetch}
          ListEmptyComponent={() => (
            <PlaceHolder
              visible={true}
              emoji={'📊'}
              description={'Nenhum consumo lançado ainda.'}
            />
          )}
        />

      </SafeAreaView>

      <AppModalSheet
        visible={!!current_item?.id}
        height_percent={70}
        onDismiss={() => setCurrentItem(null)}
        content={renderInner}
      />
    </React.Fragment>
  )
}

const styles = StyleSheet.create({
  list: {
    flex: 1
  },
  listContent: {
    paddingHorizontal: 8,
    paddingVertical: 14
  },
  cardContainer: {
    borderWidth: 1,
    borderColor: '#e8e8e8',
    borderRadius: 6,
    padding: 12,
    marginBottom: 14
  },
  panel: {
    minHeight: 700,
    paddingTop: 10,
    paddingBottom: 40,
    backgroundColor: '#f8f8f8',
    paddingRight: 20,
    paddingLeft: 20

  },
  header: {
    backgroundColor: '#efefef',
    paddingTop: 20,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20
  },
  panelHeader: {
    alignItems: 'center'
  },
  panelHandle: {
    width: 40,
    height: 8,
    borderRadius: 4,
    backgroundColor: '#00000040',
    marginBottom: 10
  }
})

export default ConsumptionListScreen
