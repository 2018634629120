import {useIsFocused, useNavigation} from '@react-navigation/native'
import {Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {Image, View} from 'react-native'
import {Button, HelperText, Paragraph} from 'react-native-paper'
import {SafeAreaView} from 'react-native-safe-area-context'
import {useMutation} from 'react-query'
import * as Yup from 'yup'
import DialogSelect from '../../../components/form/DialogSelect'
import FaceCamera from '../../../components/form/FaceCamera'
import {FormikDatePicker} from '../../../components/form/FormikInputs'
import {FF, FormContainer, FormikInput, FormikRadio} from '../../../components/form/FormInputs'
import Spacer from '../../../components/Spacer'
import {BR_STATES, GENDERS} from '../../../constants/fields'
import {RootDrawerScreenProps} from '../../../navigation/types'
import {hv, platformChecker} from '../../../utils'
import {useMeQuery} from '../../Auth/hooks/useMeQuery'
import {qs} from '../../queries'

function EditProfile() {
  const navigation = useNavigation<RootDrawerScreenProps<'EditProfile'>['navigation']>()

  const { data: meData, isFetching, refetch } = useMeQuery()

  useEffect(() => {
    refetch()
  }, [])

  const person = meData?.person ?? {}

  const [showFace, setShowFace] = useState(false)

  const mutation = useMutation((variables: any) => qs.user.updateProfile(variables), {
    onSuccess: async () => {
      await refetch()
      navigation.navigate('MyDataScreen')
    }
  })

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      {!isFetching && <Formik
        initialValues={{
          id: person?.id ?? '',

          rg: person?.rg ?? '',
          gender: person?.gender ?? '',
          birthday: person?.birthday ?? '',
          mobile1: person?.mobile1 ?? '',
          mobile2: person?.mobile2 ?? '',
          phone: person?.phone ?? '',

          same_address_condominium: person?.same_address_condominium ?? true,
          address: person?.address ?? '',
          district: person?.district ?? '',
          city: person?.city ?? '',
          state: person?.state ?? '',
          cep: person?.cep ?? '',

          image: person?.photo?.file ?? '',
          image_upload: ''
        }}
        validationSchema={Yup.object().shape({
          gender: Yup.string().required(),
          birthday: Yup.string().required(),
          // @ts-ignore
          mobile1: Yup.string().validMobile().required(),
          same_address_condominium: Yup.boolean().required(),
          address: Yup.string()
            .when('same_address_condominium',
              (same_address_condominium: boolean) => same_address_condominium ? Yup.string() : Yup.string().required()),
          district: Yup.string()
            .when('same_address_condominium',
              (same_address_condominium: boolean) => same_address_condominium ? Yup.string() : Yup.string().required()),
          city: Yup.string()
            .when('same_address_condominium',
              (same_address_condominium: boolean) => same_address_condominium ? Yup.string() : Yup.string().required()),
          state: Yup.string()
            .when('same_address_condominium',
              (same_address_condominium: boolean) => same_address_condominium ? Yup.string() : Yup.string().required()),
          cep: Yup.number()
            .when('same_address_condominium',
              // @ts-ignore
              (same_address_condominium: boolean) => same_address_condominium ? Yup.string() : Yup.string().validCEP().required()),
          phone: Yup.string(),

          image: Yup.string()
        })}
        onSubmit={(values) => {
          if (values?.id) {
            mutation.mutate(values)
          }
        }}
        render={({ values, errors, submitForm, setFieldValue }) => {
          return (
            <FormContainer>
              <Paragraph>Nome: {person?.name}</Paragraph>
              <Paragraph>CPF/CNPJ: {person?.cpf}</Paragraph>
              <Paragraph>E-mail: {person?.email}</Paragraph>

              <Spacer size={30}/>

              <FF label={'RG'} name="rg" component={FormikInput}/>
              <FormikDatePicker label={'Data de Nascimento'} name="birthday"/>
              <FF label={'Sexo'} name="gender" component={DialogSelect} local_options={GENDERS}/>

              <FF label={'Celular'} name="mobile1" component={FormikInput} keyboardType="numeric"/>
              <FF label={'Telefone'} name="phone" component={FormikInput} keyboardType="numeric"/>

              <FF label={'Atualmente mora no condomínio?'} name="same_address_condominium" component={FormikRadio}/>

              {!values.same_address_condominium &&
                <View>
                  <FF label={'Endereço'} name="address" component={FormikInput}/>
                  <FF label={'Bairro'} name="district" component={FormikInput}/>
                  <FF label={'Cidade'} name="city" component={FormikInput}/>
                  <FF label={'Estado'} name="state" component={DialogSelect} local_options={BR_STATES}/>
                  <FF label={'CEP'} name="cep" component={FormikInput} keyboardType="numeric"/>
                </View>}

              <Spacer size={30}/>

              <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                <HelperText type="info" visible={true}>
                  Atenção a foto precisa cobrir todo o rosto. Escolha um fundo branco e evite objetos ao redor da imagem.
                </HelperText>

                <HelperText type="error" visible={!!errors.image}>
                  Por favor tire uma foto do condômino.
                </HelperText>

                {platformChecker.isNative &&
                  <FaceCamera
                    show={showFace}
                    setShow={setShowFace}
                    btnTitle={!values.image ? 'Tirar foto' : 'Alterar foto'}
                    handleImageUri={(uri) => {
                      setFieldValue('image', uri)
                      setFieldValue('image_upload', uri)
                      setShowFace(false)
                    }}
                  />}

                <Spacer size={20}/>

                {hv(values, 'image') && <Image source={{ uri: values.image }} style={{ width: 225, height: 300 }}/>}
              </View>

              <Spacer size={40}/>

              <Button icon="content-save" mode="contained" loading={mutation.isLoading} disabled={mutation.isLoading}
                      onPress={submitForm}>Salvar</Button>
            </FormContainer>
          )
        }}
      />}
    </SafeAreaView>
  )
}

export default function (props: any) {
  const isFocused = useIsFocused()

  if (!isFocused) return null

  return (
    <EditProfile/>
  )
}
