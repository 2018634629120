import {useFocusEffect, useNavigation} from '@react-navigation/native'
import React from 'react'
import {TouchableOpacity} from 'react-native'
import {Title} from 'react-native-paper'
import {SafeAreaView} from 'react-native-safe-area-context'
import {useQuery} from 'react-query'
import {AclRender} from '../../../components/acl/components'
import AppFlatList from '../../../components/layout/AppFlatList'
import AppListCard from '../../../components/layout/AppListCard'
import NotPermitted from '../../../components/NotPermitted'
import PlaceHolder from '../../../components/placeholder'
import {RootDrawerScreenProps} from '../../../navigation/types'
import {qs} from '../../queries'

function InformationListScreen() {
  const navigation = useNavigation<RootDrawerScreenProps<'InformationListScreen'>['navigation']>()

  const { data, isFetching, refetch, remove } = useQuery(qs.informations.all_key, () => qs.informations.all(), { enabled: false })

  useFocusEffect(
    React.useCallback(() => {
      refetch()

      return () => {
        remove()
      }
    }, [])
  )

  const renderItem = ({ item }: { item: any }) => (
    <TouchableOpacity onPress={() => navigation.navigate('InformationDetailScreen', { item_id: item.id })}>
      <AppListCard>
        <Title>{item.name}</Title>
      </AppListCard>
    </TouchableOpacity>
  )

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      <AppFlatList
        data={data}
        renderItem={renderItem}
        keyExtractor={item => item.id + ''}
        refreshing={isFetching}
        onRefresh={refetch}
        ListEmptyComponent={() => (
          <PlaceHolder
            visible={true}
            emoji={'📄'}
            description={'Em breve todas as informações importantes estarão aqui...'}
          />
        )}
      />
    </SafeAreaView>
  )
}

export default function (props: any) {
  return (
    <AclRender required={['notice.view_information']} fallback={<NotPermitted/>}>
      <InformationListScreen/>
    </AclRender>
  )
}
