import React from 'react'
import {Text} from 'react-native-paper'
import * as Yup from 'yup'
import {setLocale} from 'yup'
import {validate_cpf_cpnj} from './validators'

const phoneMask = ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
const mobileMask = ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
const plate_regex = /[a-zA-Z]{3}[0-9]{1}[a-zA-Z]{1}[0-9]{2}|[a-zA-Z]{3}[0-9]{4}/

export const formUtils = {
  phoneMask: phoneMask,
  mobileMask: mobileMask,
  cepMask: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
  plateMask: [/[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /\d/, /\d/, /\d/, /\d/],

  unMaskNumbers: (masked: any, mask = /\D/g) => {
    return masked.replace(new RegExp(mask), '')
  },

  debug(values: any) {
    return (
      <React.Fragment>
        <Text> </Text>
        <Text>
          {JSON.stringify(values, null, 2)}
        </Text>
      </React.Fragment>
    )
  }
}

export function yupRules() {
  setLocale({
    mixed: {
      default: 'Não é válido',
      required: 'Campo obrigatório'
    },
    string: {
      email: 'Email não é válido'
    },
    number: {
      min: 'Deve ser maior que ${min}'
    }
  })
  Yup.addMethod(Yup.string, 'validCPF', () => {
    return Yup.string().test('validCPF', 'CPF incorreto, digite somente números', (value) => {
      if (typeof value === 'undefined' || value === null || value === '') {
        return true
      }
      return validate_cpf_cpnj(value)
    })
  })

  Yup.addMethod(Yup.string, 'validPhone', function () {
    return this.test('valid-phone', 'Número inválido', function (value) {
      try {
        if (typeof value === 'undefined' || value === null || value === '') {
          return true
        }
        return value.length === 10
      } catch (e) {
        console.error('validPhone catch', e)
        return false
      }
    })
  })

  Yup.addMethod(Yup.string, 'validMobile', () => {
    return Yup.string()
      .test('validMobile', 'Digite o DDD e o celular com 9 dígitos (somente números)', (value) => value ? value.length === 11 : true)
  })

  Yup.addMethod(Yup.string, 'validCEP', () => {
    return Yup.string().test('validCEP', 'Digite o CEP (somente números)', (value) => value ? (value.replace(/_/g, '')).length === 8 : true)
  })

  Yup.addMethod(Yup.string, 'validPlate', () => {
    return Yup.string().test('validPlate', 'Verifique o formato da placa', (value) => value ? value.search(plate_regex) > -1 : true)
  })
}