import {useNavigation} from '@react-navigation/native'
import {Formik, getIn} from 'formik'
import React, {useState} from 'react'
import {Image, View} from 'react-native'
import {Button, HelperText, Text} from 'react-native-paper'
import {useMutation} from 'react-query'
import tw from 'twrnc'
import * as Yup from 'yup'
import {AclRender} from '../../../components/acl/components'
import DialogSelect from '../../../components/form/DialogSelect'
import FaceCamera from '../../../components/form/FaceCamera'
import {FormikDatePicker} from '../../../components/form/FormikInputs'
import {FF, FormContainer, FormikInput, FormikRadio} from '../../../components/form/FormInputs'
import SingleInputFile from '../../../components/form/SingleFileInput'
import UnitSelect from '../../../components/form/UnitSelect'
import Spacer from '../../../components/Spacer'
import {GENDERS} from '../../../constants/fields'
import {RootDrawerScreenProps} from '../../../navigation/types'
import {EventLogger} from '../../../services'
import ToasterManager from '../../../services/ToasterManager'
import {hv, platformChecker} from '../../../utils'
import {useMeQuery} from '../../Auth/hooks/useMeQuery'
import {qs} from '../../queries'

type Props = {
  // submitAction: (values: any) => Promise<any>,
  initialValues: any,
}

function ResidentForm({ initialValues }: Props) {
  const navigation = useNavigation<RootDrawerScreenProps<'ResidentDetailScreen'>['navigation']>()

  const { data: meData } = useMeQuery()

  const [showFace, setShowFace] = useState(false)
  const require_doc_photo = meData?.team?.configs?.general?.resident__person__require__doc_photo && !initialValues?.id

  const create = useMutation((variables: any) => qs.residents.post(variables), {
    onSuccess: (res: any) => {
      EventLogger.logEvent('NewPerson', 'action', res?.id, 'person')
      ToasterManager.success('Condômino criado com sucesso!')

      navigation.navigate('MyDataScreen')
    }
  })

  const update = useMutation((variables: any) => qs.residents.patch(variables), {
    onSuccess: () => {
      ToasterManager.success('Condômino atualizado com sucesso!')

      navigation.navigate('MyDataScreen')
    }
  })

  return (
    <Formik
      initialValues={{
        id: initialValues?.id ?? '',
        unit_id_to_attach: initialValues?.unit_id ?? '',
        name: initialValues?.name ?? '',
        cpf: initialValues?.cpf ?? '',
        email: initialValues?.email ?? '',
        mobile1: initialValues?.mobile1 ?? '',
        gender: initialValues?.gender ?? '',
        kinship: initialValues?.kinship ?? '',
        birthday: initialValues?.birthday ?? '',

        image: initialValues?.photo?.file ?? '',
        image_upload: '',
        request_mobile_access: false,
        doc_photo_public_key: ''
      }}
      validationSchema={Yup.object().shape({
        unit_id_to_attach: Yup.string().required(),
        name: Yup.string().required(),
        gender: Yup.string().required(),
        kinship: Yup.string().required(),
        birthday: Yup.string().required(),
        image: platformChecker.isNative ? Yup.string().required() : Yup.string(),
        // @ts-ignore
        cpf: Yup.string().required().validCPF(),
        email: Yup.string()
          .when('request_mobile_access',
            (request_mobile_access: boolean) => request_mobile_access ? Yup.string().email().required() : Yup.string().email()),
        mobile1: Yup.number()
          .when('request_mobile_access', (request_mobile_access: boolean) => request_mobile_access ? Yup.string().required() : Yup.string()),
        doc_photo_public_key: require_doc_photo ? Yup.string().required() : Yup.string()
      })}
      onSubmit={(values) => {
        if (initialValues?.id) {
          update.mutate(values)
        } else {
          // @ts-ignore
          values['parent'] = meData?.person?.id
          create.mutate(values)
        }
      }}
      render={({ values, errors, submitForm, setFieldValue }) => {
        return (
          <FormContainer>
            {!values.unit_id_to_attach && <UnitSelect name="unit_id_to_attach"/>}

            <FF label={'Nome'} name="name" component={FormikInput}/>
            <FF label={'CPF (Somente números)'} name="cpf" component={FormikInput} keyboardType="numeric"/>
            <FF label={'E-mail'} name="email" component={FormikInput} keyboardType="email-address" textContentType="emailAddress"/>
            <FF label={'Celular'} name="mobile1" component={FormikInput} keyboardType="numeric"/>
            <FF label={'Sexo'} name="gender" component={DialogSelect} local_options={GENDERS}/>
            <FormikDatePicker label={'Data de Nascimento'} name="birthday"/>
            <FF label={'Parentesco'} name="kinship" component={FormikInput}/>

            <Spacer size={40}/>

            {require_doc_photo &&
              <SingleInputFile
                label="Foto do documento de identificação"
                error_msg={getIn(errors, 'doc_photo_public_key')}
                handleNew={(s3_file_key) => setFieldValue('doc_photo_public_key', s3_file_key)}
                handleRemoveUri={(uri) => setFieldValue('doc_photo_public_key', '')}
              />}

            {require_doc_photo && <Spacer size={30}/>}

            {platformChecker.isNative &&
              <>
                <Text style={tw`text-gray-500 font-bold pb-2`}>Foto da face</Text>
                <View style={tw.style('p-4 rounded-md flex items-center justify-center bg-gray-200')}>
                  <HelperText type="info" visible={true}>
                    Atenção a foto precisa cobrir todo o rosto. Escolha um fundo branco e evite objetos ao redor da imagem.
                  </HelperText>

                  <HelperText type="error" visible={!!errors.image}>
                    Por favor tire uma foto do condômino.
                  </HelperText>

                  <FaceCamera
                    show={showFace}
                    setShow={setShowFace}
                    btnTitle={!values.image ? 'Tirar foto' : 'Alterar foto'}
                    handleImageUri={(uri) => {
                      setFieldValue('image', uri)
                      setFieldValue('image_upload', uri)
                      setShowFace(false)
                    }}
                  />

                  <Spacer size={20}/>

                  {hv(values, 'image') && <Image source={{ uri: values.image }} style={{ width: 225, height: 300 }}/>}
                </View>
              </>}

            <Spacer size={40}/>

            <FF label={'Solicitar a administração acesso ao App para esse cadastro?'} name="request_mobile_access" component={FormikRadio}/>

            <AclRender required={['unit.add_person', 'unit.change_person']}>
              <Button
                icon="content-save" mode="contained"
                loading={create.isLoading || update.isLoading}
                disabled={create.isLoading || update.isLoading}
                onPress={() => submitForm()}
              >
                Salvar
              </Button>
            </AclRender>
          </FormContainer>
        )
      }}
    />
  )
}

export default ResidentForm
