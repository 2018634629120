import {FontAwesome5, Ionicons} from '@expo/vector-icons'
import {useFocusEffect} from '@react-navigation/native'
import color from 'color'
import * as React from 'react'
import {StyleSheet, View} from 'react-native'
import {Divider, List, Text} from 'react-native-paper'
import {useQuery} from 'react-query'
import PushNotificationService from '../../../services/PushNotificationService'
import {useAppTheme} from '../../../styles/theme'
import {gv} from '../../../utils'
import {qs} from '../../queries'

export const HomeNotificationList = () => {
  const theme = useAppTheme()
  const { data, isFetching, refetch } = useQuery(qs.notifications.all_key, () => qs.notifications.all(), { retry: false, enabled: false })

  const styles = StyleSheet.create({
    textSection: {
      color: color(theme.colors.primary).darken(0.1).rgb().string(),
      fontSize: 14,
      paddingTop: 30,
      paddingBottom: 5,
      paddingHorizontal: 15,
      fontWeight: 'bold'
    }
  })

  let icon_style = {
    size: 26,
    color: color(theme.colors.primary).darken(0.1).rgb().string(),
    style: { marginLeft: 5, marginRight: 5, marginVertical: 10 }
  }

  const formatIcons = (item: any) => {
    let icon = gv(item, 'extra_data.url')

    switch (icon) {
      case 'book':
        return <Ionicons name="ios-calendar" {...icon_style}/>
      case 'books':
        return <Ionicons name="ios-calendar" {...icon_style}/>
      case 'guest-list':
        return <Ionicons name="ios-calendar" {...icon_style}/>
      case 'guest-auth':
        return <Ionicons name="ios-key" {...icon_style}/>
      case 'document':
        return <Ionicons name="ios-document" {...icon_style}/>
      case 'construction':
        return <Ionicons name="ios-construct" {...icon_style}/>
      case 'constructions':
        return <Ionicons name="ios-construct" {...icon_style}/>
      case 'information':
        return <Ionicons name="ios-information-circle" {...icon_style}/>
      case 'mailing':
        return <Ionicons name="ios-mail" {...icon_style}/>
      case 'moving':
        return <FontAwesome5 name="truck" {...icon_style}/>
      case 'survey':
        return <Ionicons name="ios-chatbubbles" {...icon_style}/>
      case 'notice':
        return <Ionicons name="ios-megaphone" {...icon_style}/>
      case 'penalty':
        return <Ionicons name="ios-flag" {...icon_style}/>
      case 'ticket':
        return <Ionicons name="ios-warning" {...icon_style}/>
      case 'draw':
        return <Ionicons name="ios-trophy" {...icon_style}/>
      case 'classified':
        return <Ionicons name="ios-list" {...icon_style}/>
      case 'meeting':
        return <Ionicons name="ios-people" {...icon_style}/>
      case 'lost':
        return <Ionicons name="logo-dropbox" {...icon_style}/>
      case 'authorization':
        return <Ionicons name="ios-key" {...icon_style}/>
      default:
        return <Ionicons name="ios-information-circle" {...icon_style}/>
    }
  }

  useFocusEffect(
    React.useCallback(() => {
      refetch()
      qs.notifications.readMeAll()
    }, [])
  )

  return (
    <View style={{ flex: 1 }}>
      <Text style={styles.textSection}>NOTIFICAÇÕES</Text>

      {data &&
        data.map(item => (
          <React.Fragment key={item.id}>
            <List.Item
              key={item.id}
              onPress={() => {
                PushNotificationService.handleNotificationAction(item.extra_data, item.hash, item.message_id)
              }}
              title={item.subject}
              titleStyle={{ color: color(theme.colors.primary).darken(0.3).rgb().string(), fontSize: 13 }}
              descriptionStyle={{ color: '#737373', fontSize: 12 }}
              style={{ padding: 12 }}
              description={item.text_content}
              left={props => formatIcons(item)}
              right={props => <Ionicons {...icon_style} name="ios-arrow-forward"/>}
            />
            <Divider/>
          </React.Fragment>
        ))
      }

    </View>
  )
}

