import {FontAwesome5, Ionicons} from '@expo/vector-icons'
import {useNavigation} from '@react-navigation/native'
import color from 'color'
import React from 'react'
import {StyleSheet, TouchableOpacity, View} from 'react-native'
import {Button, Text} from 'react-native-paper'
import {hasPerm} from '../../../components/acl'
import Spacer from '../../../components/Spacer'
import {RootDrawerScreenProps} from '../../../navigation/types'
import Style from '../../../styles/Style'
import {useAppTheme} from '../../../styles/theme'

const HomeShortcuts = () => {
  const theme = useAppTheme()
  const navigation = useNavigation<RootDrawerScreenProps<'HomeScreen'>['navigation']>()

  let shortcuts = [
    hasPerm(['unit.add_person']) ? { route: 'ResidentNewScreen', icon: 'ios-people-circle-outline', title: 'Cadastrar condômino' } : false,
    hasPerm(['book.add_book']) ? { route: 'BookListScreen', icon: 'ios-calendar', title: 'Reservar espaço' } : false,
    hasPerm(['ticket.add_ticket']) ? { route: 'TicketNewScreen', icon: 'ios-flag', title: 'Nova ocorrência' } : false,
    hasPerm(['access_control.add_accessauthorization'])
      ? { route: 'AccessAuthorizationNewScreen', icon: 'ios-key', title: 'Nova autorização' }
      : false,
    hasPerm(['moving.add_moving']) ? { route: 'MovingNewScreen', icon: 'truck', title: 'Nova mudança', fa: true } : false,

    { route: 'ChangePasswordScreen', icon: 'ios-lock-closed', title: 'Alterar senha' }
  ]

  const is_bg_txt_dark =
    color(theme.colors.primary).darken(0.3).isDark() &&
    color(theme.colors.primary).lighten(1.2).desaturate(0.8).isDark()

  const bg_color = color(theme.colors.primary).darken(0.3).rgb().string()
  const txt_color = is_bg_txt_dark ?
    color(theme.colors.primary).white(300).rgb().string() :
    color(theme.colors.primary).lighten(1.2).desaturate(0.8).rgb().string()

  const styles = StyleSheet.create({
    iconContainer: {
      padding: 5,
      flex: 1,
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'flex-start' // if you want to fill rows left to right
    },
    iconShortcutItem: {
      backgroundColor: bg_color,
      borderRadius: 5,
      margin: 6,
      paddingVertical: 20,
      paddingHorizontal: 14,
      alignItems: 'center',
      flex: 1,
      width: 'auto',
      alignSelf: 'stretch',
      minWidth: Style.DEVICE_WIDTH / 4.5
    },
    iconShortcut: {
      alignItems: 'center'
    },
    icon: {
      color: txt_color
    },
    iconTitle: {
      color: txt_color,
      fontSize: 13,
      textAlign: 'center',
      paddingTop: 10,
      alignSelf: 'center'
    },
    textSection: {
      color: color(theme.colors.primary).darken(0.1).rgb().string(),
      fontSize: 14,
      paddingTop: 30,
      paddingBottom: 5,
      paddingHorizontal: 15,
      fontWeight: 'bold'
    }
  })

  return (
    <View>
      <Text style={styles.textSection}>ATALHOS</Text>

      <View style={styles.iconContainer}>
        {shortcuts.filter(i => i !== false).map((item: any) => (
          <TouchableOpacity onPress={() => navigation.navigate(item.route)} key={item.route} style={styles.iconShortcutItem}>
            <View style={styles.iconShortcut}>
              {item.fa ?
                <FontAwesome5 name={item.icon} size={30} style={styles.icon}/> :
                <Ionicons name={item.icon} size={30} style={styles.icon}/>}

              <Text style={styles.iconTitle}>{item.title}</Text>
            </View>
          </TouchableOpacity>
        ))}
      </View>

      <Button
        mode="outlined"
        icon="plus"
        style={{ borderColor: 'white', marginHorizontal: 14 }}
        onPress={navigation.openDrawer}
      >
        Ver mais...
      </Button>

      <Spacer size={25}/>
    </View>
  )
}

export default HomeShortcuts