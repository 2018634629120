import {useIsFocused} from '@react-navigation/native'
import * as React from 'react'
import {SafeAreaView} from 'react-native-safe-area-context'
import {AclRender} from '../../../components/acl/components'
import ClassifiedForm from '../components/ClassifiedForm'

const ClassifiedNewScreen = () => {
  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      <ClassifiedForm data={null}/>
    </SafeAreaView>
  )
}

export default function (props: any) {
  const isFocused = useIsFocused()

  if (!isFocused) return null

  return (
    <AclRender required={['classified.add_classified']}>
      <ClassifiedNewScreen/>
    </AclRender>
  )
}
