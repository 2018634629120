import {useNavigation} from '@react-navigation/native'
import {Formik} from 'formik'
import React from 'react'
import {Button} from 'react-native-paper'
import {SafeAreaView} from 'react-native-safe-area-context'
import {useMutation} from 'react-query'
import * as Yup from 'yup'
import {FF, FormContainer, FormikInput} from '../../../components/form/FormInputs'
import Spacer from '../../../components/Spacer'
import {RootDrawerScreenProps} from '../../../navigation/types'
import {postChangePassword} from '../queries'

function ChangePasswordScreen() {
  const navigation = useNavigation<RootDrawerScreenProps<'ChangePasswordScreen'>['navigation']>()

  const mutation = useMutation((variables: any) => postChangePassword(variables), {
    onSuccess: () => {
      navigation.navigate('HomeScreen')
    }
  })

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      <Formik
        initialValues={{
          current_password: '',
          new_password: '',
          new_password_confirmation: ''
        }}
        validationSchema={Yup.object().shape({
          current_password: Yup.string().required(),
          new_password: Yup.string().required(),
          new_password_confirmation: Yup.string().required()
        })}
        validate={(values) => {
          let errors: any = {}
          if (values.new_password !== values.new_password_confirmation) errors.new_password_confirmation = 'As senhas não conferem'
          return errors
        }}

        onSubmit={(values) => {
          mutation.mutate(values)
        }}
      >
        {({ submitForm }) => {
          return (
            <FormContainer>
              <FF label={'Senha atual'} name="current_password" secureTextEntry={true} component={FormikInput}/>
              <FF label={'Nova senha'} name="new_password" secureTextEntry={true} component={FormikInput}/>
              <FF label={'Confirmação nova senha'} secureTextEntry={true} name="new_password_confirmation" component={FormikInput}/>

              <Spacer size={40}/>

              <Button icon="content-save" mode="contained" loading={mutation.isLoading} onPress={() => submitForm()}>Enviar</Button>
            </FormContainer>
          )
        }}
      </Formik>
    </SafeAreaView>
  )
}

export default ChangePasswordScreen
