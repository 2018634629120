import * as React from 'react'
import {ActivityIndicator, FlatList, FlatListProps, StyleSheet, View} from 'react-native'

const styles = StyleSheet.create({
  list: {
    flex: 1
  },
  listContent: {
    paddingHorizontal: 8,
    paddingVertical: 14
  },
  loading: {
    alignSelf: 'center',
    marginVertical: 20
  }
})

type Props = <T>(props: FlatListProps<T>) => React.ReactElement<FlatListProps<T>>

const AppFlatList: Props = (props) => {
  return (
    <FlatList
      style={styles.list}
      contentContainerStyle={styles.listContent}
      ListFooterComponent={() => {
        if (!props.refreshing) return null
        return (
          <View style={styles.loading}>
            <ActivityIndicator/>
          </View>
        )
      }}
      onEndReachedThreshold={0.2}
      {...props}
    />
  )
}

export default AppFlatList