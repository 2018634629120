import {useFocusEffect, useIsFocused, useRoute} from '@react-navigation/native'
import * as React from 'react'
import {SafeAreaView} from 'react-native-safe-area-context'
import {useQuery} from 'react-query'
import ActivityLoading from '../../../components/loading'
import {RootDrawerScreenProps} from '../../../navigation/types'
import {qs} from '../../queries'
import ConstructionForm from '../components/ConstructionForm'

const ConstructionDetailScreen = () => {
  const route = useRoute<RootDrawerScreenProps<'ConstructionDetailScreen'>['route']>()

  let item_id = route.params?.item_id

  const { data, isFetching, refetch, remove } = useQuery(
    [qs.constructions.get_key, item_id],
    () => qs.constructions.get(item_id),
    { enabled: false }
  )

  useFocusEffect(
    React.useCallback(() => {
      refetch()
      return () => { remove() }
    }, [item_id])
  )

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      {
        !isFetching && data?.id ?
          <React.Fragment>
            <ConstructionForm data={data}/>
          </React.Fragment> :
          <ActivityLoading visible={isFetching}/>
      }
    </SafeAreaView>
  )
}

export default function (props: any) {
  const isFocused = useIsFocused()

  if (!isFocused) return null

  return (
    <ConstructionDetailScreen/>
  )
}
