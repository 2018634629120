import {useFocusEffect, useIsFocused, useRoute} from '@react-navigation/native'
import moment from 'moment'
import * as React from 'react'
import {ScrollView, StyleSheet, View} from 'react-native'
import {Button, Headline, Paragraph, Subheading, Text} from 'react-native-paper'
import {SafeAreaView} from 'react-native-safe-area-context'
import {useMutation, useQuery} from 'react-query'
import AppTabView from '../../../components/layout/AppTabView'
import ActivityLoading from '../../../components/loading'
import {fullNativeZoomJoin} from '../../../nativeModules/AwesomeZoomSDK'
import {RootDrawerScreenProps} from '../../../navigation/types'
import {EventLogger} from '../../../services/EventLogger'
import Style from '../../../styles/Style'
import {theme_app} from '../../../styles/theme'
import {qs} from '../../queries'
import {QuestionTab} from '../components/MeetingSubscriptionTabs'

const MeetingSubscriptionDetailScreen = () => {
  const route = useRoute<RootDrawerScreenProps<'MeetingSubscriptionDetailScreen'>['route']>()
  const item_id = route.params?.item_id

  const { data, refetch, remove, isFetching } = useQuery(
    [qs.meeting_subs.get_key, item_id],
    () => qs.meeting_subs.get(item_id),
    { enabled: false }
  )

  const update = useMutation(qs.meeting_subs.patch)

  const meeting_data = data?.meeting
  const zoom_is_enabled = meeting_data?.use_native_zoom && data?.use_native_zoom

  useFocusEffect(
    React.useCallback(() => {
      refetch()

      EventLogger.logEvent('SeeMeetingSubscriptionDetail', 'screen', item_id, 'meeting_subscription')

      update.mutate({ id: item_id })

      return () => {
        remove()
      }
    }, [item_id])
  )

  const DetailsTab = () => {
    if (meeting_data) {
      return (
        <View style={{ flex: 1 }}>
          <View style={{ backgroundColor: '#eeeeee', marginBottom: 10 }}>
            <View>
              <Headline>
                Assembleia - {meeting_data.start_date &&
                moment(meeting_data.start_date).format('DD/MM HH:mm')}
              </Headline>
              <Text style={{ marginVertical: 6 }}>{meeting_data.name}</Text>
            </View>
          </View>

          <View>
            <Text style={styles.title}>Como funciona?</Text>
            <Text>
              O Condo Id agora conta com uma nova funcionalidade, a Assembleia.
            </Text>
            <Text style={{ paddingBottom: 5 }}></Text>

            <Text style={{ paddingBottom: 20 }}> </Text>
          </View>
        </View>
      )
    }

    return (<></>)
  }

  const QuestTab = () => (<QuestionTab meeting_subscription_id={item_id}/>)

  const ZoomNativeTab = () => {
    const joinNative = () => {
      if (data && meeting_data) {
        fullNativeZoomJoin(`(${data.person_name_label}`, meeting_data.zoom_id, meeting_data.zoom_raw_pwd)
      }
    }

    return (
      <ScrollView style={{ padding: 25 }}>
        <Subheading style={{ fontWeight: 'bold', textAlign: 'center', paddingBottom: 25, color: theme_app().colors.primary }}>
          ATENÇÃO: Antes de acessar a sala ao vivo, confira o vídeo na aba "Detalhes" para conhecer mais sobre a assembleia.
        </Subheading>

        <Paragraph style={{ fontWeight: 'bold', textAlign: 'center', paddingBottom: 10 }}>
          Para acessar a assembleia ao vivo, precisamos verificar os passos abaixo:
        </Paragraph>

        <Paragraph style={{ textAlign: 'center', paddingBottom: 5 }}>
          1º - É necessário ter uma boa conexão de internet
        </Paragraph>
        <Paragraph style={{ textAlign: 'center', paddingBottom: 5 }}>
          2º - Verifique se você deu permissão para o app acessar o seu microfone e câmera
        </Paragraph>
        <Paragraph style={{ textAlign: 'center', paddingBottom: 35 }}>
          3º - Após acessar, aguarde para ser liberado!
        </Paragraph>

        <Button icon="video" mode="outlined" onPress={joinNative}>
          Acessar sala ao vivo
        </Button>

        <Text style={{ paddingBottom: 200 }}/>
      </ScrollView>
    )
  }

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>

      {
        !isFetching && data?.meeting ?
          <React.Fragment>
            {zoom_is_enabled ?
              <AppTabView scenes={[
                { key: 'live', title: 'Ao Vivo', scene: ZoomNativeTab },
                { key: 'questions', title: 'Pautas', scene: QuestTab }
              ]}/> :
              <AppTabView scenes={[
                { key: 'questions', title: 'Pautas', scene: QuestTab }
              ]}/>
            }
          </React.Fragment>
          :
          <ActivityLoading visible={true}/>
      }
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  title: {
    marginTop: 6,
    marginBottom: 10,
    fontWeight: 'bold'
  },
  body: {
    lineHeight: 22,
    marginBottom: 40
  },

  wrapper: {},
  slide: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'transparent'
  },
  text: {
    color: '#fff',
    fontSize: 30,
    fontWeight: 'bold'
  },
  image: {
    width: Style.DEVICE_WIDTH,
    flex: 1
  },
  paginationStyle: {
    width: Style.DEVICE_WIDTH,
    backgroundColor: theme_app().colors.primary,
    paddingVertical: 10,
    paddingLeft: 6,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  buttonPaginationStyle: {
    flexDirection: 'row'
  },
  paginationText: {
    fontSize: 15,
    color: '#b3c7f9'
  },
  PautaText: {
    fontSize: 12,
    color: '#b3c7f9'
  },
  buttonWrapper: {
    backgroundColor: '#e0e0e0',
    paddingHorizontal: 5,
    paddingVertical: 5,
    width: 40,
    height: 40,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 45 / 2
  }
})

export default function (props: any) {
  const isFocused = useIsFocused()

  if (!isFocused) return null

  return (
    <MeetingSubscriptionDetailScreen/>
  )
}
