import AsyncStorage from '@react-native-async-storage/async-storage'
import moment from 'moment'
import axios from 'axios'
import enviroment from '../enviroment'
import {EventLogger} from './EventLogger'
import {sdkSentry} from './sentry'
import authService from './authService'

const CTAS = [
  {
    'messageId': 'asdsaddsd',
    'templateId': 'standard',
    'template': {
      'title': 'Novo recurso Boletos no app 🤑🙌🎉',
      'image': 'https://c8.alamy.com/comp/PKNKJB/abstract-modern-background-from-colorful-garland-of-flags-of-triangular-shape-fest-celebration-concept-banner-design-PKNKJB.jpg',
      'image2': null,
      'body': [
        {
          'elementType': 'TEXT',
          'content': 'Agora é possível consultar e gerar segunda via de boletos da taxa de condomínio, reservas de espaços, compras de controles e etc...'
        },
        {
          'elementType': 'BOLD',
          'content': 'Procure a administração para verificar a disponibilidade!'
        },
      ],
      'ctas': [
        {
          'action': 'DISMISS_ALERT',
          'ctaType': 'OUTLINED',
          'copy': 'Ok, entendi! ✌',
          'color': '',
        },
      ],
    },
    'attributes': {
      'display': {
        'frequency': 'always', // once, times, always, daily, monthly
        'showQtd': 2,
        'intervalUnit': 'minutes',
        'intervalQtd': 1,
      },
      'triggers': [
        {
          'triggerType': 'SeeScreen',
          'triggerName': 'HomeScreen'
        }
      ]
    }
  },
  {
    'messageId': 'asdsad',
    'templateId': 'standard',
    'template': {
      'title': 'Está em perigo, muita calma kkkkkkkk',
      'body': [
        {
          'elementType': 'TEXT',
          'content': 'Agora é possível consultar e gerar segunda via de boletos da taxa de condomínio, reservas de espaços, compras de controles e etc...'
        },
        {
          'elementType': 'BOLD',
          'content': 'Procure a administração para verificar a disponibilidade!'
        },
      ],
      'ctas': [
        {
          'action': 'DISMISS_ALERT',
          'ctaType': 'OUTLINED',
          'copy': 'Ok, entendi! ✌',
          'color': '',
        },
      ],
    },
    'attributes': {
      'display': {
        'frequency': 'always', // once, times, always, daily
        'showQtd': 2
      },
      'triggers': [
        {
          'triggerType': 'SeeScreen',
          'triggerName': 'PanicButton'
        }
      ]
    }
  }
]

AsyncStorage.setItem('messages', JSON.stringify([]))

export default class InMessagingManager {
  static dropDown

  static set(dropDown) {
    this.dropDown = dropDown
  }

  static get() {
    return this.dropDown
  }

  static async getMessages() {
    let headers = {}
    const token = await authService.getToken()

    if (token && token !== '' && token !== null && typeof (token) === 'string') {
      headers = {'Authorization': `Token ${token}`}
    }

    const APP_ENV = await enviroment()

    return axios({
      method: 'get',
      url: APP_ENV.apiUrl + 'public/in-app',
      headers: headers
    })
      .then((res) => {
        AsyncStorage.setItem('messages', JSON.stringify(res.data))
      })
      .catch((error) => {
        sdkSentry.sendSentry(error, null)
      })
  }

  static show(trigger, param) {
    try {
      this.show_message(trigger, param)
        .then()
        .catch((a) => console.error(a))
    } catch (e) {
      sdkSentry.sendSentry(e, null)
    }
  }

  static async show_message(trigger, trigger_name) {
    try {
      let messages = JSON.parse(await AsyncStorage.getItem('messages'))
      let messages_history = JSON.parse(await AsyncStorage.getItem('messages_history'))
      messages_history = Array.isArray(messages_history) ? messages_history : []
      let now = moment().toISOString()

      if (Array.isArray(messages)) {
        for (let m of messages) {
          let {frequency, showQtd, intervalQtd, intervalUnit} = m.attributes.display

          let trigger_types = m.attributes.triggers.map(i => i.triggerType)
          let trigger_names = m.attributes.triggers.map(i => i.triggerName)
          let triggered = trigger_types.indexOf(trigger) > -1 && trigger_names.indexOf(trigger_name) > -1

          if (triggered) {
            let showed = false
            let messages_showed = messages_history.filter(i => i.messageId === m.messageId).sort((a, b) => moment(a.timestamp).isBefore(b.timestamp))
            let qtd_total = messages_showed.length

            if (qtd_total > 0 && intervalQtd > 0) {
              if (moment(messages_showed[0].timestamp).isAfter(moment().subtract(intervalQtd, intervalUnit))) {
                return
              }
            }

            switch (frequency) {
              case 'always':
                this.dropDown.show(m)
                showed = true
                break

              case 'once':
                if (qtd_total === 0) {
                  this.dropDown.show(m)
                  showed = true
                }
                break

              case 'times':
                if (qtd_total < showQtd) {
                  this.dropDown.show(m)
                  showed = true
                }
                break

              case 'daily':
                let qtd_today = messages_history.filter(i => i.messageId === m.messageId && moment().isSame(i.timestamp, 'day')).length
                if (qtd_today < showQtd) {
                  this.dropDown.show(m)
                  showed = true
                }
                break

              case 'monthly':
                let qtd_month = messages_history.filter(i => i.messageId === m.messageId && moment().isSame(i.timestamp, 'month')).length
                if (qtd_month < showQtd) {
                  this.dropDown.show(m)
                  showed = true
                }
                break
            }

            if (showed) {
              messages_history.push({messageId: m.messageId, timestamp: now})
              AsyncStorage.setItem('messages_history', JSON.stringify(messages_history))

              const token = await authService.getToken()
              if (token && token !== '' && token !== null && typeof (token) === 'string') {
                EventLogger.logEvent('ShowInAppMessaging', 'action', m.messageId)
              }
            }

            showed = false
          }
        }
      }

    } catch (e) {
      sdkSentry.sendSentry(e, null)
    }
  }
}
