import {useNavigation} from '@react-navigation/native'
import {Formik} from 'formik'
import React from 'react'
import {Text} from 'react-native'
import {Button} from 'react-native-paper'
import {SafeAreaView} from 'react-native-safe-area-context'
import {useMutation} from 'react-query'
import * as Yup from 'yup'
import {FF, FormContainer, FormikInput} from '../../../components/form/FormInputs'
import Spacer from '../../../components/Spacer'
import {RootDrawerScreenProps} from '../../../navigation/types'
import {postResetPassword} from '../queries'

function ResetPasswordScreen() {
  const navigation = useNavigation<RootDrawerScreenProps<'ResetPasswordScreen'>['navigation']>()

  const mutation = useMutation((variables: any) => postResetPassword(variables), {
    onSuccess: () => {
      navigation.navigate('Login')
    }
  })

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      <Formik
        initialValues={{
          email: ''
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().required()
        })}
        onSubmit={(values) => {
          mutation.mutate(values)
        }}
      >
        {({ values, errors, submitForm, setFieldValue }) => {
          return (
            <FormContainer>
              <Text style={{ marginTop: 6, marginBottom: 35 }}>
                Informe o seu endereço de email para que possamos enviar as instruções para resetar a sua senha!
              </Text>

              <FF
                label={'Seu e-mail'}
                name="email"
                autoCompleteType="email"
                keyboardType="email-address"
                textContentType="emailAddress"
                component={FormikInput}
              />

              <Spacer size={40}/>

              <Button
                icon="content-save" mode="contained" loading={mutation.isLoading} disabled={mutation.isLoading}
                onPress={() => submitForm()}
              >
                Enviar
              </Button>
            </FormContainer>
          )
        }}
      </Formik>
    </SafeAreaView>
  )
}

export default ResetPasswordScreen
