import {useFocusEffect, useIsFocused, useRoute} from '@react-navigation/native'
import React from 'react'
import {SafeAreaView} from 'react-native-safe-area-context'
import {useQuery} from 'react-query'
import ActivityLoading from '../../../components/loading'
import {RootDrawerScreenProps} from '../../../navigation/types'
import {EventLogger} from '../../../services/EventLogger'
import {qs} from '../../queries'
import BookForm from '../components/BookForm'

function BookNewScreen() {
  const route = useRoute<RootDrawerScreenProps<'BookNewScreen'>['route']>()

  let item_id = route.params?.item_id

  const { data, isFetching, refetch, remove } = useQuery(
    [qs.spaces.get_key, item_id],
    () => qs.spaces.get(item_id),
    { enabled: false }
  )

  useFocusEffect(
    React.useCallback(() => {
      refetch()
      EventLogger.logEvent('SeeBookNew', 'screen', item_id, 'book')
      return () => {
        remove()
      }
    }, [item_id])
  )

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      {
        !isFetching && data?.id ?
          <BookForm initialValues={null} space={data}/>
          :
          <ActivityLoading visible={isFetching}/>
      }
    </SafeAreaView>
  )
}

export default function (props: any) {
  const isFocused = useIsFocused()

  if (!isFocused) return null

  return (
    <BookNewScreen/>
  )
}
