import {useIsFocused, useNavigation} from '@react-navigation/native'
import {FieldArray, Formik, getIn, useFormikContext} from 'formik'
import moment from 'moment'
import * as React from 'react'
import {View} from 'react-native'
import {Button, Colors, Divider, HelperText, IconButton} from 'react-native-paper'
import {DatePickerModal} from 'react-native-paper-dates'
import {SafeAreaView} from 'react-native-safe-area-context'
import {useMutation} from 'react-query'
import * as Yup from 'yup'
import DialogSelect from '../../../../components/form/DialogSelect'
import {FormikDatePicker, FormikTimePicker} from '../../../../components/form/FormikInputs'
import {FF, FormContainer, FormikInput, FormikRadio} from '../../../../components/form/FormInputs'
import TouchInput from '../../../../components/form/TouchInput'
import UnitSelect from '../../../../components/form/UnitSelect'
import ModalButton from '../../../../components/layout/ModalButton'
import Spacer from '../../../../components/Spacer'
import TermsModal from '../../../../components/TermsModal'
import {PersonDocTypeEnum} from '../../../../constants/app-enums'
import {GENDERS, PERSON_DOC_TYPES} from '../../../../constants/fields'
import {RootDrawerScreenProps} from '../../../../navigation/types'
import ToasterManager from '../../../../services/ToasterManager'
import Style from '../../../../styles/Style'
import {dateFormat} from '../../../../utils'
import {qs} from '../../../queries'

type Props = {
  label: string
  start_date_field: string
  end_date_field: string
}

const PersonStruct = {
  name: '',
  doc_secondary: null,
  doc_type: PersonDocTypeEnum.cpf,
  rg: null,
  rg_issuer: null,
  birthday: null,
  mobile1: null,
  phone: null,
  email: null,
  address: null,
  district: null,
  city: null,
  state: null,
  cep: null,
  country: null
}

function ReadMeExampleRange(props: Props) {
  const { values, setFieldValue, errors } = useFormikContext<any>()

  const fields_error = getIn(errors, props.start_date_field) || getIn(errors, props.end_date_field)
  const start_value = getIn(values, props.start_date_field)
  const end_value = getIn(values, props.end_date_field)

  const [open, setOpen] = React.useState(false)

  const onDismiss = React.useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const onConfirm = React.useCallback(
    ({ startDate, endDate }) => {
      setOpen(false)

      setFieldValue(props.start_date_field, moment(startDate).format('YYYY-MM-DD'))
      setFieldValue(props.end_date_field, moment(endDate).format('YYYY-MM-DD'))
    },
    [setOpen]
  )

  const getValueFriendly = () => {
    if (start_value && end_value) {
      return `${dateFormat(start_value)} - ${dateFormat(end_value)}`
    }

    return ''
  }

  return (
    <View style={Style.form.fieldContainer}>
      <TouchInput
        label={props.label}
        mode="outlined"
        value={getValueFriendly()}
        showPicker={() => setOpen(true)}
        error={!!fields_error}
      />

      {
        !!fields_error &&
        <HelperText type="error" visible={!!fields_error}>
          {fields_error}
        </HelperText>
      }

      <DatePickerModal
        locale="pt"
        mode="range"
        visible={open}
        onDismiss={onDismiss}
        startDate={start_value ? moment(start_value).toDate() : undefined}
        endDate={end_value ? moment(end_value).toDate() : undefined}
        onConfirm={onConfirm}
        validRange={{
          startDate: new Date()  // optional
          // endDate: new Date(), // optional
          // disabledDates: [new Date()] // optional
        }}
        // onChange={} // same props as onConfirm but triggered without confirmed by user
        saveLabel="Ok" // optional
        // uppercase={false} // optional, default is true
        // label="Select period" // optional
        startLabel="Check-in" // optional
        endLabel="Check-out" // optional
        // animationType="slide" // optional, default is slide on ios/android and none on web
      />
    </View>
  )
}

const ReservationNewScreen = () => {
  const navigation = useNavigation<RootDrawerScreenProps<'ReservationNewScreen'>['navigation']>()

  const create = useMutation((variables: any) => qs.reservations.post(variables), {
    onSuccess: () => {
      ToasterManager.success('Solicitação de Hospedagem criada com sucesso!')
      navigation.navigate('ReservationListScreen')
    }
  })

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      <FormContainer>
        <Formik
          initialValues={{
            unit: '',
            check_in_out_dates: {
              in_date: '',
              out_date: ''
            },
            planned_arrival: '',
            planned_departure: '',
            observations: '',
            is_walk_in: false,

            self_main_guest: false,
            main_guest_data: {
              person: PersonStruct
            },

            guests: [],

            accept_terms: false
          }}
          validationSchema={
            Yup.lazy((curr_values: any) => {
              let __self_main_guest = curr_values.self_main_guest

              return Yup.object().shape({
                unit: Yup.string().required(),
                check_in_out_dates: Yup.object().shape({
                  in_date: Yup.string().required(),
                  out_date: Yup.string().required()
                }),
                planned_arrival: Yup.string().required(),
                planned_departure: Yup.string().required(),
                self_main_guest: Yup.boolean().required(),

                main_guest_data: Yup.object().shape({
                  person: Yup.object().shape({
                    name: __self_main_guest ? Yup.string() : Yup.string().required()
                    // @ts-ignore
                    // doc_secondary: __self_main_guest ? Yup.string() : Yup.string().required().validCPF(),
                    // doc_type: __self_main_guest ? Yup.string() : Yup.string().required(),
                    // rg: __self_main_guest ? Yup.string() : Yup.string().required().min(4, 'Digite corretamente!'),
                    // birthday: __self_main_guest ? Yup.string() : Yup.date().required().max(
                    //   moment().subtract(17, 'years').toDate(),
                    //   'Data de nascimento inválida. (Precisa ser maior de 18 anos)'
                    // ),
                    // gender: __self_main_guest ? Yup.string() : Yup.string().required(),
                    // mobile1: __self_main_guest ? Yup.string() : Yup.string().required(),
                    // email: __self_main_guest ? Yup.string() : Yup.string().required().email(),
                    // address: __self_main_guest ? Yup.string() : Yup.string().required().min(4, 'Digite corretamente!'),
                    // district: __self_main_guest ? Yup.string() : Yup.string().required().min(4, 'Digite corretamente!'),
                    // city: __self_main_guest ? Yup.string() : Yup.string().required().min(4, 'Digite corretamente!'),
                    // @ts-ignore
                    // cep: __self_main_guest ? Yup.string() : Yup.string().required().validCEP()
                  })
                }),

                // accept_terms: Yup.boolean().oneOf([true], 'Por favor aceite os termos.')
              })

            })
          }
          onSubmit={(values) => {
            let _values = JSON.parse(JSON.stringify(values))
            let person_data: any = _values?.main_guest_data?.person

            if (person_data.name) {

              for (let o of Object.keys(person_data)) {

                if (!person_data[o]) {
                  person_data[o] = null
                }

              }

              _values.main_guest_data.person = person_data
            }

            create.mutate(_values)
          }}
        >
          {({ values, errors, submitForm, ...formikBag }) => {

            return (
              <React.Fragment>
                <UnitSelect/>

                <ReadMeExampleRange
                  label="Check-in / Check-out"
                  start_date_field="check_in_out_dates.in_date"
                  end_date_field="check_in_out_dates.out_date"
                />

                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                  <View style={{ flex: 1, paddingRight: 7 }}>
                    <FormikTimePicker label={'Previsão de chegada'} name="planned_arrival"/>
                  </View>
                  <View style={{ flex: 1, paddingLeft: 7 }}>
                    <FormikTimePicker label={'Previsão de saída'} name="planned_departure"/>
                  </View>
                </View>

                <FF label={'Observações'} name="observations" multiline={true} component={FormikInput}/>

                <Spacer size={30}/>

                <FF label={'Sou o TITULAR da reserva'} name="self_main_guest" component={FormikRadio}/>

                {!values.self_main_guest &&
                  <ModalButton button_label="Dados do Titular" title="Digite os dados do titular">
                    <FF label={'Nome'} name="main_guest_data.person.name" component={FormikInput}/>


                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                      <View style={{ flex: 1, paddingRight: 7 }}>
                        <FF label={'CPF'} name="main_guest_data.person.doc_secondary" component={FormikInput}/>
                      </View>
                      <View style={{ flex: 1, paddingLeft: 7 }}>
                        <FF label={'Tipo documento'} name="main_guest_data.person.doc_type" component={DialogSelect}
                            local_options={PERSON_DOC_TYPES}/>
                      </View>
                    </View>

                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                      <View style={{ flex: 1 }}>
                        <FF label={'RG'} name="main_guest_data.person.rg" component={FormikInput}/>
                      </View>
                      <View style={{ flex: 1, paddingHorizontal: 7 }}>
                        <FormikDatePicker label={'Data de Nascimento'} name="main_guest_data.person.birthday"/>
                      </View>
                      <View style={{ flex: 1 }}>
                        <FF label={'Sexo'} name="main_guest_data.person.gender" component={DialogSelect} local_options={GENDERS}/>
                      </View>
                    </View>

                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                      <View style={{ flex: 1, paddingRight: 7 }}>
                        <FF label={'Celular'} name="main_guest_data.person.mobile1" component={FormikInput}/>
                      </View>
                      <View style={{ flex: 1, paddingLeft: 7 }}>
                        <FF label={'Telefone'} name="main_guest_data.person.phone" component={FormikInput}/>
                      </View>
                    </View>

                    <FF label={'E-mail'} name="main_guest_data.person.email" component={FormikInput}/>

                    <Spacer size={20}/>

                    <FF label={'Endereço'} name="main_guest_data.person.address" component={FormikInput}/>

                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                      <View style={{ flex: 1, paddingRight: 7 }}>
                        <FF label={'Bairro'} name="main_guest_data.person.district" component={FormikInput}/>
                      </View>
                      <View style={{ flex: 1, paddingLeft: 7 }}>
                        <FF label={'Cidade'} name="main_guest_data.person.city" component={FormikInput}/>
                      </View>
                    </View>

                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                      <View style={{ flex: 1, paddingRight: 7 }}>
                        <FF label={'Estado'} name="main_guest_data.person.state" component={FormikInput}/>
                      </View>
                      <View style={{ flex: 1, paddingLeft: 7 }}>
                        <FF label={'Código Postal'} name="main_guest_data.person.cep" component={FormikInput}/>
                      </View>
                    </View>

                    <FF label={'País'} name="main_guest_data.person.country" component={FormikInput}/>
                  </ModalButton>}

                {!!errors.main_guest_data &&
                  <HelperText type="error" visible={true}>
                    Verifique os dados do Titular
                    <Spacer size={30}/>
                  </HelperText>}

                <ModalButton button_label="Acompanhantes" title="Adicione e digite os dados dos acompanhantes">
                  <FieldArray
                    name="guests"
                    render={arrayHelpers => (
                      <React.Fragment>
                        <View style={{ paddingBottom: 20 }}>
                          <Button
                            icon="plus" mode="outlined"
                            onPress={() => arrayHelpers.push({
                              person: {
                                name: '',
                                doc_secondary: '',
                                birthday: ''
                              }
                            })}
                          >
                            Adicionar acompanhante
                          </Button>
                        </View>

                        {values.guests.map((item: any, index: number) => (
                          <View key={index}>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 20 }}>
                              <View style={{ flex: 4, paddingRight: 7 }}>
                                <FF
                                  label={'Nome'}
                                  name={`guests.${index}.person.name`}
                                  component={FormikInput}
                                />
                              </View>
                              <View style={{ flex: 3, paddingLeft: 7 }}>
                                <FF label={'Documento'} name={`guests.${index}.person.doc_secondary`} component={FormikInput}/>
                              </View>
                              <View style={{ flex: 3, paddingLeft: 7 }}>
                                <FormikDatePicker label={'Dt. Nascimento'} name={`guests.${index}.person.birthday`}/>
                              </View>
                              <View style={{ flex: 1, paddingLeft: 5 }}>
                                <IconButton
                                  color={Colors.red500} icon="delete-outline"
                                  onPress={() => arrayHelpers.remove(index)}
                                />
                              </View>
                            </View>
                            <Divider/>
                            <Spacer size={20}/>
                          </View>
                        ))}
                      </React.Fragment>
                    )}
                  />
                </ModalButton>

                <Spacer size={20}/>

                {/*<FF label={'Li e estou de acordo com os termos'} name="accept_terms" component={FormikRadio}/>*/}
                {/*<TermsModal title="Termos" terms={'Termos aqui.....'}/>*/}

                <Spacer size={40}/>

                <Button
                  icon="content-save" mode="contained"
                  loading={create.isLoading} disabled={create.isLoading}
                  onPress={() => submitForm()}
                >
                  Salvar
                </Button>
              </React.Fragment>
            )
          }}
        </Formik>
      </FormContainer>
    </SafeAreaView>
  )
}

export default function (props: any) {
  const isFocused = useIsFocused()

  if (!isFocused) return null

  return (
    <ReservationNewScreen/>
  )
}
