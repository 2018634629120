import {FontAwesome} from '@expo/vector-icons'
import {useFocusEffect, useNavigation} from '@react-navigation/native'
import moment from 'moment'
import React from 'react'
import {TouchableOpacity, View} from 'react-native'
import {Caption, Title} from 'react-native-paper'
import {SafeAreaView} from 'react-native-safe-area-context'
import {useQuery} from 'react-query'
import {AclRender} from '../../../components/acl/components'
import AppFlatList from '../../../components/layout/AppFlatList'
import AppListCard from '../../../components/layout/AppListCard'
import NotPermitted from '../../../components/NotPermitted'
import PlaceHolder from '../../../components/placeholder'
import {RootDrawerScreenProps} from '../../../navigation/types'
import {useAppTheme} from '../../../styles/theme'
import {qs} from '../../queries'

function NoticeListScreen() {
  const theme = useAppTheme()
  const navigation = useNavigation<RootDrawerScreenProps<'NoticeListScreen'>['navigation']>()

  const { data, isFetching, refetch, remove } = useQuery(qs.notices.all_key, () => qs.notices.all(), { enabled: false })

  useFocusEffect(
    React.useCallback(() => {
      refetch()

      return () => {
        remove()
      }
    }, [])
  )

  const renderItem = ({ item }: any) => (
    <React.Fragment>
      <TouchableOpacity onPress={() => navigation.navigate('NoticeDetailScreen', { item_id: item.id })}>
        <AppListCard>
          <View style={{ flex: 3 }}>

            <Title>{item.name}</Title>

            <Caption style={{ fontWeight: 'bold' }}>
              <FontAwesome name="calendar-o" size={12} color={theme.colors.primary}/>{' '}
              {moment(item.created_at).format('DD/MM/YYYY - HH:mm')}
            </Caption>

          </View>
        </AppListCard>
      </TouchableOpacity>
    </React.Fragment>
  )

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      <AppFlatList
        data={data}
        renderItem={renderItem}
        keyExtractor={item => item.id + ''}
        refreshing={isFetching}
        onRefresh={refetch}
        ListEmptyComponent={() => (
          <PlaceHolder
            visible={true}
            emoji={'📰'}
            description={'Em breve os comunicados estarão aqui. Uma forma simples e rápida para a administração comunicar.'}
          />
        )}
      />
    </SafeAreaView>
  )
}

export default function (props: any) {
  return (
    <AclRender required={['notice.view_notice']} fallback={<NotPermitted/>}>
      <NoticeListScreen/>
    </AclRender>
  )
}
