import {Field} from 'formik'
import React from 'react'
import {useQuery} from 'react-query'
import {qs} from '../../features/queries'
import DialogSelect from './DialogSelect'

export const AccessReasonSelect = React.memo((props: any) => {
  const { data, isFetching, refetch } = useQuery(qs.access_reasons.all_key, () => qs.access_reasons.all())

  return (
    <Field
      label={props.label ?? 'Tipo'}
      name={props.name ?? 'access_reason'}
      component={DialogSelect}
      data={data}
      id_option="id"
      label_option="name"
      onChange={props.onChange}
    />
  )
})
