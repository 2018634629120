import {useFocusEffect, useNavigation} from '@react-navigation/native'
import moment from 'moment'
import React from 'react'
import {TouchableOpacity, View} from 'react-native'
import {Caption, Title} from 'react-native-paper'
import {SafeAreaView} from 'react-native-safe-area-context'
import {useInfiniteQuery} from 'react-query'
import {AclRender} from '../../../components/acl/components'
import AppFab from '../../../components/layout/AppFab'
import AppFlatList from '../../../components/layout/AppFlatList'
import AppListCard from '../../../components/layout/AppListCard'
import PlaceHolder from '../../../components/placeholder'
import {TICKET_STATUS, TICKET_TYPES} from '../../../constants/fields'
import {RootDrawerScreenProps} from '../../../navigation/types'
import {theme_app} from '../../../styles/theme'
import {handleGetNextPageParam} from '../../../utils'
import {qs} from '../../queries'

function TicketListScreen() {
  const navigation = useNavigation<RootDrawerScreenProps<'TicketListScreen'>['navigation']>()

  const {
    data,
    hasNextPage,
    isFetching,
    fetchNextPage,
    refetch,
    remove
  } = useInfiniteQuery(qs.tickets.all_key, async (_params) =>
      qs.tickets.all_paginated(_params.pageParam ?? 1, 15),
    {
      enabled: false,
      getNextPageParam: handleGetNextPageParam
    })

  useFocusEffect(
    React.useCallback(() => {
      refetch()

      return () => {
        remove()
      }
    }, [])
  )

  const renderItem = ({ item }: any) => (
    <React.Fragment>
      <TouchableOpacity onPress={() => navigation.navigate('TicketDetailScreen', { item_id: item.id })}>
        <AppListCard>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <View style={{ flex: 3 }}>
              <Caption>#{item.id} - {TICKET_TYPES[item.type]}</Caption>
              <Title>{item.category?.name}</Title>
              <Caption style={{ fontWeight: 'bold' }}>{moment(item.date).format('DD/MM/YYYY - HH:mm')}</Caption>
              <Caption>
                {item.description && item.description.substr(0, 80)}
              </Caption>
            </View>
            <View style={{ flex: 1, alignSelf: 'center' }}>
              <Caption style={{ textAlign: 'right', fontWeight: 'bold', color: theme_app().colors.accent }}>
                {TICKET_STATUS[item.status]}
              </Caption>
            </View>
          </View>
        </AppListCard>
      </TouchableOpacity>
    </React.Fragment>
  )

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      <AppFlatList
        data={data?.pages.map(page => page.data).flat() ?? []}
        onEndReached={() => hasNextPage && fetchNextPage()}
        renderItem={renderItem}
        keyExtractor={item => item.id + ''}
        refreshing={isFetching}
        onRefresh={refetch}
        ListEmptyComponent={() => (
          <PlaceHolder
            visible={true}
            emoji={'😎'}
            description={'Aqui você poderá solicitar informações para a administração e registrar algo que está fora do normal. E também consultar o status e resposta da administração.'}
          />
        )}
      />

      <AclRender required={['ticket.add_ticket']}>
        <AppFab icon="plus" onPress={() => navigation.navigate('TicketNewScreen')}/>
      </AclRender>
    </SafeAreaView>
  )
}

export default TicketListScreen
