import * as queryString from 'query-string'
import {GetNextPageParamFunction} from 'react-query/types/core/types'

export * from './common'
export * from './currency'
export * from './form'
export * from './formatters'
export {default as platformChecker} from './platformChecker'
export {default as currency} from './currency'
export * from './currency'
export * from './validators'

export const handleGetNextPageParam: GetNextPageParamFunction<any> = (lastPage, pages) => queryString.parse(lastPage?.next)?.page

export const buildQs = (filters: { [key: string]: any } = {}, query_strings: { [key: string]: any } = {}): string => {
  let filter_builds: any = {
    ...query_strings
  }

  if (filters && Object.keys(filters).length) {
    Object.keys(filters).forEach(key => filter_builds[`filter{${key}}`] = filters[key])
  }

  let qs: string = queryString.stringify(filter_builds, { encode: false, arrayFormat: 'bracket' })
  qs = qs.replace(/\}\[\]/g, '}')

  if (qs) {
    return `?${qs}`
  }

  return ''
}
