import React from 'react'
import {useQuery} from 'react-query'
import {qs} from '../../queries'

export const useMeQuery = () => useQuery(
  ['useMeQuery'],
  async () => {
    try {
      return qs.user.me()
    } catch (e) {
      return null
    }
  },
  {
    cacheTime: 1000 * 60 * 2,
    staleTime: 1000 * 60 * 2,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
    // refetchInterval: 1000 * 2,
    retry: false,
    retryOnMount: false
  }
)