import {useNavigation} from '@react-navigation/native'
import {Formik} from 'formik'
import * as React from 'react'
import {Button} from 'react-native-paper'
import {useMutation} from 'react-query'
import * as Yup from 'yup'
import DialogSelect from '../../../components/form/DialogSelect'
import {FF, FormContainer, FormikInput, FormikRadio} from '../../../components/form/FormInputs'
import Uploader from '../../../components/form/Uploader'
import Spacer from '../../../components/Spacer'
import TermsModal from '../../../components/TermsModal'
import {CLASSIFIED_TYPES} from '../../../constants/fields'
import {RootDrawerScreenProps} from '../../../navigation/types'
import ToasterManager from '../../../services/ToasterManager'
import {useMeQuery} from '../../Auth/hooks/useMeQuery'
import {qs} from '../../queries'

const ClassifiedForm = ({ data }: { data?: any }) => {
  const navigation = useNavigation<RootDrawerScreenProps<'ClassifiedNewScreen'>['navigation']>()

  const { data: meData } = useMeQuery()

  const terms = meData?.team?.configs?.general.classified__rules
  const classified__auto_approve = meData?.team?.configs?.general.classified__auto_approve

  const create = useMutation((variables: any) => qs.classifieds.post(variables), {
    onSuccess: () => {
      if (!classified__auto_approve) {
        ToasterManager.warn('Aguarde a administração aprovar o seu anúncio!')
      } else {
        ToasterManager.success('Anúncio publicado com sucesso!')
      }

      navigation.navigate('ClassifiedListScreen')
    }
  })

  const update = useMutation((variables: any) => qs.classifieds.patch(variables), {
    onSuccess: () => {
      ToasterManager.success('Anúncio atualizado com sucesso!')
      navigation.navigate('ClassifiedListScreen')
    }
  })

  return (
    <FormContainer>
      <Formik
        initialValues={{
          type: data?.type ?? '',
          title: data?.title ?? '',
          description: data?.description ?? '',
          phone: data?.phone ?? '',
          files_attach: [],
          accept_terms: terms ? false : true
        }}
        validationSchema={Yup.object().shape({
          type: Yup.string().required(),
          title: Yup.string().required(),
          description: Yup.string().required(),

          accept_terms: data?.id ? Yup.boolean() : Yup.boolean().oneOf([true], 'Por favor aceite os termos.')
        })}
        onSubmit={(values) => {
          if (data?.id) {
            update.mutate({
              ...values,
              id: data.id
            })
          } else {
            create.mutate({
              ...values,
              status: 2
            })
          }
        }}
      >
        {({ values, submitForm, setFieldValue }) => {
          return (
            <React.Fragment>
              <FF label={'Tipo'} name="type" component={DialogSelect} local_options={CLASSIFIED_TYPES}/>

              <FF label={'Título'} name="title" component={FormikInput}/>
              <FF label={'Descrição'} name="description" component={FormikInput}/>
              <FF label={'Whatsapp'} name="phone" component={FormikInput} multiline={true}/>

              <Spacer size={50}/>

              <Uploader
                model_id={data?.id}
                model_type="classified"
                file_type="attachments"
                only_photos={true}
                buttonIcon="attachment"
                buttonText="Anexar Fotos"
                filesUploaded={[]}
                uploadComplete={(data: any) => {
                  let tempFile: any[] = values.files_attach
                  tempFile.push(data.id)
                  setFieldValue('files_attach', tempFile)
                }}
              />

              <Spacer size={20}/>

              {!!terms && <>
                <FF label={'Li e estou de acordo com os termos'} name="accept_terms" component={FormikRadio}/>
                <TermsModal title="Termos" terms={terms}/>
              </>}

              <Spacer size={50}/>

              <Button
                icon="content-save" mode="contained"
                loading={create.isLoading || update.isLoading} disabled={create.isLoading || update.isLoading}
                onPress={() => submitForm()}
              >
                Salvar
              </Button>
            </React.Fragment>
          )
        }}
      </Formik>
    </FormContainer>
  )
}

export default ClassifiedForm