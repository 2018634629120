import * as React from 'react'
import {ScrollView, ScrollViewProps, StyleSheet} from 'react-native'

const styles = StyleSheet.create({
  list: {
    flex: 1
  },
  listContent: {
    paddingHorizontal: 8,
    paddingVertical: 14
  }
})

type Props = (props: ScrollViewProps) => React.ReactElement<ScrollViewProps>

const AppScrollView: Props = (props) => {
  return (
    <ScrollView
      style={styles.list}
      contentContainerStyle={styles.listContent}

      {...props}
    />
  )
}

export default AppScrollView