import React from 'react'
import {SafeAreaView} from 'react-native-safe-area-context'
import {AclRender} from '../../../components/acl/components'
import AppTabView from '../../../components/layout/AppTabView'
import NotPermitted from '../../../components/NotPermitted'
import AllAdsListTab from '../components/AllAdsListTab'
import MyAdsListTab from '../components/MyAdsListTab'

function ClassifiedListScreen() {
  return (
    <SafeAreaView style={{ flex: 1 }} edges={['right', 'bottom', 'left']}>
      <AppTabView scenes={[
        { key: 'all', title: 'Todos anúncios', scene: AllAdsListTab },
        { key: 'my', title: 'Meus anúncios', scene: MyAdsListTab }
      ]}/>
    </SafeAreaView>
  )
}

export default function (props: any) {
  return (
    <AclRender required={['classified.view_classified']} fallback={<NotPermitted/>}>
      <ClassifiedListScreen/>
    </AclRender>
  )
}
