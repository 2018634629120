import AsyncStorage from '@react-native-async-storage/async-storage'
import {AxiosRequestConfig} from 'axios'
import moment from 'moment'
import * as queryString from 'query-string'
import {apiService, authService, EventLogger} from '../services'
import {postApi, uploadS3} from '../services/api'
import ToasterManager from '../services/ToasterManager'
import {buildQs} from '../utils'
import {
  AccessAuthorizationModel,
  AccessReasonModel,
  BookModel,
  BookPeriodAvailableModel,
  InvoiceModel,
  MailingModel,
  MeetingModel,
  MeetingSubscriptionModel,
  MovingModel,
  MovingTypeModel,
  PushNotificationModel,
  ReservationModel,
  SpaceModel,
  SpreadsheetModel,
  SpreadsheetRowModel,
  SurveyModel
} from './typings'

type GetRequestResult<T> = {
  data: T
}

type PaginatedGetRequestResult<T> = {
  data: T
  next: string | null
  previous: string | null
  count: number
}

type Params = {
  all_url_params?: string
  get_url_params?: string
}

const apiResourcePost = async <Model>(path: string, values: any) => {
  try {
    const { data } = await apiService.post<Model>(`${path}`, values)
    return data
  } catch (e) {
    ToasterManager.sendErrors(e)
    return Promise.reject(e)
  }
}

const apiResourcePatch = async <Model>(path: string, values: any) => {
  try {
    const { data } = await apiService.patch<Model>(`${path}`, values)
    return data
  } catch (e) {
    ToasterManager.sendErrors(e)
    return Promise.reject(e)
  }
}

const apiResourceGet = async <Model>(path: string, configs?: AxiosRequestConfig) => {
  try {
    const { data } = await apiService.get<Model>(`${path}`, configs)
    return data
  } catch (e) {
    ToasterManager.sendErrors(e)
    return Promise.reject(e)
  }
}

const apiResource = <Model>(domain: string, params: Params) => {
  return {
    all_key: `all_${domain}`,
    get_key: `get_${domain}`,
    all: async () => {
      try {
        let path = `resident/${domain}`

        if (params?.all_url_params) {
          path = `resident/${domain}${params.all_url_params}`
        }

        const { data } = await apiService.get<GetRequestResult<Model[]>>(path)
        return data.data
      } catch (e) {
        ToasterManager.sendErrors(e)
        return Promise.reject(e)
      }
    },
    all_paginated: async (page: number, page_limit: number, on_demand_filters: any = null) => {
      try {
        let path = `resident/${domain}?page=${page}&limit=${page_limit}`

        if (params?.all_url_params) {
          path = `resident/${domain}${params.all_url_params}&page=${page}&limit=${page_limit}`
        }

        const url_parsed = queryString.parseUrl(path, { arrayFormat: 'bracket' })
        console.log('parseUrl', url_parsed)

        const qs = buildQs(on_demand_filters, url_parsed.query)
        console.log('qs', qs)

        path = `resident/${domain}${qs}`

        const { data } = await apiService.get<PaginatedGetRequestResult<Model[]>>(path)
        return data
      } catch (e) {
        ToasterManager.sendErrors(e)
        return Promise.reject(e)
      }
    },
    get: async (item_id: number | string) => {
      try {
        if (!item_id) {
          return null
        }

        let path = `resident/${domain}/${item_id}`

        if (params?.get_url_params) {
          path = `resident/${domain}/${item_id}${params.get_url_params}`
        }

        const data = await apiResourceGet<Model>(path)
        return data
      } catch (e) {
        return Promise.reject(e)
      }
    },
    post: async (values: any) => {
      try {
        let res = await apiResourcePost<Model>(`resident/${domain}`, values)
        // notification.success({ message: `${title} Criado` })
        // if (all_key) await queryClient.invalidateQueries(all_key)
        return res
      } catch (e) {
        return Promise.reject(e)
      }
    },
    patch: async ({ id, ...values }: any) => {
      try {
        let res = await apiResourcePatch<Model>(`resident/${domain}/${id}`, values)
        // notification.success({ message: `${title} atualizado` })
        // if (all_key) await queryClient.invalidateQueries(all_key)
        return res
      } catch (e) {
        return Promise.reject(e)
      }
    },
    delete: async ({ id }: any) => {
      try {
        let res = await apiService.delete(`resident/${domain}/${id}`)

        // notification.success({ message: `${title} excluído!` })

        // if (all_key) await queryClient.invalidateQueries(all_key)

        return res
      } catch (e) {
        ToasterManager.sendErrors(e)
        return Promise.reject(e)
      }
    }
  }
}

const user = {
  changeTeam: async (values: { team_id: number }) => apiResourcePost('resident/change-current-team', values),
  getMyAccountSettings: async () => {
    const res = await apiResourceGet<{ use_condoid_v2: boolean }>('resident/my-account/settings')

    await authService.handleTeamVersion(res?.use_condoid_v2)

    return res
  },
  me: async () => {
    let res = await apiResourceGet<any>('resident/me')

    authService.checkUserData(res)
    await AsyncStorage.setItem('me', JSON.stringify(res))
    await EventLogger.setUser(res)

    return res
  },
  updateProfile: async ({ id, ...values }: any) => {
    try {
      if (values.image_upload) {
        try {
          let file_create_res = await uploadS3(values.image_upload, 'people', 'photoDevice.jpg')

          values['file'] = file_create_res.id
        } catch (e: any) {
          console.log(e?.response)

          ToasterManager.error('Não foi possível enviar o arquivo')
          return
        }
      }

      let res = await postApi('resident/people/update_profile', values)
      ToasterManager.success('Perfil atualizado com sucesso!')

      return res
    } catch (e) {
      ToasterManager.sendErrors(e)
      return Promise.reject(e)
    }
  }

}

const surveys = apiResource<SurveyModel>('surveys', {
  get_url_params: '?include[]=my_vote&include[]=options.*&include[]=files.*&include[]=charts'
})

const survey_votes = apiResource<SurveyModel>('survey-votes', {})

const mailings = apiResource<MailingModel>('mailings', {
  all_url_params: `?include[]=unit.with_block&include[]=files.file&filter{created_at.gte}=${moment().subtract(90, 'days').toISOString()}`
})

const tickets = apiResource<any>('tickets', {
  all_url_params: `?${
    queryString.stringify(
      {
        include: ['id', 'type', 'category.name', 'date', 'description', 'status'],
        'filter{created_at.gte}': moment().subtract(8, 'months').toISOString(),
        'exclude[]': '*'
      },
      { encode: false, arrayFormat: 'bracket' }
    )
  }`,
  get_url_params: `?${
    queryString.stringify(
      {
        include: ['category.id', 'comments.id', 'files.file']
      },
      { encode: false, arrayFormat: 'bracket' }
    )
  }`
})

const access_reasons = apiResource<AccessReasonModel>('access-reasons', {})

const access_authorizations = apiResource<AccessAuthorizationModel>('access-authorizations', {
  all_url_params: `?${
    queryString.stringify(
      {
        include: [
          'id',
          'person.name',
          'access_reason_name',
          'date',
          'start_date',
          'end_date',
          'type',
          'status',
          'description',
          'vehicle_plate',
          'vehicle_model',
          'vehicle_color',
          'pin'
        ]
      },
      { encode: false, arrayFormat: 'bracket' }
    )
  }`
})

const access_logs = apiResource<AccessAuthorizationModel>('access-logs', {
  all_url_params: `?include[]=person.photo&include=[]=access_reason_name`
})

const spreadsheets = apiResource<SpreadsheetModel>('spreadsheets', {})

const spreadsheets_rows = {
  bySpreadsheet: async (spreadsheet_id: number | string) => {
    const res = await apiResourceGet<GetRequestResult<SpreadsheetRowModel[]>>(`resident/spreadsheets-rows?${
      queryString.stringify(
        {
          include: [
            'spreadsheet_datas.*',
            'spreadsheet_datas.files.id'
          ],
          'filter{spreadsheet}': spreadsheet_id
        },
        { encode: false, arrayFormat: 'bracket' }
      )
    }`)
    return res.data
  }
}

const notifications = {
  ...apiResource<PushNotificationModel>('notifications', {
    all_url_params: `?include[]=extra_data`
  }),
  readByHash: async (hash: string) => apiResourceGet(`resident/notifications/read_by_hash?hash=${hash}`),
  readMeAll: async () => apiResourceGet(`resident/notifications/read_me_all`)
}

const comments = {
  post: async (values: {
    body: string,
    type: number,
    commentable_id: number,
    commentable_type: string,
  }) => apiResourcePost('resident/comments', {
    body: values.body,
    type: values.type,
    commentable: {
      id: values.commentable_id,
      type: values.commentable_type
    }
  })
}

const constructions = apiResource<any>('constructions', {
  all_url_params: `?include[]=unit.with_block`
})

const movings = apiResource<MovingModel>('movings', {
  all_url_params: `?include[]=moving_type.name`
})

const moving_types = {
  ...apiResource<MovingTypeModel>('moving-types', {}),

  datesAvailability: async (values: { id: number, unit_id: number, }) => apiResourcePost(
    `resident/moving-types/${values.id}/availability`, values
  ),
  periodsAvailability: async (values: { id: number, book_date: string, }) => apiResourcePost(
    `resident/moving-types/${values.id}/periods-available`, values
  )
}

const units = {
  my_units_key: 'myUnits',
  myUnits: async () => apiResourceGet<any>('resident/units')
}

const vehicles = apiResource<any>('vehicles', { all_url_params: `?include[]=parking.code` })

const parkings = apiResource<any>('parkings', { all_url_params: `?include[]=with_pavement` })

const animals = apiResource<any>('animals', { all_url_params: `?include[]=unit.id&include[]=files.file` })

const residents = {
  ...apiResource<any>('people', { all_url_params: `?include[]=unit_id&include[]=photo&include[]=units.with_block` }),
  post: async (values: any) => {
    try {
      if (values.image_upload) {
        try {
          let file_create_res = await uploadS3(values.image_upload, 'people', 'photoDevice.jpg')
          values['file'] = file_create_res.id
        } catch (e: any) {
          console.log(e?.response)
          ToasterManager.error('Não foi possível enviar o arquivo')
          return
        }
      }

      return await apiResourcePost(`resident/people`, values)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  patch: async ({ id, ...values }: any) => {
    try {
      if (values.image_upload) {
        try {
          let file_create_res = await uploadS3(values.image_upload, 'people', 'photoDevice.jpg')
          values['file'] = file_create_res.id
        } catch (e: any) {
          console.log(e?.response)
          ToasterManager.error('Não foi possível enviar o arquivo')
          return
        }
      }
      return await apiResourcePatch(`resident/people/${id}`, values)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

const books = {
  ...apiResource<BookModel>('books', {
    all_url_params: `?${
      queryString.stringify(
        {
          include: ['id', 'space_name', 'unit_name', 'book_date', 'tax', 'status'],
          'exclude[]': '*'
        },
        { encode: false, arrayFormat: 'bracket' }
      )
    }`,
    get_url_params: `?${
      queryString.stringify(
        {
          include: ['space.name', 'unit.with_block']
        },
        { encode: false, arrayFormat: 'bracket' }
      )
    }`
  }),
  cancel: async (book_id: number | string) => apiResourceGet(`resident/books/${book_id}/cancel`)
}

const guest_lists = {
  ...apiResource<any>('guest-lists', {}),
  byBook: async (book_id: number | string) => {
    const res = await apiResourceGet<any>(`resident/guest-lists?filter{book_id}=${book_id}`)
    return res.data
  }
}

const spaces = {
  ...apiResource<SpaceModel>('spaces', {
    all_url_params: `?${
      queryString.stringify(
        {
          include: ['id', 'name', 'guests_max', 'full_tax'],
          'exclude[]': '*'
        },
        { encode: false, arrayFormat: 'bracket' }
      )
    }`,
    get_url_params: `?${
      queryString.stringify(
        {
          include: ['availability', 'additional']
        },
        { encode: false, arrayFormat: 'bracket' }
      )
    }`
  }),

  periodsAvailability: async (values: { id: number, book_date: string }) => apiResourcePost<BookPeriodAvailableModel[]>(
    `resident/spaces/${values.id}/periods-available`, values
  )
}

const meetings = {
  ...apiResource<MeetingModel>('meetings', {})
}

const meeting_subs = {
  ...apiResource<MeetingSubscriptionModel>('meeting-subscriptions', {
    all_url_params: `?include[]=meeting.id`,
    get_url_params: `?include[]=meeting.id&include[]=person_name_label`
  })
}

const reservations = {
  ...apiResource<ReservationModel>(
    'reservations',
    {
      all_url_params: `?include[]=unit.with_block&include[]=main_guest.person.name`
    }),
  cancel: async (res_id: number | string) => apiResourcePost(`resident/reservations/${res_id}/cancel`, {}),
  voucher: async (res_id: number | string) => {
    const res: any = await apiResourceGet(`resident/reservations/${res_id}/voucher`)
    return res.file_url
  }
}

const invoices = {
  getPaid: async () => apiResourceGet<InvoiceModel[]>(`resident/invoices/paid`),
  getUnpaid: async () => apiResourceGet<InvoiceModel[]>(`resident/invoices/unpaid`)
}

const panics = {
  post: async (values: { team_id: number }) => apiResourcePost('resident/panics', values)
}

const classifieds = {
  ...apiResource<any>('classifieds', {
    all_url_params: '?include[]=files.file',
    get_url_params: '?include[]=files.file&include[]=user_name'
  }),
  allAds: async (page: number, page_limit: number) => {
    let path = `resident/classifieds/all?page=${page}&limit=${page_limit}`
    return await apiResourceGet<any>(path)
  },
  inactive: async (item_id: number | string) => apiResourceGet<any>(`resident/classifieds/${item_id}/inactive`)
}

const notices = apiResource<any>('notices', {
  all_url_params: '?include[]=files.file',
  get_url_params: '?include[]=files.file'
})

const documents = apiResource<any>('documents', {
  all_url_params: '?include[]=files.file',
  get_url_params: '?include[]=files.file'
})

const informations = apiResource<any>('informations', {})

const draws = apiResource<any>('draws', {
  all_url_params: '?include[]=people.id'
})

const penalties = apiResource<any>('penalties', {
  all_url_params: `?${
    queryString.stringify(
      {
        include: ['id', 'penalty_category_name', 'penalty_type_name', 'date', 'status'],
        'exclude[]': '*'
      },
      { encode: false, arrayFormat: 'bracket' }
    )
  }`,
  get_url_params: `?${
    queryString.stringify(
      {
        include: ['penalty_type_name', 'comments.id', 'penalty_category.id']
      },
      { encode: false, arrayFormat: 'bracket' }
    )
  }`
})

export const qs = {
  user,
  surveys,
  survey_votes,
  mailings,
  access_reasons,
  tickets,
  access_authorizations,
  access_logs,
  spreadsheets,
  spreadsheets_rows,
  notifications,
  comments,
  constructions,
  movings,
  moving_types,
  units,
  vehicles,
  parkings,
  animals,
  residents,
  books,
  guest_lists,
  spaces,
  reservations,
  meetings,
  meeting_subs,
  invoices,
  panics,
  classifieds,
  notices,
  documents,
  informations,
  draws,
  penalties
}
