import React from 'react'
import {connect as formikConnect} from 'formik'

class EffectInner extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.formik.values !== prevProps.formik.values) {
      this.props.onChange && this.props.onChange(
        {
          values: this.props.formik.values,
          _previous_values: prevProps.formik.values,
          touched: this.props.formik.touched,
          errors: this.props.formik.errors,
          isSubmitting: this.props.formik.isSubmitting,
          isValidating: this.props.formik.isValidating,
          submitCount: this.props.formik.submitCount,
          setFieldValue: this.props.formik.setFieldValue,
        }
      )
    }
  }

  render() {
    return null
  }
}

export default formikConnect(EffectInner)