import React from 'react'
import {StyleSheet, Text, View} from 'react-native'

const styles = StyleSheet.create({
  background: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  textContent: {
    fontSize: 35,
    fontWeight: 'bold',
    color: '#8B98A4',
    marginBottom: 10
  },
  emoji: {
    fontSize: 50,
    marginTop: 40,
    marginBottom: 25
  },
  description: {
    fontSize: 18,
    fontWeight: '200',
    color: '#8B98A4',
    paddingHorizontal: 40,
    textAlign: 'center'
  }
})

const NotPermitted = ({ msg }: { msg?: string }) => {
  return (
    <View style={styles.background}>
      <Text style={styles.emoji}>🚫</Text>
      <Text style={styles.textContent}>Oops...</Text>
      <Text style={styles.description}>{msg ?? 'Desculpe, mas você não tem permissão!'}</Text>
    </View>
  )
}

export default NotPermitted