import {useNavigation} from '@react-navigation/native'
import {Formik} from 'formik'
import * as React from 'react'
import {Button, Divider, Subheading} from 'react-native-paper'
import {useMutation} from 'react-query'
import * as Yup from 'yup'
import CommentsBox from '../../../components/CommentsBox'
import DialogSelect from '../../../components/form/DialogSelect'
import {FormikDatePicker} from '../../../components/form/FormikInputs'
import {FF, FormContainer, FormikInput, FormikRadio} from '../../../components/form/FormInputs'
import UnitSelect from '../../../components/form/UnitSelect'
import Uploader from '../../../components/form/Uploader'
import Spacer from '../../../components/Spacer'
import TermsModal from '../../../components/TermsModal'
import {CONSTRUCTION_MANAGER} from '../../../constants/fields'
import {RootDrawerScreenProps} from '../../../navigation/types'
import ToasterManager from '../../../services/ToasterManager'
import {useMeQuery} from '../../Auth/hooks/useMeQuery'
import {qs} from '../../queries'

const ConstructionForm = ({ data }: { data?: any }) => {
  const navigation = useNavigation<RootDrawerScreenProps<'ConstructionDetailScreen'>['navigation']>()

  const { data: meData } = useMeQuery()

  const create = useMutation((variables: any) => qs.constructions.post(variables), {
    onSuccess: () => {
      ToasterManager.success('Solicitação de Obra criada com sucesso!')
      navigation.navigate('ConstructionListScreen')
    }
  })

  const update = useMutation((variables: any) => qs.constructions.patch(variables), {
    onSuccess: () => {
      ToasterManager.success('Solicitação de Obra atualizada com sucesso!')
      navigation.navigate('ConstructionListScreen')
    }
  })

  return (
    <FormContainer>
      <Formik
        initialValues={{
          unit: data?.unit_id ?? '',
          start_date: data?.start_date ?? '',
          end_date: data?.end_date ?? '',
          manager: data?.manager ?? '',
          manager_name: data?.manager_name ?? '',
          manager_phone: data?.manager_phone ?? '',
          manager_doc: data?.manager_doc ?? '',
          local: data?.local ?? '',
          description: data?.description ?? '',
          has_company: data?.has_company ?? false,
          company_name: data?.company_name ?? '',
          company_phone: data?.company_phone ?? '',
          company_doc: data?.company_doc ?? '',
          has_noise: data?.has_noise ?? false,
          materials: data?.materials ?? '',
          materials_toxic: data?.materials_toxic ?? '',
          materials_combustible: data?.materials_combustible ?? '',
          materials_flammable: data?.materials_flammable ?? '',
          materials_storage: data?.materials_storage ?? '',
          leavings_storage: data?.leavings_storage ?? '',
          leavings_destiny: data?.leavings_destiny ?? '',
          files_attach: [],
          accept_terms: false
        }}
        validationSchema={Yup.object().shape({
          unit: Yup.string().required(),
          start_date: Yup.string().required(),
          end_date: Yup.string().required(),
          manager: Yup.string().required(),
          description: Yup.string().required().min(10, 'Por favor descreva a obra.'),
          local: Yup.string().required(),

          accept_terms: data?.id ? Yup.boolean() : Yup.boolean().oneOf([true], 'Por favor aceite os termos.')
        })}
        onSubmit={(values) => {
          if (data?.id) {
            update.mutate({
              ...values,
              id: data.id
            })
          } else {
            create.mutate({
              ...values,
              status: 2
            })
          }
        }}
      >
        {({ values, submitForm, setFieldValue }) => {
          return (
            <React.Fragment>
              <UnitSelect/>
              <FormikDatePicker label={'Data de início'} name="start_date"/>
              <FormikDatePicker label={'Data de término'} name="end_date"/>
              <FF label={'Local da obra'} name="local" component={FormikInput}/>
              <FF label={'Geração de ruído'} name="has_noise" component={FormikRadio}/>
              <FF label={'Descrição da obra'} name="description" component={FormikInput} multiline={true}/>

              <Spacer/>

              <FF label={'Responsável pela obra'} name="manager" component={DialogSelect} local_options={CONSTRUCTION_MANAGER}/>
              {(values.manager === 1 || values.manager === 2) &&
                <React.Fragment>
                  <FF label={'Nome do responsável'} name="manager_name" component={FormikInput}/>
                  <FF label={'Telefone do responsável'} name="manager_phone" component={FormikInput}/>
                  <FF label={'Documento do responsável'} name="manager_doc" component={FormikInput}/>
                </React.Fragment>}

              <FF label={'Tem empresa responsável'} name="has_company" component={FormikRadio}/>
              {values.has_company &&
                <React.Fragment>
                  <FF label={'Nome da empresa'} name="company_name" component={FormikInput}/>
                  <FF label={'Telefone da empresa'} name="company_phone" component={FormikInput}/>
                  <FF label={'Documento da empresa'} name="company_doc" component={FormikInput}/>
                </React.Fragment>}

              <Spacer/>
              <Subheading>Materiais e Resíduos</Subheading>
              <Divider/>
              <Spacer size={10}/>

              <FF label={'Materiais não perigosos'} name="materials" component={FormikInput} multiline={true}/>
              <FF label={'Materiais tôxicos'} name="materials_toxic" component={FormikInput} multiline={true}/>
              <FF label={'Materiais combustíveis'} name="materials_combustible" component={FormikInput} multiline={true}/>
              <FF label={'Materiais inflamáveis'} name="materials_flammable" component={FormikInput} multiline={true}/>
              <FF label={'Armazenamento dos materiais'} name="materials_storage" component={FormikInput}/>
              <FF label={'Armazenamento dos resíduos gerados'} name="leavings_storage" component={FormikInput}/>
              <FF label={'Destino dos resíduos gerados'} name="leavings_destiny" component={FormikInput} multiline={true}/>

              <Spacer size={50}/>

              <Uploader
                model_id={data?.id}
                model_type="construction"
                file_type="attachments"
                buttonIcon="attachment"
                buttonText="Anexar Documentos"
                filesUploaded={[]}
                uploadComplete={(data: any) => {
                  let tempFile: any[] = values.files_attach
                  tempFile.push(data.id)
                  setFieldValue('files_attach', tempFile)
                }}/>

              <Spacer size={20}/>

              {data?.id && <CommentsBox commentable_id={data.id} commentable_type="construction"/>}

              {!data?.id && <>
                <FF label={'Li e estou de acordo com os termos'} name="accept_terms" component={FormikRadio}/>
                <TermsModal title="Regimento para Obras" terms={meData?.team?.construction_terms}/>
              </>}

              <Spacer size={50}/>

              <Button
                icon="content-save" mode="contained"
                loading={create.isLoading || update.isLoading} disabled={create.isLoading || update.isLoading}
                onPress={() => submitForm()}
              >
                Salvar
              </Button>
            </React.Fragment>
          )
        }}
      </Formik>
    </FormContainer>
  )
}

export default ConstructionForm