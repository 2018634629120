import {FontAwesome} from '@expo/vector-icons'
import {useFocusEffect, useNavigation} from '@react-navigation/native'
import React from 'react'
import {StyleSheet, TouchableOpacity, View} from 'react-native'
import {Caption, Title} from 'react-native-paper'
import {useInfiniteQuery} from 'react-query'
import {useHasPerm} from '../../../components/acl'
import {AclRender} from '../../../components/acl/components'
import AppFlatList from '../../../components/layout/AppFlatList'
import NotPermitted from '../../../components/NotPermitted'
import PlaceHolder from '../../../components/placeholder'
import {BOOK_STATUS} from '../../../constants/fields'
import {RootDrawerScreenProps} from '../../../navigation/types'
import {useAppTheme} from '../../../styles/theme'
import {dateTimeFormat, handleGetNextPageParam} from '../../../utils'
import {qs} from '../../queries'
import {BookModel} from '../../typings'

function BookListTab() {
  const theme = useAppTheme()
  const navigation = useNavigation<RootDrawerScreenProps<'BookListScreen'>['navigation']>()

  const {
    data,
    hasNextPage,
    isFetching,
    fetchNextPage,
    refetch,
    remove
  } = useInfiniteQuery(qs.books.all_key, async (_params) =>
      qs.books.all_paginated(_params.pageParam ?? 1, 15),
    {
      enabled: false,
      getNextPageParam: handleGetNextPageParam
    })

  useFocusEffect(
    React.useCallback(() => {
      refetch()
      return () => { remove() }
    }, [])
  )

  let can_edit = useHasPerm(['book.add_book'])

  const renderItem = ({ item }: { item: BookModel }) => (
    <TouchableOpacity onPress={() => can_edit && navigation.navigate('BookDetailScreen', { item_id: item.id })}>
      <View style={styles.cardContainer}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View style={{ flex: 3 }}>
            <Caption>
              #{item.id} - {item.unit_name}
            </Caption>

            <Title>{item.space_name}</Title>

            <Caption style={{ fontWeight: 'bold' }}>
              <FontAwesome name="calendar-o" size={12} color={theme.colors.primary}/>{' '}
              {dateTimeFormat(item.book_date)}
            </Caption>
            <Caption style={{ fontWeight: 'bold' }}>
              <FontAwesome name="money" size={12} color={theme.colors.primary}/>{' '}
              Taxa: {item.tax}
            </Caption>
          </View>
          <View style={{ flex: 1, alignSelf: 'center' }}>
            <Caption style={{ textAlign: 'right', fontWeight: 'bold', color: theme.colors.accent }}>
              {BOOK_STATUS[item.status]}
            </Caption>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  )

  return (
    <View style={{ flex: 1 }}>
      <AclRender required={['book.add_book']} fallback={<NotPermitted/>}>
        <AppFlatList
          data={data?.pages.map(page => page.data).flat() ?? []}
          onEndReached={() => hasNextPage && fetchNextPage()}
          renderItem={renderItem}
          keyExtractor={item => item.id + ''}
          refreshing={isFetching}
          onRefresh={refetch}
          ListEmptyComponent={() => (
            <PlaceHolder
              visible={true}
              emoji={'🕺'}
              description={'Vamos lá! Faça a sua primeira reserva selecionando o espaço na aba "Espaços".'}
            />
          )}
        />
      </AclRender>
    </View>
  )
}

const styles = StyleSheet.create({
  cardContainer: {
    borderWidth: 1,
    borderColor: '#e8e8e8',
    borderRadius: 6,
    padding: 12,
    marginBottom: 14
  }
})

export default BookListTab