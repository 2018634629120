import React, {Component} from 'react'
import {ActivityIndicator, Modal, StyleSheet, Text, View} from 'react-native'
import {connect} from 'react-redux'

class LoadingRedux extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: this.props.visible,
    }
  }

  render() {
    if (this.props.currentlySending === true) {
      return (
        <Modal
          visible={this.props.currentlySending === true}
          animationType={'fade'}
          transparent={true}
          onRequestClose={() => {}}>
          <View style={styles.modalContainer}>
            <View style={styles.background}>
              <ActivityIndicator color='white' size='large' style={{flex: 1}}/>
              <View style={styles.textContainer}>
                <Text style={styles.textContent}>Processando...</Text>
              </View>
            </View>
          </View>
        </Modal>
      )
    } else {
      return null
    }

  }
}

function mapStateToProps(state) {
  const {app} = state
  const {currentlySending} = app || {
    currentlySending: true,
  }

  return {
    app,
    currentlySending,
  }
}

export default connect(mapStateToProps)(LoadingRedux)

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'transparent',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  background: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: 100000
  },
  textContainer: {
    flex: 1,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute'
  },
  textContent: {
    top: 80,
    height: 50,
    fontSize: 20,
    fontWeight: 'bold',
    color: '#FFFFFF'
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  innerContainer: {
    alignItems: 'center',
  },
  // container: {
  //     position: 'absolute',
  //     left: 0,
  //     right: 0,
  //     top: 0,
  //     bottom: 0,
  //     alignItems: 'center',
  //     justifyContent: 'center',
  // },
  // containerOverlay: {
  //     position: 'absolute',
  //     left: 0,
  //     right: 0,
  //     top: 0,
  //     bottom: 0,
  //     alignItems: 'center',
  //     justifyContent: 'center',
  //     backgroundColor: 'rgba(52, 52, 52, 0.8)'
  // },
  // activityIndicator: {
  //     flex: 1,
  //     justifyContent: 'center',
  //     alignItems: 'center',
  //     height: 80
  // }

})
