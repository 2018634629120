import {useFocusEffect, useNavigation} from '@react-navigation/native'
import React from 'react'
import {FlatList, StyleSheet, TouchableOpacity, View} from 'react-native'
import {Avatar, Subheading, Title} from 'react-native-paper'
import {useQuery} from 'react-query'
import {useHasPerm} from '../../../components/acl'
import {AclRender} from '../../../components/acl/components'
import AppFab from '../../../components/layout/AppFab'
import PlaceHolder from '../../../components/placeholder'
import {RootDrawerScreenProps} from '../../../navigation/types'
import {qs} from '../../queries'

function ResidentListTab() {
  const navigation = useNavigation<RootDrawerScreenProps<'MyDataScreen'>['navigation']>()

  const { data, isFetching, refetch, remove } = useQuery(
    qs.residents.all_key,
    () => qs.residents.all(),
    { enabled: false }
  )

  useFocusEffect(
    React.useCallback(() => {
      refetch()
      return () => { remove() }
    }, [])
  )

  let can_add = useHasPerm(['unit.add_person'])
  let can_edit = useHasPerm(['unit.change_person'])

  const renderItem = ({ item }: any) => {
    let units_names = Array.isArray(item?.units) ? item.units.map((i: any) => i.with_block) : []
    const avatar_url = item?.photo?.file ? { uri: item?.photo?.file } : require('../../../assets/anonymous-avatar-icon.png')

    return (
      <React.Fragment>
        <TouchableOpacity onPress={() => can_edit && navigation.navigate('ResidentDetailScreen', { data: item, item_id: item.id })}>
          <View style={styles.cardContainer}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <Avatar.Image
                source={avatar_url}
                size={80} style={{ marginRight: 10 }}
              />
              <View style={{ flex: 1 }}>
                <Title>{item.name}</Title>
                <Subheading>{units_names.join(', ')}</Subheading>
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </React.Fragment>
    )
  }

  return (
    <View style={{ flex: 1 }}>
      <View style={{ backgroundColor: '#eccf6e' }}>
        <TouchableOpacity onPress={() => navigation.navigate('EditProfile')}>
          <Subheading style={{ textAlign: 'center', padding: 15, color: '#665930' }}>
            Para editar o seu cadastro, toque aqui!
          </Subheading>
        </TouchableOpacity>
      </View>

      <FlatList
        style={styles.list}
        contentContainerStyle={styles.listContent}
        data={data}
        renderItem={renderItem}
        keyExtractor={item => item.id + ''}
        refreshing={isFetching}
        onRefresh={refetch}
        ListEmptyComponent={() => (
          <PlaceHolder
            visible={true}
            emoji={'😇'}
            description={can_add ? 'Clique no botão "+" para adicionar os seus condôminos.' : 'Nenhum condômino cadastrado'}
          />
        )}
      />

      <AclRender required={['unit.add_person']}>
        <AppFab icon="plus" onPress={() => navigation.navigate('ResidentNewScreen')}/>
      </AclRender>
    </View>
  )
}

const styles = StyleSheet.create({
  list: {
    flex: 1
  },
  listContent: {
    paddingHorizontal: 8,
    paddingVertical: 14
  },
  cardContainer: {
    borderWidth: 1,
    borderColor: '#e8e8e8',
    borderRadius: 6,
    padding: 12,
    marginBottom: 14
  }
})

export default ResidentListTab
