import {Field} from 'formik'
import * as React from 'react'
import {useQuery} from 'react-query'
import {qs} from '../../features/queries'
import DialogSelect from './DialogSelect'

const MovingTypeSelect = (props: any) => {
  const { data } = useQuery(qs.moving_types.all_key, () => qs.moving_types.all())

  return (
    <Field
      label={props.label ? props.label : 'Tipo'}
      name={props.name ? props.name : 'moving_type'}
      component={DialogSelect}
      data={data}
      id_option="id"
      label_option="name"
      onChange={props.onChange}
      showValidator={props.showValidator}
    />
  )
}

export default MovingTypeSelect