import React from 'react'
import {FlatList, StyleSheet, View} from 'react-native'
import {Title} from 'react-native-paper'
import {useQuery} from 'react-query'
import PlaceHolder from '../../../components/placeholder'
import {qs} from '../../queries'

function UnitListTab() {
  const { data, isFetching, refetch } = useQuery(qs.units.my_units_key, () => qs.units.myUnits())

  const renderItem = ({ item }: any) => {

    return (
      <View style={styles.cardContainer}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View style={{ flex: 3 }}>
            <Title>{item?.with_block}</Title>
          </View>
        </View>
      </View>
    )
  }

  return (
    <View style={{ flex: 1 }}>
      <FlatList
        style={styles.list}
        contentContainerStyle={styles.listContent}
        data={data?.data ?? []}
        renderItem={renderItem}
        keyExtractor={item => item.id + ''}
        refreshing={isFetching}
        onRefresh={refetch}
        ListEmptyComponent={() => (
          <PlaceHolder
            visible={true}
            emoji={'🏠'}
            description={'Não foi encontrado nenhuma unidade vinculada ao seu cadastro'}
          />
        )}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  list: {
    flex: 1
  },
  listContent: {
    paddingHorizontal: 8,
    paddingVertical: 14
  },
  cardContainer: {
    borderWidth: 1,
    borderColor: '#e8e8e8',
    borderRadius: 6,
    padding: 12,
    marginBottom: 14
  }
})

export default UnitListTab
