import Constants from 'expo-constants'
import * as Notifications from 'expo-notifications'
import {NotificationResponse} from 'expo-notifications/src/Notifications.types'
import {qs} from '../features/queries'
import ZohoSalesIQ from '../nativeModules/ZohoSalesIQ'
import {getInstallationId, gv, platformChecker} from '../utils'
import {postApi} from './api'
import {EventLogger} from './EventLogger'
import navigationService from './navigationService'
import {sdkSentry} from './sentry'
import {getAppId} from './tenantService'
import ToasterManager from './ToasterManager'

const PushNotificationService = {
  handleNotificationListener: (notification_response: NotificationResponse | null | undefined) => {
    console.log(JSON.stringify(notification_response))
    EventLogger.debugLog(notification_response)

    if (notification_response?.actionIdentifier === Notifications.DEFAULT_ACTION_IDENTIFIER) {
      let _notification_data = notification_response?.notification?.request?.content?.data
      // É possível receber esses dados quando é recebida uma notificação somente via extra_data da notificação
      let msg_hash = notification_response?.notification?.request?.content?.data?.hash
      let message_id = notification_response?.notification?.request?.content?.data?.message_id
      PushNotificationService.handleNotificationAction(_notification_data, msg_hash, message_id)
    }
  },

  handleNotificationAction: (
    notification_data: { [key: string]: unknown } | null | undefined,
    msg_hash: string | unknown | null, message_id: string | unknown | null
  ) => {

    if (msg_hash && typeof msg_hash === 'string') {
      qs.notifications.readByHash(msg_hash)
    }

    if (notification_data?.url) {
      switch (notification_data.url) {
        case 'book':
          if (notification_data?.object_id) {
            navigationService.navigate('BookDetailScreen', { item_id: notification_data.object_id })
          }
          break
        case 'document':
          navigationService.navigate('DocumentListScreen')
          break
        case 'construction':
          navigationService.navigate('ConstructionListScreen')
          break
        case 'constructions':
          navigationService.navigate('ConstructionListScreen')
          break
        case 'information':
          if (notification_data?.object_id) {
            navigationService.navigate('InformationDetailScreen', { item_id: notification_data.object_id })
          }
          break
        case 'mailing':
          navigationService.navigate('MailingListScreen')
          break
        case 'moving':
          navigationService.navigate('MovingListScreen')
          break
        case 'survey':
          navigationService.navigate('SurveyListScreen')
          break
        case 'notice':
          if (notification_data?.object_id) {
            navigationService.navigate('NoticeDetailScreen', { item_id: notification_data.object_id })
          }
          break
        case 'penalty':
          if (notification_data?.object_id) {
            navigationService.navigate('PenaltyDetailScreen', { item_id: notification_data.object_id })
          }
          break
        case 'ticket':
          if (notification_data?.object_id) {
            navigationService.navigate('TicketDetailScreen', { item_id: notification_data.object_id })
          }
          break
        case 'classified':
          if (notification_data?.object_id) {
            navigationService.navigate('ClassifiedViewScreen', { item_id: notification_data.object_id })
          }
          break
        case 'draw':
          navigationService.navigate('DrawListScreen')
          break
        case 'meeting':
          navigationService.navigate('MeetingListScreen')
          break
        case 'lost':
          navigationService.navigate('LostListScreen')
          break
        case 'authorization':
          navigationService.navigate('AccessAuthorizationListScreen')
          break
        case 'spreadsheet':
          navigationService.navigate('SpreadsheetScreen', { item_id: notification_data.object_id })
          break
        case 'support_chat':
          ZohoSalesIQ.handleChatNotification(notification_data.message as string)
          break
      }
    }
  },

  registerForPushNotificationsAsync: async () => {
    // try {
    //   const { status: existingStatus } = await Notifications.getPermissionsAsync()
    //   let finalStatus = existingStatus
    //
    //   if (existingStatus !== 'granted') {
    //     const { status } = await Notifications.requestPermissionsAsync({
    //       ios: {
    //         allowAlert: true,
    //         allowBadge: true,
    //         allowSound: true,
    //         allowAnnouncements: true,
    //         allowDisplayInCarPlay: true
    //       }
    //     })
    //     finalStatus = status
    //   }
    //
    //   // Stop here if the user did not grant permissions
    //   if (finalStatus !== 'granted') {
    //     Toast.show({ text: 'Você não autorizou as notificações!', type: 'danger', duration: 15000 })
    //     return
    //   }
    //
    //   // Get the token that uniquely identifies this device
    //   let { data } = await Notifications.getExpoPushTokenAsync()
    //
    //   console.log('##################')
    //   console.log('TOKEN:', data)
    //   console.log('##################')
    //
    //   const device_data = await EventLogger.context()
    //   const installation_id = await getInstallationId()
    //
    //   const deviceToken = {
    //     'token': data,
    //     'token_type': 'Expo',
    //     'uuid': installation_id,
    //     'model': Constants.deviceName,
    //     'version': hasValue(device_data, 'app.app_version'),
    //     'platform': hasValue(device_data, 'device.os_name')
    //   }
    //
    //   postApi('resident/devices', deviceToken)
    //     .then((res) => {
    //     })
    //     .catch((err) => {
    //     })
    //
    //   if (Platform.OS === 'android') {
    //     await Notifications.setNotificationChannelAsync('default', {
    //       name: 'default',
    //       importance: Notifications.AndroidImportance.MAX,
    //       enableVibrate: true,
    //       showBadge: true,
    //       vibrationPattern: [0, 250, 250, 250]
    //     })
    //   }
    // } catch (e) {
    //   console.error(e)
    //   sdkSentry.sendSentry(e, null)
    // }
  },

  registerForNativePushNotificationsAsync: async () => {
    try {
      if (platformChecker.isWeb) return

      if (Constants.appOwnership === 'expo') {
        ToasterManager.error('Sem notificação nativa em modo Dev!')
        return
      }

      const { status: existingStatus } = await Notifications.getPermissionsAsync()
      let finalStatus = existingStatus

      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync({
          ios: {
            allowAlert: true,
            allowBadge: true,
            allowSound: true,
            allowAnnouncements: true
          }
        })
        finalStatus = status
      }

      // Stop here if the user did not grant permissions
      if (finalStatus !== 'granted') {
        ToasterManager.error('Você não autorizou as notificações!')
        return
      }

      let token_fcm = await Notifications.getDevicePushTokenAsync()

      console.log('##################')
      console.log('TOKEN FCM:', token_fcm)
      console.log('##################')

      if (token_fcm) {
        const device_data = await EventLogger.context()
        const installation_id = await getInstallationId()
        const bundler_identifier = getAppId()

        const deviceTokenFCM = {
          'token': token_fcm.data,
          'token_type': token_fcm.type,
          'uuid': installation_id,
          'model': Constants.deviceName,
          'bundler_identifier': bundler_identifier,
          'version': gv(device_data, 'app.app_version'),
          'platform': gv(device_data, 'device.os_name')
        }

        postApi('resident/devices', deviceTokenFCM)
          .then((res) => {
          })
          .catch((err) => {
          })
      }
    } catch (err) {
      console.error(err)
      sdkSentry.sendSentry(err, null)
    }
  },

  setNotificationHandle: () => {
    Notifications.setNotificationHandler({
      handleNotification: async (notification) => {
        let in_chat = await ZohoSalesIQ.isInChat()

        if (in_chat && notification?.request?.content?.data?.url === 'support_chat') {
          return {
            shouldShowAlert: false,
            shouldPlaySound: false,
            shouldSetBadge: false
          }
        } else {
          return {
            shouldShowAlert: true,
            shouldPlaySound: true,
            shouldSetBadge: true
          }
        }
      }
    })
  }
}

export default PushNotificationService