import {useFocusEffect, useNavigation} from '@react-navigation/native'
import React from 'react'
import {TouchableOpacity, View} from 'react-native'
import {useInfiniteQuery} from 'react-query'
import {AclRender} from '../../../components/acl/components'
import AppFab from '../../../components/layout/AppFab'
import AppFlatList from '../../../components/layout/AppFlatList'
import NotPermitted from '../../../components/NotPermitted'
import PlaceHolder from '../../../components/placeholder'
import {RootDrawerScreenProps} from '../../../navigation/types'
import {EventLogger} from '../../../services/EventLogger'
import {handleGetNextPageParam} from '../../../utils'
import {qs} from '../../queries'
import AdItemCard from './AdItemCard'

function AllAdsListTab() {
  const navigation = useNavigation<RootDrawerScreenProps<'ClassifiedListScreen'>['navigation']>()

  const {
    data,
    hasNextPage,
    isFetching,
    fetchNextPage,
    refetch,
    remove
  } = useInfiniteQuery('classifieds.allAds', async (_params) =>
      qs.classifieds.allAds(_params.pageParam ?? 1, 15),
    {
      // enabled: false,
      getNextPageParam: handleGetNextPageParam
    })

  useFocusEffect(
    React.useCallback(() => {
      // refetch()
      EventLogger.logEvent('SeeClassifiedListScreen', 'screen')
      // return () => { remove() }
    }, [])
  )

  const renderItem = ({ item }: { item: any }) => (
    <TouchableOpacity onPress={() => navigation.navigate('ClassifiedViewScreen', { item_id: item.id })}>
      <AdItemCard item={item} show_inactive_btn={false}/>
    </TouchableOpacity>
  )

  return (
    <View style={{ flex: 1 }}>
      <AclRender required={['classified.view_classified']} fallback={<NotPermitted/>}>
        <AppFlatList
          data={data?.pages.map(page => page.data).flat() ?? []}
          onEndReached={() => hasNextPage && fetchNextPage()}
          renderItem={renderItem}
          keyExtractor={item => item.id + ''}
          refreshing={isFetching}
          onRefresh={refetch}
          ListEmptyComponent={() => (
            <PlaceHolder
              visible={true}
              emoji={'😎'}
              description={'Aqui você pode pedir algo emprestado, anunciar vagas para aluguel e muito mais! Assim que você publica um anúncio todos os condôminos do seu condomínio recebe uma notificação. Clique no botão "+" para adicionar um anúncio!'}
            />
          )}
        />
      </AclRender>

      <AclRender required={['classified.add_classified']}>
        <AppFab icon="plus" onPress={() => navigation.navigate('ClassifiedNewScreen')}/>
      </AclRender>
    </View>
  )
}

export default AllAdsListTab